import React, { useState, useEffect } from "react";
import Input from "../../common/inputs/input";
import "./passwordInputs.scss";
import { passwordIsStrong } from "../../../core/validations";
import PassToolTipContent from "../common/passToolTipContent";
import { useTranslation } from "react-i18next";

const PasswordInputs = ({ oneColumn, onChange }) => {
  const [touched, setTouched] = useState({});
  const [passwordStrength, setPasswordStrength] = useState({});

  const { t } = useTranslation();
  const [value, setValue] = useState({
    password: "",
    password_repeat: "",
  });

  const requiredMsg = t("validation.required");
  const passMismatchMsg = t("validation.password-mismatch");
  const PassIsNotstrongMsg = t("validation.password-strength");

  const handleChange = (event) => {
    if (!touched[event.target.name])
      setTouched({ ...touched, [event.target.name]: true });

    if (event.target.name == "password")
      setPasswordStrength(passwordIsStrong(event.target.value));

    setValue({ ...value, [event.target.name]: event.target.value });

    let pass =
      event.target.name == "password" ? event.target.value : value.password;
    let passrepeate =
      event.target.name == "password_repeat"
        ? event.target.value
        : value.password_repeat;

    onChange({
      target: {
        name: "password",
        value: value.password,
        isValid: isValid(pass, passrepeate),
      },
    });
  };

  const isValid = (pass, passrepeate) => {
    return (
      pass.length >= 8 &&
      passwordStrength.meetedConditions == 4 &&
      pass == passrepeate
    );
  };
  return (
    <div className="padding-20 flex-column">
      <form>
        <div className="flex-item-col flex-column col-12">
          <Input
            error={
              touched.password &&
              (value.password === "" ||
                !passwordIsStrong(value.password).all ||
                (value.password &&
                  value.password_repeat &&
                  value.password_repeat != value.password))
            }
            helperText={
              !touched.password
                ? ""
                : value.password === ""
                ? requiredMsg
                : !passwordIsStrong(value.password).all
                ? PassIsNotstrongMsg
                : value.password &&
                  value.password_repeat &&
                  value.password_repeat != value.password
                ? passMismatchMsg
                : ""
            }
            onBlur={() => setTouched({ ...touched, password: true })}
            name={"password"}
            value={value.password}
            onChange={handleChange}
            label={t("personal-info.password") + "*"}
            type={"password"}
          />
          <div className="div-pass-strength">
            <hr
              style={{ opacity: 100 }}
              className={`hr-strength ${
                !passwordStrength.meetedConditions ||
                passwordStrength.meetedConditions == 0
                  ? ""
                  : passwordStrength.meetedConditions == 1
                  ? "pred"
                  : passwordStrength.meetedConditions != 4
                  ? "porange"
                  : "pgreen"
              }`}
            ></hr>
            <hr
              style={{ opacity: 100 }}
              className={`hr-strength ${
                !passwordStrength.meetedConditions ||
                passwordStrength.meetedConditions <= 1
                  ? ""
                  : passwordStrength.meetedConditions != 4
                  ? "porange"
                  : "pgreen"
              }`}
            ></hr>
            <hr
              style={{ opacity: 100 }}
              className={`hr-strength ${
                !passwordStrength.meetedConditions ||
                passwordStrength.meetedConditions <= 2
                  ? ""
                  : passwordStrength.meetedConditions != 4
                  ? "porange"
                  : "pgreen"
              }`}
            ></hr>
            <hr
              style={{ opacity: 100 }}
              className={`hr-strength ${
                !passwordStrength.meetedConditions ||
                passwordStrength.meetedConditions != 4
                  ? ""
                  : "pgreen"
              }`}
            ></hr>
          </div>
        </div>
        <div className="flex-item-col col-12" style={{ paddingTop: 5 }}>
          <Input
            error={
              touched.password_repeat &&
              (value.password_repeat === "" ||
                (value.password_repeat &&
                  value.password &&
                  value.password_repeat != value.password))
            }
            helperText={
              !touched.password_repeat
                ? ""
                : value.password_repeat === ""
                ? requiredMsg
                : value.password_repeat &&
                  value.password &&
                  value.password_repeat != value.password
                ? passMismatchMsg
                : ""
            }
            onBlur={() => setTouched({ ...touched, password_repeat: true })}
            name={"password_repeat"}
            value={value.password_repeat}
            onChange={handleChange}
            label={t("personal-info.password-repeat") + "*"}
            type={"password"}
          />
        </div>
        <PassToolTipContent password={value.password}></PassToolTipContent>
      </form>
    </div>
  );
};

export default PasswordInputs;
