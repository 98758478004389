import React, { useState, useEffect } from "react";
import { useApi } from "../../../core/hooks/useApi";
import { BASE_URL } from "../../../core/apiconfig/environment";
import { useSnackbar } from "notistack";
import Button from "../../common/buttons/button";
import { useNavigate } from "react-router-dom";
import "../registration/registration.scss";
import { useParams } from "react-router-dom";
import { URL, ERROR_CONTACT_US } from "../../../core/constants";
import PasswordInputs from "../common/passwordInputs";
import LoadingOverlay from "../../common/LoadingOverlay/LoadingOverlay";
import { resetCache } from "../../../core/utils/cachManager";
import { useTranslation } from "react-i18next";

const DefinePass = ({ needEmailVerification, title, subTitle, buttonTxt }) => {
  const { post } = useApi();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const token = useParams().token;
  const contactId = useParams().contactid;
  const [loading, setLoading] = useState(false);

  const { t } = useTranslation();

  const [touched, setTouched] = useState({});
  const [passwordIsValid, setPasswordIsValid] = useState(false);

  const [value, setValue] = useState({
    password: "",
    password_repeat: "",
  });

  useEffect(() => {
    resetCache();
    checkTokenValidity();
  }, []);

  useEffect(() => {}, [passwordIsValid]);

  const handleChange = (event) => {
    if (!touched[event.target.name])
      setTouched({ ...touched, [event.target.name]: true });

    if (event.target.name == "password")
      setPasswordIsValid(event.target.isValid);

    setValue({ ...value, [event.target.name]: event.target.value });
  };

  const checkTokenValidity = async () => {
    let tokenObj = {
      token: token,
      contactId: contactId,
    };

    setLoading(true);
    let result = await post(`${BASE_URL}user/password/forgot/check`, tokenObj);
    setLoading(false);

    if (result?.status != 200) {
      enqueueSnackbar(
        t("registration.set-password.error-token-invalid") + ERROR_CONTACT_US,
        { variant: "error", autoHideDuration: 20000 }
      );
      navigate(URL.LOGIN);
    }
  };

  const passChanged = async () => {
    let passObj = {
      token: token,
      contactId: contactId,
      password: value.password,
      confirmPassword: value.password,
    };

    setLoading(true);
    let result = await post(`${BASE_URL}user/password/forgot/renew`, passObj);
    setLoading(false);

    if (result && result.status != 200 && result.status != 201) {
      enqueueSnackbar(
        result.statusText + ERROR_CONTACT_US,
        { variant: "error", autoHideDuration: 20000 }
      );
      return false;
    } else if (result && (result.status == 200 || result.status == 201)) {
      enqueueSnackbar(
        t("registration.set-password.success"),
        {
          variant: "success",
          autoHideDuration: 3000,
        }
      );
      return true;
    } else return false;
  };

  const handleSubmit = async () => {
    if (!formIsValid()) return;

    let success = await passChanged();
    if (success) {
      navigate(URL.LOGIN + "/first");
    }
  };

  const formIsValid = () => {
    return passwordIsValid;
  };
  return (
    <LoadingOverlay show={loading}>
      <div className="box-wrapper">
        <span className="box-title">{title}</span>
        <span className="step">{subTitle}</span>
        <div className="box">
          <span>{t("registration.set-password.instructions1")}</span>
          <span>{t("registration.set-password.instructions2")}</span>
          <br></br>
          <PasswordInputs
            oneColumn={true}
            onChange={handleChange}
          ></PasswordInputs>
          <div className="padding-50 " style={{ paddingTop: 22 }}>
            <Button
              disabled={!formIsValid()}
              className="form-item"
              onClick={handleSubmit}
            >
              {buttonTxt}
            </Button>
          </div>
        </div>
      </div>
    </LoadingOverlay>
  );
};

export default DefinePass;

