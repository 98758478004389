import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";

import MdArrowBackIosNew from "@mui/icons-material/ArrowBackIosNew";
import Datepicker from "../common/inputs/datepicker";
import { useApi } from "../../core/hooks/useApi";
import { BASE_URL } from "../../core/apiconfig/environment";
import { Input } from "../common/inputs";
import { useParams } from "react-router";
import CustomerCommissionList from "./customerCommissionList";
import { useNavigate } from "react-router-dom";
import CustomerStorageList from "./customerStorageList";
import { URL } from "../../core/constants";
import { useTranslation } from "react-i18next";
import switchToContact from "./switchToContact";

function CustomerProfile() {
  const id = useParams().id;
  const { get } = useApi();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [value, setValue] = useState({
    id: null,
    firstname: "",
    lastname: "",
    fax: "",
    websiteurl: "",
    telephone1: "",
    mobilephone: "",
    address1_name: "",
    address1_city: "",
    address1_line1: "",
    address1_line2: "",
    address1_line3: "",
    address1_postalcode: "",
    emailaddress1: "",
    arades_anrede: "",
    arades_titel: "",
    arades_land: "",
    birthdate: null,
    arades_alias: "",
    arades_ratior_key: "",
    vermittlerId: "",
  });

  useEffect(() => {
    loadPage();
  }, []);

  const loadPage = async () => {
    setContact();
  };

  const setEmptyIfNull = (str) => {
    return str ? str : " ";
  };
  const setContact = async () => {
    const isContactResult = await get(`${BASE_URL}data/contacts/is-contact/${id}/`);
    const isContact = isContactResult?.isContact;
    let result;
    if (isContact) {
      result = await get(`${BASE_URL}data/contacts/${id}/`);
    } else {
      result = await get(`${BASE_URL}data/accounts/${id}/`);
    }
    const contactOrAccount = result.data;
    setValue({
      ...value,
      id: contactOrAccount.contactid || contactOrAccount.accountid,
      isContact: isContact,
      firstname: setEmptyIfNull(contactOrAccount.firstname),
      lastname: setEmptyIfNull(contactOrAccount.lastname),
      telephone1: setEmptyIfNull(contactOrAccount.telephone1),
      mobilephone: setEmptyIfNull(contactOrAccount.mobilephone),
      address1_name: setEmptyIfNull(contactOrAccount.address1_name),
      address1_line1: setEmptyIfNull(contactOrAccount.address1_line1),
      address1_postalcode: setEmptyIfNull(contactOrAccount.address1_postalcode),
      emailaddress1: setEmptyIfNull(contactOrAccount.emailaddress1),
      arades_anrede: setEmptyIfNull(contactOrAccount.arades_anrede),
      arades_anrede_title: setEmptyIfNull(
        contactOrAccount["arades_anrede@OData.Community.Display.V1.FormattedValue"]
      ),
      arades_titel: setEmptyIfNull(
        contactOrAccount[
          "_arades_titel_value@OData.Community.Display.V1.FormattedValue"
        ]
      ),
      arades_land: setEmptyIfNull(
        contactOrAccount[
          "_arades_land1_value@OData.Community.Display.V1.FormattedValue"
        ]
      ),
      birthdate: contactOrAccount.birthdate,
      arades_alias: setEmptyIfNull(contactOrAccount.arades_alias),
      arades_ratior_key: setEmptyIfNull(contactOrAccount.arades_ratior_key),
      address1_country: setEmptyIfNull(contactOrAccount.address1_country),
      address1_city: setEmptyIfNull(contactOrAccount.address1_city),
      vermittlerId: contactOrAccount._arades_vermittler_value,
    });
  };

  return (
    <div className="container-card">
      <Box sx={{ maxWidth: "1536px" }}>
        {/* Header  */}
        <Box
          sx={{
            marginBottom: "32px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "15px",
            }}
          >
            <IconButton
              sx={{ fontSize: "15px", color: "#000" }}
              aria-label="back"
              onClick={() => navigate(URL.CUSTOMER)}
            >
              <MdArrowBackIosNew />
            </IconButton>
            <Typography sx={{ fontWeight: 500 }} variant="h5" component="h5">
              {value.firstname + " " + value.lastname}
            </Typography>
          </Box>
          <Button
            className="btn-center-text btn-theme-letter-space btn-color-blue "
            color="primary"
            sx={{ fontWeight: 400 }}
            variant="contained"
            onClick={() => switchToContact(value.id, (value.firstname || "") + " " + (value.lastname || ""), value.vermittlerId, navigate)}
          >
            {t("customer-profile.view-dashboard")}
          </Button>
        </Box>

        <Grid container spacing={8}>
          {/* Form */}
          <Grid container item spacing={6} sm={12} md={5} lg={7}>
            <Grid xs={12} lg={6} item>
              <Input
                disabled
                label={t("personal-info.gender")}
                value={
                  value.arades_anrede_title ? value.arades_anrede_title : " "
                }
              />
            </Grid>
            <Grid xs={12} lg={6} item>
              <Input
                disabled
                label={t("personal-info.title")}
                value={value.arades_titel}
              />
            </Grid>
            <Grid xs={12} lg={6} item>
              <Input
                disabled
                label={t("personal-info.first-name")}
                value={value.firstname}
              />
            </Grid>
            <Grid xs={12} lg={6} item>
              <Input
                disabled
                label={t("personal-info.last-name")}
                value={value.lastname}
              />
            </Grid>
            <Grid xs={12} lg={6} item>
              <Input
                disabled
                label={t("personal-info.alias")}
                value={value.arades_alias}
              />
            </Grid>
            <Grid xs={12} lg={6} item>
              <Datepicker
                disabled={true}
                value={value.birthdate}
                label={t("personal-info.birth-date") + "*"}
              />
            </Grid>
            <Grid xs={12} lg={6} item>
              <Input
                disabled
                label={t("personal-info.email-address")}
                value={value.emailaddress1}
              />
            </Grid>
            <Grid xs={12} lg={6} item>
              <Input
                disabled
                label={t("personal-info.mobile")}
                value={value.mobilephone}
              />
            </Grid>
            <Grid xs={12} lg={6} item>
              <Input
                disabled
                label={t("personal-info.ratior-key")}
                value={value.arades_ratior_key}
              />
            </Grid>
            <Grid xs={12} item>
              <Divider
                sx={{
                  marginTop: "20px",
                  color: "rgba(0, 0, 0, 0.38)",
                  "&::before": {
                    width: 0,
                  },
                }}
                textAlign="left"
              >
                {t("address.address")}
              </Divider>
            </Grid>
            <Grid xs={12} item>
              <Input
                disabled
                label={t("address.country")}
                value={value.arades_land}
              />
            </Grid>
            <Grid xs={12} item>
              <Input
                disabled
                label={t("address.house-no-and-street") + "*"}
                value={value.address1_line1}
              />
            </Grid>
            <Grid xs={12} lg={6} item>
              <Input
                disabled
                label={t("address.postal-code")}
                value={value.address1_postalcode}
              />
            </Grid>
            <Grid xs={12} lg={6} item>
              <Input
                disabled
                label={t("address.city")}
                value={value.address1_city}
              />
            </Grid>
            <Grid xs={12} item>
              <Input
                disabled
                label={t("address.line2")}
                value={value.address1_line2}
              />
            </Grid>
          </Grid>

          {/* Table */}
          <Grid item sm={12} md={7} lg={5}>
            <Box>
              {value.id && /* Only add component after load to know if the customer is a contact or account */
                <CustomerCommissionList customerId={id} isContact={value.isContact}></CustomerCommissionList>
              }
            </Box>
            <Box sx={{ mt: "24px" }}>
              {value.id && /* Only add component after load to know if the customer is a contact or account */
                <CustomerStorageList customerId={id} isContact={value.isContact}></CustomerStorageList>
              }
            </Box>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}

export default CustomerProfile;
