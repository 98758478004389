import { configureStore } from "@reduxjs/toolkit";
import underageReducer from "./slices/underage/underage.slice";
import storageReducer from "./slices/storage/storage.slice";
import transferReducer from "./slices/transfer/transfer.slice";
import sellReducer from "./slices/sell/sell.slice";
import localeReducer from "./slices/locale/locale.slice";
import exchangeReducer from "./slices/exchange/exchange.slice";
import profileReducer from "./slices/profile/profile.slice";
import globalsReducer from "./slices/globals/globals.slice";
import authReducer from "./slices/auth/auth.slice";
import contractReducer from "./slices/contract/contract.slice";
import storageListReducer from "./slices/storageList/storageList.slice";
import priceListReducer from "./slices/priceList/priceList.slice";
import clientDashboardReducer from "./slices/clientDashboard/clientDashboard.slice";
import vermittlerDashboardReducer from "./slices/vermittlerDashboard/vermittlerDashboard.slice";
import vermittlerListsReducer from "./slices/vermittler/vermittlerLists.slice";
import powerOfAttorneyReducer from "./slices/powerOfAttorney/powerOfAttorney.slice";
import inboxReducer from "./slices/inbox/inbox.slice";
import becomeSellerReducer from "./slices/becomeSeller/becomeSeller.slice";
import customerListReducer from "./slices/customerList/customerList.slice";
import commissionsReducer from "./slices/commissions/commissions.slice";
import myStructureReducer from "./slices/myStructure/myStructure.slice";
import transactionListReducer from "./slices/transactionList/transactionList.slice";

const store = configureStore({
  reducer: {
    globals: globalsReducer,
    auth: authReducer,
    locale: localeReducer,
    clientDashboard: clientDashboardReducer,
    vermittlerDashboard: vermittlerDashboardReducer,
    underage: underageReducer,
    storage: storageReducer,
    transfer: transferReducer,
    exchange: exchangeReducer,
    sell: sellReducer,
    profile: profileReducer,
    contract: contractReducer,
    storageList: storageListReducer,
    priceList: priceListReducer,
    vermittlerLists: vermittlerListsReducer,
    powerOfAttorney: powerOfAttorneyReducer,
    inbox: inboxReducer,
    becomeSeller: becomeSellerReducer,
    customerList: customerListReducer,
    commissions: commissionsReducer,
    myStructure: myStructureReducer,
    transactionList: transactionListReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export default store;
