import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../../../core/apiconfig/environment";
import {
  TOKEN_NAME,
  WORKSPACE_ID,
  WORKSPACE_TYPE,
} from "../../../core/apiconfig/constantApi";
import {
  WORKSPACETYPE,
} from "../../../core/constants";

const postSellInfoAction = createAsyncThunk(
  "sell/postSellInfo",
  async (dataParams, { getState }) => {
    const storageBankAccountData = getState().sell.storageBankAccount;
    const fromStorage = getState().sell.sellSource.fromStorage;
    //const ratiorStorages = getState().sell.globalData.ratiorStorages;
    const enterAmountData = getState().sell.enterAmount;
    const enterDescriptionData = getState().sell.enterDescription;

    if (!fromStorage.arades_Edelmetall_Typ?._defaultuomid_value)
      throw Error("Es wurde keine Gewichtseinheit zum Produkt angegeben"); //("There is no Weight unit set for product");

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${localStorage.getItem(TOKEN_NAME)}`,
      },
    };

    const workspaceId = localStorage.getItem(WORKSPACE_ID);
    const workspaceType = localStorage.getItem(WORKSPACE_TYPE);

    const quoteObj = {
      description: enterDescriptionData.description,
    };

    let result;
    if (workspaceType === WORKSPACETYPE.CONTACT || workspaceType === WORKSPACETYPE.UNDER_AGE) {
      result = await axios.post(`${BASE_URL}data/quotes/sell/contact/${ workspaceId }/warehouse/${ fromStorage.msdyn_warehouseid }/konto/${ storageBankAccountData.selectedBankAccount }/?amountGrams=${ enterAmountData.amountPerGram }`, quoteObj, config);
    } else {
      result = await axios.post(`${BASE_URL}data/quotes/sell/account/${ workspaceId }/warehouse/${ fromStorage.msdyn_warehouseid }/konto/${ storageBankAccountData.selectedBankAccount }/?amountGrams=${ enterAmountData.amountPerGram }`, quoteObj, config);
    }

    if (result?.data?.status != 200 && result?.data?.status != 201)
      throw Error("Angebot wurde nicht gespeichert"); //("Quote not saved");
  }
);

export default postSellInfoAction;
