import React, { useState, useEffect } from "react";
import { Input, ConfirmModal, LoadingOverlay, Select } from "../common";
import { Box, Button, Tab, Grid, Typography } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { useParams } from "react-router";
import { useApi } from "../../core/hooks/useApi";
import { useSnackbar } from "notistack";
import { BASE_URL } from "../../core/apiconfig/environment";
import { WORKSPACE_ID, WORKSPACE_TYPE } from "../../core/apiconfig/constantApi";
import { useNavigate } from "react-router-dom";
import { URL, WORKSPACETYPE } from "../../core/constants";
import MUIButtom from "@mui/material/Button";
import { globalsBankCurrencies } from "../../redux/slices/globals/globals.slice";
import { useSelector } from "react-redux";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useTranslation } from "react-i18next";

const BankAccountAdd = () => {
  const { patch, post, get } = useApi();
  const navigate = useNavigate();
  const id = useParams().id;
  const type = useParams().type;
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const bankCurrencies = useSelector(globalsBankCurrencies);

  const [ibanValidationModalOpen, setIbanValidationModalOpen] = useState(false);
  const [ibanValidations, setIbanValidations] = useState("");
  const [loading, setLoading] = useState(false);
  const [selectedTab, setSelectedTab] = useState(type);
  const [accountValue, setAccountValue] = useState();
  const [ibanValid, setIbanValid] = useState(false);
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);

  const [defaultFields, setDefaultFields] = useState({
    arades_kontoinhaber: "",
    arades_waehrung: "",
    arades_iban: "",
    arades_bic: "",
    arades_bank: "",
    arades_kontonummer: "",
    arades_bankleitzahl: "",
  });

  const requiredMsg = t("validation.required");

  const setBank = async () => {
    const result = await get(`${BASE_URL}data/kontos/${id}/`);
    if (result.data) {
      const banckAccount = result.data;
      setValue("arades_kontoinhaber", banckAccount.arades_kontoinhaber);
      setValue("arades_waehrung", banckAccount.arades_waehrung ?? "");
      setValue("arades_iban", banckAccount.arades_iban);
      setValue("arades_bic", banckAccount.arades_bic);
      setValue("arades_bank", banckAccount.arades_bank);
      setValue("arades_kontonummer", banckAccount.arades_kontonummer);
      setValue("arades_bankleitzahl", banckAccount.arades_bankleitzahl);
      if (banckAccount.arades_kontonummer) setSelectedTab("2");
      else setSelectedTab("1");
    }
  };

  const onSubmit = async (value) => {
    setAccountValue(value);
    //if (selectedTab == "1") await IBANIsValid (value.arades_iban, true);
    //else
    await saveBank(value);
  };

  const saveBank = async (value) => {
    try {
      let result = null;
      if (id) {
        setLoading(true);
        result = await patch(`${BASE_URL}data/kontos/${id}`, value);
        setLoading(false);
      } else {
        if (!value.arades_kontonummer) delete value.arades_kontonummer;
        if (!value.arades_waehrung) delete value.arades_waehrung;

        setLoading(true);
        const workspaceType = localStorage.getItem(WORKSPACE_TYPE);
        if (workspaceType === WORKSPACETYPE.CONTACT || workspaceType === WORKSPACETYPE.UNDER_AGE) {
          result = await post(`${BASE_URL}data/kontos/contact/${localStorage.getItem(WORKSPACE_ID)}/`, value);
        } else {
          result = await post(`${BASE_URL}data/kontos/account/${localStorage.getItem(WORKSPACE_ID)}/`, value);
        }
        setLoading(false);
      }
      if (result.status == 200 || result.status == 201) {
        enqueueSnackbar("Die Änderungen wurden gespeichert", {
          variant: "success",
          autoHideDuration: 3000,
        });
        navigate(URL.BANKACCOUNT);
      }
    } catch (error) {
      setLoading(false);
      console.log(`error`, error);
    }
  };

  const deactivateBank = async () => {
    setLoading(true);
    const result = await patch(`${BASE_URL}data/kontos/deactivate/${id}`, {});
    setLoading(false);
    if (result.status == 200) {
      enqueueSnackbar(t("bank-account.delete-success"), {
        variant: "success",
        autoHideDuration: 3000,
      });
      navigate(URL.BANKACCOUNT);
    }
  };

  const IBANIsValid = async (iban, checkMode) => {
    setLoading(true);
    const result = await get(`${BASE_URL}iban/check?iban=${iban}`);
    setLoading(false);
    if (result?.data?.errors?.length > 0) {
      setIbanValidationModalOpen(true);
      setIbanValidations({ error: result?.data?.errors[0]?.message });
      setIbanValid(false);
      return false;
    } else if (
      result?.status == 200 &&
      result?.data?.bank_data?.bic &&
      result?.data?.bank_data?.bank &&
      result?.data?.bank_data?.country &&
      result?.data?.bank_data?.country_iso &&
      result?.data?.bank_data?.account &&
      result?.data?.bank_data?.bank_code
    ) {
      setValue("arades_bic", result.data.bank_data.bic);
      setValue("arades_bank", result.data.bank_data.bank);
      if (checkMode) {
        setIbanValidationModalOpen(true);
        setIbanValidations({ IbanInfo: result?.data?.bank_data });
      }
      setIbanValid(true);
      return true;
    } else {
      setIbanValidationModalOpen(true);
      setIbanValidations(result?.data?.validations);
      setIbanValid(false);
      return false;
    }
  };

  useEffect(() => {
    if (id) setBank();
  }, []);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const schema = yup.object().shape({
    arades_kontoinhaber: yup.string().required(requiredMsg),
    arades_waehrung: yup.string().required(requiredMsg),
    arades_iban:
      selectedTab == "1"
        ? yup.string().required(requiredMsg)
        : yup.string().nullable(),
    arades_kontonummer:
      selectedTab == "2"
        ? yup
            .number()
            .required(requiredMsg)
            .typeError(t("validation.bank-account-number"))
        : yup.string().nullable(),
    arades_bankleitzahl:
      selectedTab == "2"
        ? yup.string().required(requiredMsg)
        : yup.string().nullable(),
  });

  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    watch,
    formState: { errors },
  } = useForm({
    mode: "all",
    defaultValues: {
      ...defaultFields,
    },
    resolver: yupResolver(schema),
  });
  return (
    <LoadingOverlay show={loading}>
      <div className="container-card">
        <ConfirmModal
          open={ibanValidationModalOpen}
          showCloseButton={false}
          title={
            ibanValidations?.IbanInfo
              ? t("bank-account.iban-valid")
              : t("bank-account.iban-result")
          }
          message={
            <div>
              {ibanValidations?.error && <div>{ibanValidations?.error}</div>}
              {!ibanValidations?.error && !ibanValidations?.IbanInfo && (
                <div>
                  {ibanValidations?.chars?.message}
                  <br></br>
                  {ibanValidations?.account?.message}
                  <br></br>
                  {ibanValidations?.iban?.message}
                  <br></br>
                  {ibanValidations?.structure?.message}
                  <br></br>
                  {ibanValidations?.length?.message}
                  <br></br>
                  {ibanValidations?.country_support?.message}
                  <br></br>
                </div>
              )}
              {ibanValidations?.IbanInfo && (
                <div>
                  BIC:&nbsp;{ibanValidations?.IbanInfo.bic}
                  <br></br>
                  BANK:&nbsp;{ibanValidations?.IbanInfo.bank}
                  <br></br>
                  BANK CODE:&nbsp;{ibanValidations?.IbanInfo.bank_code}
                  <br></br>
                  ACCOUNT:&nbsp;{ibanValidations?.IbanInfo.account}
                  <br></br>
                  COUNTRY:&nbsp;{ibanValidations?.IbanInfo.country}
                  <br></br>
                </div>
              )}
            </div>
          }
          noImage={true}
          okTitle={"Ok"}
          onOk={() => {
            //if (ibanValid) saveBank(accountValue);
            setIbanValidationModalOpen(false);
          }}
        ></ConfirmModal>
        <ConfirmModal
          open={confirmDeleteOpen}
          title={t("bank-account.delete-title")}
          message={
            <div>{t("bank-account.delete-message")}</div>
          }
          showCloseButton={true}
          noImage={true}
          acceptTitle={t("bank-account.delete")}
          rejectTitle={t("buttons.cancel")}
          onAccept={() => {
            deactivateBank();
            setConfirmDeleteOpen(false);
          }}
          onReject={() => setConfirmDeleteOpen(false)}
          onClose={() => setConfirmDeleteOpen(false)}
        ></ConfirmModal>
        <Typography
          sx={{ fontWeight: "500", fontSize: "24px", marginBottom: "20px" }}
        >
          {t("bank-account.bank-account")}
        </Typography>
        <Box sx={{ width: "100%", typography: "body1" }}>
          <TabContext value={selectedTab}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList onChange={handleTabChange} aria-label="tabs">
                <Tab label="IBAN" value="1" />
                <Tab label={t("bank-account.without-iban")} value="2" />
              </TabList>
            </Box>
            <TabPanel value="1">
              <Grid spacing={8} sx={{ marginTop: 1 }} container direction="row">
                <Grid xs={12} lg={6} item>
                  <Input
                    error={Boolean(errors.arades_kontoinhaber)}
                    helperText={errors.arades_kontoinhaber?.message}
                    control={control}
                    name={"arades_kontoinhaber"}
                    label={t("bank-account.owner") + "*"}
                  />
                </Grid>
                {ibanValid && (
                  <Grid xs={12} lg={6} item>
                    <Input
                      disabled={true}
                      control={control}
                      name={"arades_bank"}
                      label={t("bank-account.bank")}
                    />
                  </Grid>
                )}
                <Grid xs={12} lg={6} item>
                  <Input
                    error={Boolean(errors.arades_iban)}
                    helperText={errors.arades_iban?.message}
                    control={control}
                    name={"arades_iban"}
                    label={"IBAN*"}
                  />
                  {watch("arades_iban") && (
                    <MUIButtom
                      variant="text"
                      onClick={() => IBANIsValid(watch("arades_iban"), true)}
                      sx={{
                        color: "info.main",
                        cursor: "pointer",
                      }}
                    >
                      {t("bank-account.check-iban")}
                    </MUIButtom>
                  )}
                </Grid>

                {ibanValid && (
                  <Grid xs={12} lg={6} item>
                    <Input
                      disabled={true}
                      name={"arades_bic"}
                      control={control}
                      label={"BIC"}
                    />
                  </Grid>
                )}

                {ibanValid && (
                  <Grid xs={12} lg={6} item>
                    <Select
                      name={"arades_waehrung"}
                      control={control}
                      error={Boolean(errors.arades_waehrung)}
                      helperText={errors.arades_waehrung?.message}
                      options={[
                        { key: "", value: t("bank-account.select") },
                      ].concat(bankCurrencies)}
                      label={t("bank-account.currency") + "*"}
                    />
                  </Grid>
                )}
              </Grid>
            </TabPanel>
            <TabPanel value="2">
              <Grid spacing={8} sx={{ marginTop: 1 }} container direction="row">
                <Grid xs={12} lg={6} item>
                  <Input
                    error={Boolean(errors.arades_kontoinhaber)}
                    helperText={errors.arades_kontoinhaber?.message}
                    control={control}
                    name={"arades_kontoinhaber"}
                    label={t("bank-account.owner") + "*"}
                  />
                </Grid>
                <Grid xs={12} lg={6} item>
                  <Input
                    control={control}
                    name={"arades_bank"}
                    label={t("bank-account.bank")}
                  />
                </Grid>
                <Grid xs={12} lg={6} item>
                  <Input
                    error={Boolean(errors.arades_kontonummer)}
                    helperText={errors.arades_kontonummer?.message}
                    control={control}
                    name={"arades_kontonummer"}
                    label={t("bank-account.account-number") + "*"}
                  />
                </Grid>
                <Grid xs={12} lg={6} item>
                  <Input
                    error={Boolean(errors.arades_bankleitzahl)}
                    helperText={errors.arades_bankleitzahl?.message}
                    control={control}
                    name={"arades_bankleitzahl"}
                    label={t("bank-account.bank-code") + "*"}
                  />
                </Grid>
                <Grid xs={12} lg={6} item>
                  <Select
                    name={"arades_waehrung"}
                    control={control}
                    error={Boolean(errors.arades_waehrung)}
                    helperText={errors.arades_waehrung?.message}
                    options={[
                      { key: "", value: t("bank-account.select") },
                    ].concat(bankCurrencies)}
                    label={t("bank-account.currency") + "*"}
                  />
                </Grid>
              </Grid>
            </TabPanel>
          </TabContext>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "end", marginTop: 20 }}>
          {(id && 
            <Button
              onClick={() => setConfirmDeleteOpen(true)}
              variant="contained"
              style={{ marginRight: "4px" }}
            >
              {t("bank-account.delete")}
            </Button>
          )}
          <Button
            disabled={!ibanValid && selectedTab == "1"}
            onClick={handleSubmit(onSubmit)}
            fullWidth
            sx={{ bgcolor: "info.main", maxWidth: "192px" }}
            variant="contained"
          >
            {t("buttons.save")}
          </Button>
        </Box>
      </div>
    </LoadingOverlay>
  );
};

export default BankAccountAdd;
