import axios from "axios";
import { BASE_URL } from "../../../core/apiconfig/environment";
import { TOKEN_NAME } from "../../../core/apiconfig/constantApi";

const getOwnerByStorageIdFunc = async (storageId, { getState }) => {
  try {
    console.log(storageId, "storageId");
    return { firstname: "Babak" };
  } catch (err) {
    throw Error(
      err.response && err.response.data.detail
        ? err.response.data.detail
        : err.message
    );
  }
};

export default getOwnerByStorageIdFunc;
