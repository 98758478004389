import React, {useState,useEffect } from "react";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import {IconButton,Paper,InputBase} from "@mui/material";
import { SixKPlus } from "@mui/icons-material";
const Input = ({control=null,sx,onChange,...props})=>{
  
  const [copyFieldValue, setCopyFieldValue] = useState(props.value);


  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(copyFieldValue)
  };

  useEffect(()=>{
    if(props.value){
      setCopyFieldValue(props.value)
    }
  },[props.value])

  return (

        <>

        <Paper
          sx={{ 
            p: '1px 4px',
            display: 'flex',
            margin:'0',
            alignItems: 'center', 
            width: 'auto',
            boxShadow:'none',
            border:'1px solid rgba(0, 0, 0, 0.12)',
            ...sx
          }}
        >
        <InputBase
         {...props}
         fullWidth
          sx={{ flex: 1 }}

          onChange={event=>{
            setCopyFieldValue(event.target.value)
            onChange(event)
          }}
        />
        <IconButton onClick={handleCopyToClipboard} sx={{ p: '10px' }} aria-label="search">
          <ContentCopyIcon  />
        </IconButton>

        </Paper>
      </>
  
       
   );
};

export default Input;
