import React, { useState, useEffect } from "react";
import { useApi } from "../../../../core/hooks/useApi";
import SelectOrAddBankAccount from "../../../common/selectOrAddBankAccount";
import { Typography, Grid, Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  storageBankAccountStep,
  setBankAccountStep,
} from "../../../../redux/slices/storage/storage.slice";

const BankAccountStep = ({ handleCurrentStep }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const bankAccountInfo = useSelector(storageBankAccountStep);

  const onSubmit = async (accountId) => {
    dispatch(setBankAccountStep({ currentBankAccount: accountId }));
    handleCurrentStep("next");
  };
  const onSkip = () => {
    handleCurrentStep("next");
  };
  return (
    <Box>
      <Grid container spacing={8}>
        <Grid xs={12} item>
          <Typography
            sx={{
              fontWeight: 500,
              color: "ragba(0,0,0,0.67)",
              fontSize: 14,
            }}
          >
            {t("messages.bank-account-attention")}
          </Typography>
        </Grid>
        <Grid xs={12} item>
          <SelectOrAddBankAccount
            selectedBankAccount={bankAccountInfo.currentBankAccount}
            onBankAccountSelected={onSubmit}
            confirmButtonText={t("buttons.next")}
            secoundButtonText={t("buttons.later")}
            onClickSecoundButton={onSkip}
          ></SelectOrAddBankAccount>
        </Grid>
      </Grid>
    </Box>
  );
};

export default BankAccountStep;
