import React, { useEffect, useState } from "react";
import { Box, Button, Typography, Grid } from "@mui/material";
import {
  FormSteperStructure,
  ConfirmModal,
  LoadingOverlay,
  DownloadGroup,
  MUICheckBox,
} from "../../common";
import { useSnackbar } from "notistack";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  getDocumentsAction,
  postSellerInfoAction,
  signSellerContractWorkFlowAction,
  verificationSendSMSCodeAction,
} from "../../../redux/slices/becomeSeller";
import {
  globalData,
  resetActionStatus,
  resetError,
} from "../../../redux/slices/becomeSeller/becomeSeller.slice";
import { URL, ERROR_CONTACT_US } from "../../../core/constants";
import blueCircle from "../../../assets/images/blueCircle.svg";
import {
  WORKSPACE_ID,
  MOBILE_NUMBER,
} from "../../../core/apiconfig/constantApi";
import { useTranslation } from "react-i18next";
import { BYPASS_ADOBE_SIGN } from "../../../core/apiconfig/environment";

function SignContractStep({ handleCurrentStep, justSign }) {
  const becomeSellerGlobalData = useSelector(globalData);

  const [loading, setLoading] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [confirmSuccessOpen, setConfirmSuccessOpen] = useState(false);
  const [signLater, setSignLater] = useState(false);
  const [agreement, setAgreement] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(getDocumentsAction());
  }, []);

  const onSubmit = (skipSign) => {
    dispatch(resetActionStatus());
    if (!justSign) dispatch(postSellerInfoAction());
    else if (skipSign) navigate(URL.DASHBOARD);
    else setOpenConfirm(true);

    setSignLater(skipSign);
  };

  useEffect(() => {
    if (
      becomeSellerGlobalData.actionStatus &&
      becomeSellerGlobalData.actionType == "postSellerInfo"
    ) {
      dispatch(resetActionStatus());
      if (signLater) navigate(URL.DASHBOARD);
      else setOpenConfirm(true);
    } else if (
      becomeSellerGlobalData.actionStatus &&
      becomeSellerGlobalData.actionType == "postSellerContractInfo"
    ) {
      dispatch(resetActionStatus());
      setOpenConfirm(false);
      setConfirmSuccessOpen(true);
    }
  }, [becomeSellerGlobalData.actionStatus]);

  useEffect(() => {
    if (becomeSellerGlobalData.error != null) {
      enqueueSnackbar(becomeSellerGlobalData.error + ERROR_CONTACT_US, {
        variant: "error",
        autoHideDuration: 20000,
      });
      dispatch(resetError());
    }
  }, [becomeSellerGlobalData.error]);

  useEffect(() => {
    setLoading(becomeSellerGlobalData.loading);
  }, [becomeSellerGlobalData.loading]);

  return (
    <LoadingOverlay show={loading}>
      <ConfirmModal
        open={openConfirm}
        size="md"
        title={t("become-seller.sign-contracts")}
        imageSrc={blueCircle}
        showCloseButton={false}
        message={
          <div>
            {t("become-seller.sign-modal-1")}
            <br></br>
            {t("become-seller.sign-modal-2")}
            <br></br>
            {t("become-seller.sign-modal-3")}
          </div>
        }
        acceptTitle={t("buttons.send-email")}
        onAccept={() => {
          dispatch(
            signSellerContractWorkFlowAction({
              ownerId: localStorage.getItem(WORKSPACE_ID),
            })
          );
        }}
        rejectTitle={t("buttons.sign-later")}
        onReject={() => {
          setOpenConfirm(false);
          navigate(URL.DASHBOARD);
        }}
      ></ConfirmModal>
      <ConfirmModal
        open={confirmSuccessOpen}
        title={"Herzlichen Glückwunsch"}
        showCloseButton={false}
        message={<div>{t("become-seller.email-contract")}</div>}
        okTitle={t("buttons.go-to-dashboard")}
        onOk={() => navigate(URL.DASHBOARD)}
      ></ConfirmModal>

      <Box
        sx={{
          marginTop: "32px",
        }}
      >
        <Typography
          variant="subtitle2"
          mb="13px"
          sx={{
            fontSize: "16px",
            fontWeight: "400",
          }}
        >
          {t("become-seller.look-again")}
        </Typography>
        <Box
          sm={12}
          sx={{
            width: "100%",
            borderRadius: "8px",
            backgroundColor: "#E3F2FD",
            padding: 10,
          }}
        >
          <Typography
            variant="subtitle2"
            sx={{
              fontSize: "16px",
              fontWeight: "400",
            }}
          >
            {t("become-seller.only-read")}
          </Typography>
        </Box>
        <Box
          sm={12}
          mt={20}
          mb={30}
          sx={{
            width: "100%",
            "& span": {
              marginRight: "0 !important",
            },
          }}
        >
          <Grid container spacing={10}>
            <DownloadGroup
              title={"Ratior AG"}
              downloads={[
                { name: "Ratior AG Vertriebsvereinbarung", downloadPath: "Vermittler/Ratior AG/Ratior AG Vertriebsvereinbarung.pdf" },
                { name: "Ratior AG Vergütungsübersicht",   downloadPath: "Vermittler/Ratior AG/Ratior AG Verguetungsuebersicht.pdf" },
              ]}
            ></DownloadGroup>
            <DownloadGroup
              title={"Ratior Gold GmbH"}
              downloads={[
                { name: "Ratior Gold GmbH Vertriebsvereinbarung", downloadPath: "Vermittler/Ratior Gold GmbH/Ratior Gold GmbH Vertriebsvereinbarung.pdf" },
                { name: "Ratior Gold GmbH Vergütungsübersicht",   downloadPath: "Vermittler/Ratior Gold GmbH/Ratior Gold GmbH Verguetungsuebersicht.pdf" },
              ]}
            ></DownloadGroup>
            <DownloadGroup
              title={"Ratior GmbH"}
              downloads={[
                { name: "Ratior GmbH Vertriebsvereinbarung", downloadPath: "Vermittler/Ratior GmbH/Ratior GmbH Vertriebsvereinbarung.pdf" },
                { name: "Ratior GmbH Vergütungsübersicht",   downloadPath: "Vermittler/Ratior GmbH/Ratior GmbH Verguetungsuebersicht.pdf" },
              ]}
            ></DownloadGroup>
            <DownloadGroup
              title={"Allgemein"}
              downloads={[
                { name: "MyRatior Nutzungsbedingungen",                              downloadPath: "Vermittler/allgemein/MyRatior Nutzungsbedingungen.pdf" },
                { name: "Ratior Vertriebsplan",                                      downloadPath: "Vermittler/allgemein/Vertriebsplan RATIOR.pdf" },
                { name: "Ratior Datenschutzhinweise",                                downloadPath: "Vermittler/allgemein/Ratior Datenschutzhinweise.pdf" },
                { name: "Ratior Vereinbarung Gemeinsame Verantwortlichkeit Partner", downloadPath: "Vermittler/allgemein/Ratior Vereinbarung Gemeinsame Verantwortlichkeit Partner.pdf" },
              ]}
            ></DownloadGroup>
          </Grid>

          <Grid xs={12} lg={12} mt={20} item>
            <MUICheckBox
              name="agreement"
              onClick={() => setAgreement(!agreement)}
              label={
                "Hiermit bestätige ich die oben aufgelisteten Dokument gelesen und verstanden zu haben. Weiter erkläre ich mit dem Inhalt der Dokumente einverstanden zu sein. *"
              }
            />
          </Grid>
        </Box>

        <FormSteperStructure.Footer>
          {!BYPASS_ADOBE_SIGN && (
            <>
              <Button
                onClick={() => onSubmit(true)}
                fullWidth
                sx={{
                  borderColor: "info.main",
                  color: "info.main",
                  marginRight: 10,
                  maxWidth: "250px",
                }}
                variant="outlined"
              >
                {t("buttons.sign-later")}
              </Button>
              <Button
                onClick={() => onSubmit(false)}
                fullWidth
                sx={{ bgcolor: "info.main", maxWidth: "350px" }}
                variant="contained"
              >
                {t("buttons.sign-now")}
              </Button>
            </>
          )}
          {BYPASS_ADOBE_SIGN && (
            <Button
              disabled={!agreement}
              onClick={() => {
                dispatch(
                  verificationSendSMSCodeAction({
                    mobileNumber: localStorage.getItem(MOBILE_NUMBER),
                  })
                );
                handleCurrentStep("next");
              }}
              fullWidth
              sx={{ bgcolor: "info.main", maxWidth: "192px" }}
              variant="contained"
            >
              Weiter
            </Button>
          )}
        </FormSteperStructure.Footer>
      </Box>
    </LoadingOverlay>
  );
}

export default SignContractStep;
