export const TOKEN_NAME = "token";
export const CONTACT_ID = "contactId";
export const FULLNAME = "fullName";
export const RATIOR_KEY = "ratiorKey";
export const MOBILE_VERIFIED = "mobileVerified";
export const NECT_VERIFIED = "nectVerified";
export const ADDRESS_VERIFIED = "addressVerified";
export const STATUS_CODE = "statusCode";
export const CONTACT_BIRTHDATE = "contactHasBithDate";
export const MOBILE_NUMBER = "mobileNumber";
export const WORKSPACE_ID = "workspaceId";
export const WORKSPACE_TYPE = "workspaceType";
export const WORKSPACE_NAME = "workspaceName";
export const VERMITTLER_ID = "vermittlerId";
export const ACTION_CODE_ID = "actionCode";
export const GUARDIAN1 = "guardian1";
export const GUARDIAN2 = "guardian2";
export const LANGUAGE = "i18nextLng";
export const DOCUMENTS_UPLOADED = "documents-uploaded";

export const TMP_CONTACT_ID = "tmpContactId";
export const TMP_WORKSPACE_ID = "tmpWorkspaceId";
export const TMP_WORKSPACE_TYPE = "tmpWorkspaceType";
export const TMP_WORKSPACE_NAME = "tmpWorkspaceName";
export const TMP_VERMITTLER_ID = "tmpVermittlerId";
export const TMP_FULLNAME = "tmpFullname";

export const CREATE_API = "/create";
export const UPDATE_API = "/update";
export const DELETE_API = "/delete";
export const SELECT_API = "/select";
