import { createSlice } from "@reduxjs/toolkit";
import {
  getDocumentsAction,
  postSellerInfoAction,
  signSellerContractWorkFlowAction,
  verificationCheckSMSCodeAction,
  verificationSendSMSCodeAction,
} from ".";

const initialState = {
  globalData: {
    actionStatus: false,
    actionType: "",
    loading: false,
    error: null,
  },
  bankAccountInfo: {},
  taxDetails: {},
  selectSignContract: true,
  signContract: { documents: [] },
  verificationSteps: {
    verification: false,
    sentSMS: false,
  },
};
export const becomeSellerSlice = createSlice({
  name: "becomeSeller",
  initialState,
  reducers: {
    setBankAccountInfo: (state, action) => {
      state.bankAccountInfo = action.payload;
    },
    setTaxDetails: (state, action) => {
      state.taxDetails = action.payload;
    },
    setSelectSignContract: (state, action) => {
      state.selectSignContract = action.payload;
    },
    resetSentSMS: (state) => {
      state.verificationSteps.sentSMS = false;
    },
    resetVerification: (state) => {
      state.verificationSteps.verification = false;
    },
    resetError: (state) => {
      state.globalData.error = null;
    },
    resetActionStatus: (state) => {
      state.globalData.actionStatus = false;
      state.globalData.actionType = null;
    },
    resetAll: () => {
      return initialState;
    },
  },
  extraReducers: {
    //Post Become Seller Info
    [postSellerInfoAction.pending]: (state, action) => {
      state.globalData.loading = true;
    },
    [postSellerInfoAction.fulfilled]: (state, action) => {
      state.globalData.loading = false;
      state.globalData.actionType = "postSellerInfo";
      state.globalData.actionStatus = true;
    },
    [postSellerInfoAction.rejected]: (state, action) => {
      state.globalData.loading = false;
      state.globalData.error = action.error.message;
      state.globalData.actionStatus = false;
    },

    // sign Seller Contract WorkFlow Action
    [signSellerContractWorkFlowAction.pending]: (state, action) => {
      state.globalData.loading = true;
    },
    [signSellerContractWorkFlowAction.fulfilled]: (state, action) => {
      state.globalData.loading = false;
      state.globalData.actionType = "postSellerContractInfo";
      state.globalData.actionStatus = true;
    },
    [signSellerContractWorkFlowAction.rejected]: (state, action) => {
      state.globalData.loading = false;
      state.globalData.error = action.error.message;
      state.globalData.actionStatus = false;
    },

    //Verifications send
    [verificationSendSMSCodeAction.pending]: (state, action) => {
      state.globalData.loading = true;
    },
    [verificationSendSMSCodeAction.fulfilled]: (state, action) => {
      state.globalData.loading = false;
      state.verificationSteps.sentSMS = true;
    },
    [verificationSendSMSCodeAction.rejected]: (state, action) => {
      state.globalData.loading = false;
      state.globalData.error = action.error.message;
      state.verificationSteps.sentSMS = false;
    }, // Verifications Check
    [verificationCheckSMSCodeAction.pending]: (state, action) => {
      state.globalData.loading = true;
    },
    [verificationCheckSMSCodeAction.fulfilled]: (state, action) => {
      state.globalData.loading = false;
      const { data } = action.payload;

      if (data.status === 200 || data.status === 201) {
        state.globalData.actionStatus = true;
        state.verificationSteps.verification = true;
        state.globalData.actionType = "verification";
      } else if (Object.keys(data?.data?.error).length > 0) {
        state.globalData.actionStatus = false;
        state.verificationSteps.verification = false;
        state.globalData.actionType = null;
        state.globalData.error = data.data.error.message;
      }
    },
    [verificationCheckSMSCodeAction.rejected]: (state, action) => {
      state.globalData.loading = false;
      state.verificationSteps.verification = false;
      state.globalData.error = action.error.message;
      state.globalData.actionStatus = false;
      state.globalData.actionType = null;
    },

    //parichehr use redux action to save seller
    //save seller data
    // [saveSellerAction.pending]: (state, action) => {
    //   state.globalData.loading = true;
    // },
    // [saveSellerAction.fulfilled]: (state, action) => {
    //   state.globalData.loading = false;
    //   state.globalData.actionStatus = true;
    //   state.globalData.actionType = "saveSeller";
    // },
    // [saveSellerAction.rejected]: (state, action) => {
    //   state.globalData.loading = false;
    //   state.globalData.error = action.error.message;
    // },
  },
});

export const fullData = (state) => state.becomeSeller;
export const globalData = (state) => state.becomeSeller.globalData;

export const bankAccountInfo = (state) => state.becomeSeller.bankAccountInfo;
export const taxDetails = (state) => state.becomeSeller.taxDetails;
export const selectSignContract = (state) => state.becomeSeller.selectSignContract;
export const verificationSteps = (state) => state.exchange.verificationSteps;

export const {
  setBankAccountInfo,
  setTaxDetails,
  setSelectSignContract,
  setSignContract,
  resetError,
  resetActionStatus,
  resetSentSMS,
  resetVerification,
  resetAll,
} = becomeSellerSlice.actions;
export default becomeSellerSlice.reducer;
