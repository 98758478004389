import React, { useState, useEffect } from "react";
import DataTable from "../common/grids";

const EmptyList = ({ title }) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    setData([]);
  }, []);
  return (
    <div className="container-card">
      <DataTable
        title={title}
        data={data}
        columns={[
          { name: "arades_name", caption: "Name" },
          { name: "createdon", caption: "Erstellt am" },
        ]}
      ></DataTable>
    </div>
  );
};
export default EmptyList;
