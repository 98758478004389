import React, { useState } from "react";
import { FormSteper } from "../common";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { resetAll } from "../../redux/slices/transfer/transfer.slice";
import { useNavigate, useParams } from "react-router-dom";
import { URL } from "../../core/constants";

import SelectSourceStorageStep from "./selectSourceStorage.step";
import SelectReceptor from "./selectReceptor.step";
import OtherPersonSteps from "./otherPerson/otherPerson.step.manager";
import MySelfSteps from "./myself/myself.step.manager";

function Transfer() {
  const stepsCount = 4;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const detailStorageId = useParams().storageid;
  const parentstorageid = useParams().parentstorageid;

  const [loading, setLoading] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);

  const HandleCurrentStep = (step) => {
    if (isNaN(parseInt(step))) {
      if (step === "next" && currentStep < stepsCount) {
        setCurrentStep((prev) => prev + 1);
      } else if (step === "prev" && currentStep > 1) {
        setCurrentStep((prev) => prev - 1);
      }
    } else {
      if (currentStep <= stepsCount && currentStep >= 1) {
        setCurrentStep(step);
      }
    }
  };

  const handleCancel = () => {
    dispatch(resetAll());
    navigate(
      `${parentstorageid ? URL.SUBSTORAGE : URL.STORAGE}/${
        parentstorageid ? parentstorageid + "/" : ""
      }${detailStorageId}`
    );
  };

  return (
    <div className="container-card">
      <FormSteper
        currentStep={currentStep}
        setCurrentStep={HandleCurrentStep}
        loading={loading}
        noHeader={[3, 4]}
        hasCancel={[1, 2]}
        onCancel={handleCancel}
        noFooter={[1, 2, 3, 4]}
        headerTitles={[
          t("transfer.select-source"),
          t("transfer.select-receptor"),
        ]}
      >
        {/* Step 1 */}
        <SelectSourceStorageStep handleCurrentStep={HandleCurrentStep} />

        {/* Step 2 */}
        <SelectReceptor
          currentStep={currentStep}
          handleCurrentStep={HandleCurrentStep}
        />

        {/* Step 3  */}
        <OtherPersonSteps parent={2} handleCurrentStep={HandleCurrentStep} />

        {/* Step 4 */}
        <MySelfSteps parent={2} handleCurrentStep={HandleCurrentStep} />
      </FormSteper>
    </div>
  );
}

export default Transfer;
