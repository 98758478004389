import styled from '@emotion/styled';
import {InputBase} from '@mui/material'

const InputUIBootstrap = styled(InputBase)`
    label + & {
      margin-top: 20px;
    }
    & .MuiInputBase-input {
      position: 'relative';
      background-color: '#fcfcfb' ;
      border: 1px solid rgba(0, 0, 0, 0.12);
      border-radius: 8px;
      font-size: 16;
      width: 'auto';
      padding: '10px 12px';
      border: 1px solid rgba(0, 0, 0, 0.12);
     
      &:focus {
        border-color: "#eee";
      }
    }
`
  

export default InputUIBootstrap