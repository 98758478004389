import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../../../core/apiconfig/environment";
import { TOKEN_NAME } from "../../../core/apiconfig/constantApi";

const getTitleListAction = createAsyncThunk(
  "global/titles",
  async (dataParams, { getState }) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${localStorage.getItem(TOKEN_NAME)}`,
      },
    };

    const result = await axios.get(`${BASE_URL}/auth/titles`);

    if (!result?.data?.status === 200 || !result?.data?.data?.value?.length) throw Error("Anrede konnte nicht geladen werden");

    let list = [];
    result.data.data.value.map((item) => {
      list.push({
        key: item.arades_titelid,
        value: item.arades_name,
      });
    });
    return list;
  }
);

export default getTitleListAction;
