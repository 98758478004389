import axios from "axios";
import { BASE_URL } from "../../../core/apiconfig/environment";
import { getMetalPriceFunc } from "../";
import { GermanFloat } from "../../../core/utils/convert";
import { storageIsPending, storageStatus } from "../../../core/utils/business";
import { TOKEN_NAME, WORKSPACE_ID, WORKSPACE_TYPE } from "../../../core/apiconfig/constantApi";
import { METAL_CHARS, WORKSPACETYPE } from "../../../core/constants";

const getVermittlerStorageListFunc = async (data, { getState }) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `bearer ${localStorage.getItem(TOKEN_NAME)}`,
    },
  };

  // Start fetching prices in parallel for performance
  const pricesPromise = Promise.all(
    Object.values(METAL_CHARS).map((metal) =>
      getMetalPriceFunc({ productName: metal }, { getState })
    )
  ).then(([gold, silver, platin, palladium]) => ({
    gold: gold.amount,
    silver: silver.amount,
    platin: platin.amount,
    palladium: palladium.amount,
  }));

  let result;
  const vermittlerId = localStorage.getItem(WORKSPACE_ID);
  const vermittlerType = localStorage.getItem(WORKSPACE_TYPE);
  try {
    if (vermittlerType === WORKSPACETYPE.CONTACT || vermittlerType === WORKSPACETYPE.UNDER_AGE) {
      result = await axios.get(`${BASE_URL}data/warehouses/vermittler/contact/${vermittlerId}/`, config);
    } else {
      result = await axios.get(`${BASE_URL}data/warehouses/vermittler/account/${vermittlerId}/`, config);
    }
  } catch (e) {
    console.error(e);
    throw Error("Lager konnten nicht geladen werden");
  }

  const prices = await pricesPromise; // Wait for prices to be fetched

  let list = [];
  result.data.map((item) => {
    let unitPrice =
      item["p.productnumber"] == "AU"
        ? prices.gold
        : item["p.productnumber"] == "AG"
        ? prices.silver
        : item["p.productnumber"] == "PT"
        ? prices.platin
        : item["p.productnumber"] == "PL"
        ? prices.palladium
        : 0;
    let tmp = {
      isPending: storageIsPending(item),
      storageNumber: item["s.msdyn_name"],
      storageDescription: item["s.msdyn_description"],
      arades_verfuegbare_menge: item["sm.arades_verfuegbare_menge"] ?? 0,
      arades_verfuegbare_menge_formatted: GermanFloat(
        item["sm.arades_verfuegbare_menge"] ?? 0
      ),
      arades_menge: item["sm.arades_menge"],
      arades_menge_formatted: GermanFloat(item["sm.arades_menge"]),
      arades_verzicht_auf_widerruf: item["s.arades_verzicht_auf_widerruf"],
      arades_einrichtungsgebuehr_beglichen:
        item["s.arades_einrichtungsgebuehr_beglichen"],
      arades_vertrag: item["s.arades_vertrag"],
      contractSignDate: item["s.arades_vertrag_unterschrieben_am"],
      storageStatus: storageStatus(item["s.statuscode"]),
      createDate: item["s.createdon"],
      customerId: item["c.contactid"], //account ham biad toosh
      customerName: item["c.firstname"]
        ? item["t.arades_name"]
          ? item["t.arades_name"] +
            " " +
            item["c.firstname"] +
            " " +
            item["c.lastname"]
          : "" + item["c.firstname"] + " " + item["c.lastname"]
        : item["a.name"],
      metalName: item["p.name"],
      productNumber: item["p.productnumber"],
      unitPrice: unitPrice,
      totalPrice: unitPrice * (item["sm.arades_verfuegbare_menge"] ?? 0),
    };
    list.push(tmp);
  });
  return list.sort((a, b) => (new Date(a?.createDate) >= new Date(b?.createDate) ? -1 : 1)); // Sort by creation date and return
};

export default getVermittlerStorageListFunc;
