import React from "react";

import "./input.scss";

const CheckBox = ({
  label,
  width,
  height,
  value,
  onChange,
  id,
  name,
  disabled,
  style,
  multiline,
  className,
  ...rest
}) => {
  return (
    <>
      <div className="d-flex ">
        <input
          id={id}
          name={name}
          autoComplete={"none"}
          onChange={onChange}
          value={value}
          disabled={disabled}
          {...rest}
          type={"checkbox"}
          className={`checkbox ${className}`}
        />
        <label className="labek d-flex" htmlFor={id}>
          {label}
        </label>
      </div>
    </>
  );
};

export default CheckBox;
