import React, { useState } from "react";
import { FormSteper } from "../common";
import { useTranslation } from "react-i18next";
import SelectWorkspaceTypeStep from "./SelectWorkspaceType.step";
import EnterChildDataStep from "./EnterChildData.step";
import LegalGuardianStatusStep from "./LegalGuardianStatus.step";
import VerificationStep from "./Verification.step";
import LegalGuardianOrNotStep from "./LegalGuardianOrNot.step";
import OtherLegalGuardianStep from "./otherLegalGuardian.step";
import UploadDocumentsStep from "./uploadDocuments.step";

function AddUnderageStepManager() {
  const [currentStep, setCurrentStep] = useState(1);
  const { t } = useTranslation();

  const stepsCount = 7;

  const HandleCurrentStep = (step) => {
    if (isNaN(parseInt(step))) {
      if (step === "next" && currentStep < stepsCount) {
        setCurrentStep((prev) => prev + 1);
      }
      if (step === "prev" && currentStep > 1) {
        setCurrentStep((prev) => prev - 1);
      }
    } else {
      if (currentStep <= stepsCount && currentStep >= 1) {
        setCurrentStep(step);
      }
    }
  };

  return (
    <div className="container-card">
      <FormSteper
        currentStep={currentStep}
        setCurrentStep={HandleCurrentStep}
        noFooter={[1, 2, 3, 4, 5, 6, 7]}
        noHeader={[6]}
        noBackButton={[6]}
        headerTitles={[
          t("underage.header-select-workspace-type"),
          <div>
            &nbsp;Erstellung von Ratior-Bereich <br></br> &nbsp;
            <span style={{ color: "rgba(0, 0, 0, 0.87)", fontSize: 18 }}>
              für einen Minderjährigen
            </span>
          </div>,
          t("underage.header-legal-guardian-status"),
          "Zweiter Erziehungsberechtigter",
          t("underage.header-other-guardian-acceptance"),
          t("underage.header-enter-data"),
          "Dokumente hochladen",
        ]}
      >
        {/* Step 1 */}
        <SelectWorkspaceTypeStep handleCurrentStep={HandleCurrentStep} />

        {/* Step 2 */}
        <LegalGuardianOrNotStep handleCurrentStep={HandleCurrentStep} />

        {/* Step 3 */}
        <LegalGuardianStatusStep
          handleCurrentStep={HandleCurrentStep}
          currentStep={currentStep}
        />

        {/* Step 4 */}
        <OtherLegalGuardianStep handleCurrentStep={HandleCurrentStep} />

        {/* Step 5 */}
        <VerificationStep handleCurrentStep={HandleCurrentStep} />

        {/* Step 6 */}
        <EnterChildDataStep
          handleCurrentStep={HandleCurrentStep}
          currentStep={currentStep}
        />

        {/*step 7 upload documnets */}
        <UploadDocumentsStep handleCurrentStep={HandleCurrentStep} />
      </FormSteper>
    </div>
  );
}

export default AddUnderageStepManager;
