import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Link,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import MdModeDownload from "@mui/icons-material/Download";
import { URL, ERROR_CONTACT_US } from "../../core/constants";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { getContractListAction } from "../../redux/slices/contract";
import {
  contractGlobalState,
  resetActionStatus,
  resetError,
} from "../../redux/slices/contract/contract.slice";
import { WORKSPACE_ID, WORKSPACE_TYPE } from "../../core/apiconfig/constantApi";
import { LoadingOverlay } from "../common";
import { useTranslation } from "react-i18next";

const ContractList = () => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const contractGlobalData = useSelector(contractGlobalState);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    dispatch(
      getContractListAction({
        ownerId: localStorage.getItem(WORKSPACE_ID),
        ownerType: localStorage.getItem(WORKSPACE_TYPE),
      })
    );
  }, []);

  useEffect(() => {
    if (
      contractGlobalData.actionStatus &&
      contractGlobalData.actionType === "getContractList"
    ) {
      setLoading(false);
      dispatch(resetActionStatus());
    }
  }, [contractGlobalData.actionStatus]);

  useEffect(() => {
    if (contractGlobalData.error !== null) {
      enqueueSnackbar(contractGlobalData.error + ERROR_CONTACT_US, {
        variant: "error",
        autoHideDuration: 20000,
      });
      setLoading(false);
      dispatch(resetError());
    }
  }, [contractGlobalData.error]);

  return (
    <LoadingOverlay show={loading}>
      <div className="container-card">
        <Box
          mb={10}
          sx={{
            display: "flex",
            height: "64px",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography sx={{ fontWeight: 500 }} variant="h5" component="h5">
            {t("contract-list.title")}
          </Typography>
        </Box>
        <TableContainer component={Box}>
          <Table sx={{ minWidth: 930 }} aria-label="simple table">
            <TableHead sx={{ backgroundColor: "rgba(33, 33, 33, 0.08)" }}>
              <TableRow>
                <TableCell>{t("contract-list.name")}</TableCell>
                <TableCell>{t("contract-list.sent-on")}</TableCell>
                <TableCell>{t("contract-list.signed-on")}</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {contractGlobalData.contracts?.map((row) => (
                <TableRow
                  key={row.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell>{row.adobe_name}</TableCell>
                  <TableCell>{row.adobe_datesent}</TableCell>
                  <TableCell>{row.adobe_datesigned}</TableCell>
                  <TableCell>
                    <Link href={row.downloadLink} target="_blank">
                      <MdModeDownload />
                    </Link>
                    {/* <IconButton
                      aria-label="download"
                      component="span"
                      onClick={() => download()}
                    >
                      <MdModeDownload />
                    </IconButton> */}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </LoadingOverlay>
  );
};

export default ContractList;
