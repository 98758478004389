import axios from "axios";
import { BASE_URL, BYPASS_ME } from "../../../core/apiconfig/environment";
import { TOKEN_NAME } from "../../../core/apiconfig/constantApi";

const verificationCheckSMSCodeFunc = async (dataParams, { getState }) => {
  try {
    if (BYPASS_ME) return { data: { status: 200 } };

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${localStorage.getItem(TOKEN_NAME)}`,
      },
    };

    let smsObj = {
      mobileNumber: dataParams.mobileNumber,
      code: dataParams.activationCode,
    };

    const result = await axios.post(
      `${BASE_URL}auth/otp/verify`,
      smsObj,
      config
    );

    if (result) {
      return result;
    }
  } catch (err) {
    throw Error(
      err.response && err.response.data.detail
        ? err.response.data.detail
        : err.message
    );
  }
};

export default verificationCheckSMSCodeFunc;
