import { createSlice } from "@reduxjs/toolkit";
import { getCommissionListAction, getCommissionHeaderListAction } from ".";

const initialState = {
  globalData: {
    actionStatus: false,
    actionType: "",
    loading: false,
    error: null,
  },
  commissionHeaderList: [],
  commissionListAG: [],
  commissionListGmbh: [],
  commissionListGoldGmbh: [],
  // sumAGBetrag: 0,
  // sumGmbhBetrag: 0,
  // sumGoldGmbhBetrag: 0,
};
export const commissionsSlice = createSlice({
  name: "commissions",
  initialState,
  reducers: {
    resetError: (state) => {
      state.globalData.error = null;
    },
    resetActionStatus: (state) => {
      state.globalData.actionStatus = false;
      state.globalData.actionType = null;
    },
    resetAll: () => {
      return initialState;
    },
  },
  extraReducers: {
    //commission list
    [getCommissionListAction.pending]: (state, action) => {
      state.globalData.loading = true;
      state.globalData.actionStatus = false;
    },
    [getCommissionListAction.fulfilled]: (state, action) => {
      state.globalData.loading = false;
      state.commissionListAG = action.payload.commissionListAG;
      state.commissionListGmbh = action.payload.commissionListGmbh;
      state.commissionListGoldGmbh = action.payload.commissionListGoldGmbh;
      state.globalData.actionStatus = true;
      state.globalData.actionType = "getCommissionList";
    },
    [getCommissionListAction.rejected]: (state, action) => {
      state.globalData.loading = false;
      state.globalData.error = action.error.message;
      state.globalData.actionStatus = false;
    },

    //commission header list
    [getCommissionHeaderListAction.pending]: (state, action) => {
      state.globalData.loading = true;
      state.globalData.actionStatus = false;
    },
    [getCommissionHeaderListAction.fulfilled]: (state, action) => {
      state.globalData.loading = false;
      state.commissionHeaderList = action.payload;
      state.globalData.actionStatus = true;
      state.globalData.actionType = "getCommissionHeaderList";
    },
    [getCommissionHeaderListAction.rejected]: (state, action) => {
      state.globalData.loading = false;
      state.globalData.error = action.error.message;
      state.globalData.actionStatus = false;
    },
  },
});

export const fullData = (state) => state.commissions;
export const globalData = (state) => state.commissions.globalData;

export const commissionHeaderList = (state) =>
  state.commissions.commissionHeaderList;
export const commissionListAG = (state) => state.commissions.commissionListAG;
export const commissionListGmbh = (state) =>
  state.commissions.commissionListGmbh;
export const commissionListGoldGmbh = (state) =>
  state.commissions.commissionListGoldGmbh;

// export const sumAGBetrag = (state) => state.commissions.sumAGBetrag;
// export const sumGmbhBetrag = (state) => state.commissions.sumGmbhBetrag;
// export const sumGoldGmbhBetrag = (state) => state.commissions.sumGoldGmbhBetrag;

export const { resetError, resetActionStatus, resetAll } =
  commissionsSlice.actions;
export default commissionsSlice.reducer;
