import { Box, Grid, Button } from "@mui/material";
import React, { useState, useEffect } from "react";
import { Select, FormSteperStructure, LoadingOverlay } from "../../common";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  getStorageListAction,
  getSubStorageListAction,
} from "../../../redux/slices/transfer";
import { useDispatch, useSelector } from "react-redux";
import {
  setSelectStorage,
  transferGlobalState,
  transferSelectStorageTypeFields,
  transferSelectStorageFields,
  transferStorages,
  transferSubStorages,
  resetActionStatus,
  resetError,
} from "../../../redux/slices/transfer/transfer.slice";
import { useSnackbar } from "notistack";
import { WORKSPACE_ID } from "../../../core/apiconfig/constantApi";
import { ERROR_CONTACT_US } from "../../../core/constants";
import { useTranslation } from "react-i18next";

function SelectStorage({ handleCurrentStep }) {
  const requiredMsg = "erforderlich";
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const transferGlobalData = useSelector(transferGlobalState);
  const storageTypeFields = useSelector(transferSelectStorageTypeFields);
  const storageFields = useSelector(transferSelectStorageFields);
  const storageList = useSelector(transferStorages);
  const subStorageList = useSelector(transferSubStorages);
  const [loading, setLoading] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    dispatch(getStorageListAction(localStorage.getItem(WORKSPACE_ID)));
    if (storageFields.storageId) {
      dispatch(
        getSubStorageListAction({
          storageId: storageFields.storageId,
          fetchAmount: false,
        })
      );
    }
  }, []);

  const submitForm = (data) => {
    if (storageTypeFields.storageType == "mainStorage") {
      data.subStorageId = "";
      data.subStorageNumber = "";
    }

    if (data.storageId)
      data.storageNumber =
        storageList.filter((x) => {
          return x.key == data.storageId;
        })[0].value ?? "";
    if (data.subStorageId)
      data.subStorageNumber =
        subStorageList.filter((x) => {
          return x.key == data.subStorageId;
        })[0].value ?? "";

    dispatch(setSelectStorage(data));
    handleCurrentStep("next");
  };

  const storageChanegd = (e) => {
    if (storageTypeFields.storageType != "mainStorage")
      if (e.target.value) {
        dispatch(
          getSubStorageListAction({
            storageId: e.target.value,
            fetchAmount: false,
          })
        );
      }
  };

  const schema = yup.object().shape({
    storageId: yup.string().nullable().required(requiredMsg),
    subStorageId:
      storageTypeFields.storageType == "mainStorage"
        ? yup.string().nullable()
        : yup.string().nullable().required(requiredMsg),
  });

  useEffect(() => {
    if (transferGlobalData.actionStatus) {
      dispatch(resetActionStatus());
    }
  }, [transferGlobalData.actionStatus]);

  useEffect(() => {
    setLoading(transferGlobalData.loading);
  }, [transferGlobalData.loading]);

  useEffect(() => {
    if (transferGlobalData.error != null) {
      enqueueSnackbar(transferGlobalData.error + ERROR_CONTACT_US, {
        variant: "error",
        autoHideDuration: 20000,
      });
      dispatch(resetError());
      setLoading(false);
    }
  }, [transferGlobalData.error]);

  const {
    control,
    handleSubmit,
    setError,
    getValues,
    formState: { errors },
  } = useForm({
    mode: "all",
    defaultValues: {
      ...storageFields,
      agreed: false,
    },
    resolver: yupResolver(schema),
  });

  return (
    <LoadingOverlay show={loading}>
      <Box
        sx={{
          margin: "0 auto",
          minHeight: "calc(100vh - 250px)",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "space-around",
        }}
      >
        <Box sx={{ minWidth: "50%", margin: "0 auto" }}>
          <Grid spacing={8} container direction="column">
            <Grid xs={12} lg={6} item>
              <Select
                control={control}
                error={Boolean(errors.storageId)}
                helperText={errors.storageId?.message}
                name="storageId"
                label={t("transfer.select-storage")}
                onChange={storageChanegd}
                options={storageList}
              />
            </Grid>
            {storageTypeFields.storageType != "mainStorage" && (
              <Grid xs={12} lg={6} item>
                <Select
                  control={control}
                  error={Boolean(errors.subStorageId)}
                  helperText={errors.subStorageId?.message}
                  name="subStorageId"
                  label={t("transfer.select-substorage")}
                  options={subStorageList}
                />
              </Grid>
            )}
          </Grid>
        </Box>
        <FormSteperStructure.Footer>
          <Button
            onClick={handleSubmit(submitForm)}
            fullWidth
            sx={{ backgroundColor: "info.main", maxWidth: "192px" }}
            variant="contained"
          >
            {t("buttons.next")}
          </Button>
        </FormSteperStructure.Footer>
      </Box>
    </LoadingOverlay>
  );
}

export default SelectStorage;
