import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { TOKEN_NAME } from "../../../core/apiconfig/constantApi";
import { BASE_URL } from "../../../core/apiconfig/environment";

const deactivateActionCodeAction = createAsyncThunk(
  "vermittlerDashboard/deactivateActionCode",
  async (id, { getState }) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${localStorage.getItem(TOKEN_NAME)}`,
      },
    };

    const result = await axios.patch(`${BASE_URL}data/action-codes/deactivate/${id}/`, {}, config);
    if (result.data.status != 200)
      throw Error("Aktionscode konnte nicht deaktiviert werden"); //("Could not deactivate action code");

    return result;
  }
);

export default deactivateActionCodeAction;
