import React, { useState, useEffect } from "react";
import { useLoginState } from "../../../context/login/LoginState";
import Login from "./login";
import TwoFactor from "./twoFactor";

const LoginStepper = () => {
  const { loginInfoAction, loginInfo } = useLoginState();

  useEffect(() => {
    if (!loginInfo) loginInfoAction({ step: 1 });
  }, []);

  return (
    <div>
      {(!loginInfo || loginInfo.step == 1) && <Login></Login>}
      {loginInfo && loginInfo.step == 2 && <TwoFactor></TwoFactor>}
    </div>
  );
};

export default LoginStepper;
