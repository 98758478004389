
import { createAsyncThunk } from "@reduxjs/toolkit";

const postNewMessageAction = createAsyncThunk(
    "inbox/postNewMessage",
    async (dataParams, { getState }) => {
        
    }
);

export default postNewMessageAction
