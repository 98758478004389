import axios from "axios";
import { BASE_URL } from "../../../core/apiconfig/environment";
import { TOKEN_NAME } from "../../../core/apiconfig/constantApi";

const verificationSendSMSCodeFunc = async (dataParams, { getState }) => {
  const { mobileNumber } = dataParams;

  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `bearer ${localStorage.getItem(TOKEN_NAME)}`,
    },
  };

  const result = await axios.post(`${BASE_URL}auth/otp`, { mobileNumber }, config);
  if (result?.data?.status != 200) throw Error(result.data.statusText);

  return result;
};
export default verificationSendSMSCodeFunc;
