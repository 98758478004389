import { Box, Grid, Button, TextField, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import { Input, LoadingOverlay, Datepicker } from "../../common";

import { FormSteperStructure } from "../../common";
import { useForm } from "react-hook-form";
import moment from "moment";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { getOwnerDataAction } from "../../../redux/slices/powerOfAttorney";
import { ERROR_CONTACT_US } from "../../../core/constants";
import {
  setPerson,
  choosePersonStep,
  globalData,
  resetError,
} from "../../../redux/slices/powerOfAttorney/powerOfAttorney.slice";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

function ChoosePersonStep({ handleCurrentStep }) {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const requiredMsg = "validation.required";
  const fields = useSelector(choosePersonStep);
  const globalFields = useSelector(globalData);

  const submitForm = (data) => {
    dispatch(setPerson(data));
    dispatch(getOwnerDataAction({ ratiorKey: data.ownerKey, birthdate: data.ownerBirthDate }));
    setLoading(true);
  };
  useEffect(() => {
    if (fields.ownerInfo?.firstname) {
      setLoading(false);
      handleCurrentStep("next");
    }
  }, [fields.ownerInfo?.firstname]);

  useEffect(() => {
    if (globalFields.error != null) {
      enqueueSnackbar(globalFields.error + ERROR_CONTACT_US, {
        variant: "error",
        autoHideDuration: 20000,
      });
      setLoading(false);
      dispatch(resetError());
    }
  }, [globalFields.error]);

  const schema = yup.object().shape({
    ownerKey: yup.string().required(requiredMsg),
    ownerBirthDate: yup
      .string()
      .required(requiredMsg)
      .test("DOB", "validation.age-above-18", (value) => {
        return moment().diff(moment(value), "years") > 18;
      })
      .nullable(),
  });

  const {
    control,
    handleSubmit,
    setError,
    getValues,
    formState: { errors },
  } = useForm({
    mode: "all",
    defaultValues: {
      ...fields,
    },
    resolver: yupResolver(schema),
  });

  return (
    <LoadingOverlay show={loading}>
      <Box
        sx={{
          margin: "0 auto",
          minHeight: "calc(100vh - 250px)",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "space-around",
        }}
      >
        <Box sx={{ margin: "0 auto" }}>
          <Typography mb="30px" component="h6" variant="body1">
            Please enter the Ratiorkey and Birthdate of the person that you want
            to grant them a power of attorney
          </Typography>
          <Typography
            mb="20px"
            sx={{ color: "rgba(0, 0, 0, 0.38)" }}
            variant="subtitle2"
          >
            Specify the person
          </Typography>

          <Grid spacing={8} container direction="row">
            <Grid xs={12} lg={6} item>
              <Input
                control={control}
                error={Boolean(errors.ownerKey)}
                helperText={t(errors.ownerKey?.message)}
                name="ownerKey"
                label="Ratior key*"
              />
            </Grid>
            <Grid xs={12} lg={6} item>
              <Datepicker
                label="Birthdate of the owner*"
                control={control}
                name="ownerBirthDate"
                maxDate={Date.now()}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={Boolean(errors.ownerBirthDate)}
                    helperText={t(errors.ownerBirthDate?.message)}
                    onBlur={() => {
                      if (getValues("ownerBirthDate") === "") {
                        setError("ownerBirthDate", {
                          type: "manual",
                          message: requiredMsg,
                        });
                      }
                    }}
                  />
                )}
              />
            </Grid>
          </Grid>
        </Box>
        <FormSteperStructure.Footer>
          <Button
            onClick={handleSubmit(submitForm)}
            fullWidth
            sx={{ backgroundColor: "info.main", maxWidth: "192px" }}
            variant="contained"
          >
            Weiter
            {/* Next */}
          </Button>
        </FormSteperStructure.Footer>
      </Box>
    </LoadingOverlay>
  );
}

export default ChoosePersonStep;
