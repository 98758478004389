import React, { useState, useEffect } from "react";
import Link from "@mui/material/Link";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { useApi } from "../../core/hooks/useApi";
import { BASE_URL } from "../../core/apiconfig/environment";
import "./documents.scss";
import { CONTACT_ID } from "../../core/apiconfig/constantApi";
import { useTranslation } from "react-i18next";

const Documents = ({ companyId }) => {
  const { get } = useApi();
  const { t } = useTranslation();

  const [documents, setDocuments] = useState([]);
  const linkRef = React.createRef();

  useEffect(() => {
    fetchDocuments();
  }, []);

  const fetchDownloadLink = async (doc) => {
    return `${BASE_URL}download?firmendokumentId=${
      doc.firmendokumentId
    }&filename=${doc.filename}&contactId=${localStorage.getItem(CONTACT_ID)}`;
  };

  const fetchDocuments = async () => {
    const result = await get(`${BASE_URL}download/list?accountId=${companyId}`);
    if (result.data) {
      let docList = [];
      for (let i = 0; i < result.data.length; i++) {
        let doc = result.data[i];

        if (
          doc.sharepointFiles &&
          doc.sharepointFiles[0] &&
          doc.sharepointFiles[0].Name
        ) {
          let item = {
            filename: doc.sharepointFiles[0].Name,
            firmendokumentId: doc.arades_firmendokumentid,
          };

          let downloadLink = await fetchDownloadLink(item);
          item.downloadLink = downloadLink;

          docList.push(item);
        }
      }
      setDocuments(docList);
    }
  };
  return (
    <div className="div-container">
      {(!documents || documents.length == 0) && (
        <span>{t("company-profile.no-file-uploaded")}</span>
      )}
      {documents &&
        documents.map((doc, index) => {
          return (
            <div className="d-flex div-row" key={index}>
              <Link href={doc.downloadLink}>{doc.filename}</Link>
              <a className="icon" ref={linkRef} href={doc.downloadLink}>
                <FontAwesomeIcon
                  style={{ color: "#1565C0" }}
                  icon={faDownload}
                />
              </a>
            </div>
          );
        })}
    </div>
  );
};

export default Documents;
