import React from "react";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Typography, Grid, Link } from "@mui/material";

const DownloadGroup = ({ title, downloads }) => {
  return (
    <Grid item sm={12} md={6}>
      <Grid container>
        <Typography
          variant="subtitle2"
          sx={{
            fontSize: "16px",
            fontWeight: "400",
            color: "rgba(0, 0, 0, 0.38)",
          }}
        >
          {title}
        </Typography>
        {downloads?.map((item) => (
          <DownloadLink
            title={item.name}
            downloadPath={"/pdf/" + encodeURI(item.downloadPath)}
          ></DownloadLink>
        ))}
      </Grid>
    </Grid>
  );
};
const DownloadLink = ({ title, downloadPath }) => {
  return (
    <Grid
      item
      sm={12}
      mt={10}
      sx={{
        display: "flex",
        fontSize: "14px",
      }}
    >
      <Link href={downloadPath} target="_blank" sx={{ marginRight: 4 }} download>
        <FontAwesomeIcon style={{ color: "#323232" }} icon={faDownload} />
      </Link>
      <Link href={downloadPath} target="_blank">
        {title}
      </Link>
    </Grid>
  );
};

export default DownloadGroup;
