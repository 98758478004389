import React, { useEffect, useRef } from "react";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { Controller, useForm } from "react-hook-form";
import TextField from "@mui/material/TextField";
import i18next from "i18next";

const Datepicker = ({
  id,
  label,
  disabled,
  name,
  value,
  placeholder,
  onChange,
  register,
  className,
  width,
  height,
  style,
  maxDate,
  renderInput,
  control = null,
  disablePast = false,
  minDate = undefined,
  ...rest
}) => {
  const lastValueRef = useRef(value ? new Date(value) : null); // Store last date to prevent date glitches while typing
  const handleChange = (e) => {
    let event = { target: { name: name, value: e } };
    lastValueRef.current = event.target.value; // Store last date to prevent date glitches while typing
    if (onChange) { onChange(event); }
  };

  const maskMap = {
    en: "__/__/____",
    de: "__.__.____",
  };
  const inputFormatMap = {
    en: "dd/MM/yyyy",
    de: "dd.MM.yyyy",
  };
  const currentLanguage = i18next.language || window.localStorage.i18nextLng;

  name = name || "date"; // Ensure that form has a name so that the controller works

  const { control: defaultControl, setValue } = useForm({ defaultValues: {[name]: value ? value : null} });
  control = control || defaultControl;
  // Update value when changed from outside e.g. when loading it on the profile page
  useEffect(() => {
    const parsedDate = new Date(value); // Parse date from string to check whether it is valid
    if (value && parsedDate !== lastValueRef.current) { // New value provided from the outside? (Not the form value to avoid changes while typing)
      if (!isNaN(parsedDate.getTime())) { // Is it a valid date?
        setValue(name, parsedDate); // Update form value
      }
    }
  }, [value, setValue, name]);

  const inputRef = useRef(null);

  // Function to handle key down events for custom formatting
  const handleKeyDown = (e) => {
    const { value, selectionStart } = e.target;

    // Custom handling of the "." or "/" key press for less confusing date input using "."
    // e.g. "3." -> "03." for the input
    let newValue = value;
    if (e.key === "." || e.key === "/") {
      e.preventDefault(); // Prevent the default behavior of adding the character

      if (selectionStart === 1) { // Dot pressed after first day number? -> Day only has one digit -> Fill with "0"
        newValue = `0${value}${e.key}`; // e.g. "3." -> "03."
      } else if (selectionStart === 4) { // Dot pressed after first month number? -> Month only has one digit -> Fill with "0"
        newValue = `${value.slice(0, 3)}0${value.slice(3)}${e.key}`; // e.g. "03.2." -> "03.02."
      }

      // Trigger a change event to update the form state
      inputRef.current.value = newValue;
      const event = new Event("change", { bubbles: true });
      inputRef.current.dispatchEvent(event);
    }
  };

  return (
    <div className="d-flex ml-4 mr-4 flex-column ">
      <Controller
        control={control}
        name={name}
        render={({
          field: {
            onChange: formOnChange,
            onBlur: formOnBlur,
            value: formValue,
            name: formName,
            ref: fromRef,
          },
          fieldState: { invalid, isTouched, isDirty, error: formError },
          formState,
        }) => (
          <DesktopDatePicker
            id={id}
            inputFormat={inputFormatMap[currentLanguage] || inputFormatMap["de"]}
            mask={maskMap[currentLanguage] || maskMap["de"]}
            label={label}
            value={formValue}
            disabled={disabled}
            onChange={(e) => {
              formOnChange(e);
              handleChange(e);
            }}
            name={formName}
            renderInput={(params) => {
              const paramsWithInputRefAndEventHandlers = {
                ...params,
                inputRef: (el) => {
                  inputRef.current = el;
                  fromRef(el);
                  if (params.inputRef) { params.inputRef(el); }
                },
                onBlur: (el) => {
                  formOnBlur(el);
                  if (params.onBlur) { params.onBlur(el); }
                },
                onKeyDown: (el) => {
                  handleKeyDown(el);
                  if (params.onKeyDown) { params.onKeyDown(el); }
                },
              };
              if (renderInput) {
                return renderInput(paramsWithInputRefAndEventHandlers);
              } else {
                return <TextField {...paramsWithInputRefAndEventHandlers} />;
              }
            }}
            minDate={minDate}
            maxDate={maxDate}
            disablePast={disablePast}
          />
        )}
      />
    </div>
  );
};

export default Datepicker;