import axios from "axios";
import { BASE_URL } from "../../core/apiconfig/environment";
import { TOKEN_NAME } from "../../core/apiconfig/constantApi";

const resetRatiorKeyFunc = async (data, { getState }) => {
  const { email } = data;
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `bearer ${localStorage.getItem(TOKEN_NAME)}`,
    },
  };
  try {
    const result = await axios.post(
      `${BASE_URL}user/ratiorkey/forgot/email`,
      {
        email,
      },
      config
    );

    if (result?.data?.status != 200)
      throw Error("Diese E-Mail-Adresse existiert nicht"); //("Email does not exist");

    return result;
  } catch (err) {
    throw Error(
      err.response && err.response.data.detail
        ? err.response.data.detail
        : err.message
    );
  }
};

export default resetRatiorKeyFunc;
