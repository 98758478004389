import axios from "axios";
import { BASE_URL } from "../../../core/apiconfig/environment";
import { DEPOSIT_DIRECTION } from "../../../core/constants";
import { dateString } from "../../../core/utils/convert";
import { TOKEN_NAME } from "../../../core/apiconfig/constantApi";

const getDepositList = async (vermittlerId) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `bearer ${localStorage.getItem(TOKEN_NAME)}`,
    },
  };
  try {
    const result = await axios.get(`${BASE_URL}data/sales-orders/managed/account/${vermittlerId}/`, config);
    return result?.data;
  } catch {
    throw Error("Kundenzahlungen-Liste konnte nicht geladen werden"); //("customer deposit list not fetched");
  }
};

const getCustomerDepositListFunc = async (params, { getState }) => {
  const { vermittlerId } = params;
  const deposits = (await getDepositList(vermittlerId)) ?? [];

  let list = [];

  deposits.map((item) => {
    let tmp = {
      arades_ueberweisungsdatum: item.arades_datum_geldeingang,
      arades_richtung: item.arades_richtung,
      arades_richtung_title:
        item.arades_richtung == DEPOSIT_DIRECTION.OUTGOING
          ? "Outgoing"
          : "Incoming",
      arades_betrag: item.arades_ueberweisungsbetrag,
      arades_betrag_invested: item.arades_anlagebetrag,
      unit_price: item.arades_einzelpreis,
      createdon: item.createdon,
      createdon_formatted: dateString(item.createdon),
      storageNumber:
        item[
          "_arades_lagerplatz_kaeufer_value@OData.Community.Display.V1.FormattedValue"
        ],
    };

    tmp.customerName = item["entity.firstname"] //customer is contact otherwise is account
      ? (item["entity.arades_titel"]
          ? item[
              "entity.arades_titel@OData.Community.Display.V1.FormattedValue"
            ] + " "
          : "") +
        item["entity.firstname"] +
        " " +
        item["entity.lastname"]
      : item["entity.name"];

    list.push(tmp);
  });

  return list;
};
export default getCustomerDepositListFunc;
