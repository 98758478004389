import React, { useState } from "react";
import { TableRow, TableCell, Typography } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperclip } from "@fortawesome/free-solid-svg-icons";

const SentMessageRow = (rowInfo) => {
  return (
    <React.Fragment>
      <TableRow
        sx={{
          position: "relative",
          cursor: "pointer",
          "&:hover": {
            bgcolor: "#f5f5f5",
            transition: ".3s all",
          },
          "& td": {
            verticalAlign: "middle !important",
          },
        }}
      >
        <TableCell>{rowInfo.data.to} </TableCell>
        <TableCell>{rowInfo.data.subject}</TableCell>
        <TableCell>
          <Typography variant="caption">{rowInfo.data.regardedTo}</Typography>
        </TableCell>
        <TableCell>
          <Typography variant="caption">
            {" "}
            {rowInfo.data.receivedTime} . {rowInfo.data.receivedDate}{" "}
          </Typography>
        </TableCell>
        <TableCell>
          {rowInfo.data.attachedFiles.length > 0 && (
            <FontAwesomeIcon color="rgba(0, 0, 0, 0.38)" icon={faPaperclip} />
          )}
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};
export default SentMessageRow;
