import React from "react";
import {Controller } from 'react-hook-form';
import {FormControl, RadioGroup,FormHelperText} from '@mui/material';
 
const MUIRadioGroup = ({
  control=null,
  label,
  error,
  required,
  helperText,
  component,
  onChange,
  onBlur,
  value,
  name,
  inputRef,
  sx,
  children,
  ...props
}) => {
  return (
    <>
    <Controller
      control={control}
      name={name}
      render={({
          field: {onChange:formOnChange, onBlur:formOnBlur, value:formValue, name:formName, ref:fromRef },
          fieldState: { invalid, isTouched, isDirty, error:formError },
          formState,
      }) => (


        <>
      <FormControl
        required={required}
        error={Boolean(formError)}
        component={component}
        sx={sx}
      >
            <RadioGroup 
              onChange={(e)=>{
                formOnChange(e)
                if(onChange) onChange(e)
              }}
              onBlur={formOnBlur}
              value={formValue}
              name={formName}
              ref={fromRef}
              {...props} 
            >
              {children}

            </RadioGroup>

            {helperText && (
              <FormHelperText sx={{mx:0}}>{helperText}</FormHelperText>
            )}
          </FormControl>
         </>

  
      )} />

    </>
  );
};

export default MUIRadioGroup;
