import React, { useState, useEffect } from "react";
import DataTable from "../common/grids";
import { useApi } from "../../core/hooks/useApi";
import { BASE_URL } from "../../core/apiconfig/environment";
import { useTranslation } from "react-i18next";

const CustomerCommissionList = ({ customerId, isContact }) => {
  const [data, setData] = useState([]);
  const { get } = useApi();
  const { t } = useTranslation();

  const loadData = async () => {
    let result;
    if (isContact) {
      result = await get(`${BASE_URL}data/sales-orders/contact/${customerId}/`);
    } else {
     result = await get(`${BASE_URL}data/sales-orders/account/${customerId}/`);
    }
    if (result.data && result.data.value) { setData(result.data.value); }
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    <DataTable
      title={t("commission-list.title")}
      entityName={"salesorder"}
      size={"sm"}
      data={data}
      columns={[
        { name: "name", caption: t("commission-list.name") },
        {
          name: "totallineitemamount",
          caption: t("commission-list.total"),
          alignment: "right",
        },
        { name: "createdon", caption: t("commission-list.created-on") },
      ]}
    ></DataTable>
  );
};
export default CustomerCommissionList;
