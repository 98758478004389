import React, { useEffect, useState } from "react";
import { Box, FormControl, FormLabel, Button } from "@mui/material";
import { RadioListIcon, FormSteperStructure } from "../../common";
import { useDispatch, useSelector } from "react-redux";
import {
  setSelectStorageType,
  transferSelectStorageTypeFields,
  resetSelectStorage,
} from "../../../redux/slices/transfer/transfer.slice";
import { useTranslation } from "react-i18next";

function SelectReceptor({ handleCurrentStep }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const storageTypeFields = useSelector(transferSelectStorageTypeFields);
  const [storageType, setStorageType] = useState(storageTypeFields.storageType);

  const radioList = [
    {
      label: t("transfer.main-storage"),
      value: "mainStorage",
      disabled: !storageTypeFields.canSelectStorage,
    },
    {
      label: t("transfer.sub-storage"),
      value: "subStorage",
      disabled: !storageTypeFields.canSelectSubstorage,
    },
  ];

  const submitForm = () => {
    dispatch(
      setSelectStorageType({ ...storageTypeFields, storageType: storageType })
    );
    handleCurrentStep("next");
  };
  return (
    <>
      <Box
        sx={{
          margin: "0 auto",
          minHeight: "calc(100vh - 250px)",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "space-around",
        }}
      >
        <Box sx={{ width: { xs: "100%", md: "50%" } }}>
          <FormControl sx={{ minWidth: "100%" }}>
            <FormLabel sx={{ fontSize: "14px", mb: "20px" }} id="radio-group">
              {t("transfer.select-destination")}
            </FormLabel>
            <RadioListIcon
              currentValue={storageType}
              handleSetCurrentValue={(value) => {
                dispatch(resetSelectStorage());
                setStorageType(value);
              }}
              data={radioList}
            />
          </FormControl>
        </Box>

        <FormSteperStructure.Footer>
          <Button
            onClick={submitForm}
            fullWidth
            sx={{ backgroundColor: "info.main", maxWidth: "192px" }}
            variant="contained"
          >
            {t("buttons.next")}
          </Button>
        </FormSteperStructure.Footer>
      </Box>
    </>
  );
}

export default SelectReceptor;
