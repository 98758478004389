import { createAsyncThunk } from "@reduxjs/toolkit";
import { getOptionSetFunc } from "./../../sharedFunctions";

const getSecurityOptionsAction = createAsyncThunk(
  "storage/securityOptions",
  async (filters, { getState }) => {
    let dictionary = await getOptionSetFunc(
      { ownerTable: "msdyn_warehouse", optionFieldName: "arades_sicherheit" },
      { getState }
    );
    return dictionary;
  }
);

export default getSecurityOptionsAction;
