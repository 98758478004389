import { Currency } from "../../../core/utils/convert";
import axios from "axios";
import { BASE_URL } from "../../../core/apiconfig/environment";
import { TOKEN_NAME } from "../../../core/apiconfig/constantApi";

const getLevelsDataFunc = async (params, { getState }) => {
  const { sumAmount } = params;
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `bearer ${localStorage.getItem(TOKEN_NAME)}`,
    },
  };

  const result = await axios.get(`${BASE_URL}data/fee-levels/`, config);

  if (!result?.data?.data?.value || result.status != 200)
    throw Error("Gebuehrenstaffelung konnte nicht geladen werden"); //("fee list list not fetched");

  let funcResult = {
    remaintToNextLevel: null,
    remaintToNextLevel_formatted: null,
    nextLevel: null,
    currentLevelIsLast: true,
  };
  for (let i = 0; i < result?.data?.data?.value?.length; i++) {
    let item = result.data.data.value[i];
    if (
      sumAmount >= item.arades_von &&
      sumAmount <= item.arades_bis &&
      i != result?.data?.data?.value?.length - 1
    )
      funcResult = {
        remaintToNextLevel: item.arades_bis - sumAmount + 0.01,
        remaintToNextLevel_formatted: Currency(
          item.arades_bis - sumAmount + 0.01
        ),
        nextLevel: result.data.data.value[i + 1].arades_name,
        currentLevelIsLast: false,
      };
  }
  return funcResult;
};

export default getLevelsDataFunc;
