import React, { useState, useEffect } from "react";
import { Radio, Button } from "@mui/material";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import alarm from "../../assets/images/alarm.svg";
import "./bankAccountStep.scss";
import { useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import {
  sellStorageBankAccount,
  setStorageBankAccount,
} from "../../redux/slices/sell/sell.slice";
import { useTranslation } from "react-i18next";

import { FormSteperStructure } from "../common";

const StorageBankAccountsStep = ({ handleCurrentStep }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const detailStorageId = useParams().storageid;
  const storagebankAccount = useSelector(sellStorageBankAccount);

  const OTHER_ACCOUNT = "other";

  const [selectedAccount, setSelectedAccount] = useState(OTHER_ACCOUNT);

  useEffect(() => {
    setSelectedAccount(storagebankAccount.selectedBankAccount);
  }, []);

  const handleRadio = (e) => {
    setSelectedAccount(e.target.value);
  };

  const onSubmit = async () => {
    if (selectedAccount != OTHER_ACCOUNT) {
      dispatch(
        setStorageBankAccount({
          selectedBankAccount:
            storagebankAccount.bankAccountInfo.arades_kontoid,
          bankAccountInfo: storagebankAccount.bankAccountInfo,
          detailStorageId: detailStorageId,
        })
      );
      handleCurrentStep("next2");
    } else {
      dispatch(setStorageBankAccount({ selectedBankAccount: "other" }));
      handleCurrentStep("next");
    }
  };

  return (
    <div className="row d-flex">
      <div className="d-flex flex-md-row col-12 ">
        <div className="row d-flex align-self-start">
          <div className="col-12 mt-4">
            <FormControl>
              <div className="d-flex div-attention">
                <img src={alarm}></img>
                <span className=" attention">
                  {t("sell.bank-account-attention")}
                </span>
              </div>
              <RadioGroup
                onChange={handleRadio}
                value={selectedAccount}
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue={selectedAccount}
                name="radio-buttons-group"
              >
                {storagebankAccount?.bankAccountInfo && (
                  <FormControlLabel
                    value={storagebankAccount.bankAccountInfo.arades_kontoid}
                    control={<Radio />}
                    label={
                      <div>
                        <b>
                          {
                            storagebankAccount.bankAccountInfo
                              .arades_kontoinhaber
                          }
                        </b>
                        &nbsp;
                        {"(" +
                          (storagebankAccount.bankAccountInfo.arades_iban
                            ? storagebankAccount.bankAccountInfo.arades_iban
                            : storagebankAccount.bankAccountInfo
                                .arades_kontonummer +
                              " - " +
                              storagebankAccount.bankAccountInfo
                                .arades_bankleitzahl) +
                          " - " +
                          storagebankAccount.bankAccountInfo.arades_bank +
                          ")"}
                      </div>
                    }
                  />
                )}

                <FormControlLabel
                  value={OTHER_ACCOUNT}
                  control={<Radio />}
                  label={t("bank-account.add-another")}
                />
              </RadioGroup>
            </FormControl>
          </div>
        </div>
      </div>
      <FormSteperStructure.Footer>
        <Button
          onClick={onSubmit}
          fullWidth
          sx={{ bgcolor: "info.main", maxWidth: "192px" }}
          variant="contained"
        >
          {t("buttons.confirm")}
        </Button>
      </FormSteperStructure.Footer>
    </div>
  );
};

export default StorageBankAccountsStep;
