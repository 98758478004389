import React, { useEffect, useState } from "react";
import Header from "./Header";
import Sidebar from "./sidebar/sidebar";
import PanelRoutes from "./PanelRoutes";
import {
  TOKEN_NAME,
  MOBILE_VERIFIED,
  ADDRESS_VERIFIED,
  NECT_VERIFIED,
  FULLNAME,
  RATIOR_KEY,
  CONTACT_BIRTHDATE,
  CONTACT_ID,
  MOBILE_NUMBER,
  WORKSPACE_ID,
  WORKSPACE_TYPE,
  WORKSPACE_NAME,
  VERMITTLER_ID,
  ACTION_CODE_ID,
  STATUS_CODE,
  GUARDIAN1,
  GUARDIAN2,
  DOCUMENTS_UPLOADED,
} from "../../core/apiconfig/constantApi";
import { useNavigate, useSearchParams } from "react-router-dom";
import { BASE_URL, BYPASS_NECT } from "../../core/apiconfig/environment";
import {
  CONTACT_STATUS,
  URL,
  WORKSPACETYPE,
  ERROR_CONTACT_US,
} from "../../core/constants";
import { useSnackbar } from "notistack";
import { useApi } from "../../core/hooks/useApi";
import { ConfirmModal } from "../common";
import { screenIsDesktop } from "../../core/utils/responsive";
import {
  getRatiorDataAction,
  getCountryListAction,
  getGeschlechtListAction,
  getGenderListAction,
  getTitleListAction,
  getCustomerLoyaltyDataAction,
  getBankCurrencyListAction,
} from "../../redux/slices/globals";
import {
  globalsGlobalData,
  resetError,
} from "../../redux/slices/globals/globals.slice";
import { useDispatch, useSelector } from "react-redux";
import { resetCache } from "../../core/utils/cachManager";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import nextStepArrow from "../../assets/images/nextStepArrow.svg";
import { useTranslation } from "react-i18next";

const Panel = (props) => {
  const { get } = useApi();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const globalState = useSelector(globalsGlobalData);
  const [fullName, setFullName] = useState("");
  const [workSpaceName, setWorkSpaceName] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  const [showMenu, setShowMenu] = useState(false);
  const [openModalStorage, setOpenModalStorage] = useState(
    Boolean(
      searchParams.get("first-login") ||
        (BYPASS_NECT && localStorage.getItem(DOCUMENTS_UPLOADED) == "true")
    )
  );
  const [openModalContactInfoConflict, setOpenModalContactInfoConflict] =
    useState(false);
  // const [openModalWorkspaceType, setOpenModalWorkspaceType] = useState(
  //   Boolean(searchParams.get("first-login"))
  // );
  //const [openModalPermisson, setOpenModalPermisson] = useState(false);
  //const [workspaceType, setWorkspaceType] = useState("person");

  const [firstLogin, setFirstLogin] = useState(
    Boolean(searchParams.get("first-login"))
  );

  const isDesktop = screenIsDesktop();

  useEffect(() => {
    if (!localStorage.getItem(TOKEN_NAME)) navigate(URL.LOGIN);

    dispatch(getRatiorDataAction());
    dispatch(getCountryListAction());
    dispatch(getGenderListAction());
    dispatch(getGeschlechtListAction());
    dispatch(getTitleListAction());
    dispatch(getBankCurrencyListAction());

    dispatch(
      getCustomerLoyaltyDataAction({
        customerId: localStorage.getItem(WORKSPACE_ID),
        isContact:
          localStorage.getItem(WORKSPACE_TYPE) == WORKSPACETYPE.CONTACT ||
          localStorage.getItem(WORKSPACE_TYPE) == WORKSPACETYPE.UNDER_AGE,
      })
    );

    setContact(localStorage.getItem(CONTACT_ID));
    if (
      !(
        localStorage.getItem(WORKSPACE_TYPE) == WORKSPACETYPE.CONTACT ||
        localStorage.getItem(WORKSPACE_TYPE) == WORKSPACETYPE.UNDER_AGE
      )
    )
      setVermittler(localStorage.getItem(WORKSPACE_ID));

    if (!firstLogin) {
      setFullName(localStorage.getItem(FULLNAME));
      setWorkSpaceName(localStorage.getItem(WORKSPACE_NAME));
    }
  }, []);

  const setContact = async (id) => {
    const result = await get(`${BASE_URL}data/contacts/${id}/`);
    let contact = result?.data;
    if (contact) {
      let title =
        contact[
          "_arades_titel_value@OData.Community.Display.V1.FormattedValue"
        ];

      let fullName =
        (title ? title + " " : "") + contact.firstname + " " + contact.lastname;

      localStorage.setItem(RATIOR_KEY, contact.arades_ratior_key);
      localStorage.setItem(MOBILE_NUMBER, contact.mobilephone);
      localStorage.setItem(VERMITTLER_ID, contact._arades_vermittler_value);
      localStorage.setItem(ACTION_CODE_ID, contact._arades_aktionscode_value);
      localStorage.setItem(STATUS_CODE, contact.statuscode);
      localStorage.setItem(
        GUARDIAN1,
        contact._arades_erziehungsberechtigter1_value
      );
      localStorage.setItem(
        GUARDIAN2,
        contact._arades_erziehungsberechtigter2_value
      );
      if (contact.birthdate)
        localStorage.setItem(CONTACT_BIRTHDATE, contact.birthdate);
      if (contact.arades_mobilnummer_verifiziert)
        localStorage.setItem(MOBILE_VERIFIED, "true");
      else localStorage.setItem(MOBILE_VERIFIED, "false");
      if (contact.arades_adresse_verifiziert)
        localStorage.setItem(ADDRESS_VERIFIED, "true");
      else localStorage.setItem(ADDRESS_VERIFIED, "false");
      if (contact.arades_nectverifiziert)
        localStorage.setItem(NECT_VERIFIED, "true");
      else localStorage.setItem(NECT_VERIFIED, "false");

      if (firstLogin) {
        localStorage.setItem(WORKSPACE_ID, contact.contactid);
        localStorage.setItem(WORKSPACE_TYPE, WORKSPACETYPE.CONTACT);
        localStorage.setItem(FULLNAME, fullName);
        localStorage.setItem(WORKSPACE_ID, contact.contactid);
        localStorage.setItem(WORKSPACE_TYPE, WORKSPACETYPE.CONTACT);
        localStorage.setItem(WORKSPACE_NAME, fullName);
      }

      if (contact.statuscode == CONTACT_STATUS.NECT_RED)
        setOpenModalContactInfoConflict(true);
      else {
        setFullName(localStorage.getItem(FULLNAME));
        setWorkSpaceName(localStorage.getItem(WORKSPACE_NAME));
      }
    }
  };

  const setVermittler = async (id) => {
    const result = await get(`${BASE_URL}data/accounts/${id}/`);
    let account = result?.data;
    if (account) { localStorage.setItem(VERMITTLER_ID, account._arades_vermittler_value); }
  };

  useEffect(() => {
    if (globalState.error !== null) {
      enqueueSnackbar(globalState.error + ERROR_CONTACT_US, {
        variant: "error",
        autoHideDuration: 20000,
      });
      dispatch(resetError());
    }
  }, [globalState.error]);

  const toggleDrawer = (open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setShowMenu(open);
  };

  // const radioList = [
  //   {
  //     icon: <FontAwesomeIcon icon={faUser} />,
  //     label: "Continue as a person",
  //     value: "person",
  //   },
  //   {
  //     icon: <FontAwesomeIcon icon={faBuilding} />,
  //     label: "I want to use it for my company",
  //     value: "company",
  //   },
  // ];

  return (
    <>
      {/* <ConfirmModal
        noImage={true}
        open={openModalWorkspaceType}
        title="Welcome"
        showCloseButton={false}
        message={
          <Box
            sx={{
              margin: "0 auto",
              width: "100%",
              "& span": {
                marginRight: "0 !important",
              },
            }}
          >
            <FormControl sx={{ minWidth: "100%" }}>
              <FormLabel
                sx={{ fontSize: "14px", textAlign: "center", mb: "20px" }}
                id="radio-group"
              >
                Welcome to ratior! Please choose your next step
              </FormLabel>
              <RadioListIcon
                currentValue={workspaceType}
                handleSetCurrentValue={(value) => {
                  setWorkspaceType(value);
                }}
                data={radioList}
              />
            </FormControl>
          </Box>
        }
        okTitle="Continue"
        onOk={() => {
          if (workspaceType === "person") {
            navigate("/");
            setOpenModalWorkspaceType(false);
          } else {
            setOpenModalWorkspaceType(false);
            setOpenModalPermisson(true);
          }
        }}
      ></ConfirmModal>
      <ConfirmModal
        open={openModalPermisson}
        title={t("underage.confirm-you-need-permisson")}
        showCloseButton={false}
        message={
          <div>
            {t("underage.confirm-new-account-for-permission")}            
          </div>
        }
        noImage={true}
        acceptTitle={t("underage.confirm-yes-continue")}
        rejectTitle={t("buttons.cancel")}
        onAccept={() => {
          setOpenModalPermisson(false);
          navigate(URL.REGISTERCOMPANY);
        }}
        onReject={() => {
          navigate("/");
          setOpenModalPermisson(false);
        }}
      ></ConfirmModal> */}

      <ConfirmModal
        open={openModalStorage}
        title={t("components.first-login.title")}
        size="md"
        showCloseButton={false}
        imageSrc={nextStepArrow}
        message={t("components.first-login.set-up-storage-instructions")}
        acceptTitle={"Lagerplätze eröffnen"}
        highlightAccept={true} /* Highlight more so that customers click it more likely */
        rejectTitle={"Später"}
        onAccept={() => {
          setOpenModalStorage(false);
          localStorage.setItem(DOCUMENTS_UPLOADED, "false");
          navigate(URL.ADDSTORAGE);
        }}
        onReject={() => {
          localStorage.setItem(DOCUMENTS_UPLOADED, "false");
          navigate("/");
          setOpenModalStorage(false);
        }}
      ></ConfirmModal>

      <ConfirmModal
        open={openModalContactInfoConflict}
        title="Ihre Daten stimmen nicht überein" //{"Data is not matched"}
        showCloseButton={false}
        size="md"
        message={
          <div>
            Liebe/r Anrede {localStorage.getItem(FULLNAME)},<br></br>
            ups, da ging etwas schief bei Ihrer Verifizierung über Nect. Unser
            Kundendienst prüft den Vorgang gerade. Wir werden Sie sofort
            informieren, sobald die Verifizierung erfolgreich beendet ist.
            Sollten weiterhin Unklarheiten auftreten, werden wir auf Sie
            zukommen.<br></br>
            In der Zwischenzeit können Sie sich für Fragen und Wünsche gerne
            auch von sich aus an uns wenden, per Telefon +49-(0)6227-65506 10
            oder per E-Mail an Kunde@Ratior.de
            <br></br>
            Herzliche Grüße
            <br></br>
            Ihr Ratior Team
            {/* The personal data that you entered is not correctly matched with the
            data verified with NECT. Our support will contact you soon to solve
            the problem. You can’t use the system until your data get verified,
            please be patient. */}
          </div>
        }
        okTitle="Ausloggen" //{"LOG OUT"}
        onOk={() => {
          resetCache();
          navigate(URL.LOGIN);
          window.location.reload();
        }}
      ></ConfirmModal>

      <div className="panel-container">
        <Header
          fullname={fullName}
          showMenu={showMenu}
          setShowMenu={setShowMenu}
        />
        <div className="dashboard-wrapper">
          {isDesktop && (
            <Sidebar fullname={fullName} workspaceName={workSpaceName} />
          )}
          {!isDesktop && (
            <SwipeableDrawer
              anchor={"left"}
              open={showMenu}
              onClose={toggleDrawer(false)}
              onOpen={toggleDrawer(true)}
              PaperProps={{
                sx: {
                  backgroundColor: "black",
                },
              }}
            >
              <Sidebar
                fullname={fullName}
                workspaceName={workSpaceName}
                onClick={toggleDrawer(false)}
              />
            </SwipeableDrawer>
          )}
          <PanelRoutes />
        </div>
      </div>
    </>
  );
};

export default Panel;
