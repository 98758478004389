import axios from "axios";
import { BASE_URL } from "../../../core/apiconfig/environment";
import { WORKSPACETYPE } from "../../../core/constants";
import { TOKEN_NAME } from "../../../core/apiconfig/constantApi";

const getContractListFunc = async (params, { getState }) => {
  const { ownerId, ownerType } = params;
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `bearer ${localStorage.getItem(TOKEN_NAME)}`,
    },
  };

  let result;
  if (ownerType === WORKSPACETYPE.CONTACT || ownerType === WORKSPACETYPE.UNDER_AGE) {
    result = await axios.get(`${BASE_URL}data/contracts/contact/${ownerId}/`, config);
  } else {
    result = await axios.get(`${BASE_URL}data/contracts/account/${ownerId}/`, config);
  }

  if (!result?.data?.data?.value || result.status != 200)
    throw Error("Auftragsliste konnte nicht geladen werden"); //("contract list not fetched");

  let list = [];
  result.data.data.value.map((item) => {
    let tmpItem = item;
    tmpItem.downloadLink = `${BASE_URL}download/adobe/agreement?id=${item.adobe_esagreementid}`;
    console.log(tmpItem.downloadLink);
    list.push(tmpItem);
  });
  return list;
};
export default getContractListFunc;
