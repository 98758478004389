import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { dateString } from "../../../core/utils/convert";
import { TOKEN_NAME, WORKSPACE_ID, WORKSPACE_TYPE } from "../../../core/apiconfig/constantApi";
import { BASE_URL } from "../../../core/apiconfig/environment";
import { WORKSPACETYPE } from "../../../core/constants";

const getCustomerActionCodeListAction = createAsyncThunk(
  "clientDashboard/getCustomerActionCodeList",
  async (params, { getState }) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${localStorage.getItem(TOKEN_NAME)}`,
      },
    };

    const workspaceId = localStorage.getItem(WORKSPACE_ID);
    const workspaceType = localStorage.getItem(WORKSPACE_TYPE);
    let result;
    if (workspaceType === WORKSPACETYPE.CONTACT || workspaceType === WORKSPACETYPE.UNDER_AGE) {
      result = await axios.get(`${BASE_URL}data/action-codes/contact/${workspaceId}/`, config);
    } else {
      result = await axios.get(`${BASE_URL}data/action-codes/account/${workspaceId}/`, config);
    }

    if (result?.data?.status != 200)
      throw Error("Aktionscode konnte nicht geladen werden"); //("Action Codes not fetched");

    let toShowList = result.data.data.value.sort((a, b) =>
      a?.createdon >= b?.createdon ? -1 : 1
    );

    let list = [];
    for (let i = 0; i < toShowList?.length; i++) {
      let itemUser = toShowList[i];
      let item = itemUser?.arades_aktionscode;
      let tmp = {
        id: item?.arades_aktionscodeid,
        actionCode: item?.arades_name,
        discount: (item?.arades_prozent ? item?.arades_prozent : 0) + "%",
        allowRegisterCount: item?.arades_maximum_anzahl,
        registeredCount: item?.arades_aktuelle_anzahl,
        loyalityMonthCount: item?.arades_treue_monate,
        usersUnLimited: item?.arades_aktionscode_limitiert == false,
        validForever: item?.arades_aktionscode_unbegrenzt_gueltig,
        arades_typ: item?.arades_typ,
        startDate: dateString(item?.arades_startdatum),
        endDate: dateString(item?.arades_enddatum),
        description: item?.arades_anlas,
        active: itemUser?.statecode == 0 && item?.statecode == 0,
      };
      list.push(tmp);
    }

    return list;
  }
);

export default getCustomerActionCodeListAction;
