import React from "react";
import { useNavigate } from "react-router-dom";
import { Box, Button, Grid, Typography } from "@mui/material";
import { FormSteperStructure, PhoneInput } from "../../common";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { verificationSendSMSCodeAction } from "../../../redux/slices/profile";
import {
  globalData,
  setPhoneNumber,
} from "../../../redux/slices/profile/profile.slice";
import { useSnackbar } from "notistack";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

function ChangePhoneNumberStep({ handleCurrentStep }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const requiredMsg = "validation.required";

  const schema = yup.object().shape({
    mobilephone: yup
      .string()
      .test(
        "len",
        t("components.change-phone.invalid-number"),
        (val) => val.length >= 8
      )
      .required(requiredMsg),
  });

  const {
    control,
    handleSubmit,
    setError,
    getValues,
    formState: { errors },
  } = useForm({
    mode: "all",
    defaultValues: {
      mobilephone: "",
    },
    resolver: yupResolver(schema),
  });

  const submitForm = (data) => {
    dispatch(setPhoneNumber("+" + data.mobilephone));
    dispatch(
      verificationSendSMSCodeAction({ mobileNumber: "+" + data.mobilephone })
    );
    handleCurrentStep("next");
  };

  return (
    <>
      <Box
        sx={{
          margin: "0 auto",
          minHeight: "calc(100vh - 250px)",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "space-around",
        }}
      >
        <Box sx={{ width: { xs: "100%", md: "50%" } }}>
          <Grid direction="column" container spacing={8}>
            <Grid item>
              <Typography variant="body1" mb="30px">
                {t("components.registration-verification.legitimation-description")}
              </Typography>
              <PhoneInput
                control={control}
                country="de"
                name="mobilephone"
                label={t("personal-info.mobile") + "*"}
                error={Boolean(errors.mobilephone)}
                helperText={errors.mobilephone?.message}
                onEnter={() => handleSubmit(submitForm)}
              />
            </Grid>
          </Grid>
        </Box>

        <FormSteperStructure.Footer>
          <Button
            onClick={handleSubmit(submitForm)}
            fullWidth
            sx={{ backgroundColor: "info.main", maxWidth: "192px" }}
            variant="contained"
          >
            {t("components.change-phone.send-code")}
          </Button>
        </FormSteperStructure.Footer>
      </Box>
    </>
  );
}

export default ChangePhoneNumberStep;
