import { createAsyncThunk } from "@reduxjs/toolkit";

const cancelPowerOfAttorneyAction = createAsyncThunk(
  "powerOfAttorney/cancelPowerOfAttorney",
  async (dataParams, { getState }) => {
      alert("Cancel "+ dataParams)
  }
);

export default cancelPowerOfAttorneyAction;
