import { createSlice } from "@reduxjs/toolkit";
import { METAL_CHARS } from "../../../core/constants";
import {
  getRatiorDataAction,
  getCountryListAction,
  getGenderListAction,
  getGeschlechtListAction,
  getTitleListAction,
  getMetalPriceAction,
  getBankCurrencyListAction,
} from "./";

import { getCustomerLoyaltyDataAction } from "./";

const initialState = {
  globalData: {
    actionStatus: false,
    actionType: "",
    loading: false,
    error: null,
  },
  countries: [],
  bankCurrencies: [],
  titles: [],
  genders: [],
  geschlechts: [],
  ratiorData: {
    ratiorBankAccount: {},
    ratiorAGBankAccount: {},
    ratiorInfo: {},
    ratiorAGInfo: {},
    ratiorStorages: [],
    standardPriceLevelId: null,
    transferToOtherFee: 0,
    transferFee: 0,
    buyFee: 0,
    sellFee: 0,
    ratiorAgAccountId: null,
    ratiorGmbHAccountId: null,
    ratiorGoldAccountId: null,
  },
  customerLoyaltyData: {
    hasLoyalty: false,
    loyaltyMonthes: 0,
    loyaltyMonthId: null,
    loyaltyName: "",
    feePercent: 0,
    feePercent_formatted: "0",
  },
  metalPrices: {
    goldPrice: null,
    silverPrice: null,
    platinPrice: null,
    palladiumPrice: null,
  },
};
export const globalsSlice = createSlice({
  name: "globals",
  initialState,
  reducers: {
    resetAll: () => initialState,
    resetActionStatus: (state) => {
      state.globalData.actionStatus = false;
      state.globalData.actionType = false;
    },
    resetError: (state) => {
      state.globalData.error = null;
    },
  },
  extraReducers: {
    // get metal price
    [getMetalPriceAction.pending]: (state, action) => {
      state.globalData.loading = true;
    },
    [getMetalPriceAction.fulfilled]: (state, action) => {
      state.globalData.loading = false;
      if (action.payload.product == METAL_CHARS.GOLD)
        state.metalPrices.goldPrice = action.payload;
      else if (action.payload.product == METAL_CHARS.SILVER)
        state.metalPrices.silverPrice = action.payload;
      if (action.payload.product == METAL_CHARS.PLATIN)
        state.metalPrices.platinPrice = action.payload;
      if (action.payload.product == METAL_CHARS.PALLADIUM)
        state.metalPrices.palladiumPrice = action.payload;

      state.globalData.actionStatus = true;
    },
    [getMetalPriceAction.rejected]: (state, action) => {
      state.globalData.loading = false;
      state.globalData.error = action.error.message;
      state.globalData.goldPrice = null;
    },

    //get get Customer Loyalty Data
    [getCustomerLoyaltyDataAction.pending]: (state, action) => {
      state.globalData.loading = true;
    },
    [getCustomerLoyaltyDataAction.fulfilled]: (state, action) => {
      state.globalData.loading = false;
      state.customerLoyaltyData = action.payload;
    },
    [getCustomerLoyaltyDataAction.rejected]: (state, action) => {
      state.globalData.loading = false;
      state.globalData.error = action.error.message;
    },

    // get ratior data
    [getRatiorDataAction.pending]: (state, action) => {
      state.globalData.loading = true;
    },
    [getRatiorDataAction.fulfilled]: (state, action) => {
      state.globalData.loading = false;
      state.ratiorData = action.payload;
      state.globalData.actionType = "getRatiorData";
      state.globalData.actionStatus = true;
    },
    [getRatiorDataAction.rejected]: (state, action) => {
      state.globalData.loading = false;
      state.globalData.error = action.error.message;
    },

    // get countries
    [getCountryListAction.pending]: (state, action) => {
      state.globalData.loading = true;
    },
    [getCountryListAction.fulfilled]: (state, action) => {
      state.globalData.loading = false;
      state.countries = action.payload;
      state.globalData.actionStatus = true;
    },
    [getCountryListAction.rejected]: (state, action) => {
      state.globalData.loading = false;
      state.globalData.error = action.error.message;
    },

    // get genders
    [getGenderListAction.pending]: (state, action) => {
      state.globalData.loading = true;
    },
    [getGenderListAction.fulfilled]: (state, action) => {
      state.globalData.loading = false;
      state.genders = action.payload;
      state.globalData.actionStatus = true;
    },
    [getGenderListAction.rejected]: (state, action) => {
      state.globalData.loading = false;
      state.globalData.error = action.error.message;
    },

    // get geschlechts
    [getGeschlechtListAction.pending]: (state, action) => {
      state.globalData.loading = true;
    },
    [getGeschlechtListAction.fulfilled]: (state, action) => {
      state.globalData.loading = false;
      state.geschlechts = action.payload;
      state.globalData.actionStatus = true;
    },
    [getGeschlechtListAction.rejected]: (state, action) => {
      state.globalData.loading = false;
      state.globalData.error = action.error.message;
    },

    // get titles
    [getTitleListAction.pending]: (state, action) => {
      state.globalData.loading = true;
    },
    [getTitleListAction.fulfilled]: (state, action) => {
      state.globalData.loading = false;
      state.titles = action.payload;
      state.globalData.actionStatus = true;
    },
    [getTitleListAction.rejected]: (state, action) => {
      state.globalData.loading = false;
      state.globalData.error = action.error.message;
    },

    // get bank currencies
    [getBankCurrencyListAction.pending]: (state, action) => {
      state.globalData.loading = true;
    },
    [getBankCurrencyListAction.fulfilled]: (state, action) => {
      state.globalData.loading = false;
      state.bankCurrencies = action.payload;
      state.globalData.actionStatus = true;
    },
    [getBankCurrencyListAction.rejected]: (state, action) => {
      state.globalData.loading = false;
      state.globalData.error = action.error.message;
    },
  },
});

export const { resetError, resetActionStatus, resetAll } = globalsSlice.actions;

export const customerLoyaltyData = (state) => state.globals.customerLoyaltyData;
export const globalsGlobalData = (state) => state.globals.globalData;
export const globalsRatiorData = (state) => state.globals.ratiorData;
export const globalsMetalPrices = (state) => state.globals.metalPrices;
export const globalsCountries = (state) => state.globals.countries;
export const globalsGenders = (state) => state.globals.genders;
export const globalsGeschlechts = (state) => state.globals.geschlechts;
export const globalsTitles = (state) => state.globals.titles;
export const globalsBankCurrencies = (state) => state.globals.bankCurrencies;

export default globalsSlice.reducer;
