import React, { useState, useEffect } from "react";
import SubmitModal from "../popups/submitModal";
import { Input, Select } from "..";
import "./addressModal.scss";
import { useTranslation } from "react-i18next";
import { Radio, RadioGroup, FormControlLabel, Typography } from "@mui/material";

const AddressModal = ({ open, onClose, onAccept, initVal, countries }) => {
  const { t } = useTranslation();

  const radioList = [
    { label: "Address", value: "address" },
    { label: "Postfach", value: "postBox" },
  ];

  const [value, setValue] = useState({ ...initVal });
  const [selectedRadio, setSelectedRadio] = useState(radioList[0].value);

  useEffect(() => {
    setValue({ ...initVal });
    window.scrollTo(0, 0);
  }, [initVal]);

  const handleChange = (event) => {
    if (event.target.name == "country")
      setValue({
        ...value,
        countryName: countries?.filter((x) => x.key == event.target.value)[0]
          .value,
        countryId: event.target.value,
      });
    else setValue({ ...value, [event.target.name]: event.target.value });
  };

  const handleRadio = (e) => {
    setSelectedRadio(e.target.value);
    if (e.target.value == radioList[0].value) value.postbox = "";
    else if (e.target.value == radioList[1].value) {
      value.line1 = "";
      value.line2 = "";
    }
  };

  const formIsValid = () => {
    return (
      value.city &&
      value.countryId &&
      (selectedRadio != radioList[0].value || value.line1) &&
      value.postalcode
    );
  };

  return (
    <SubmitModal
      open={open}
      title={t("address.confirm-new-address")}
      acceptTitle={t("buttons.save")}
      rejectTitle={t("buttons.cancel")}
      size="lg"
      onAccept={() => {
        onAccept(value);
        setValue({});
      }}
      onReject={() => {
        onClose();
        setValue({});
      }}
      formIsValid={formIsValid()}
      content={
        <div className="container">
          <div className="row d-flex ">
            <div className="col-12 mt-4">
              <RadioGroup
                onChange={handleRadio}
                value={selectedRadio}
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue={selectedRadio}
                name="radio-buttons-group"
              >
                {radioList.map((item, i) => (
                  <FormControlLabel
                    label={
                      <Typography sx={{ fontSize: 16 }}>
                        {item.label}
                      </Typography>
                    }
                    key={`radio${i}`}
                    value={item.value}
                    control={<Radio />}
                  />
                ))}
              </RadioGroup>
            </div>
          </div>
          {selectedRadio == radioList[0].value && (
            <>
              <div className="row d-flex ">
                <div className="col-12 mt-4">
                  <Input
                    name={"line1"}
                    value={value.line1}
                    onChange={handleChange}
                    label={t("address.house-no-and-street") + "*"}
                  />
                </div>
              </div>

              <div>
                <div className="col-12 mt-4">
                  <Input
                    name={"line2"}
                    value={value.line2}
                    onChange={handleChange}
                    label={t("address.line2")}
                  />
                </div>
              </div>
            </>
          )}
          {selectedRadio == radioList[1].value && (
            <div className="row d-flex mt-4">
              <div className=" col-12 pl-2">
                <Input
                  name={"postbox"}
                  value={value.postbox}
                  onChange={handleChange}
                  label={t("address.postbox") + "*"}
                />
              </div>
            </div>
          )}
          <div className="row d-flex mt-4">
            <div className="col-md-6 col-12 ">
              <Input
                name={"postalcode"}
                value={value.postalcode}
                onChange={handleChange}
                label={t("address.postal-code") + "*"}
              />
            </div>
            <div className="col-md-6 col-12 pl-2">
              <Input
                name={"city"}
                value={value.city}
                onChange={handleChange}
                label={t("address.city") + "*"}
              />
            </div>
          </div>
          <div className="col-12 mt-4 ">
            <Select
              name={"country"}
              value={value.countryId}
              onChange={handleChange}
              options={countries}
              label={"Land*"}
            />
          </div>
        </div>
      }
    ></SubmitModal>
  );
};

export default AddressModal;
