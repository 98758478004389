import React from "react";
import { Button, Typography, Box } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import Structure from "./FormSteperStructure";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";
import { screenIsDesktop } from "../../../core/utils/responsive";

function FormSteper({
  children,
  parent = 0,
  noHeader = [],
  noFooter = [],
  headerTitles = [],
  noBackButton = [],
  hasCancel = [],
  onCancel,
  currentStep,
  setCurrentStep,
  loading,
}) {
  const { t } = useTranslation();
  const isDesktop = screenIsDesktop();

  return (
    <Structure loading={loading}>
      <Structure.Container withoutFlexAlign={noFooter.includes(currentStep)}>
        {!noHeader.includes(currentStep) && (
          <Structure.Header>
            <Box
              sx={{
                display: "flex",
                alignItems: "start",
              }}
            >
              {!noBackButton.includes(currentStep) && (
                <>
                  {currentStep === 1 && parent !== 0 && (
                    <Structure.StepBack
                      onClick={() => setCurrentStep("prev", parent)}
                      c="black"
                      pl="12px"
                      pr="12px"
                    >
                      <FontAwesomeIcon icon={faChevronLeft} />
                    </Structure.StepBack>
                  )}
                  {currentStep > 1 && (
                    <Structure.StepBack
                      onClick={() => setCurrentStep("prev")}
                      c="black"
                      pl="12px"
                      pr="12px"
                    >
                      <FontAwesomeIcon icon={faChevronLeft} />
                    </Structure.StepBack>
                  )}
                </>
              )}
              {headerTitles?.length && (
                <Typography
                  sx={{ fontWeight: 500 }}
                  variant={isDesktop ? "h5" : "h8"}
                  component={isDesktop ? "h5" : "div"}
                >
                  {headerTitles[currentStep - 1]}
                </Typography>
              )}
            </Box>
            {hasCancel.includes(currentStep) && (
              <Structure.StepRightButton
                size="small"
                onClick={onCancel}
                variant="outlined"
                startIcon={<CloseIcon />}
              >
                {t("buttons.cancel")}
              </Structure.StepRightButton>
            )}
          </Structure.Header>
        )}

        <Structure.Content currentStep={currentStep}>
          {children}
        </Structure.Content>

        {!noFooter.includes(currentStep) && (
          <Structure.Footer>
            <Button
              onClick={() => setCurrentStep("next")}
              fullWidth
              sx={{ bgcolor: "info.main", maxWidth: "192px" }}
              variant="contained"
            >
              {t("buttons.next")}
            </Button>
          </Structure.Footer>
        )}
      </Structure.Container>
    </Structure>
  );
}

export default FormSteper;
