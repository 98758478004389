import React, { useEffect, useState } from "react";
import UploadDocumentsStep from "./uploadDocuments.step";
import { Typography } from "@mui/material";
import { useParams } from "react-router";

function JustUploadDocuments() {
  const underAgeId = useParams().id;
  return (
    <div className="container-card">
      <Typography
        mb="13px"
        sx={{
          fontSize: "24px",
          fontWeight: "500",
        }}
      >
        {"Dokumente hochladen"}
      </Typography>

      <UploadDocumentsStep contactid={underAgeId}></UploadDocumentsStep>
    </div>
  );
}

export default JustUploadDocuments;
