import axios from "axios";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import { TOKEN_NAME } from "../apiconfig/constantApi";
import { BASE_URL } from "../apiconfig/environment";
import { URL, ERROR_CONTACT_US } from "../constants";
import { resetCache } from "../utils/cachManager";

export const useApi = () => {
  let instance;
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  let token = localStorage.getItem(TOKEN_NAME);

  const headers = {
    "Content-Type": "application/json",
  };

  if (token) {
    headers["Authorization"] = `Bearer ${token}`;
  }

  instance = axios.create({
    baseURL: BASE_URL,
    headers,
  });

  // API respone interceptor
  instance.interceptors.response.use(
    (response) => {
      if (response && response.data && response.data.status === 401) {
        enqueueSnackbar(
          "Ihre Sitzung abgelaufen ist, bitte loggen Sie sich erneut ein",
          {
            variant: "error",
            autoHideDuration: 20000,
          }
        );

        resetCache();
        navigate(URL.LOGIN);
        window.location.reload();
      } else return response.data;
    },
    (error) => {
      const notificationParam = {
        message: "",
      };

      if (!error.response) {
        notificationParam.message = "Network Error";
      }

      if (error.response && error.response.status === 401) {
        notificationParam.message = "please login";
        resetCache();
        navigate(URL.LOGIN);
      }

      if (error.response && error.response.status === 500) {
        notificationParam.message = "internal server error";
      }

      if (
        error.response &&
        (error.response.status === 400 ||
          (error.response.status > 401 && error.response.status < 500))
      ) {
        notificationParam.message =
          error.response && error.response.data && error.response.data
            ? error.response.data.message
            : "connection to server failed";
      }

      if (notificationParam.message?.length) {
        enqueueSnackbar(notificationParam.message + ERROR_CONTACT_US, {
          variant: "error",
          autoHideDuration: 20000,
        });
      }

      return Promise.reject(error);
    }
  );

  const get = (url, config) => {
    return instance.get(url, config);
  };

  const post = (url, data, config) => {
    return instance.post(url, data, config);
  };

  const put = (url, data, config) => {
    return instance.put(url, data, config);
  };

  const patch = (url, data, config) => {
    return instance.patch(url, data, config);
  };

  const remove = (url, data, config) => {
    return instance.delete(
      url,
      {
        data,
      },
      config
    );
  };

  const fetchOptions = async (ownerTable, optionFieldName) => {
    let dictionary = [];
    const result = await instance.get(`${BASE_URL}data/options/${ownerTable}/${optionFieldName}/`);

    if (result.data && result.data.OptionSet && result.data.OptionSet.Options) {
      result.data.OptionSet.Options.forEach((item) => {
        if (item.Value && item.Label && item.Label.LocalizedLabels)
          dictionary.push({
            key: item.Value,
            value: item.Label.LocalizedLabels[0].Label,
          });
      });
    }

    return dictionary;
  };

  return {
    get,
    post,
    put,
    patch,
    remove,
    fetchOptions,
  };
};
