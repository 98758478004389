import axios from "axios";
import { BASE_URL } from "../../../core/apiconfig/environment";
import { dateString, longDateTo10 } from "../../../core/utils/convert";
import { TOKEN_NAME, WORKSPACE_ID, WORKSPACE_TYPE } from "../../../core/apiconfig/constantApi";
import { WORKSPACETYPE } from "../../../core/constants";

const getActionCodeCustomerDataFunc = async (params, { getState }) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `bearer ${localStorage.getItem(TOKEN_NAME)}`,
    },
  };

  let queryParams = `?justActive=${params.justActive ? "true" : "false"}`; // Query Params for query
  if (params.actionCodeType) { // Specific action code type requested?
    queryParams += `&type=${params.actionCodeType}`; // Add action code type to query params
  }

  const workspaceId = params.customerId ?? localStorage.getItem(WORKSPACE_ID); // Use customerId if provided, else use global workspaceId
  const workspaceType = params.customerType ?? localStorage.getItem(WORKSPACE_TYPE); // Use customerType if provided, else use global workspaceType
  let result;
  if (workspaceType === WORKSPACETYPE.CONTACT || workspaceType === WORKSPACETYPE.UNDER_AGE) {
    result = await axios.get(`${BASE_URL}data/action-codes/by-type/contact/${workspaceId}/${queryParams}`, config);
  } else {
    result = await axios.get(`${BASE_URL}data/action-codes/by-type/account/${workspaceId}/${queryParams}`, config);
  }

  if (result?.data?.status !== 200)
    throw Error("Aktionscodes könnten nicht geladen werden"); //("customer Action code not fetched");

  let toShowList = result.data.data.value.sort((a, b) =>
    a?.createdon >= b?.createdon ? -1 : 1
  );

  let list = [];
  toShowList.map((item) => {
    if (
      item["a.arades_aktionscode_unbegrenzt_gueltig"] ||
      (longDateTo10(new Date(item["a.arades_startdatum"])) <=
        longDateTo10(new Date()) &&
        longDateTo10(new Date(item["a.arades_enddatum"])) >=
          longDateTo10(new Date()))
    ) {
      let tmp = {
        actionCodeUserId: item["arades_aktionscode_nutzerid"],
        id: item["a.arades_aktionscodeid"],
        actionCode: item["a.arades_name"],
        arades_prozent: item["a.arades_prozent"] ? item["a.arades_prozent"] : 0,
        discount:
          (item["a.arades_prozent"] ? item["a.arades_prozent"] : 0) + "%",
        allowRegisterCount: item["a.arades_maximum_anzahl"],
        registeredCount: item["a.arades_aktuelle_anzahl"] ?? 0,
        loyalityMonthCount: item["a.arades_treue_monate"],
        usersUnLimited: item["a.arades_aktionscode_limitiert"] == false,
        validForever: item["a.arades_aktionscode_unbegrenzt_gueltig"],
        arades_typ: item["a.arades_typ"],
        startDate: dateString(item["a.arades_startdatum"]),
        endDate: dateString(item["a.arades_enddatum"]),
      };

      list.push(tmp);
    }
  });

  return list;
};

export default getActionCodeCustomerDataFunc;
