import { createSlice } from "@reduxjs/toolkit";
import {
  STORAGE_PARTNER_SECURITY,
  STORAGE_UNDER_AGE_SECURITY,
} from "../../../core/constants";
import {
  verificationCheckSMSCodeAction,
  verificationSendSMSCodeAction,
  getStorageAmountAction,
  postSellAction,
  getStorageBankAccountAction,
  getPartnerDataAction,
  //getStorageListAction,
  getFormattedStorageAndSubStoragesAction,
} from ".";

const initialState = {
  globalData: {
    loading: false,
    actionStatus: false,
    actionType: null,
    //ratiorStorages: [],
    error: null,
  },
  sellSource: {
    fromStorageId: "",
    fromStorage: {},
    options: [],
  },
  verificationSteps: {
    needOtherPersonVerification: false,
    verification: false,
    sentSMS: false,
    partnerPhoneNumber: null,
  },
  storageBankAccount: {
    selectedBankAccount: "other",
    bankAccountInfo: {},
    detailStorageId: "",
    fromStorageNumber: "",
  },
  workspaceBankAccount: {
    selectedBankAccount: "other",
  },
  enterAmount: {
    amount: "",
    amountPerGram: "",
    maxAmountPerGram: "",
  },
  enterDescription: {
    description: "",
  },
};

export const sellSlice = createSlice({
  name: "sell",
  initialState,
  reducers: {
    setSelectedSource: (state, action) => {
      const { fromStorage, fromStorageId } = action.payload;
      state.sellSource.fromStorage = fromStorage;
      state.sellSource.fromStorageId = fromStorageId;
      if (
        String(fromStorage.arades_vertretungsberechtigung) ===
          STORAGE_PARTNER_SECURITY.BOTH ||
        String(fromStorage.arades_sicherheit) ===
          STORAGE_UNDER_AGE_SECURITY.BOTH_LEGAL_GUARDIAN
      )
        state.verificationSteps.needOtherPersonVerification = true;
      else state.verificationSteps.needOtherPersonVerification = false;
    },
    setStorageBankAccount: (state, action) => {
      const { selectedBankAccount, bankAccountInfo, detailStorageId } =
        action.payload;

      state.storageBankAccount.selectedBankAccount = selectedBankAccount;
      state.storageBankAccount.bankAccountInfo = bankAccountInfo;
      state.storageBankAccount.detailStorageId = detailStorageId;
    },
    setWorkspaceBankAccount: (state, action) => {
      state.workspaceBankAccount.selectedBankAccount = action.payload;
    },
    setEnterAmount: (state, action) => {
      state.enterAmount.amount = action.payload.amount;
      state.enterAmount.amountPerGram = action.payload.amountPerGram;
    },
    setEnterDescription: (state, action) => {
      state.enterDescription.description = action.payload;
    },
    resetSentSMS: (state) => {
      state.verificationSteps.sentSMS = false;
    },
    resetVerification: (state) => {
      state.verificationSteps.verification = false;
    },
    resetAll: () => initialState,
    resetActionStatus: (state) => {
      state.globalData.actionStatus = false;
    },
    resetError: (state) => {
      state.globalData.error = null;
    },
  },
  extraReducers: {
    //storage bank account
    [getStorageBankAccountAction.pending]: (state, action) => {
      state.globalData.loading = true;
    },
    [getStorageBankAccountAction.fulfilled]: (state, action) => {
      state.globalData.loading = false;
      state.storageBankAccount.bankAccountInfo = action.payload;
      state.globalData.actionStatus = true;
      state.globalData.actionType = "getStorageBankAccount";
    },
    [getStorageBankAccountAction.rejected]: (state, action) => {
      state.globalData.loading = false;
      state.globalData.error = action.error.message;
    },

    //dont get ratior storages from lager table , we get it from settings
    //get ratior storage List
    // [getStorageListAction.pending]: (state, action) => {
    //   state.globalData.loading = true;
    // },
    // [getStorageListAction.fulfilled]: (state, action) => {
    //   state.globalData.loading = false;
    //   const storages = action.payload;
    //   let list = [];
    //   storages.map((item) => {
    //     list.push({
    //       id: item.msdyn_warehouseid,
    //       metal: item._arades_edelmetall_typ_value,
    //       name: item.msdyn_name,
    //     });
    //   });
    //   state.globalData.ratiorStorages = list;
    // },
    // [getStorageListAction.rejected]: (state, action) => {
    //   state.globalData.loading = false;
    //   state.globalData.error = action.error.message;
    // },

    //Storage max amount
    [getStorageAmountAction.pending]: (state, action) => {
      state.globalData.loading = true;
    },
    [getStorageAmountAction.fulfilled]: (state, action) => {
      state.globalData.loading = false;
      state.enterAmount.maxAmountPerGram =
        action.payload.arades_verfuegbare_menge;
      state.globalData.actionType = "getStorageAmount";
      state.globalData.actionStatus = true;
    },
    [getStorageAmountAction.rejected]: (state, action) => {
      state.globalData.loading = false;
      state.globalData.error = action.error.message;
      state.globalData.actionStatus = false;
    },

    // post sell
    [postSellAction.pending]: (state, action) => {
      state.globalData.loading = true;
    },
    [postSellAction.fulfilled]: (state, action) => {
      state.globalData.loading = false;
      state.globalData.actionStatus = true;
      state.globalData.actionType = "postSell";
    },
    [postSellAction.rejected]: (state, action) => {
      state.globalData.loading = false;
      state.globalData.actionStatus = false;
      state.globalData.error = action.error.message;
    },

    //Verifications send
    [verificationSendSMSCodeAction.pending]: (state, action) => {
      state.globalData.loading = true;
    },
    [verificationSendSMSCodeAction.fulfilled]: (state, action) => {
      state.globalData.loading = false;
      state.verificationSteps.sentSMS = true;
    },
    [verificationSendSMSCodeAction.rejected]: (state, action) => {
      state.globalData.loading = false;
      state.globalData.error = action.error.message;
      state.verificationSteps.sentSMS = false;
    }, // Verifications Check
    [verificationCheckSMSCodeAction.pending]: (state, action) => {
      state.globalData.loading = true;
    },
    [verificationCheckSMSCodeAction.fulfilled]: (state, action) => {
      state.globalData.loading = false;
      const { data } = action.payload;

      if (data.status === 200 || data.status === 201) {
        state.globalData.actionStatus = true;
        state.verificationSteps.verification = true;
        state.globalData.actionType = "verification";
      } else if (Object.keys(data?.data?.error).length > 0) {
        state.globalData.actionStatus = false;
        state.verificationSteps.verification = false;
        state.globalData.actionType = null;
        state.globalData.error = data.data.error.message;
      }
    },
    [verificationCheckSMSCodeAction.rejected]: (state, action) => {
      state.globalData.loading = false;
      state.verificationSteps.verification = false;
      state.globalData.error = action.error.message;
      state.globalData.actionStatus = false;
      state.globalData.actionType = null;
    },

    // get other person phone onlyNumber
    [getPartnerDataAction.pending]: (state, action) => {
      state.globalData.loading = true;
    },
    [getPartnerDataAction.fulfilled]: (state, action) => {
      state.globalData.loading = false;
      state.verificationSteps.partnerPhoneNumber = action.payload.mobilephone;
    },
    [getPartnerDataAction.rejected]: (state, action) => {
      state.globalData.loading = false;
      state.globalData.error = action.error.message;
      state.verificationSteps.partnerPhoneNumber = null;
    },
    // get Source Storage And SubStorages Action

    [getFormattedStorageAndSubStoragesAction.pending]: (state, action) => {
      state.globalData.loading = true;
    },
    [getFormattedStorageAndSubStoragesAction.fulfilled]: (state, action) => {
      state.globalData.loading = false;
      state.sellSource.options = action.payload;
    },
    [getFormattedStorageAndSubStoragesAction.rejected]: (state, action) => {
      state.globalData.loading = false;
      state.globalData.error = action.error.message;
    },
  },
});

export const sellFromStorage = (state) => state.sell.sellSource.fromStorage;
export const sellGlobalState = (state) => state.sell.globalData;

export const sellStorageBankAccount = (state) => state.sell.storageBankAccount;
export const sellWorkspaceBankAccount = (state) =>
  state.sell.workspaceBankAccount;
export const sellEnterAmount = (state) => state.sell.enterAmount;
export const sellEnterDescription = (state) => state.sell.enterDescription;
export const verificationSteps = (state) => state.sell.verificationSteps;

export const sellFullState = (state) => state.sell;
export const sellSource = (state) => state.sell.sellSource;
export const {
  setSelectedSource,
  setStorageBankAccount,
  setWorkspaceBankAccount,
  setSelectStorage,
  setEnterAmount,
  setEnterDescription,
  resetVerification,
  resetActionStatus,
  resetSentSMS,
  resetError,
  resetAll,
} = sellSlice.actions;
export default sellSlice.reducer;
