import React, { useEffect, useState } from "react";
import SignContractStep from "./signContract.step";
import { Typography } from "@mui/material";

function JustSignContract() {
  return (
    <div className="container-card">
      <Typography
        mb="13px"
        sx={{
          fontSize: "24px",
          fontWeight: "500",
        }}
      >
        {"Überblick über sämtliche Vertragsunterlagen"}
      </Typography>

      <SignContractStep justSign={true}></SignContractStep>
    </div>
  );
}

export default JustSignContract;
