import React from "react";
import { SnackbarProvider } from "notistack";
import { Button } from "@mui/material";
//import Grow from "@mui/material/Grow";
const styles = {
  success: { backgroundColor: "purple" },
  error: { backgroundColor: "blue" },
  warning: { backgroundColor: "green" },
  info: { backgroundColor: "yellow" },
};
const NotiStackProivder = ({ children }) => {
  const notistackRef = React.createRef();
  const onClickDismiss = (key) => () => {
    notistackRef.current.closeSnackbar(key);
  };

  return (
    <SnackbarProvider
      //hideIconVariant={true}
      ref={notistackRef}
      maxSnack={5}
      action={(key) => (
        <Button
          variant="outlined"
          sx={{ border: "none", color: "white" }}
          onClick={onClickDismiss(key)}
        >
          Close
        </Button>
      )}
      autoHideDuration={5000}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      //TransitionComponent={Grow}
    >
      {children}
    </SnackbarProvider>
  );
};

export default NotiStackProivder;
