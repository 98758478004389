import React, { useEffect } from "react";
import { Paper, Box, Typography, Divider, Button, Link } from "@mui/material";
// import pdfIcon from "../../assets/images/pdf.svg";
// import jpgIcon from "../../assets/images/jpg.svg";
import { FilePresent } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { showNewMessageForm } from "../../redux/slices/inbox/inbox.slice";

function MessageDetailStep({ shareData, handleCurrentStep }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  // function download(content, mimeType, filename) {
  //   const a = document.createElement("a"); // Create "a" element
  //   const blob = new Blob([content], { type: mimeType }); // Create a blob (file-like object)
  //   const url = URL.createObjectURL(blob); // Create an object URL from blob
  //   a.setAttribute("href", url); // Set "a" element link
  //   a.setAttribute("download", filename); // Set download filename
  //   a.click(); // Start downloading
  // }

  // useEffect(() => {
  //   download(
  //     "text file content is here",
  //     "text/plain",
  //     "ss.txt"
  //   );
  // }, []);

  const handleSendMessage = () => {
    dispatch(showNewMessageForm({ reply: "info@arades.de" }));
  };
  const allowReply = () => {
    const noReplyStatus = shareData?.labels?.filter(
      (item) => item.type === "NOREPLY"
    );
    if (!shareData?.to && noReplyStatus.length === 0) {
      return true;
    }
    return false;
  };
  return (
    <>
      <Paper sx={{ p: "32px" }} variant="outlined">
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "start",
          }}
        >
          <Box>
            <Typography
              sx={{
                display: "flex",
                alignItems: "center",
              }}
              variant="h6"
              component="h6"
            >
              {t("inbox.from") + " :"} {shareData?.from}
              <br></br>
              {t("inbox.to") + " :"} {shareData?.to}
            </Typography>

            {shareData.regardedTo && (
              <Typography variant="caption">
                {t("inbox.regarded-to") + " :"} {shareData.regardedTo}
              </Typography>
            )}
          </Box>
          <Box>
            {shareData?.labels?.map((item, index) => (
              <Typography
                key={item.type + index}
                variant="caption"
                sx={{
                  bgcolor:
                    item.type === "NOREPLY"
                      ? "rgba(33, 33, 33, 0.08)"
                      : item.type === "IMPORTANT"
                      ? "#FFEBEE"
                      : "",
                  color: item.type === "IMPORTANT" ? "#B71C1C" : "#000",
                  display: "inline-block",
                  padding: "4px 8px",
                  borderRadius: "5px",
                  marginLeft: "16px",
                  fontWeight: "bold",
                  lineHeight: "16px",
                }}
              >
                {item.type == "IMPORTANT"
                  ? t("inbox.important")
                  : item.type == "NOREPLY"
                  ? t("inbox.no-reply")
                  : ""}
              </Typography>
            ))}
          </Box>
          <Box>
            <Typography variant="caption">
              {shareData.receivedTime} . {shareData.receivedDate}
            </Typography>
          </Box>
        </Box>
        <Divider sx={{ mt: "20px", mb: "20px" }} />
        <Box>
          <Typography>
            <b>{t("inbox.subject")}:</b>
            {" " + shareData.subject}
          </Typography>
          <Divider sx={{ mt: "20px", mb: "20px" }} />
          <Typography>{shareData.description}</Typography>
          <Box sx={{ display: "flex", mt: "20px", alignItems: "center" }}>
            {shareData.attachedFiles?.map((item) => {
              return (
                <Link href={item.downloadLink} target="_blank">
                  <Box
                    sx={{
                      border: "1px solid rgba(33, 33, 33, 0.08)",
                      borderRadius: "5px",
                      bgcolor: "rgba(255, 255, 255, 0.04)",
                      p: "9px",
                      display: "flex",
                      alignItems: "center",
                      maxWidth: "250px",
                      minWidth: "200px",
                      mr: "8px",
                    }}
                  >
                    <Box pr="10px">
                      <FilePresent />
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                      }}
                    >
                      <Typography
                        sx={{
                          whiteSpace: "noWrap",
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                          maxWidth: "146px",
                          fontSize: "12px",
                        }}
                        variant="subtitle2"
                      >
                        {item.fileName}
                      </Typography>
                      <Typography sx={{ fontSize: "10px" }} variant="caption">
                        {item.fileSize}
                      </Typography>
                    </Box>
                  </Box>
                </Link>
              );
            })}
          </Box>
          {/* {shareData.attachedFiles.length > 0 && (
            <Box mt="10px">
              <Button
                size="small"
                sx={{ color: "info.main", borderColor: "info.main" }}
                variant="outlined"
              >
                Download All Files
              </Button>
            </Box>
          )} */}
        </Box>
      </Paper>

      {/* {allowReply() && (
        <Box sx={{ textAlign: "right", mt: "20px" }}>
          <Button
            onClick={handleSendMessage}
            fullWidth
            sx={{ bgcolor: "rgba(5, 132, 124, 1)", maxWidth: "145px" }}
            variant="contained"
          >
            Send Message
          </Button>
        </Box>
      )} */}
    </>
  );
}

export default MessageDetailStep;
