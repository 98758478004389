import { REGISTER_PERSONAL_INFO_TYPE } from "../type";

const RegisterReducer = (state, action) => {
  switch (action.type) {
    case REGISTER_PERSONAL_INFO_TYPE:
      return {
        ...state,
        personalInfo: action.payload,
      };

    default:
      return state;
  }
};

export default RegisterReducer;
