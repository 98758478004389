import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../../../core/apiconfig/environment";
import {
  TOKEN_NAME,
  WORKSPACE_ID,
  WORKSPACE_TYPE,
} from "../../../core/apiconfig/constantApi";
import {
  WORKSPACETYPE,
  QUOTE_DIRECTION,
  QUOTE_TYPE,
} from "../../../core/constants";

const postExchangeAction = createAsyncThunk(
  "exchange/postExchange",
  async (dataParams, { getState }) => {
    const standardPriceLevelId =
      getState().globals.ratiorData.standardPriceLevelId;
    const fromStorage = getState().exchange.exchangeSource.fromStorage;
    const selectStorageTypeData = getState().exchange.selectStorageType;
    const exchangeInfo = getState().exchange.exchangeInfo;
    const selectStorageData = getState().exchange.selectStorage;
    const enterAmountData = getState().exchange.enterAmount;

    if (!fromStorage.arades_Edelmetall_Typ?._defaultuomid_value)
      throw Error("Es wurde keine Gewichtseinheit zum Produkt angegeben"); //("There is no Weight unit set for product");

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${localStorage.getItem(TOKEN_NAME)}`,
      },
    };

    const workspaceId = localStorage.getItem(WORKSPACE_ID);
    const workspaceType = localStorage.getItem(WORKSPACE_TYPE);

    let quoteObj = {
      arades_typ: QUOTE_TYPE.CUSTOMER,
      arades_richtung: QUOTE_DIRECTION.EXCHANGE,
      name: "Switch/Tausch von Edelmetallen", //"Exchange",
    };

    quoteObj["arades_lagerplatz_verkaeufer@odata.bind"] =
      "/msdyn_warehouses(" + fromStorage.msdyn_warehouseid + ")";

    quoteObj["arades_lagerplatz_kaeufer@odata.bind"] =
      "/msdyn_warehouses(" +
      (selectStorageTypeData.storageType == "mainStorage"
        ? selectStorageData.storageId
        : selectStorageData.subStorageId) +
      ")";

    if (
      workspaceType === WORKSPACETYPE.CONTACT ||
      workspaceType === WORKSPACETYPE.UNDER_AGE
    ) {
      quoteObj["customerid_contact@odata.bind"] =
        "/contacts(" + workspaceId + ")";
      quoteObj["arades_verkaeufer_contact@odata.bind"] =
        "/contacts(" + workspaceId + ")";
    } else {
      quoteObj["customerid_account@odata.bind"] =
        "/accounts(" + workspaceId + ")";
      quoteObj["arades_verkaeufer_account@odata.bind"] =
        "/accounts(" + workspaceId + ")";
    }

    quoteObj["pricelevelid@odata.bind"] =
      "/pricelevels(" +
      (fromStorage._arades_gebuehrentabelle_value
        ? fromStorage._arades_gebuehrentabelle_value
        : standardPriceLevelId) +
      ")";

    // @paul This endpoint is currently not implemented because the feature isn't even active. Make sure to use proper server-side validation!
    // @paul This endpoint should actually be implemented more like in postSellInfoAction
    const quoteResult = await axios.post(
      `${BASE_URL}data/quotes/`,
      quoteObj,
      config
    );

    if (quoteResult?.data?.status != 200 && quoteResult?.data?.status != 201)
      throw Error("Angebot wurde nicht gespeichert"); //("Quote not saved");

    if (quoteResult?.data?.data) {
      let quoteId = quoteResult.data.data.quoteid;
      let quoteProductObj = {
        quantity: enterAmountData.amountPerGram * 1,
      };

      quoteProductObj["quoteid@odata.bind"] = "/quotes(" + quoteId + ")";
      quoteProductObj["uomid@odata.bind"] = "/uoms(" + fromStorage.arades_Edelmetall_Typ._defaultuomid_value + ")"; // @paul This should be done by the endpoint and be hardcoded to use the `await getGramsWeightUnitId()` weight unit
      quoteProductObj["productid@odata.bind"] = "/products(" + fromStorage._arades_edelmetall_typ_value + ")";

      // @paul This endpoint call should be included in the endpoint call above, like in postSellInfoAction
      const quoteDetailResult = await axios.post(
        `${BASE_URL}data/quotedetails/`,
        quoteProductObj,
        config
      );

      if (
        quoteDetailResult?.data?.status != 200 &&
        quoteDetailResult?.data?.status != 201
      )
        throw Error("Angebotdetails wurden nicht gespeichert");
      //("Quote detail not saved");
      else {
        const productFieldResult = await axios.patch(
          `${BASE_URL}data/quotes/${quoteId}/`,
          {
            arades_produktanlage_abgeschlossen: true,
            arades_weiterleiten: true,
          },
          config
        );

        if (
          productFieldResult?.data?.status != 200 &&
          productFieldResult?.data?.status != 201
        )
          throw Error("Produktfeld in Angebot wurde nicht aktualisiert"); //("product field not updated in quote");
      }
    }
  }
);

export default postExchangeAction;
