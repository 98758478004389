import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import enTranslation from "./en.json";
import deTranslation from "./de.json";

const resources = {
  de: {
    translation: deTranslation,
  },
  en: {
    translation: enTranslation,
  },
};

i18next
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    supportedLngs: ["de", "en"],
    fallbackLng: "de",
    debug: false,
    detection: {
      order: ["localStorage", "cookie", "path", "htmlTag"],
      caches: ["localStorage", "cookie"],
    },
  });
