import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import * as serviceWorker from "./serviceWorker";
import "./Locale/configure.locale";
import App from "./App";
import { ConfigureAxios } from "./core/apiconfig/axiosApi";
import { MUIX_LISENCE } from "./core/constants";
import { LicenseInfo } from "@mui/x-license-pro";

const loadingMarkup = <h3>Loading..</h3>;

LicenseInfo.setLicenseKey(MUIX_LISENCE);

//ConfigureAxios();

ReactDOM.render(
  // <Suspense fallback={loadingMarkup}>
  <App />,
  // </Suspense>
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
