import React, { useState, useEffect } from "react";
import { useApi } from "../../../core/hooks/useApi";
import { BASE_URL } from "../../../core/apiconfig/environment";
import Input from "../../common/inputs/input";
import Select from "../../common/inputs/select";
import AutoComplete from "../../common/inputs/autocompelete";
import Button from "../../common/buttons/button";
import { useSnackbar } from "notistack";
import AddressModal from "../../common/address/addressModal";
import AddressShow from "../../common/address/addressShow";
import { useRegisterCompanyState } from "../../../context/registerCompany/RegisterCompanyState";
import { CONTACT_ID } from "../../../core/apiconfig/constantApi";
import LoadingOverlay from "../../common/LoadingOverlay/LoadingOverlay";
import { URL, ERROR_CONTACT_US } from "../../../core/constants";
import { useNavigate } from "react-router-dom";
import { Box, IconButton, Typography } from "@mui/material";
import MdArrowBackIosNew from "@mui/icons-material/ArrowBackIosNew";
import ConfirmModal from "../../common/popups/confirmModal";
import { useTranslation } from "react-i18next";
import CustomDivider from "../../common/customDivider/customDivider";
import PhoneInput from "../../common/inputs/phoneInput";
import "./registerCompany.scss";

const RegisterCompany = () => {
  const { post, get, patch } = useApi();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [countries, setCountries] = useState([]);
  const [companyTypes, setCompanyTypes] = useState([]);
  const [touched, setTouched] = useState({});
  const [addressModalOpen, setAddressModalOpen] = useState(false);
  const [addressIndex, setAddressIndex] = useState(1);
  const [currentAddress, setCurrentAddress] = useState({});
  const [loading, setLoading] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);
  // const [address1, setAddress1] = useState({
  //   city: "",
  //   line1: "",
  //   postalcode: "",
  //   line2: "",
  //   line3: "",
  // });
  const [address2, setAddress2] = useState({
    countryName: "",
    countryId: "",
    city: "",
    line1: "",
    postalcode: "",
    line2: "",
    line3: "",
    postbox: "",
  });

  const { registerCompanyInfoAction, registerCompanyInfo } =
    useRegisterCompanyState();

  const [value, setValue] = useState({
    arades_land: "",
    arades_unternehmensform: "",
    name: "",
    telephone1: "",
    telephone2: "",
    fax: "",
    emailaddress1: "",
    websiteurl: "",
    address1_city: "",
    address1_line1: "",
    address1_postalcode: "",
    address1_postofficebox: "",
  });

  const requiredMsg = t("validation.required");

  useEffect(() => {
    loadForm();
  }, []);

  const loadForm = () => {
    if (registerCompanyInfo && registerCompanyInfo.company) {
      let registerInfo = registerCompanyInfo.company;
      delete registerInfo.step;
      setValue({ ...value, ...registerInfo });
      if (registerInfo.address2_city) {
        setAddress2({
          country: registerInfo.arades_land1,
          city: registerInfo.address2_city,
          line1: registerInfo.address2_line1,
          postalcode: registerInfo.address2_postalcode,
          postbox: registerInfo.address2_postofficebox,
          line2: registerInfo.address2_line2,
          line3: registerInfo.address2_line3,
        });
      }
    }
    fetchCountries();
    fetchCompanyTypes();
  };

  const fetchCompanyTypes = async () => {
    const result = await get(`${BASE_URL}data/company-forms/`);
    if (result.data && result.data.value) {
      {
        let list = [];
        result.data.value.forEach((item) => {
          list.push({
            key: item.arades_unternehmensformid,
            value: item.arades_name,
            active: item.statecode === 0, // 0 = active, 1 = deactivated in MS Dynamics
          });
        });
        list = list.filter((item) => item.active); // Only show active company types (can be deactivated in Dynamics)
        setCompanyTypes(list);
      }
    }
  };

  const fetchCountries = async () => {
    const result = await get(`${BASE_URL}data/countries/`);
    if (result.data && result.data.value) {
      {
        let list = [];
        result.data.value.map((item) => {
          list.push({ key: item.arades_landid, value: item.arades_name });
        });
        setCountries(list);
      }
    }
  };

  const handleChange = (event) => {
    setValue({ ...value, [event.target.name]: event.target.value });
  };

  const GotoNext = () => {
    registerCompanyInfoAction({
      ...registerCompanyInfo,
      company: { ...value },
      step: 2,
    });
  };

  const save = async () => {
    // if ((!address1 || !address1.city) && (!address2 || !address2.city)) {
    //   enqueueSnackbar(t("company-register.must-add-address"), {
    //     variant: "error",
    //     autoHideDuration: 20000,
    //   });
    //   return;
    // }

    setLoading(true);
    try {
      let companyAdded = await saveCompany();
      if (companyAdded) {
        setConfirmOpen(true);
      }
    } catch (error) {
      console.log(`error`, error);
    }
    setLoading(false);
  };

  const saveCompany = async () => {
    value.address2_city = address2.city;
    value.address2_line1 = address2.line1;
    value.address2_postalcode = address2.postalcode;
    value.address2_postofficebox = address2.postbox;
    value.address2_line2 = address2.line2;
    value.address2_line3 = address2.line3;

    var saveObj = { ...value };
    saveObj.statecode = 0;
    saveObj.statuscode = 1;
    saveObj.arades_firmentyp = "100000000";

    if (saveObj.arades_land)
      saveObj["arades_land@odata.bind"] =
        "/arades_lands(" + saveObj.arades_land.key + ")";
    else saveObj["arades_land@odata.bind"] = null;

    if (address2.countryId)
      saveObj["arades_land1@odata.bind"] =
        "/arades_lands(" + address2.countryId + ")";
    else saveObj["arades_land1@odata.bind"] = null;

    if (saveObj.arades_unternehmensform) { saveObj["arades_unternehmensform@odata.bind"] = "/arades_unternehmensforms(" + saveObj.arades_unternehmensform + ")"; }
    else { saveObj["arades_unternehmensform@odata.bind"] = null; }

    delete saveObj.arades_unternehmensform;
    delete saveObj.arades_land;

    let result = null;
    if (value.accountid) { result = await patch(`${BASE_URL}data/accounts/${value.accountid}/`, saveObj); }
    else { result = await post(`${BASE_URL}data/accounts/contact/${localStorage.getItem(CONTACT_ID)}/`, saveObj); }

    if (result.status === 200 || result.status === 201) {
      if (value.accountid) {
        return true;
      } else {
        value.accountid = result.data.accountid;
        enqueueSnackbar(t("messages.success"), {
          variant: "success",
          autoHideDuration: 3000,
        });
        return true;
      }
    } else if (result.data && result.data.error) {
      enqueueSnackbar(result.data.error.message + ERROR_CONTACT_US, {
        variant: "error",
        autoHideDuration: 20000,
      });
    }
  };

  const openAddressModal = (index) => {
    setCurrentAddress(null);
    if (index) {
      setAddressIndex(index);
      setAddressModalOpen(true);
      // } else if (!value.address1_city) {
      //   setAddressIndex(1);
      //   setAddressModalOpen(true);
    } else if (!value.address2_city) {
      setAddressIndex(2);
      setAddressModalOpen(true);
    }
  };

  const editAddress = (index) => {
    //if (index == 1) setCurrentAddress({ ...address1 });
    //else
    if (index == 2) setCurrentAddress({ ...address2 });

    setAddressIndex(index);
    setAddressModalOpen(true);
  };
  const deleteAddress = (index) => {
    setCurrentAddress({});
    // if (index == 1)
    //   setAddress1({
    //     city: "",
    //     line1: "",
    //     postalcode: "",
    //     line2: "",
    //     line3: "",
    //   });
    //else
    if (index == 2)
      setAddress2({
        city: "",
        line1: "",
        postalcode: "",
        line2: "",
        line3: "",
        postbox: "",
      });
  };

  const addAddress = (address) => {
    //if (addressIndex == 1) setAddress1({ ...address1, ...address });
    //else
    setAddress2({ ...address2, ...address });
    setAddressModalOpen(false);
  };

  const formIsValid = () => {
    return (
      value.arades_unternehmensform &&
      value.name &&
      value.arades_land &&
      value.address1_line1 &&
      value.address1_postalcode &&
      value.address1_city &&
      value.emailaddress1 &&
      value.telephone1
    );
  };

  return (
    <>
      <ConfirmModal
        open={confirmOpen}
        title={t("company-register.success-title")}
        showCloseButton={false}
        message={t("company-register.success-message")}
        okTitle={t("buttons.next")}
        onOk={() => {
          setConfirmOpen(false);
          GotoNext();
        }}
      ></ConfirmModal>
      <LoadingOverlay show={loading}>
        <div className="container-card">
          <AddressModal
            initVal={currentAddress}
            open={addressModalOpen}
            onAccept={(address) => addAddress(address)}
            onClose={() => {
              setAddressModalOpen(false);
            }}
            countries={countries}
          ></AddressModal>

          <Box
            sx={{
              marginBottom: "32px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "15px",
              }}
            >
              <IconButton
                sx={{ fontSize: "15px", color: "#000" }}
                aria-label="back"
                onClick={() => navigate(URL.WORKSPACEADD)}
              >
                <MdArrowBackIosNew />
              </IconButton>
              <Typography sx={{ fontWeight: 500 }} variant="h5" component="h5">
                {t("company-register.add-company")}
              </Typography>
            </Box>
          </Box>
          <div className="row d-flex">
            <div className="d-flex flex-md-row col-12 ">
              <div className="row d-flex align-self-start">
                <div className="col-12 mt-4 ">
                  <AutoComplete
                    error={touched.arades_land && value.arades_land === ""}
                    helperText={
                      touched.arades_land && value.arades_land === ""
                        ? requiredMsg
                        : ""
                    }
                    onBlur={() => setTouched({ ...touched, arades_land: true })}
                    options={countries}
                    name={"arades_land"}
                    value={value.arades_land}
                    onChange={handleChange}
                    label={t("address.country") + "*"}
                  ></AutoComplete>
                </div>
                <div className="d-flex padding-80">
                  <div className="col-md-2 col-12 ">
                    <h1 className="seperator">{"Firmendetails"}</h1>
                  </div>
                  <div className="col-md-10 col-12  ">
                    <hr></hr>
                  </div>
                </div>

                <div className="col-md-6 col-12 mt-4">
                  <Select
                    error={
                      touched.arades_unternehmensform &&
                      value.arades_unternehmensform === ""
                    }
                    helperText={
                      touched.arades_unternehmensform &&
                      value.arades_unternehmensform === ""
                        ? requiredMsg
                        : ""
                    }
                    onBlur={() =>
                      setTouched({ ...touched, arades_unternehmensform: true })
                    }
                    name={"arades_unternehmensform"}
                    value={value.arades_unternehmensform}
                    onChange={handleChange}
                    options={companyTypes}
                    label={t("company-register.company-type") + "*"}
                  />
                </div>
                <div className="col-md-12 col-12 mt-4">
                  <Input
                    error={touched.name && value.name === ""}
                    helperText={
                      touched.name && value.name === "" ? requiredMsg : ""
                    }
                    onBlur={() => setTouched({ ...touched, name: true })}
                    name={"name"}
                    value={value.name}
                    onChange={handleChange}
                    label={t("company-register.name") + "*"}
                  />
                </div>
                <div className="col-md-12 col-12 mt-4">
                  <Input
                    error={
                      touched.address1_line1 && value.address1_line1 === ""
                    }
                    helperText={
                      touched.address1_line1 && value.address1_line1 === ""
                        ? requiredMsg
                        : ""
                    }
                    onBlur={() =>
                      setTouched({ ...touched, address1_line1: true })
                    }
                    name={"address1_line1"}
                    value={value.address1_line1}
                    onChange={handleChange}
                    label={t("address.office-house-no-and-street") + "*"}
                  />
                </div>
                <div className="col-md-12 col-12 mt-4">
                  <Input
                    name={"address1_postofficebox"}
                    value={value.address1_postofficebox}
                    onChange={handleChange}
                    label={t("address.postbox")}
                  />
                </div>
                <div className="col-md-12 col-12 mt-4">
                  <Input
                    error={
                      touched.address1_postalcode &&
                      value.address1_postalcode === ""
                    }
                    helperText={
                      touched.address1_postalcode &&
                      value.address1_postalcode === ""
                        ? requiredMsg
                        : ""
                    }
                    onBlur={() =>
                      setTouched({ ...touched, address1_postalcode: true })
                    }
                    name={"address1_postalcode"}
                    value={value.address1_postalcode}
                    onChange={handleChange}
                    label={t("address.postal-code") + "*"}
                  />
                </div>
                <div className="col-md-12 col-12 mt-4">
                  <Input
                    error={touched.address1_city && value.address1_city === ""}
                    helperText={
                      touched.address1_city && value.address1_city === ""
                        ? requiredMsg
                        : ""
                    }
                    onBlur={() =>
                      setTouched({ ...touched, address1_city: true })
                    }
                    name={"address1_city"}
                    value={value.address1_city}
                    onChange={handleChange}
                    label={t("address.city") + "*"}
                  />
                </div>
                <div className="col-md-6 col-12 mt-4 ">
                  <PhoneInput
                    error={touched.telephone1 && value.telephone1 === ""}
                    helperText={
                      touched.telephone1 && value.telephone1 === ""
                        ? requiredMsg
                        : ""
                    }
                    onBlur={() => setTouched({ ...touched, telephone1: true })}
                    country="de"
                    name={"telephone1"}
                    value={value.telephone1}
                    onChange={handleChange}
                    label={t("company-info.phone") + "*"}
                    className="phone-input-small"
                  />
                  {/* <Input
                    error={touched.telephone1 && value.telephone1 === ""}
                    helperText={
                      touched.telephone1 && value.telephone1 === ""
                        ? requiredMsg
                        : ""
                    }
                    onBlur={() => setTouched({ ...touched, telephone1: true })}
                    name={"telephone1"}
                    value={value.telephone1}
                    onChange={handleChange}
                    label={t("company-info.phone") + "*"}
                  /> */}
                </div>
                <div className="col-md-6 col-12 mt-4">
                  <PhoneInput
                    country="de"
                    name={"telephone2"}
                    value={value.telephone2}
                    onChange={handleChange}
                    label={t("company-info.mobile")}
                    className="phone-input-small"
                  />
                  {/* <Input
                    name={"telephone2"}
                    value={value.telephone2}
                    onChange={handleChange}
                    label={t("company-info.mobile")}
                  /> */}
                </div>
                <div className="col-md-6 col-12 mt-4">
                  <Input
                    error={ touched.emailaddress1 && value.emailaddress1 === "" }
                    helperText={
                      touched.emailaddress1 &&
                      value.emailaddress1 === ""
                        ? requiredMsg
                        : ""
                    }
                    onBlur={() => setTouched({ ...touched, emailaddress1: true })}
                    name={"emailaddress1"}
                    value={value.emailaddress1}
                    onChange={handleChange}
                    label={t("company-info.email") + "*"}
                  />
                </div>
                <div className="col-md-6 col-12 mt-4">
                  <Input
                    name={"fax"}
                    value={value.fax}
                    onChange={handleChange}
                    label={t("company-info.fax")}
                  />
                </div>
                <div className="col-md-6 col-12 mt-4 ">
                  <Input
                    name={"websiteurl"}
                    value={value.websiteurl}
                    onChange={handleChange}
                    label={t("company-info.website")}
                  />
                </div>

                {address2.city && (
                  <CustomDivider
                    title={t("company-register.different-shipping-address")}
                  >
                    {" "}
                  </CustomDivider>
                )}
                {/*  {
                  !address1.city && !address2.city ? (
                    <div className="div-address-btn">
                      <div className="description">
                        {t("company-register.address-desc-1")} <br></br>
                        {t("company-register.address-desc-2")}
                        <br></br>
                      </div>
                      <Button
                        className={"tertiary"}
                        onClick={() => openAddressModal(1)}
                      >
                        {t("company-info.new-address")}
                      </Button>
                    </div>
                  ) :
                  address1.city &&
                  !address2.city ? (
                    <div className="d-flex justify-content-end">
                      <Button
                        className={"tertiary"}
                        onClick={() => openAddressModal(2)}
                      >
                        {t("company-info.new-address")}
                      </Button>
                    </div>
                  ) : null
                }
                {address1.city && (
                  <AddressShow
                    key="i1"
                    country={value.arades_land ? value.arades_land.value : ""}
                    address={address1}
                    index={1}
                    onEdit={()=>editAddress(1)}
                    onDelete={()=>deleteAddress(1)}
                  ></AddressShow>
                )} */}
                {address2.city && (
                  <AddressShow
                    title={t("company-register.different-shipping-address")}
                    key="i2"
                    address={address2}
                    index={2}
                    onEdit={() => editAddress(2)}
                    onDelete={() => deleteAddress(2)}
                  ></AddressShow>
                )}
              </div>
            </div>
            <div className="btn-next">
              <Button
                style={{ marginRight: 15 }}
                className={"tertiary"}
                onClick={() =>
                  !address2.city ? openAddressModal(2) : editAddress(2)
                }
              >
                {t("company-register.add-second-address")}
              </Button>
              <Button disabled={!formIsValid()} onClick={save}>
                {t("buttons.next")}
              </Button>
            </div>
          </div>
        </div>
      </LoadingOverlay>
    </>
  );
};

export default RegisterCompany;
