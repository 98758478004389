import React from "react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { useSelector } from "react-redux";
import { localefullData } from "../../../redux/slices/locale/locale.slice";
import deLocale from "date-fns/locale/de";
import enLocale from "date-fns/locale/en-US";

function DateLocalization({ children }) {
  const localeData = useSelector(localefullData);
  const localeMap = {
    en: enLocale,
    de: deLocale,
  };
  return (
    <>
      <LocalizationProvider
        dateAdapter={AdapterDateFns}
        adapterLocale={localeMap[localeData.currentLanguage]}
      >
        {children}
      </LocalizationProvider>
    </>
  );
}

export default DateLocalization;
