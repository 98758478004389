import React, { useState, useEffect } from "react";
import SelectOrAddBankAccount from "../../common/selectOrAddBankAccount";
import {
  bankAccountInfo,
  setBankAccountInfo,
} from "../../../redux/slices/becomeSeller/becomeSeller.slice";
import { useDispatch, useSelector } from "react-redux";
import { Grid, Box, Typography } from "@mui/material";
import { URL, WORKSPACETYPE } from "../../../core/constants";
import { WORKSPACE_TYPE } from "../../../core/apiconfig/constantApi";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const SellerBankAccountStep = ({ handleCurrentStep }) => {
  const OTHER_ACCOUNT = "other";
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [selectedAccount, setSelectedAccount] = useState(OTHER_ACCOUNT);
  const [newAccount, setNewAccount] = useState();

  const bankAccountData = useSelector(bankAccountInfo);

  useEffect(() => {
    //parichehr set if vermittlerwerden us false user cant see this page
    // if (
    //   localStorage.getItem(WORKSPACE_TYPE) !=
    //   WORKSPACETYPE.SIMPLE_ACCOUNT_VERIFIED
    // ) {
    //   navigate(URL.DASHBOARD);
    // }

    if (bankAccountData.arades_kontoid)
      setSelectedAccount(bankAccountData.arades_kontoid);
    else {
      setSelectedAccount(OTHER_ACCOUNT);
      setNewAccount(bankAccountData);
    }
  }, []);

  const nextStep = (accountId, bankAccounts, bankValue) => {
    let account = {};
    if (accountId) {
      account = bankAccounts.filter((item) => {
        if (item.arades_kontoid == accountId) return item;
      })[0];
    }
    dispatch(setBankAccountInfo({ ...bankValue, ...account }));
    handleCurrentStep("next");
  };

  return (
    <Box>
      <Grid container spacing={8}>
        <Grid xs={12} item>
          <Typography
            sx={{
              fontWeight: 500,
              color: "ragba(0,0,0,0.67)",
              fontSize: 14,
            }}
          >
            {t("messages.bank-account-attention")}
          </Typography>
        </Grid>
        <Grid xs={12} item>
          <SelectOrAddBankAccount
            selectedBankAccount={selectedAccount}
            onBankAccountSelected={nextStep}
            newBankAccountInfo={newAccount}
            confirmButtonText={t("buttons.next")}
            notSaveBankAccount={true}
          ></SelectOrAddBankAccount>
        </Grid>
      </Grid>
    </Box>
  );
};

export default SellerBankAccountStep;
