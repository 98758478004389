import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Grid,
  TextField,
  Typography,
  IconButton,
} from "@mui/material";
import MdArrowBackIosNew from "@mui/icons-material/ArrowBackIosNew";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import moment from "moment";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import { postUnderAgeInfoAction } from "../../redux/slices/underage";
import {
  setChildData,
  childDataStepFields,
  legalGuardianStatusFields,
  underAgeFullState,
  resetActionStatus,
  resetAll,
  resetError,
} from "../../redux/slices/underage/underage.slice";
import {
  globalsCountries,
  globalsGenders,
  globalsGeschlechts,
} from "../../redux/slices/globals/globals.slice";
import { useNavigate } from "react-router-dom";
import { ERROR_CONTACT_US } from "../../core/constants";

import {
  FormSteperStructure,
  //MUICheckBox,
  Input,
  Select,
  Datepicker,
  LoadingOverlay,
  ConfirmModal,
  PhoneInput,
} from "../common";
import { useSnackbar } from "notistack";

function EnterChildDataStep({ handleCurrentStep, currentStep }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const fields = useSelector(childDataStepFields);
  const legalGuardianStatusData = useSelector(legalGuardianStatusFields);
  const underAgeData = useSelector(underAgeFullState);
  const countries = useSelector(globalsCountries);
  const genders = useSelector(globalsGenders);
  const geschlechtList = useSelector(globalsGeschlechts);
  const [successStatusModal, setSuccessStatusModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const requiredMsg = "validation.required";
  const emailMismatchMsg = "validation.email-mismatch";
  const emailIsValidMsg = "validation.email-invalid";

  const schema = yup.object().shape({
    firstname: yup.string().required(requiredMsg),
    lastname: yup.string().required(requiredMsg),
    arades_anrede: yup.string().required(requiredMsg),
    arades_geschlecht: yup.string().required(requiredMsg),
    address1_city: yup.string().required(requiredMsg),
    address1_postalcode: yup.string().required(requiredMsg),
    address1_line1: yup.string().required(requiredMsg),
    arades_land: yup.string().required(requiredMsg),
    emailaddress1: yup.string().email(emailIsValidMsg),
    emailRepeat: yup
      .string()
      .email(emailIsValidMsg)
      .oneOf([yup.ref("emailaddress1")], emailMismatchMsg),
    //agreed: yup.boolean().oneOf([true], "validation.must-accept-terms"),
    birthdate: yup
      .string()
      .required(requiredMsg)
      .nullable()
      .test("DOB", "validation.age-under-18", (value) => {
        return moment().diff(moment(value), "years") < 18;
      }),
  });

  const {
    control,
    handleSubmit,
    setError,
    getValues,
    watch,
    formState: { errors },
  } = useForm({
    mode: "all",
    defaultValues: {
      ...fields,
      //agreed: false,
    },
    resolver: yupResolver(schema),
  });

  const onSubmit = (data) => {
    dispatch(setChildData(data));
    dispatch(postUnderAgeInfoAction());
  };

  useEffect(() => {
    if (underAgeData.actionStatus) {
      setSuccessStatusModal(true);
      dispatch(resetActionStatus());
    }
  }, [underAgeData.actionStatus]);

  useEffect(() => {
    setLoading(underAgeData.loading);
  }, [underAgeData.loading]);

  const onError = (error) => {
    //error
  };

  const handleCancel = () => {
    dispatch(resetAll());
    navigate(URL.STORAGE);
  };

  const onBack = () => {
    if (legalGuardianStatusData.legalStatus == "only") {
      handleCurrentStep(currentStep - 3);
    } else {
      handleCurrentStep("prev");
    }
  };

  useEffect(() => {
    if (underAgeData.error != null) {
      enqueueSnackbar(underAgeData.error + ERROR_CONTACT_US, {
        variant: "error",
        autoHideDuration: 20000,
      });
      setLoading(false);
      dispatch(resetError());
    }
  }, [underAgeData.error]);

  return (
    <LoadingOverlay show={loading}>
      <Box>
        <ConfirmModal
          open={successStatusModal}
          title={t("underage.confirm-congratulation")}
          showCloseButton={false}
          message={
            <Box sx={{ margin: "0 auto" }}>
              {t("underage.confirm-request-submited")}
            </Box>
          }
          okTitle={t("buttons.next")}
          onOk={() => {
            setSuccessStatusModal(false);
            handleCurrentStep("next");
          }}
        ></ConfirmModal>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "15px",
            marginBottom: 15,
          }}
        >
          <IconButton
            sx={{ fontSize: "15px", color: "#000" }}
            aria-label="back"
            onClick={() => onBack()}
          >
            <MdArrowBackIosNew />
          </IconButton>
          <Typography sx={{ fontWeight: 500 }} variant="h5" component="h5">
            {"Persönliche Daten des Minderjährigen"}
          </Typography>

          <FormSteperStructure.StepRightButton
            size="small"
            onClick={handleCancel}
            variant="outlined"
            startIcon={<CloseIcon />}
          >
            Cancel
          </FormSteperStructure.StepRightButton>
        </Box>
        <Box sx={{ minWidth: "50%" }}>
          <Grid container spacing={8}>
            <Grid xs={12} lg={6} item>
              <Select
                control={control}
                error={Boolean(errors.arades_anrede)}
                helperText={t(errors.arades_anrede?.message)}
                name="arades_anrede"
                label={`${t("personal-info.gender")}*`}
                options={genders}
              />
            </Grid>
            <Grid xs={12} lg={6} item>
              <Select
                control={control}
                error={Boolean(errors.arades_geschlecht)}
                helperText={t(errors.arades_geschlecht?.message)}
                name="arades_geschlecht"
                label={`${t("personal-info.geschlecht")}*`}
                options={geschlechtList}
              />
            </Grid>
            <Grid xs={12} lg={6} item>
              <Input
                control={control}
                error={Boolean(errors.firstname)}
                helperText={t(errors.firstname?.message)}
                name="firstname"
                label={`${t("personal-info.first-name")}*`}
              />
            </Grid>
            <Grid xs={12} lg={6} item>
              <Input
                control={control}
                error={Boolean(errors.lastname)}
                helperText={t(errors.lastname?.message)}
                name="lastname"
                label={`${t("personal-info.last-name")}*`}
              />
            </Grid>
            <Grid xs={12} lg={6} item>
              <Datepicker
                label={`${t("personal-info.birth-date")}*`}
                control={control}
                name="birthdate"
                maxDate={Date.now()}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={Boolean(errors.birthdate)}
                    helperText={t(errors.birthdate?.message)}
                    onBlur={() => {
                      if (getValues("birthdate") === "") {
                        setError("birthdate", {
                          type: "manual",
                          message: requiredMsg,
                        });
                      }
                    }}
                  />
                )}
              />
            </Grid>
            <Grid xs={12} item>
              <Input
                control={control}
                error={Boolean(errors.address1_line1)}
                helperText={t(errors.address1_line1?.message)}
                name="address1_line1"
                label={t("address.house-no-and-street") + "*"}
              />
            </Grid>
            <Grid xs={12} item>
              <Input
                control={control}
                name="address1_line2"
                label={"Adresse Zusatz"}
              />
            </Grid>
            <Grid xs={12} lg={6} item>
              <Input
                control={control}
                error={Boolean(errors.address1_postalcode)}
                helperText={t(errors.address1_postalcode?.message)}
                name="address1_postalcode"
                label={t("address.postal-code") + "*"}
              />
            </Grid>
            <Grid xs={12} lg={6} item>
              <Input
                control={control}
                error={Boolean(errors.address1_city)}
                helperText={t(errors.address1_city?.message)}
                name="address1_city"
                label={t("address.city") + "*"}
              />
            </Grid>
            <Grid xs={12} item>
              <Select
                control={control}
                error={Boolean(errors.arades_land)}
                helperText={t(errors.arades_land?.message)}
                options={countries}
                name="arades_land"
                label={t("address.country") + "*"}
              />
            </Grid>
            <Grid xs={12} lg={6} item>
              <PhoneInput
                country="de"
                control={control}
                name={"mobilephone"}
                label={"Mobiltelefonnummer"}
                className="phone-input-small"
              />
            </Grid>
            <Grid xs={12} lg={6} item>
              <PhoneInput
                country="de"
                control={control}
                name={"telephone1"}
                label={"Telefonnummer"}
                className="phone-input-small"
              />
            </Grid>
            <Grid xs={12} lg={6} item>
              <Input
                control={control}
                error={Boolean(errors.emailaddress1)}
                helperText={t(errors.emailaddress1?.message)}
                name="emailaddress1"
                label={t("personal-info.email-address")}
                placeholder="Exp: mail@website.com"
              />
            </Grid>
            <Grid xs={12} lg={6} item>
              <Input
                control={control}
                error={Boolean(errors.emailRepeat)}
                helperText={t(errors.emailRepeat?.message)}
                name="emailRepeat"
                placeholder="Exp: mail@website.com"
                label={t("personal-info.repeat-email-address")}
              />
            </Grid>
          </Grid>
          <FormSteperStructure.Footer>
            <Button
              onClick={handleSubmit(onSubmit, onError)}
              fullWidth
              sx={{ bgcolor: "info.main", maxWidth: "192px" }}
              variant="contained"
            >
              {t("buttons.next")}
            </Button>
          </FormSteperStructure.Footer>
        </Box>
      </Box>
    </LoadingOverlay>
  );
}

export default EnterChildDataStep;
