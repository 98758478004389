import React, { useEffect, useState } from "react";
import { Box, FormControl, FormLabel, Button } from "@mui/material";
import { RadioListIcon, FormSteperStructure } from "../common";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import {
  setSelectReceptorType,
  transferFromStorage,
  transferSelectReceptorTypeFields,
  setSelectStorageType,
  resetSelectStorage,
  transferSelectStorageTypeFields,
  storagesListWithSubstorages,
} from "../../redux/slices/transfer/transfer.slice";
import { useTranslation } from "react-i18next";

function SelectReceptor({ handleCurrentStep, currentStep }) {
  const detailStorageId = useParams().storageid;
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [radioList, setRadioList] = useState([
    {
      label: t("transfer.other-storage"),
      value: "mySelf",
      disabled: true,
    },
    {
      label: t("transfer.other-person"),
      value: "otherPerson",
      disabled: false,
    },
  ]);
  const { receptorType: receptor } = useSelector(
    transferSelectReceptorTypeFields
  );

  const storageTypeFields = useSelector(transferSelectStorageTypeFields);
  const fromStorage = useSelector(transferFromStorage);
  const storageList = useSelector(storagesListWithSubstorages);

  const [receptorType, setReceptorType] = useState(receptor);

  useEffect(() => {
    setCanTransferToStorageOption();
  }, []);

  const setCanTransferToStorageOption = () => {
    let result = { ...storageTypeFields };
    result.canSelectStorage =
      storageList?.filter(
        (x) =>
          !x.pending &&
          !x.arades_uebergeordneter_lagerplatz &&
          x.msdyn_warehouseid != fromStorage.msdyn_warehouseid &&
          x.metalId == fromStorage._arades_edelmetall_typ_value
      ).length > 0;

    for (let i = 0; i < storageList?.length; i++) {
      let item = storageList[i];
      if (
        item.subStorageList?.filter(
          (x) =>
            !x.pending &&
            x.msdyn_warehouseid != fromStorage.msdyn_warehouseid &&
            x.metalId == fromStorage._arades_edelmetall_typ_value
        ).length > 0
      ) {
        result.canSelectSubstorage = true;
        break;
      }
    }

    let myRadioList = [...radioList];
    myRadioList[0].disabled = !(
      result.canSelectStorage || result.canSelectSubstorage
    );
    setRadioList(myRadioList);

    if (!result.canSelectStorage && result.canSelectSubstorage)
      result.storageType = "subStorage";
    dispatch(setSelectStorageType(result));
    if (!(result.canSelectStorage || result.canSelectSubstorage))
      setReceptorType("otherPerson");
  };

  const submitForm = () => {
    dispatch(
      setSelectReceptorType({
        receptorType: receptorType,
        detailStorageId: detailStorageId,
      })
    );
    if (receptorType === "otherPerson") {
      handleCurrentStep("next");
    } else {
      handleCurrentStep(currentStep + 2);
    }
  };

  return (
    <>
      <Box
        sx={{
          margin: "0 auto",
          minHeight: "calc(100vh - 250px)",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "space-around",
        }}
      >
        <Box sx={{ width: { xs: "100%", md: "50%" } }}>
          <FormControl sx={{ minWidth: "100%" }}>
            <FormLabel sx={{ fontSize: "14px", mb: "20px" }} id="radio-group">
              {t("transfer.select-receptor")}
            </FormLabel>
            <RadioListIcon
              currentValue={receptorType}
              handleSetCurrentValue={(value) => {
                dispatch(resetSelectStorage());
                setReceptorType(value);
              }}
              data={radioList}
            />
          </FormControl>
        </Box>

        <FormSteperStructure.Footer>
          <Button
            onClick={submitForm}
            fullWidth
            sx={{ backgroundColor: "info.main", maxWidth: "192px" }}
            variant="contained"
          >
            {t("buttons.next")}
          </Button>
        </FormSteperStructure.Footer>
      </Box>
    </>
  );
}

export default SelectReceptor;
