import React from "react";
import "./bankAccountStep.scss";
import { Box, Button, Grid, IconButton, Typography } from "@mui/material";
import MdArrowBackIosNew from "@mui/icons-material/ArrowBackIosNew";
import { FormSteperStructure } from "../common";
import { useDispatch, useSelector } from "react-redux";
import {
  sellWorkspaceBankAccount,
  setWorkspaceBankAccount,
  sellStorageBankAccount,
  setStorageBankAccount,
  resetAll,
} from "../../redux/slices/sell/sell.slice";
import SelectOrAddBankAccount from "../common/selectOrAddBankAccount";
import { useNavigate, useParams } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";

const WorkspaceBankAccountStep = ({ handleCurrentStep, currentStep }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const workspacebankAccountInfo = useSelector(sellWorkspaceBankAccount);
  const storagebankAccountInfo = useSelector(sellStorageBankAccount);
  const navigate = useNavigate();
  const detailStorageId = useParams().storageid;

  const onSubmit = async (accountId, bankAccounts, newBankAccount) => {
    let bankAccount = newBankAccount;
    if (!bankAccount)
      bankAccount = bankAccounts.filter(
        (x) => x.arades_kontoid == accountId
      )[0];

    dispatch(
      setStorageBankAccount({
        selectedBankAccount: accountId,
        bankAccountInfo: bankAccount,
        detailStorageId: detailStorageId,
      })
    );
    dispatch(setWorkspaceBankAccount(accountId));
    handleCurrentStep("next");
  };

  const onBack = () => {
    if (!storagebankAccountInfo.bankAccountInfo) {
      handleCurrentStep(currentStep - 2);
    } else {
      handleCurrentStep("prev");
    }
  };

  const handleCancel = () => {
    dispatch(resetAll());
    navigate(`${URL.STORAGE}/${detailStorageId}`);
  };

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "15px",
        }}
      >
        <IconButton
          sx={{ fontSize: "15px", color: "#000" }}
          aria-label="back"
          onClick={() => onBack()}
        >
          <MdArrowBackIosNew />
        </IconButton>
        <Typography sx={{ fontWeight: 500 }} variant="h5" component="h5">
          {t("bank-account.bank-account")}
        </Typography>

        <FormSteperStructure.StepRightButton
          size="small"
          onClick={handleCancel}
          variant="outlined"
          startIcon={<CloseIcon />}
        >
          {t("buttons.cancel")}
        </FormSteperStructure.StepRightButton>
      </Box>
      <Box
        sx={{
          margin: "0 auto",
          minHeight: "calc(100vh - 250px)",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "space-around",
        }}
      >
        <Box sx={{ width: "100%", margin: "0 auto" }}>
          <SelectOrAddBankAccount
            selectedBankAccount={workspacebankAccountInfo.selectedBankAccount}
            onBankAccountSelected={onSubmit}
            confirmButtonText={t("buttons.confirm")}
          ></SelectOrAddBankAccount>
        </Box>
      </Box>
    </Box>
  );
};

export default WorkspaceBankAccountStep;
