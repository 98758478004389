function emailIsValid(email) {
  if (
    String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
  )
    return true;
  else return false;
}

function passwordIsStrong(password) {
  let meetedCondCount = 0;
  let result = {
    length: null,
    caps: null,
    number: null,
    specialLetter: null,
    meetedConditions: 0,
  };

  result.length = password && password.length >= 8;
  if (result.length) meetedCondCount++;
  result.caps =
    String(password).match(/[A-Z]/) && String(password).match(/[a-z]/)
      ? true
      : false;
  if (result.caps) meetedCondCount++;
  result.number = String(password).match(/\d/) ? true : false;
  if (result.number) meetedCondCount++;
  result.specialLetter = String(password).match(/[^A-Za-z0-9]/) ? true : false;
  if (result.specialLetter) meetedCondCount++;
  result.all = meetedCondCount == 4;

  result.meetedConditions = meetedCondCount;
  return result;
}

function postaCodeIsValid(email) {
  if (
    String(email)
      .toLowerCase()
      .match(/^[1-9][0-9]{3} ?(?!sa|sd|ss)[a-z]{2}$/i)
  )
    return true;
  else return false;
}

export { emailIsValid, passwordIsStrong, postaCodeIsValid };
