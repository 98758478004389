import React, { useState, useEffect } from "react";
import {
  SliderInput,
  InputSupportCopy,
  Input,
  Datepicker,
  MUIRadioGroup,
  ConfirmModal,
  LoadingOverlay,
} from "../../common";
import {
  Typography,
  Box,
  Button,
  Grid,
  TextField,
  FormControlLabel,
  IconButton,
  Radio,
} from "@mui/material";
import MdArrowBackIosNew from "@mui/icons-material/ArrowBackIosNew";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  addActionCodeStep,
  globalData,
  resetError,
  resetActionStatus,
  resetAddActionCodeStep,
} from "../../../redux/slices/vermittlerDashboard/vermittlerDashboard.slice";
import { postActionCodeAction } from "../../../redux/slices/vermittlerDashboard";
import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import { useSnackbar } from "notistack";
import { ACTION_CODE_TYPE, ERROR_CONTACT_US } from "../../../core/constants";
import { BASE_URL_UI } from "../../../core/apiconfig/environment";
import { useTranslation } from "react-i18next";

function ActionCodeAdd({ handleCurrentStep }) {
  const requiredMsg = "erforderlich";
  const globalfields = useSelector(globalData);
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const addActionCodefields = useSelector(addActionCodeStep);
  const [successStatusModal, setSuccessStatusModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const today = new Date().toLocaleDateString("en-US", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  });

  const marks = [
    {
      value: 0,
      label: "0",
    },
    {
      value: 10,
      label: "10",
    },
    {
      value: 25,
      label: "25",
    },
    {
      value: 50,
      label: "50",
    },
    {
      value: 75,
      label: "75",
    },
  ];

  const schema = yup.object().shape({
    discount: yup.number().when("actionCodeType", {
      is: ACTION_CODE_TYPE.STORAGE_FEE,
      then: yup
        .number()
        .required(requiredMsg),
        //.notOneOf([0, "0"], t("validation.none-zero-number"))
        //.typeError(t("validation.none-zero-number")),
      otherwise: yup.number().nullable(),
    }),
    // loyaltyMonthes: yup.number().when("actionCodeType", {
    //   is: ACTION_CODE_TYPE.LOYALTY,
    //   then: yup
    //     .number()
    //     .required(requiredMsg)
    //     .notOneOf([0, "0"], "Dieses Feld darf nicht Null sein") //"This field must be a non-zero number")
    //     .typeError("Wert muss eine Zahl sein"), //("Value should be a number"),
    //   otherwise: yup.number().nullable(),
    // }),
    allowRegisterCount: yup.number().when("usersUnLimited", {
      is: "false",
      then: yup
        .number()
        .required(requiredMsg)
        .notOneOf([0, "0"], t("validation.none-zero-number"))
        .typeError(t("validation.none-zero-number")),
      otherwise: yup.number().nullable(),
    }),
    startDate: yup.date().when("validForever", {
      is: "false",
      then: yup
        .date()
        .required(requiredMsg)
        .min(today, t("validation.from-not-less-than-today"))
        .typeError(requiredMsg)
        .nullable(),
      otherwise: yup.date().nullable(),
    }),
    endDate: yup
      .date()
      .when("validForever", {
        is: "false",
        then: yup
          .date()
          .required(requiredMsg)
          .typeError(requiredMsg)
          .nullable(),
        otherwise: yup.date().nullable(),
      })
      .when("startDate", (startDate, schema) => {
        if (startDate) {
          const dayAfter = new Date(new Date(today).getTime() + 86400000);

          return schema.min(dayAfter, t("validation.end-more-than-start"));
        }

        return schema;
      })

      .nullable(),
  });
  const {
    control,
    handleSubmit,
    setError,
    getValues,
    watch,
    formState: { errors },
  } = useForm({
    mode: "all",
    defaultValues: {
      ...addActionCodefields,
    },
    resolver: yupResolver(schema),
  });

  const onSubmit = (data) => {
    setLoading(true);
    dispatch(postActionCodeAction(data));
  };

  useEffect(() => {
    if (globalfields.actionStatus) {
      setSuccessStatusModal(true);
      dispatch(resetActionStatus());
      setLoading(false);
    }
  }, [globalfields.actionStatus]);

  useEffect(() => {
    if (globalfields.error != null) {
      enqueueSnackbar(globalfields.error + ERROR_CONTACT_US, {
        variant: "error",
        autoHideDuration: 20000,
      });
      dispatch(resetError());
      setLoading(false);
    }
  }, [globalfields.error]);

  const affiliateLink = (actionCode) => {
    return `${BASE_URL_UI}registration/personalinfo?ac=${actionCode}`;
  };

  return (
    <div className="container-card">
      <LoadingOverlay show={loading}>
        <Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "15px",
            }}
          >
            <IconButton
              sx={{ fontSize: "15px", color: "#000" }}
              aria-label="back"
              onClick={() => handleCurrentStep("prev")}
            >
              <MdArrowBackIosNew />
            </IconButton>
            <Typography sx={{ fontWeight: 500 }} variant="h5" component="h5">
              {t("action-code.generate")}
            </Typography>
          </Box>

          <Box
            sx={{
              padding: 10,
              paddingTop: 20,
              "& .text-gray": {
                color: "rgba(0, 0, 0, 0.38)",
              },
            }}
          >
            <ConfirmModal
              open={successStatusModal}
              title={t("action-code.congrats")}
              size="lg"
              showCloseButton={false}
              message={
                <Box sm={12} sx={{ margin: "0 auto", width: "100%" }}>
                  {t("action-code.created-modal-message")}
                  <Box mt="8px">
                    <InputSupportCopy
                      disabled
                      sx={{ bgcolor: "rgba(0, 0, 0, 0.04)", width: "100%" }}
                      value={affiliateLink(
                        addActionCodefields.responseActionCode
                      )}
                    />
                  </Box>
                </Box>
              }
              okTitle={t("buttons.go-to-dashboard")}
              onOk={() => {
                dispatch(resetAddActionCodeStep());
                handleCurrentStep("prev");
              }}
            ></ConfirmModal>

            <Grid rowSpacing={12} columnSpacing={0} container>
              <Grid item xs={12}>
                <Typography className="text-gray">
                  {t("action-code.select-type")}
                </Typography>
                <MUIRadioGroup row control={control} name="actionCodeType">
                  <FormControlLabel
                    value={ACTION_CODE_TYPE.STORAGE_FEE}
                    control={<Radio />}
                    label={t("action-code.storage-fee")}
                  />
                  <FormControlLabel
                    value={ACTION_CODE_TYPE.BECOME_SELLER}
                    control={<Radio />}
                    label={t("action-code.become-seller")}
                  />
                  {/* <FormControlLabel
                    value={ACTION_CODE_TYPE.LOYALTY}
                    control={<Radio />}
                    label="Treue Monate"
                  /> */}
                </MUIRadioGroup>
              </Grid>
              <Grid item xs={6}>
                {/* {watch("actionCodeType") == ACTION_CODE_TYPE.LOYALTY && (
                  <Box
                    sx={{
                      maxWidth: "265px",
                      pt: "10px",
                    }}
                  >
                    <Input
                      control={control}
                      error={Boolean(errors.loyaltyMonthes)}
                      helperText={errors?.loyaltyMonthes?.message}
                      name="loyaltyMonthes"
                      label="Anzahl der Treuemonate"
                    />
                  </Box>
                )} */}

                <>
                  <Typography className="text-gray" variant="subtitle2">
                    {t("action-code.discount-description")}
                  </Typography>
                  <Box
                    sx={{
                      maxWidth: "265px",
                      pt: "10px",
                    }}
                  >
                    <SliderInput
                      disabled={
                        watch("actionCodeType") != ACTION_CODE_TYPE.STORAGE_FEE
                      }
                      minLabel="0%"
                      maxLabel="75%"
                      control={control}
                      helperText={errors?.discount?.message}
                      valueLabelDisplay="auto"
                      aria-labelledby="non-linear-slider"
                      sx={{ color: "info.main" }}
                      min={0}
                      step={null}
                      max={75}
                      marks={marks}
                      name="discount"
                    />
                  </Box>
                </>
              </Grid>
              <Grid item xs={6}>
                <Input
                  control={control}
                  name="description"
                  label={t("action-code.description")}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography className="text-gray">
                  {t("action-code.max-usage")}
                </Typography>
                <MUIRadioGroup control={control} name="usersUnLimited">
                  <FormControlLabel
                    value={"true"}
                    control={<Radio />}
                    label={t("action-code.no-limit")}
                  />
                  <Grid container spacing={4}>
                    <Grid item xs={12} md={6}>
                      <FormControlLabel
                        value={"false"}
                        control={<Radio />}
                        label={t("action-code.enter-number")}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Input
                        disabled={watch("usersUnLimited") == "true"}
                        control={control}
                        error={Boolean(errors.allowRegisterCount)}
                        helperText={errors?.allowRegisterCount?.message}
                        name="allowRegisterCount"
                        label={t("action-code.max-number")}
                      />
                    </Grid>
                  </Grid>
                </MUIRadioGroup>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography className="text-gray">
                  {t("action-code.valid-range")}
                </Typography>
                <MUIRadioGroup control={control} name="validForever">
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <FormControlLabel
                      value={"true"}
                      control={<Radio />}
                      label={t("action-code.no-limit")}
                    />
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={4}>
                        <FormControlLabel
                          value={"false"}
                          control={<Radio />}
                          label={t("action-code.pick-date")}
                        />
                      </Grid>
                      <Grid item xs={12} md={8} columnSpacing={2} container>
                        <Grid xs={12} md={6} item>
                          <Datepicker
                            disabled={watch("validForever") == "true"}
                            label={t("action-code.from")}
                            control={control}
                            name="startDate"
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                error={Boolean(errors?.startDate)}
                                helperText={errors?.startDate?.message}
                                onBlur={() => {
                                  if (getValues("startDate") === "") {
                                    setError("startDate", {
                                      type: "manual",
                                      message: requiredMsg,
                                    });
                                  }
                                }}
                              />
                            )}
                          />
                        </Grid>
                        <Grid xs={12} md={6} item>
                          <Datepicker
                            disabled={watch("validForever") == "true"}
                            label={t("action-code.to")}
                            control={control}
                            name="endDate"
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                error={Boolean(errors?.endDate)}
                                helperText={errors?.endDate?.message}
                                onBlur={() => {
                                  if (getValues("endDate") === "") {
                                    setError("endDate", {
                                      type: "manual",
                                      message: requiredMsg,
                                    });
                                  }
                                }}
                              />
                            )}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Box>
                </MUIRadioGroup>
              </Grid>
              <Grid item sx={{ textAlign: "right" }} xs={12} md={12} mt={10}>
                <Button
                  onClick={handleSubmit(onSubmit)}
                  fullWidth
                  sx={{ bgcolor: "info.main", maxWidth: "192px" }}
                  variant="contained"
                >
                  {t("buttons.create")}
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </LoadingOverlay>
    </div>
  );
}

export default ActionCodeAdd;
