import React, { useReducer, useContext } from "react";

import { REGISTER_COMPANY_INFO_TYPE } from "../type";

import RegisterCompanyContext from "./registerCompanyContext";
import RegisterCompanyReducer from "./registerCompanyReducer";

export const RegisterCompanyStateProvider = (props) => {
  const initState = {
    registerCompanyInfo: null,
  };

  const [state, dispatch] = useReducer(RegisterCompanyReducer, initState);

  // Actions

  const registerCompanyInfoAction = (value) => {
    dispatch({
      type: REGISTER_COMPANY_INFO_TYPE,
      payload: value,
    });
  };

  return (
    <RegisterCompanyContext.Provider
      value={{
        registerCompanyInfo: state.registerCompanyInfo,
        registerCompanyInfoAction,
      }}
    >
      {props.children}
    </RegisterCompanyContext.Provider>
  );
};

export const useRegisterCompanyState = () => useContext(RegisterCompanyContext);
