import { getStorageByIdFunc, getSubStoragesOfOneStorageFunc } from "..";

const getFormattedStorageAndSubStoragesFunc = async (
  id,
  { getState, dispatch }
) => {
  try {
    let parentstorage = await getStorageByIdFunc(id, {
      getState,
      dispatch,
    });
    let subStorageList = await getSubStoragesOfOneStorageFunc(
      { storageId: parentstorage.msdyn_warehouseid, fetchAmount: true },
      {
        getState,
        dispatch,
      }
    );

    const filteredSubStorages = subStorageList.filter((x) => !x.pending);

    let list = [
      {
        key: parentstorage.msdyn_warehouseid,
        value: `${parentstorage.msdyn_name} (${
          parentstorage.msdyn_name.endsWith("00")
            ? "Hauptlagerplatz"
            : parentstorage.msdyn_description
            ? parentstorage.msdyn_description
            : "No description"
        })`, // " (if storage Main storage else substorage)",
        item: parentstorage,
        mainStorageId: parentstorage.msdyn_warehouseid,
      },
    ];
    filteredSubStorages.map((subStorage) => {
      list.push({
        key: subStorage.msdyn_warehouseid,
        value:
          subStorage.msdyn_name +
          " (" +
          (subStorage.msdyn_description
            ? subStorage.msdyn_description
            : "No description") +
          ")",
        item: subStorage,
        mainStorageId: parentstorage.msdyn_warehouseid,
      });
    });

    return list;
  } catch (err) {
    throw Error(
      err.response && err.response.data.detail
        ? err.response.data.detail
        : err.message
    );
  }
};
export default getFormattedStorageAndSubStoragesFunc;
