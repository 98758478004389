import { createAsyncThunk } from "@reduxjs/toolkit";
import { WORKSPACE_ID } from "../../../core/apiconfig/constantApi";
import { postBankAccountFunc } from "../../sharedFunctions";
import { updateCompanyInfoFunc, signSellerContractWorkFlowFunc } from ".";
import { BYPASS_ADOBE_SIGN } from "../../../core/apiconfig/environment";

const postSellerInfoAction = createAsyncThunk(
  "becomeSeller/postSellerInfo",
  async (dataParams, { getState }) => {
    const bankAccountInfo = getState().becomeSeller.bankAccountInfo;

    let bankAccountId = bankAccountInfo.arades_kontoid;

    let bankAccountAddResult = {};
    if (!bankAccountId)
      bankAccountAddResult = await postBankAccountFunc(bankAccountInfo, {
        getState,
      });

    if (bankAccountId || bankAccountAddResult) {
      if (!bankAccountId) bankAccountId = bankAccountAddResult.arades_kontoid;

      let updateCompanyResult = await updateCompanyInfoFunc(bankAccountId, {
        getState,
      });

      if (BYPASS_ADOBE_SIGN)
        await signSellerContractWorkFlowFunc(
          { ownerId: localStorage.getItem(WORKSPACE_ID) },
          { getState }
        );

      return updateCompanyResult;
    } else return null;
  }
);

export default postSellerInfoAction;
