import React from "react";
import { MenuItem, TextField } from "@mui/material";

import { Controller } from "react-hook-form";

const Select = ({
  control = null,
  label,
  options,
  value,
  onChange,
  placeholder,
  id,
  name,
  disabled,
  style,
  multiline,
  className,
  required,
  ...rest
}) => {
  return (
    <div className="d-flex ml-4 mr-2 flex-column ">
      {control ? (
        <Controller
          control={control}
          name={name}
          render={({
            field: {
              onChange: formOnChange,
              onBlur: formOnBlur,
              value: formValue,
              name: formName,
              ref: fromRef,
            },
            fieldState: { invalid, isTouched, isDirty, error },
            formState,
          }) => (
            <TextField
              id={id}
              select
              name={formName}
              value={formValue}
              InputLabelProps={{ shrink: true }}
              autoComplete="off"
              variant="outlined"
              fullWidth
              placeholder={placeholder}
              disabled={disabled}
              required={required}
              className={`${className}`}
              label={label}
              onChange={(e) => {
                formOnChange(e);
                if (onChange) {
                  onChange(e);
                }
              }}
              onBlur={formOnBlur}
              inputRef={fromRef}
              {...rest}
            >
              {options &&
                options.map((option) => (
                  <MenuItem key={option.key} value={option.key}>
                    {option.value}
                  </MenuItem>
                ))}
            </TextField>
          )}
        />
      ) : (
        <TextField
          id={id}
          name={name}
          value={value}
          InputLabelProps={{ shrink: true }}
          select
          autoComplete="off"
          variant="outlined"
          fullWidth
          placeholder={placeholder}
          disabled={disabled}
          required={required}
          className={`${className}`}
          label={label}
          onChange={onChange}
          {...rest}
        >
          {options &&
            options.map((option) => (
              <MenuItem key={option.key} value={option.key}>
                {option.value}
              </MenuItem>
            ))}
        </TextField>
      )}
    </div>
  );
};

export default Select;
