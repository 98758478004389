import React, { useState, useEffect } from "react";
import { ConfirmModal, LoadingOverlay, Verification } from "../common";
import { useDispatch, useSelector } from "react-redux";
import {
  verificationCheckSMSCodeAction,
  verificationSendSMSCodeAction,
} from "../../redux/slices/exchange";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import {
  globalData,
  exchangeFromStorage,
  verificationSteps,
  resetError,
  resetActionStatus,
  resetAll,
  resetVerification,
  resetSentSMS,
} from "../../redux/slices/exchange/exchange.slice";
import {
  postExchangeAction,
  getPartnerDataAction,
} from "../../redux/slices/exchange";

import { useTranslation } from "react-i18next";
import { URL, ERROR_CONTACT_US } from "../../core/constants";
import { Box } from "@mui/material";
import tickIcon from "../../assets/images/tick.svg";

function VerificationStep({ handleCurrentStep }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const exchangeGlobalData = useSelector(globalData);
  const fromStorage = useSelector(exchangeFromStorage);
  const verificationStepsData = useSelector(verificationSteps);

  const [successStatusModal, setSuccessStatusModal] = useState(false);
  const [activationCode, setActivationCode] = useState();
  const [loading, setLoading] = useState(false);

  const onSubmit = () => {
    if (!verificationStepsData.verification) {
      setLoading(true);
      dispatch(
        verificationCheckSMSCodeAction({
          activationCode: activationCode,
          mobileNumber: localStorage.getItem("mobileNumber"),
        })
      );
    }
  };

  useEffect(() => {
    if (verificationStepsData.needOtherPersonVerification) {
      dispatch(getPartnerDataAction(fromStorage.msdyn_warehouseid));
    }
  }, [verificationStepsData.needOtherPersonVerification]);

  useEffect(() => {
    if (
      exchangeGlobalData.actionStatus &&
      exchangeGlobalData.actionType === "verification"
    ) {
      enqueueSnackbar(t("messages.verification-successful"), {
        variant: "success",
        autoHideDuration: 3000,
      });
      setLoading(false);
      if (verificationStepsData.needOtherPersonVerification) {
        dispatch(resetSentSMS());
        dispatch(resetActionStatus());
        dispatch(resetVerification());
        sendSMS(verificationStepsData.partnerPhoneNumber);
        handleCurrentStep("next");
      } else {
        setLoading(true);
        dispatch(resetActionStatus());
        dispatch(postExchangeAction());
      }
    } else if (
      exchangeGlobalData.actionStatus &&
      exchangeGlobalData.actionType === "postExchange"
    ) {
      enqueueSnackbar("Exchange successful", {
        variant: "success",
        autoHideDuration: 3000,
      });
      setLoading(false);
      setSuccessStatusModal(true);
    }
  }, [exchangeGlobalData.actionStatus]);

  useEffect(() => {
    if (exchangeGlobalData.error != null) {
      enqueueSnackbar(exchangeGlobalData.error + ERROR_CONTACT_US, {
        variant: "error",
        autoHideDuration: 20000,
      });
      dispatch(resetError());
      setLoading(false);
    }
  }, [exchangeGlobalData.error]);

  const sendSMS = (to = null) => {
    let phoneNumber;
    if (to) {
      phoneNumber = to;
    } else {
      phoneNumber = localStorage.getItem("mobileNumber");
    }
    dispatch(
      verificationSendSMSCodeAction({
        mobileNumber: phoneNumber,
      })
    );
  };

  const resendCode = () => {
    sendSMS();
  };

  return (
    <>
      <ConfirmModal
        imageSrc={tickIcon}
        open={successStatusModal}
        title={t("exchange.request-sent")}
        showCloseButton={false}
        message={
          <Box sx={{ margin: "0 auto" }}>
            {t("exchange.request-modal-content")}
          </Box>
        }
        okTitle={t("exchange.go-to-storage")}
        onOk={() => {
          dispatch(resetAll());
          navigate(URL.STORAGE);
        }}
      ></ConfirmModal>

      <LoadingOverlay show={loading}>
        <Verification
          number={localStorage?.getItem("mobileNumber")}
          activationCode={activationCode}
          setActivationCode={setActivationCode}
          handleNext={onSubmit}
          resendCode={resendCode}
          verified={verificationStepsData.verification}
        />
      </LoadingOverlay>
    </>
  );
}

export default VerificationStep;
