import { createSlice } from "@reduxjs/toolkit";
import {
  getStorageListAction,
  getSubStorageListAction,
  postPowerOfAttorneyAction,
  getOwnerDataAction,
  getGrantedPowerOfAttorneyListAction,
  getReceivedPowerOfAttorneyListAction,
  cancelPowerOfAttorneyAction,
} from ".";

const initialState = {
  globalData: {
    actionStatus: false,
    actionType: "",
    loading: false,
    error: null,
    storageList: [],
    subStorageList: [],
  },
  choosePersonStep: {
    ownerKey: "",
    ownerBirthDate: "",
    ownerInfo: {},
  },
  chooseStorageStep: {
    storage: "",
  },
  chooseFinalStorageAndSubStoragesStep: {
    mainStorage: "",
    subStorages: [],
  },
  futureSubstoragesStep: {
    activePowerOfAttorney: true,
  },
  summaryStep: {
    validForever: true,
    expirationDate: null,
  },
  lists: {
    grantedPowerOfAttorneyList: [],
    receivedPowerOfAttorneyList: [],
  },
};
export const powerOfAttorneySlice = createSlice({
  name: "powerOfAttorney",
  initialState,
  reducers: {
    setPerson: (state, action) => {
      state.choosePersonStep.ownerKey = action.payload.ownerKey;
      state.choosePersonStep.ownerBirthDate = action.payload.ownerBirthDate;
    },
    setStorage: (state, action) => {
      state.chooseStorageStep.storage = action.payload.storage;
    },
    setFutureSubstoragesStatus: (state, action) => {
      state.futureSubstoragesStep.activePowerOfAttorney =
        action.payload.activePowerOfAttorney;
    },
    setFinalStorageAndSubStorages: (state, action) => {
      state.chooseFinalStorageAndSubStoragesStep.mainStorage =
        action.payload.mainStorage;
      state.chooseFinalStorageAndSubStoragesStep.subStorages =
        action.payload.subStorages;
    },
    setValidStatusPowerOfAttorney: (state, action) => {
      state.summaryStep.validForever = action.payload.validForever;
      state.summaryStep.expirationDate = action.payload.expirationDate;
    },
    resetError: (state) => {
      state.globalData.error = null;
    },
    resetActionStatus: (state) => {
      state.globalData.actionStatus = false;
      state.globalData.actionType = null;
    },
    resetAll: () => {
      return initialState;
    },
  },
  extraReducers: {
    //get ratior storage List
    [getStorageListAction.pending]: (state, action) => {
      state.globalData.loading = true;
    },
    [getStorageListAction.fulfilled]: (state, action) => {
      state.globalData.loading = false;
      // state.globalData.actionStatus = true;
      // state.globalData.actionType = "getStorageList";
      const storages = action.payload;
      let list = [];
      storages.map((item) => {
        list.push({
          key: item.msdyn_warehouseid,
          label: item.msdyn_name,
        });
      });
      state.globalData.storageList = list;
    },
    [getStorageListAction.rejected]: (state, action) => {
      state.globalData.loading = false;
      state.globalData.error = action.error.message;
    },

    //Sub Storage list
    [getSubStorageListAction.pending]: (state, action) => {
      state.globalData.loading = true;
    },
    [getSubStorageListAction.fulfilled]: (state, action) => {
      state.globalData.loading = false;

      const allSubStorages = action.payload;
      if (!allSubStorages || allSubStorages.length == 0) {
        state.globalData.subStorageList = [];
        return;
      }
      const filteredSubStorages = allSubStorages.filter((x) => !x.pending);
      // const filteredSubStorages = allSubStorages.filter((x) => true);

      let list = [];
      filteredSubStorages.map((storage) => {
        list.push({
          key: storage.msdyn_warehouseid,
          label:
            storage.msdyn_name +
            " (" +
            (storage.msdyn_description
              ? storage.msdyn_description
              : "No description") +
            ")",
        });
      });

      state.globalData.subStorageList = list;
    },
    [getSubStorageListAction.rejected]: (state, action) => {
      state.globalData.loading = false;
      state.globalData.error = action.error.message;
    },

    //Post Power Of Attorney Action
    [postPowerOfAttorneyAction.pending]: (state, action) => {
      state.globalData.loading = true;
    },
    [postPowerOfAttorneyAction.fulfilled]: (state, action) => {
      state.globalData.loading = false;
      state.globalData.actionStatus = true;
      state.globalData.actionType = "postPowerOfAttorney";
    },
    [postPowerOfAttorneyAction.rejected]: (state, action) => {
      state.globalData.loading = false;
      state.globalData.error = action.error.message;
      state.globalData.actionType = null;
    },

    //Get Owner Data Action
    [getOwnerDataAction.pending]: (state, action) => {
      state.loading = true;
    },
    [getOwnerDataAction.fulfilled]: (state, action) => {
      state.loading = false;
      const contact = action.payload;
      if (contact.value.length !== 0) {
        state.choosePersonStep.ownerInfo = {
          firstname: contact.value[0].firstname,
          lastname: contact.value[0].lastname,
          birthdate: contact.value[0].birthdate,
          ratiorKey: contact.value[0].arades_ratior_key,
        };
      } else {
        state.globalData.loading = false;
        state.globalData.error = "Owner not found!";
      }
    },
    [getOwnerDataAction.rejected]: (state, action) => {
      state.globalData.loading = false;
      state.globalData.error = action.error.message;
    },

    // getGrantedPowerOfAttorneyListAction
    [getGrantedPowerOfAttorneyListAction.pending]: (state, action) => {
      state.globalData.loading = true;
    },
    [getGrantedPowerOfAttorneyListAction.fulfilled]: (state, action) => {
      state.globalData.loading = false;
      state.lists.grantedPowerOfAttorneyList = action.payload;
    },
    [getGrantedPowerOfAttorneyListAction.rejected]: (state, action) => {
      state.globalData.loading = false;
      state.globalData.error = action.error.message;
    },

    //get Granted Power Of Attorney List Action
    [getGrantedPowerOfAttorneyListAction.pending]: (state, action) => {
      state.globalData.loading = true;
    },
    [getGrantedPowerOfAttorneyListAction.fulfilled]: (state, action) => {
      state.globalData.loading = false;
      state.lists.grantedPowerOfAttorneyList = action.payload;
    },
    [getGrantedPowerOfAttorneyListAction.rejected]: (state, action) => {
      state.globalData.loading = false;
      state.globalData.error = action.error.message;
    },
    //get Received Power Of Attorney List Action
    [getReceivedPowerOfAttorneyListAction.pending]: (state, action) => {
      state.globalData.loading = true;
    },
    [getReceivedPowerOfAttorneyListAction.fulfilled]: (state, action) => {
      state.globalData.loading = false;
      state.lists.receivedPowerOfAttorneyList = action.payload;
    },
    [getReceivedPowerOfAttorneyListAction.rejected]: (state, action) => {
      state.globalData.loading = false;
      state.globalData.error = action.error.message;
    },
    //Cancel Power Of Attorney Action
    [cancelPowerOfAttorneyAction.pending]: (state, action) => {
      state.globalData.loading = true;
    },
    [cancelPowerOfAttorneyAction.fulfilled]: (state, action) => {
      state.globalData.loading = false;
      state.globalData.actionStatus = true;
      state.globalData.actionType = "cancelPowerOfAttorney";
    },
    [cancelPowerOfAttorneyAction.rejected]: (state, action) => {
      state.globalData.loading = false;
      state.globalData.error = action.error.message;
      state.globalData.actionType = null;
    },
  },
});

export const {
  resetError,
  resetActionStatus,
  resetAll,
  setPerson,
  setStorage,
  setFinalStorageAndSubStorages,
  setFutureSubstoragesStatus,
  setValidStatusPowerOfAttorney,
} = powerOfAttorneySlice.actions;

export const fullData = (state) => state.powerOfAttorney;
export const globalData = (state) => state.powerOfAttorney.globalData;
export const storageList = (state) =>
  state.powerOfAttorney.globalData.storageList;
export const subStorageList = (state) =>
  state.powerOfAttorney.globalData.subStorageList;
export const choosePersonStep = (state) =>
  state.powerOfAttorney.choosePersonStep;
export const chooseStorageStep = (state) =>
  state.powerOfAttorney.chooseStorageStep;
export const chooseFinalStorageAndSubStoragesStep = (state) =>
  state.powerOfAttorney.chooseFinalStorageAndSubStoragesStep;
export const futureSubstoragesStep = (state) =>
  state.powerOfAttorney.futureSubstoragesStep;
export const summaryStep = (state) => state.powerOfAttorney.summaryStep;
export const lists = (state) => state.powerOfAttorney.lists;

export default powerOfAttorneySlice.reducer;
