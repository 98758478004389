import React, { useState, useEffect } from "react";
import { Box, Button } from "@mui/material";
import { FormSteperStructure, LoadingOverlay, Select } from "../common";
import { useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { ERROR_CONTACT_US } from "../../core/constants";
import {
  getFormattedStorageAndSubStoragesAction,
  getStorageListWithSubstoragesAction,
} from "../../redux/slices/exchange";
import { WORKSPACE_TYPE, WORKSPACE_ID } from "../../core/apiconfig/constantApi";
import {
  exchangeSource,
  globalData,
  setSelectedSource,
  resetError,
} from "../../redux/slices/exchange/exchange.slice";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

function SelectSourceStorageStep({ handleCurrentStep }) {
  const requiredMsg = "erforderlich";

  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const detailStorageId = useParams().storageid;
  const exchangeSourceData = useSelector(exchangeSource);
  const exchangeGlobalData = useSelector(globalData);

  useEffect(() => {
    setLoading(true);
    dispatch(
      getStorageListWithSubstoragesAction({
        workspaceId: localStorage.getItem(WORKSPACE_ID),
        workspaceType: localStorage.getItem(WORKSPACE_TYPE),
      })
    );
    //parichehr : read from the new action used for disable enable
    dispatch(getFormattedStorageAndSubStoragesAction(detailStorageId));
  }, []);

  useEffect(() => {
    setLoading(exchangeGlobalData.loading);
  }, [exchangeGlobalData.loading]);

  useEffect(() => {
    if (exchangeGlobalData.error != null) {
      enqueueSnackbar(exchangeGlobalData.error + ERROR_CONTACT_US, {
        variant: "error",
        autoHideDuration: 20000,
      });
      dispatch(resetError());
    }
  }, [exchangeGlobalData.error]);

  const schema = yup.object().shape({
    fromStorageId: yup.string().required(requiredMsg),
  });

  const {
    control,
    handleSubmit,
    setError,
    getValues,
    formState: { errors },
  } = useForm({
    mode: "all",
    defaultValues: {
      fromStorageId: exchangeSourceData.fromStorageId,
    },
    resolver: yupResolver(schema),
  });

  const submitForm = (data) => {
    console.log(data);
    dispatch(
      setSelectedSource({
        fromStorageId: data.fromStorageId,
        fromStorage: exchangeSourceData?.options?.filter(
          (x) => x.key == data.fromStorageId
        )[0].item,
      })
    );
    handleCurrentStep("next");
  };

  return (
    <LoadingOverlay show={loading}>
      <Box
        sx={{
          margin: "0 auto",
          minHeight: "calc(100vh - 250px)",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "space-around",
        }}
      >
        <Box sx={{ minWidth: "50%", margin: "0 auto" }}>
          <Select
            control={control}
            error={Boolean(errors.fromStorageId)}
            helperText={errors.fromStorageId?.message}
            name="fromStorageId"
            label={t("exchange.select-source")}
            options={exchangeSourceData.options}
          />
        </Box>
        <FormSteperStructure.Footer>
          <Button
            onClick={handleSubmit(submitForm)}
            fullWidth
            sx={{ backgroundColor: "info.main", maxWidth: "192px" }}
            variant="contained"
          >
            {t("buttons.next")}
          </Button>
        </FormSteperStructure.Footer>
      </Box>
    </LoadingOverlay>
  );
}

export default SelectSourceStorageStep;
