import axios from "axios";
import { BASE_URL } from "../../../core/apiconfig/environment";
import { TOKEN_NAME } from "../../../core/apiconfig/constantApi";

const getStorageAmountFunc = async (storageId, { getState }) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `bearer ${localStorage.getItem(TOKEN_NAME)}`,
    },
  };

  if (!storageId) return { arades_verfuegbare_menge: 0, arades_menge: 0 };
  const result = await axios.get(`${BASE_URL}data/product-inventories/warehouse/${storageId}/`, config);

  if (result?.data?.status != 200 || result?.data?.data?.value?.length == 0) {
    //parichehr throw Error("storage amount is not defined");
    return { arades_verfuegbare_menge: 0, arades_menge: 0 };
  } else {
    return result.data?.data?.value[0];
  }
};

export default getStorageAmountFunc;
