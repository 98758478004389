import { createSlice } from "@reduxjs/toolkit";
import { getContractListAction } from ".";
import { dateString } from "../../../core/utils/convert";

const initialState = {
  globalData: {
    actionStatus: false,
    actionType: "",
    loading: false,
    error: null,
    contracts: [],
  },
  ratiorData: {
    ratiorBankAccount: {},
    ratiorInfo: {},
  },
};
export const contractSlice = createSlice({
  name: "contract",
  initialState,
  reducers: {
    resetAll: () => initialState,
    resetActionStatus: (state) => {
      state.globalData.actionStatus = false;
      state.globalData.actionType = false;
    },
    resetError: (state) => {
      state.globalData.error = null;
    },
  },
  extraReducers: {
    // get ratior data
    [getContractListAction.pending]: (state, action) => {
      state.globalData.loading = true;
    },
    [getContractListAction.fulfilled]: (state, action) => {
      state.globalData.loading = false;
      let contracts = action.payload;
      contracts.map((item) => {
        item.adobe_datesent = dateString(
          item.adobe_datesent ? new Date(item.adobe_datesent) : null
        );
        item.adobe_datesigned = dateString(
          item.adobe_datesigned ? new Date(item.adobe_datesigned) : null
        );
      });
      state.globalData.contracts = contracts;
      state.globalData.actionType = "getContractList";
      state.globalData.actionStatus = true;
    },
    [getContractListAction.rejected]: (state, action) => {
      state.globalData.loading = false;
      state.globalData.error = action.error.message;
    },
  },
});

export const { resetError, resetActionStatus, resetAll } =
  contractSlice.actions;

export const contractGlobalState = (state) => state.contract.globalData;

export default contractSlice.reducer;
