import axios from "axios";
import { dateString, timeString } from "../../../core/utils/convert";
import {
  TOKEN_NAME,
  WORKSPACE_ID,
  WORKSPACE_NAME,
  WORKSPACE_TYPE,
} from "../../../core/apiconfig/constantApi";
import { BASE_URL } from "../../../core/apiconfig/environment";
import { WORKSPACETYPE } from "../../../core/constants";

const tablenameMap = {
  contact: "person",
  msdyn_warehouse: "warehouse",
  account: "company",
  arades_konto: "bank account",
  arades_zahlungsverkehr: "payment",
  quote: "quote",
  salesorder: "sales order",
  invoice: "invoice",
  msdyn_productinventory: "product inventory",
};
const getMessagesListFunc = async (params, { getState }) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `bearer ${localStorage.getItem(TOKEN_NAME)}`,
    },
  };

  const workspaceId = localStorage.getItem(WORKSPACE_ID);
  const workspaceType = localStorage.getItem(WORKSPACE_TYPE);
  let result;
  if (workspaceType === WORKSPACETYPE.CONTACT || workspaceType === WORKSPACETYPE.UNDER_AGE) {
    result = await axios.get(`${BASE_URL}data/messages/contact/${workspaceId}/?received=${params.received ? "true" : "false"}`, config);
  } else {
    result = await axios.get(`${BASE_URL}data/messages/account/${workspaceId}/?received=${params.received ? "true" : "false"}`, config);
  }

  if (result?.data?.status !== 200)
    throw Error("Nachrichten könnten nicht geladen werden"); //("Messages not fetched");

  let toShowList = result.data.data.value.sort((a, b) =>
    a?.createdon >= b?.createdon ? -1 : 1
  );

  const workspaceName = localStorage.getItem(WORKSPACE_NAME);

  let list = [];
  toShowList.forEach((item) => {
    if (!list.find((x) => x.id === item.activityid)) {
      const otherPartyName =
        item["apv.partyid@OData.Community.Display.V1.FormattedValue"];
      let labels = [];
      if (item.arades_wichtige_nachricht === "100000000")
        labels.push({ type: "IMPORTANT" });
      if (item.arades_antworten_moeglich === "100000001")
        labels.push({ type: "NOREPLY" });
      let tmp = {
        id: item.activityid,
        from: params.Received ? otherPartyName : workspaceName,
        to: params.Received ? workspaceName : otherPartyName,
        subject: item.subject,
        regardedToId: item._regardingobjectid_value,
        regardedTo: item._regardingobjectid_value
          ? item[
              "_regardingobjectid_value@OData.Community.Display.V1.FormattedValue"
            ] +
            " _ (" +
            tablenameMap[
              item[
                "_regardingobjectid_value@Microsoft.Dynamics.CRM.lookuplogicalname"
              ]
            ] +
            ")"
          : "",
        receivedDate: dateString(item.createdon),
        receivedTime: timeString(item.createdon),
        description: item.description,
        viewed: true,
        labels: labels,
        attachedFiles: [],
      };

      let attachments = result.data.data.value.filter(
        (x) => x.activityid === item.activityid && x["at.annotationid"]
      );
      for (let i = 0; i < attachments?.length; i++) {
        tmp.attachedFiles.push({
          id: attachments[i]["at.annotationid"],
          fileName: attachments[i]["at.filename"],
          downloadLink: `${BASE_URL}download/attachment?id=${attachments[i]["at.annotationid"]}`,
          fileSize: (attachments[i]["at.filesize"] ?? 0) + " kb",
        });
      }

      list.push(tmp);
    }
  });

  return list;
};

export default getMessagesListFunc;
