import axios from "axios";
import { BASE_URL } from "../../../core/apiconfig/environment";
import { TOKEN_NAME } from "../../../core/apiconfig/constantApi";

const getRatiorDataFunc = async (filter, { getState }) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `bearer ${localStorage.getItem(TOKEN_NAME)}`,
    },
  };

  const result = await axios.get(`${BASE_URL}data/settings/`, config);
  if (!result?.status == 200) throw Error("Einstellungen konnten nicht geladen werden");
  return result.data;
};

export default getRatiorDataFunc;
