import React, { useState, useEffect } from "react";
import { useApi } from "../../../core/hooks/useApi";
import { BASE_URL } from "../../../core/apiconfig/environment";
import { Radio, Box, Button, Tab, Grid } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import { useSnackbar } from "notistack";
import MUIButtom from "@mui/material/Button";
import { ConfirmModal, LoadingOverlay, Input, Select } from "../../common";
import {
  CONTACT_ID,
  WORKSPACE_ID,
  WORKSPACE_TYPE,
} from "../../../core/apiconfig/constantApi";
import { WORKSPACETYPE, ERROR_CONTACT_US } from "../../../core/constants";
import "./selectOrAddBankAccount.scss";
import { globalsBankCurrencies } from "../../../redux/slices/globals/globals.slice";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const SelectOrAddBankAccount = ({
  selectedBankAccount,
  onBankAccountSelected,
  newBankAccountInfo,
  confirmButtonText,
  secoundButtonText,
  notSaveBankAccount,
  onClickSecoundButton,
}) => {
  const { get, post } = useApi();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const bankCurrencies = useSelector(globalsBankCurrencies);

  const OTHER_ACCOUNT = "other";
  const [ibanValidationModalOpen, setIbanValidationModalOpen] = useState(false);
  const [ibanValidations, setIbanValidations] = useState("");
  const [loading, setLoading] = useState(false);
  const [bankAccounts, setBankAccounts] = useState([]);
  const [selectedAccount, setSelectedAccount] = useState(OTHER_ACCOUNT);
  const [selectedTab, setSelectedTab] = useState("1");
  const [accountValue, setAccountValue] = useState();
  const [ibanValid, setIbanValid] = useState(false);

  const [defaultFields, setDefaultFields] = useState({
    arades_kontoid: "",
    arades_kontoinhaber: "",
    arades_waehrung: "",
    arades_iban: "",
    arades_bic: "",
    arades_bank: "",
    arades_kontonummer: "",
    arades_bankleitzahl: "",
  });

  const requiredMsg = t("validation.required");

  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    if (selectedBankAccount) setSelectedAccount(selectedBankAccount);
  }, [selectedBankAccount]);

  useEffect(() => {
    if (newBankAccountInfo) {
      setValue("arades_kontoinhaber", newBankAccountInfo.arades_kontoinhaber);
      setValue("arades_waehrung", newBankAccountInfo.arades_waehrung);
      setValue("arades_iban", newBankAccountInfo.arades_iban);
      setValue("arades_bic", newBankAccountInfo.arades_bic);
      setValue("arades_bank", newBankAccountInfo.arades_bank);
      setValue("arades_kontonummer", newBankAccountInfo.arades_kontonummer);
      setValue("arades_bankleitzahl", newBankAccountInfo.arades_bankleitzahl);
      if (newBankAccountInfo.arades_kontonummer) setSelectedTab("2");
      else setSelectedTab("1");
    }
  }, [newBankAccountInfo]);

  const loadData = async () => {
    setLoading(true);
    const workspaceType = localStorage.getItem(WORKSPACE_TYPE);
    let myKontosResult;
    if (workspaceType === WORKSPACETYPE.CONTACT || workspaceType === WORKSPACETYPE.UNDER_AGE) { // Contact workspace (person)?
      myKontosResult = await get(`${BASE_URL}data/kontos/contact/${localStorage.getItem(WORKSPACE_ID)}/`);
    } else { // Account workspace (company)?
      myKontosResult = await get(`${BASE_URL}data/kontos/account/${localStorage.getItem(WORKSPACE_ID)}/`);
    }
    const kontos = myKontosResult.data?.value;
    if (localStorage.getItem(WORKSPACE_TYPE) === WORKSPACETYPE.UNDER_AGE) { // Underage workspace account?
      kontos.push(...(await get(`${BASE_URL}data/kontos/contact/${localStorage.getItem(CONTACT_ID)}/`)).data?.value ?? []); // Add parent's (Bank-) kontos
    }
    setLoading(false);
    if (kontos.length) {
      setBankAccounts(kontos);
    }
  };

  const handleRadio = (e) => {
    setSelectedAccount(e.target.value);
  };

  const onSubmit = async (value) => {
    if (selectedTab == "1") {
      setValue("arades_kontonummer", "");
      setValue("arades_bankleitzahl", "");
      value.arades_kontonummer = "";
      value.arades_bankleitzahl = "";
    } else if (selectedTab == "2") {
      setValue("arades_bic", "");
      setValue("arades_iban", "");
      value.arades_bic = "";
      value.arades_iban = "";
    }

    if (selectedAccount == OTHER_ACCOUNT) {
      // if (selectedTab == "1") {
      //   setAccountValue(value);
      //   let ibanIsValid = await IBANIsValid(value.arades_iban, true);
      //   if (!ibanIsValid) return ;
      // } else {
      setLoading(true);
      if (!notSaveBankAccount) {
        let accountId = await addBankAccount(value);
        onBankAccountSelected(accountId, bankAccounts, value);
      } else onBankAccountSelected(null, bankAccounts, value);
      setLoading(false);
      //}
    } else onBankAccountSelected(selectedAccount, bankAccounts);
  };

  const IBANIsValid = async (iban, checkMode) => {
    setLoading(true);
    const result = await get(`${BASE_URL}iban/check?iban=${iban}`);
    setLoading(false);
    if (result?.data?.errors?.length > 0) {
      setIbanValidationModalOpen(true);
      setIbanValidations({ error: result?.data?.errors[0]?.message });
      setIbanValid(false);
      return false;
    } else if (
      result?.status == 200 &&
      result?.data?.bank_data?.bic &&
      result?.data?.bank_data?.bank &&
      result?.data?.bank_data?.country &&
      result?.data?.bank_data?.country_iso &&
      result?.data?.bank_data?.account &&
      result?.data?.bank_data?.bank_code
    ) {
      setValue("arades_bic", result.data.bank_data.bic);
      setValue("arades_bank", result.data.bank_data.bank);
      if (checkMode) {
        setIbanValidationModalOpen(true);
        setIbanValidations({ IbanInfo: result?.data?.bank_data });
      }
      setIbanValid(true);
      return true;
    } else {
      setIbanValidationModalOpen(true);
      setIbanValidations(result?.data?.validations);
      setIbanValid(false);
      return false;
    }
  };

  const addBankAccount = async (value) => {
    try {
      let bankAccount = { ...value };
      delete bankAccount.arades_kontoid;

      if (!bankAccount.arades_kontonummer)
        bankAccount.arades_kontonummer = null;
      if (!bankAccount.arades_waehrung) bankAccount.arades_waehrung = null;

      const workspaceType = localStorage.getItem(WORKSPACE_TYPE);
      let result;
      if (workspaceType === WORKSPACETYPE.CONTACT || workspaceType === WORKSPACETYPE.UNDER_AGE) {
        result = await post(`${BASE_URL}data/kontos/contact/${localStorage.getItem(WORKSPACE_ID)}`, bankAccount);
      } else {
        result = await post(`${BASE_URL}data/kontos/account/${localStorage.getItem(WORKSPACE_ID)}`, bankAccount);
      }

      if (result.data && result.data.error) {
        enqueueSnackbar(result.data.error.message + ERROR_CONTACT_US, {
          variant: "error",
          autoHideDuration: 20000,
        });
        return false;
      }
      return result.data.arades_kontoid;
    } catch (error) {
      console.log(`error`, error);
    }
  };

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const schema = yup.object().shape({
    arades_kontoinhaber:
      selectedAccount == OTHER_ACCOUNT
        ? yup.string().required(requiredMsg)
        : yup.string(),
    arades_waehrung:
      selectedAccount == OTHER_ACCOUNT
        ? yup.string().required(requiredMsg)
        : yup.string(),
    arades_iban:
      selectedAccount == OTHER_ACCOUNT && selectedTab == "1"
        ? yup.string().required(requiredMsg)
        : yup.string().nullable(),
    arades_kontonummer:
      selectedAccount == OTHER_ACCOUNT && selectedTab == "2"
        ? yup
            .number()
            .required(requiredMsg)
            .typeError(t("validation.bank-account-number"))
        : yup.string().nullable(),
    arades_bankleitzahl:
      selectedAccount == OTHER_ACCOUNT && selectedTab == "2"
        ? yup.string().required(requiredMsg)
        : yup.string().nullable(),
  });

  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    watch,
    formState: { errors },
  } = useForm({
    mode: "all",
    defaultValues: {
      ...defaultFields,
    },
    resolver: yupResolver(schema),
  });

  return (
    <LoadingOverlay show={loading}>
      <ConfirmModal
        open={ibanValidationModalOpen}
        showCloseButton={false}
        title={
          ibanValidations?.IbanInfo
            ? t("bank-account.iban-valid")
            : t("bank-account.iban-result")
        }
        message={
          <div>
            {ibanValidations?.error && <div>{ibanValidations?.error}</div>}
            {!ibanValidations?.error && !ibanValidations?.IbanInfo && (
              <div>
                {ibanValidations?.chars?.message}
                <br></br>
                {ibanValidations?.account?.message}
                <br></br>
                {ibanValidations?.iban?.message}
                <br></br>
                {ibanValidations?.structure?.message}
                <br></br>
                {ibanValidations?.length?.message}
                <br></br>
                {ibanValidations?.country_support?.message}
                <br></br>
              </div>
            )}
            {ibanValidations?.IbanInfo && (
              <div>
                BIC:&nbsp;{ibanValidations?.IbanInfo.bic}
                <br></br>
                BANK:&nbsp;{ibanValidations?.IbanInfo.bank}
                <br></br>
                BANK CODE:&nbsp;{ibanValidations?.IbanInfo.bank_code}
                <br></br>
                ACCOUNT:&nbsp;{ibanValidations?.IbanInfo.account}
                <br></br>
                COUNTRY:&nbsp;{ibanValidations?.IbanInfo.country}
                <br></br>
              </div>
            )}
          </div>
        }
        noImage={true}
        okTitle={"Ok"}
        onOk={async () => {
          // if (ibanValid) {
          //   setLoading(true);
          //   if (!notSaveBankAccount) {
          //     let accountId = await addBankAccount(accountValue);
          //     onBankAccountSelected(accountId, bankAccounts, accountValue);
          //   } else onBankAccountSelected(null, bankAccounts, accountValue);
          //   setLoading(false);
          // }
          setIbanValidationModalOpen(false);
        }}
      ></ConfirmModal>

      <Box>
        <Box mt={4}>
          <FormControl>
            {/* <div className="d-flex div-attention">
                  <img src={alarm}></img>
                  <span className=" attention">
                    Achtung: Ratior verwendet dieses Konto für alle finanziellen
                    Aufgaben im Zusammenhang mit dem Verkäuferkonto
                  </span>
                </div> */}
            {bankAccounts.length > 0 && (
              <RadioGroup
                onChange={handleRadio}
                value={selectedAccount}
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue={selectedAccount}
                name="radio-buttons-group"
              >
                {bankAccounts.map((ba, i) => (
                  <FormControlLabel
                    key={`radio${i}`}
                    value={ba.arades_kontoid}
                    control={<Radio />}
                    label={
                      <div>
                        <b>{ba.arades_kontoinhaber}</b>&nbsp;
                        {"(" +
                          (ba.arades_iban
                            ? ba.arades_iban
                            : ba.arades_kontonummer +
                              " - " +
                              ba.arades_bankleitzahl) +
                          (ba.arades_bank ? " - " + ba.arades_bank : "") +
                          ")"}
                      </div>
                    }
                  />
                ))}

                <FormControlLabel
                  value={OTHER_ACCOUNT}
                  control={<Radio />}
                  label={t("bank-account.add-another")}
                />
              </RadioGroup>
            )}
            {bankAccounts.length == 0 && (
              <div style={{ marginBottom: 10 }}>
                {t("bank-account.add-account")}
              </div>
            )}
          </FormControl>
        </Box>

        {selectedAccount == OTHER_ACCOUNT && (
          <Box sx={{ width: "100%", typography: "body1" }}>
            <TabContext value={selectedTab}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <TabList onChange={handleTabChange} aria-label="tabs">
                  <Tab label="IBAN" value="1" />
                  <Tab label={t("bank-account.without-iban")} value="2" />
                </TabList>
              </Box>
              <TabPanel value="1">
                <Grid
                  spacing={8}
                  sx={{ marginTop: 1 }}
                  container
                  direction="row"
                >
                  <Grid xs={12} lg={6} item>
                    <Input
                      error={Boolean(errors.arades_kontoinhaber)}
                      helperText={errors.arades_kontoinhaber?.message}
                      control={control}
                      name={"arades_kontoinhaber"}
                      label={t("bank-account.owner") + "*"}
                    />
                  </Grid>
                  {ibanValid && (
                    <Grid xs={12} lg={6} item>
                      <Input
                        disabled={true}
                        control={control}
                        name={"arades_bank"}
                        label={t("bank-account.bank")}
                      />
                    </Grid>
                  )}

                  <Grid xs={12} lg={6} item>
                    <Input
                      error={Boolean(errors.arades_iban)}
                      helperText={errors.arades_iban?.message}
                      control={control}
                      name={"arades_iban"}
                      label={"IBAN*"}
                    />
                    {watch("arades_iban") && (
                      <MUIButtom
                        variant="text"
                        onClick={() => IBANIsValid(watch("arades_iban"), true)}
                        sx={{
                          color: "info.main",
                          cursor: "pointer",
                        }}
                      >
                        Check IBAN
                      </MUIButtom>
                    )}
                  </Grid>

                  {ibanValid && (
                    <Grid xs={12} lg={6} item>
                      <Input
                        disabled={true}
                        name={"arades_bic"}
                        control={control}
                        label={"BIC"}
                      />
                    </Grid>
                  )}
                  {ibanValid && (
                    <Grid xs={12} lg={6} item>
                      <Select
                        name={"arades_waehrung"}
                        control={control}
                        error={Boolean(errors.arades_waehrung)}
                        helperText={errors.arades_waehrung?.message}
                        options={[
                          { key: "", value: t("bank-account.select") },
                        ].concat(bankCurrencies)}
                        label={t("bank-account.currency") + "*"}
                      />
                    </Grid>
                  )}
                </Grid>
              </TabPanel>
              <TabPanel value="2">
                <Grid
                  spacing={8}
                  sx={{ marginTop: 1 }}
                  container
                  direction="row"
                >
                  <Grid xs={12} lg={6} item>
                    <Input
                      error={Boolean(errors.arades_kontoinhaber)}
                      helperText={errors.arades_kontoinhaber?.message}
                      control={control}
                      name={"arades_kontoinhaber"}
                      label={t("bank-account.owner") + "*"}
                    />
                  </Grid>
                  <Grid xs={12} lg={6} item>
                    <Input
                      control={control}
                      name={"arades_bank"}
                      label={t("bank-account.bank")}
                    />
                  </Grid>
                  <Grid xs={12} lg={6} item>
                    <Input
                      error={Boolean(errors.arades_kontonummer)}
                      helperText={errors.arades_kontonummer?.message}
                      control={control}
                      name={"arades_kontonummer"}
                      label={t("bank-account.account-number") + "*"}
                    />
                  </Grid>
                  <Grid xs={12} lg={6} item>
                    <Input
                      error={Boolean(errors.arades_bankleitzahl)}
                      helperText={errors.arades_bankleitzahl?.message}
                      control={control}
                      name={"arades_bankleitzahl"}
                      label={t("bank-account.bank-code") + "*"}
                    />
                  </Grid>

                  <Grid xs={12} lg={6} item>
                    <Select
                      name={"arades_waehrung"}
                      control={control}
                      error={Boolean(errors.arades_waehrung)}
                      helperText={errors.arades_waehrung?.message}
                      options={[
                        { key: "", value: t("bank-account.select") },
                      ].concat(bankCurrencies)}
                      label={t("bank-account.currency") + "*"}
                    />
                  </Grid>
                </Grid>
              </TabPanel>
            </TabContext>
          </Box>
        )}
      </Box>
      <Box sx={{ display: "flex", justifyContent: "end", marginTop: 20 }}>
        {secoundButtonText && (
          <Button
            onClick={onClickSecoundButton}
            fullWidth
            sx={{ maxWidth: "192px", mx: 10 }}
            variant="outlined"
          >
            {secoundButtonText}
          </Button>
        )}

        <Button
          disabled={
            !ibanValid && selectedTab == "1" && selectedAccount == OTHER_ACCOUNT
          }
          onClick={handleSubmit(onSubmit)}
          fullWidth
          sx={{ bgcolor: "info.main", maxWidth: "192px" }}
          variant="contained"
        >
          {confirmButtonText || t("buttons.confirm")}
        </Button>
      </Box>
    </LoadingOverlay>
  );
};

export default SelectOrAddBankAccount;
