import React from "react";
import ContactStepManager from "./contact/contact.step.manager";
import CompanyStepManager from "./company/company.step.manager";
import ContactWith2FAStepManager from "./contact/contactWith2FA.step.manager";
import CompanyWith2FAStepManager from "./company/companyWith2FA.step.manager";
import UnderAgeStepManager from "./underage/underage.step.manager";
import UnderAgeWith2FAStepManager from "./underage/underageWith2FA.step.manager";
import { WORKSPACE_TYPE } from "../../../../core/apiconfig/constantApi";
import { WORKSPACETYPE } from "../../../../core/constants";
import { BYPASS_ADOBE_SIGN } from "../../../../core/apiconfig/environment";

function ForMySelfStepManager({ handleCurrentStep, parent }) {
  return (
    <>
      {localStorage.getItem(WORKSPACE_TYPE) === WORKSPACETYPE.CONTACT &&
        !BYPASS_ADOBE_SIGN && (
          <ContactStepManager
            parent={parent}
            handleCurrentStep={handleCurrentStep}
          />
        )}
      {localStorage.getItem(WORKSPACE_TYPE) === WORKSPACETYPE.CONTACT &&
        BYPASS_ADOBE_SIGN && (
          <ContactWith2FAStepManager
            parent={parent}
            handleCurrentStep={handleCurrentStep}
          />
        )}
      {localStorage.getItem(WORKSPACE_TYPE) !== WORKSPACETYPE.CONTACT &&
        localStorage.getItem(WORKSPACE_TYPE) !== WORKSPACETYPE.UNDER_AGE &&
        !BYPASS_ADOBE_SIGN && (
          <CompanyStepManager
            parent={parent}
            handleCurrentStep={handleCurrentStep}
          />
        )}
      {localStorage.getItem(WORKSPACE_TYPE) !== WORKSPACETYPE.CONTACT &&
        localStorage.getItem(WORKSPACE_TYPE) !== WORKSPACETYPE.UNDER_AGE &&
        BYPASS_ADOBE_SIGN && (
          <CompanyWith2FAStepManager
            parent={parent}
            handleCurrentStep={handleCurrentStep}
          />
        )}
      {localStorage.getItem(WORKSPACE_TYPE) === WORKSPACETYPE.UNDER_AGE &&
        !BYPASS_ADOBE_SIGN && (
          <UnderAgeStepManager
            parent={parent}
            handleCurrentStep={handleCurrentStep}
          />
        )}
      {localStorage.getItem(WORKSPACE_TYPE) === WORKSPACETYPE.UNDER_AGE &&
        BYPASS_ADOBE_SIGN && (
          <UnderAgeWith2FAStepManager
            parent={parent}
            handleCurrentStep={handleCurrentStep}
          />
        )}
    </>
  );
}

export default ForMySelfStepManager;
