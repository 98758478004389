import React, { useState } from "react";
import { FormSteper } from "../../common";

import ChangePhoneNumberStep from "./changePhoneNumber.step";
import VerificationStep from "./verification.step";
import VerificationNewNumberStep from "./verificationNewNumber.step";
import { useTranslation } from "react-i18next";

function ChangePhoneNumnberStepManager({
  handleCurrentStep: handlerParentStep,
}) {
  const {t} = useTranslation();
  const [currentStep, setCurrentStep] = useState(1);
  const stepsCount = 3;

  const HandleCurrentStep = (step, parent) => {
    if (parent) {
      handlerParentStep(parent);
    } else {
      if (isNaN(parseInt(step))) {
        if (step === "next" && currentStep < stepsCount) {
          setCurrentStep((prev) => prev + 1);
        } else if (step === "prev" && currentStep > 1) {
          setCurrentStep((prev) => prev - 1);
        }
      } else {
        if (currentStep <= stepsCount && currentStep >= 1) {
          setCurrentStep(step);
        }
      }
    }
  };

  return (
    <div className="container-card">
      <FormSteper
        currentStep={currentStep}
        setCurrentStep={HandleCurrentStep}
        noFooter={[1, 2, 3]}
        noBackButton={[2]}
        headerTitles={[
          t("components.verification.title"),
          t("components.change-phone.enter-new-title"),
          t("components.change-phone.change-title"),
        ]}
      >
        {/* Step 1- Verification */}
        <VerificationStep handleCurrentStep={HandleCurrentStep} />

        {/* Step 2- Change Email */}
        <ChangePhoneNumberStep handleCurrentStep={HandleCurrentStep} />

        {/* Step 3- Change Email */}
        <VerificationNewNumberStep handleCurrentStep={HandleCurrentStep} />
      </FormSteper>
    </div>
  );
}

export default ChangePhoneNumnberStepManager;
