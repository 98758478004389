import React, { useState, useEffect } from "react";
import { Box, Button, Grid, Divider, Typography } from "@mui/material";
import { FormSteperStructure } from "../common";
import { useTranslation } from "react-i18next";
import alarm from "../../assets/images/alarm.svg";
import { useSelector, useDispatch } from "react-redux";
import { exchangeFullData } from "../../redux/slices/exchange/exchange.slice";
import { verificationSendSMSCodeAction } from "../../redux/slices/exchange";
import { GermanFloat, Currency } from "../../core/utils/convert";
import {
  globalsRatiorData,
  globalsMetalPrices,
  customerLoyaltyData,
} from "../../redux/slices/globals/globals.slice";

function SummaryStep({ handleCurrentStep }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const exchangeData = useSelector(exchangeFullData);
  const ratiorData = useSelector(globalsRatiorData);
  const metalPrices = useSelector(globalsMetalPrices);
  const customerLoyaltyInfo = useSelector(customerLoyaltyData);
  const [calculatedInfo, setCalculatedInfo] = useState({});

  useEffect(() => {
    calculateFees();
  }, []);

  const calculateFees = () => {
    const sellFirstGrams = exchangeData.enterAmount.amountPerGram;
    const goldUnitPrice = metalPrices.goldPrice.amount;
    const silverUnitPrice = metalPrices.silverPrice.amount;

    const sellFirstPrice =
      exchangeData.exchangeInfo.from == "Gold"
        ? sellFirstGrams * goldUnitPrice
        : sellFirstGrams * silverUnitPrice;

    const feePercent = customerLoyaltyInfo.hasLoyalty
      ? customerLoyaltyInfo.feePercent
      : ratiorData.sellFee;
    const sellFee = (feePercent * sellFirstPrice) / 100;
    const sellLastPrice = sellFirstPrice - sellFee;

    let buyFirstPrice = sellLastPrice;

    const buyFee = (ratiorData.buyFee * buyFirstPrice) / 100;
    const buyLastPrice = buyFirstPrice - buyFee;
    const buyLastGrams =
      exchangeData.exchangeInfo.to == "Gold"
        ? buyLastPrice / goldUnitPrice
        : buyLastPrice / silverUnitPrice;

    setCalculatedInfo({
      goldUnitPrice: goldUnitPrice,
      silverUnitPrice: silverUnitPrice,
      sellFirstPrice: sellFirstPrice,
      sellFee: sellFee,
      sellLastPrice: sellLastPrice,
      buyFirstPrice: buyFirstPrice,
      buyFee: buyFee,
      buyLastPrice: buyLastPrice,
      buyLastGrams: buyLastGrams,
    });
  };

  const sendSMS = () => {
    if (!exchangeData.globalData.verification) {
      const currentAccountPhoneNumber = localStorage.getItem("mobileNumber");
      dispatch(
        verificationSendSMSCodeAction({
          mobileNumber: currentAccountPhoneNumber,
        })
      );
    }
  };

  const onSubmit = (data) => {
    sendSMS();
    handleCurrentStep("next");
  };

  const boxStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  };
  const headrStyle = {
    color: "rgba(0, 0, 0, 0.38)",
    fontSize: 14,
  };
  const infoStyle = {
    color: "rgba(0, 0, 0, 0.87);",
    fontSize: 16,
  };

  return (
    <Box
      sx={{
        margin: "0 auto",
      }}
    >
      <Box>
        <Grid container spacing={8}>
          <Grid xs={12} md={5} item>
            <Grid container spacing={8}>
              <Grid xs={12} item>
                <Divider
                  sx={{
                    marginTop: "20px",
                    color: "rgba(0, 0, 0, 0.38)",
                    "&::before": {
                      width: 0,
                    },
                  }}
                  textAlign="left"
                >
                  {t("exchange.from")}
                </Divider>
              </Grid>
              <Grid xs={12} item>
                <Box sx={{ ...boxStyle }}>
                  <Typography sx={{ ...headrStyle }}>
                    {t("exchange.storage-number")}
                  </Typography>
                  <Typography sx={{ ...infoStyle }}>
                    {exchangeData.exchangeSource.fromStorage.msdyn_name}
                  </Typography>
                </Box>
              </Grid>
              <Grid xs={12} item>
                <Box sx={{ ...boxStyle }}>
                  <Typography sx={{ ...headrStyle }}>
                    {t("exchange.from")}
                  </Typography>
                  <Typography sx={{ ...infoStyle }}>
                    {GermanFloat(exchangeData.enterAmount.amountPerGram * 1) +
                      " " +
                      t("exchange.gram")}{" "}
                    {exchangeData.exchangeInfo.from == "Gold"
                      ? t("exchange.gold")
                      : t("exchange.silver")}
                  </Typography>
                </Box>
              </Grid>
              <Grid xs={12} item>
                <Box sx={{ ...boxStyle }}>
                  <Typography sx={{ ...headrStyle }}>
                    {exchangeData.exchangeInfo.from == "Gold"
                      ? t("exchange.gold")
                      : t("exchange.silver")}{" "}
                    {t("exchange.price")}
                  </Typography>
                  <Typography sx={{ ...infoStyle }}>
                    {exchangeData.exchangeInfo.from === "Gold"
                      ? metalPrices.goldPrice.formattedAmount
                      : metalPrices.silverPrice.formattedAmount}
                  </Typography>
                </Box>
              </Grid>
              <Grid xs={12} item>
                <Box sx={{ ...boxStyle }}>
                  <Typography sx={{ ...headrStyle }}>
                    {t("exchange.total-price")}
                  </Typography>
                  <Typography sx={{ ...infoStyle }}>
                    {Currency(calculatedInfo.sellFirstPrice)}
                  </Typography>
                </Box>
              </Grid>
              <Grid xs={12} item>
                <Box sx={{ ...boxStyle }}>
                  <Typography sx={{ ...headrStyle }}>
                    {t("exchange.standard-fee-percent")}
                  </Typography>
                  <Typography
                    sx={{
                      ...infoStyle,
                      textDecoration: customerLoyaltyInfo.hasLoyalty
                        ? "line-through"
                        : "initial",
                    }}
                  >
                    {ratiorData.sellFee + " %"}
                  </Typography>
                </Box>
              </Grid>
              {customerLoyaltyInfo.hasLoyalty && (
                <Grid xs={12} item>
                  <Box sx={{ ...boxStyle }}>
                    <Typography sx={{ ...headrStyle }}>
                      {t("exchange.your-fee")}
                    </Typography>
                    <Typography sx={{ ...infoStyle }}>
                      {`${customerLoyaltyInfo.feePercent_formatted} % (${t(
                        "exchange.loyalty-months"
                      )})`}
                    </Typography>
                  </Box>
                </Grid>
              )}
              <Grid xs={12} item>
                <Box sx={{ ...boxStyle }}>
                  <Typography sx={{ ...headrStyle }}>
                    {t("exchange.exchange-fee")}
                  </Typography>
                  <Typography sx={{ ...infoStyle }}>
                    {Currency(calculatedInfo.sellFee)}
                  </Typography>
                </Box>
              </Grid>
              <Grid xs={12} item>
                <Box sx={{ ...boxStyle }}>
                  <Typography sx={{ ...headrStyle }}>
                    {t("exchange.remaining-price")}
                  </Typography>
                  <Typography sx={{ ...infoStyle }}>
                    {Currency(calculatedInfo.sellLastPrice)}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid xs={12} md={1} item></Grid>
          <Grid xs={12} md={6} item>
            <Grid container spacing={8}>
              <Grid xs={12} item>
                <Divider
                  sx={{
                    marginTop: "20px",
                    color: "rgba(0, 0, 0, 0.38)",
                    "&::before": {
                      width: 0,
                    },
                  }}
                  textAlign="left"
                >
                  To
                </Divider>
              </Grid>
              <Grid xs={12} item>
                <Box sx={{ ...boxStyle }}>
                  <Typography sx={{ ...headrStyle }}>
                    {t("exchange.storage-number")}
                  </Typography>
                  <Typography sx={{ ...infoStyle }}>
                    {exchangeData.selectStorage.storageNumber}
                  </Typography>
                </Box>
              </Grid>
              <Grid xs={12} item>
                <Box sx={{ ...boxStyle }}>
                  <Typography sx={{ ...headrStyle }}>
                    {t("exchange.buy-total-money")}
                  </Typography>
                  <Typography sx={{ ...infoStyle }}>
                    {Currency(calculatedInfo.buyFirstPrice)}
                  </Typography>
                </Box>
              </Grid>
              <Grid xs={12} item>
                <Box sx={{ ...boxStyle }}>
                  <Typography sx={{ ...headrStyle }}>
                    {t("exchange.buy-fee")}
                  </Typography>
                  <Typography sx={{ ...infoStyle }}>
                    {Currency(calculatedInfo.buyFee)}
                  </Typography>
                </Box>
              </Grid>
              <Grid xs={12} item>
                <Box sx={{ ...boxStyle }}>
                  <Typography sx={{ ...headrStyle }}>
                    {t("exchange.buy-remaining-money")}
                  </Typography>
                  <Typography sx={{ ...infoStyle }}>
                    {Currency(calculatedInfo.buyLastPrice)}
                  </Typography>
                </Box>
              </Grid>
              <Grid xs={12} item>
                <Box sx={{ ...boxStyle }}>
                  <Typography sx={{ ...headrStyle }}>
                    {exchangeData.exchangeInfo.to == "Gold"
                      ? t("exchange.gold")
                      : t("exchange.silver")}{" "}
                    {t("exchange.price-per-gram")}
                  </Typography>
                  <Typography sx={{ ...infoStyle }}>
                    {exchangeData.exchangeInfo.to === "Gold"
                      ? metalPrices.goldPrice.formattedAmount
                      : metalPrices.silverPrice.formattedAmount}
                  </Typography>
                </Box>
              </Grid>
              <Grid xs={12} item>
                <Box sx={{ ...boxStyle }}>
                  <Typography sx={{ ...headrStyle }}>
                    {t("exchange.you-will-get")}{" "}
                  </Typography>
                  <Typography sx={{ fontWeight: 500 }} variant="h5">
                    {GermanFloat(calculatedInfo.buyLastGrams)}
                    &nbsp; {" " + t("exchange.gram") + " "}
                    {exchangeData.exchangeInfo.to == "Gold"
                      ? t("exchange.gold")
                      : t("exchange.silver")}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid xs={12} item>
            <Box mt="10px">
              <img src={alarm}></img>
              <Typography ml="10px" variant="caption" sx={{ color: "#FF8F00" }}>
                {t("exchange.attention")}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>

      <FormSteperStructure.Footer>
        <Button
          onClick={onSubmit}
          fullWidth
          sx={{ bgcolor: "info.main", maxWidth: "192px" }}
          variant="contained"
        >
          {t("buttons.next")}
        </Button>
      </FormSteperStructure.Footer>
    </Box>
  );
}

export default SummaryStep;
