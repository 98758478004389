import React, { useState, useEffect } from "react";
import { Box, Button } from "@mui/material";
import { FormSteperStructure, LoadingOverlay, Select } from "../common";
import { useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import {
  getFormattedStorageAndSubStoragesAction,
  getStorageBankAccountAction,
} from "../../redux/slices/sell";
import { useSnackbar } from "notistack";
import {
  sellSource,
  setSelectedSource,
  sellGlobalState,
  sellStorageBankAccount,
  resetError,
  resetActionStatus,
} from "../../redux/slices/sell/sell.slice";
import { ERROR_CONTACT_US, METAL_CHARS } from "../../core/constants";
import { getMetalPriceAction } from "../../redux/slices/globals";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useTranslation } from "react-i18next";

function SelectSourceStorageStep({ handleCurrentStep, currentStep }) {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const requiredMsg = t("validation.required");
  const [loading, setLoading] = useState(false);
  const detailStorageId = useParams().storageid;
  const sellSourceData = useSelector(sellSource);
  const sellGlobalData = useSelector(sellGlobalState);
  const storagebankAccount = useSelector(sellStorageBankAccount);

  useEffect(() => {
    setLoading(true);
    dispatch(getMetalPriceAction({ productName: METAL_CHARS.GOLD }));
    dispatch(getMetalPriceAction({ productName: METAL_CHARS.SILVER }));
    dispatch(getMetalPriceAction({ productName: METAL_CHARS.PLATIN }));
    dispatch(getMetalPriceAction({ productName: METAL_CHARS.PALLADIUM }));
    //parichehr : read from the new action used for disable enable
    dispatch(getFormattedStorageAndSubStoragesAction(detailStorageId));
  }, []);

  const submitForm = (data) => {
    dispatch(
      setSelectedSource({
        fromStorageId: data.fromStorageId,
        fromStorage: sellSourceData?.options?.filter(
          (x) => x.key == data.fromStorageId
        )[0].item,
      })
    );
    if (storagebankAccount.bankAccountInfo) handleCurrentStep("next");
    else handleCurrentStep(currentStep + 2);
  };

  useEffect(() => {
    dispatch(getStorageBankAccountAction(detailStorageId));
  }, []);

  useEffect(() => {
    if (sellGlobalData.actionStatus) {
      dispatch(resetActionStatus());
    }
  }, [sellGlobalData.actionStatus]);

  useEffect(() => {
    if (sellGlobalData.error !== null) {
      enqueueSnackbar(sellGlobalData.error + ERROR_CONTACT_US, {
        variant: "error",
        autoHideDuration: 20000,
      });
      dispatch(resetError());
    }
  }, [sellGlobalData.error]);

  useEffect(() => {
    setLoading(sellGlobalData.loading);
  }, [sellGlobalData.loading]);

  const schema = yup.object().shape({
    fromStorageId: yup.string().required(requiredMsg),
  });

  const {
    control,
    handleSubmit,
    setError,
    getValues,
    formState: { errors },
  } = useForm({
    mode: "all",
    defaultValues: {
      fromStorageId: sellSourceData.fromStorageId,
    },
    resolver: yupResolver(schema),
  });

  return (
    <LoadingOverlay show={loading}>
      <Box
        sx={{
          margin: "0 auto",
          minHeight: "calc(100vh - 250px)",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "space-around",
        }}
      >
        <Box sx={{ minWidth: "50%", margin: "0 auto" }}>
          <Select
            control={control}
            error={Boolean(errors.fromStorageId)}
            helperText={errors.fromStorageId?.message}
            name="fromStorageId"
            label={t("sell.select-source")}
            value={sellSourceData.fromStorageId}
            options={sellSourceData.options}
          />
        </Box>
        <FormSteperStructure.Footer>
          <Button
            onClick={handleSubmit(submitForm)}
            fullWidth
            sx={{ backgroundColor: "info.main", maxWidth: "192px" }}
            variant="contained"
          >
            {t("buttons.next")}
          </Button>
        </FormSteperStructure.Footer>
      </Box>
    </LoadingOverlay>
  );
}

export default SelectSourceStorageStep;
