import axios from "axios";
import { BASE_URL } from "../../../core/apiconfig/environment";
import { TOKEN_NAME, WORKSPACE_ID, WORKSPACE_TYPE } from "../../../core/apiconfig/constantApi";
import { WORKSPACETYPE } from "../../../core/constants";

const getInvoiceTotalDataFunc = async (params, { getState }) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `bearer ${localStorage.getItem(TOKEN_NAME)}`,
    },
  };
  const vermittlerId = localStorage.getItem(WORKSPACE_ID);
  const vermittlerType = localStorage.getItem(WORKSPACE_TYPE);
  let result;
  if (vermittlerType === WORKSPACETYPE.CONTACT || vermittlerType === WORKSPACETYPE.UNDER_AGE) {
    result = await axios.get(`${BASE_URL}data/invoices/total/contact/${vermittlerId}/`, config);
  } else {
    result = await axios.get(`${BASE_URL}data/invoices/total/account/${vermittlerId}/`, config);
  }

  if (result?.data?.data?.value?.length > 0)
    return result.data.data.value[0].sum_amount;
  else return 0;
};

export default getInvoiceTotalDataFunc;
