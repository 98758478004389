import React,{useState,useEffect} from 'react'
import {Box,MenuItem,FormControl,Select} from '@mui/material';
import i18next from 'i18next';
import UKIcon from '../../../assets/images/uk.png'
import GermanyIcon from '../../../assets/images/germany.png'
import { useDispatch } from 'react-redux';
import {changeLanguage} from '../../../redux/slices/locale/locale.slice'
const languageList=[
    {
        code:'de',
        name:'Deutsch',
        country_icon: GermanyIcon
    },
    {
        code:'en',
        name:'English',
        country_icon:UKIcon
    },
]

function SelectLanguage({sx}) {

  const getLanguage = () => i18next.language || window.localStorage.i18nextLng
  const [language,setLanguage]=useState(getLanguage)
  const dispatch=useDispatch()

  useEffect(()=>{
    i18next.changeLanguage(language)
    dispatch(changeLanguage(language))
  },[language])
  return (
    <>
       <FormControl fullWidth>
        <Select
         
          labelId="lng"
          id="lng"
          value={language}
          onChange={(e)=>setLanguage(e.target.value)}
          sx={{ 
          '& .MuiSelect-select':{
            p:'8px'
          },...sx }}

        >
         {languageList.map(({code,country_icon,name})=>(
             <MenuItem
             sx={{
               display:'flex',
               alignItems:'center',
               justifyContent:'center'
             }}
             key={code} value={code}>
               <img src={country_icon} />
             </MenuItem>
         ))} 
        </Select>
      </FormControl>
    </>
  )
}

export default SelectLanguage