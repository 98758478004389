import React, { useState, useEffect } from "react";
import DataTable from "../common/grids";
import { BASE_URL } from "../../core/apiconfig/environment";
import { useApi } from "../../core/hooks/useApi";
import { WORKSPACE_ID, WORKSPACE_TYPE } from "../../core/apiconfig/constantApi";
import { WORKSPACETYPE } from "../../core/constants";
import { useTranslation } from "react-i18next";

const BankAccountList = () => {
  const [listData, setListData] = useState();
  const { t } = useTranslation();
  const { get } = useApi();

  const loadData = async (workspaceId) => {
    const workspaceType = localStorage.getItem(WORKSPACE_TYPE);
    let result;
    if (workspaceType === WORKSPACETYPE.CONTACT || workspaceType === WORKSPACETYPE.UNDER_AGE) { // Contact workspace (person)?
      result = await get(`${BASE_URL}data/kontos/contact/${workspaceId}/`);
    } else { // Account workspace (company)?
      result = await get(`${BASE_URL}data/kontos/account/${workspaceId}/`);
    }
    if (result.data && result.data.value) {
      let list = [];
      result.data.value.map((item) => {
        let tmp = item;
        tmp.arades_waehrung_title =
          item["arades_waehrung@OData.Community.Display.V1.FormattedValue"];
        list.push(tmp);
      });

      setListData(list);
    }
  };

  useEffect(() => {
    let workspaceId = localStorage.getItem(WORKSPACE_ID);
    loadData(workspaceId);
  }, []);

  return (
    <div className="container-card">
      <DataTable
        title={t("bank-account.with-iban")}
        entityName={"arades_konto"}
        canAdd={true}
        addPath="add/1"
        canEdit={true}
        data={listData?.filter((x) => x.arades_iban)}
        defaultPageSize="5"
        columns={[
          { name: "arades_kontoinhaber", caption: t("bank-account.owner") },
          { name: "arades_iban", caption: "IBAN " },
          { name: "arades_bic", caption: "BIC" },
          { name: "arades_bank", caption: t("bank-account.bank") },
          {
            name: "arades_waehrung_title",
            caption: t("bank-account.currency"),
          },
        ]}
      ></DataTable>
      <br></br>
      <br></br>
      <DataTable
        title={t("bank-account.without-iban")}
        entityName={"arades_konto"}
        canAdd={true}
        addPath="add/2"
        canEdit={true}
        data={listData?.filter((x) => x.arades_bankleitzahl)}
        defaultPageSize="5"
        columns={[
          { name: "arades_kontoinhaber", caption: t("bank-account.owner") },
          { name: "arades_bankleitzahl", caption: t("bank-account.bank-code") },
          {
            name: "arades_kontonummer",
            caption: t("bank-account.account-number"),
          },
          { name: "arades_bank", caption: t("bank-account.bank") },
          {
            name: "arades_waehrung_title",
            caption: t("bank-account.currency"),
          },
        ]}
      ></DataTable>
    </div>
  );
};
export default BankAccountList;
