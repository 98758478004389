import React, { useEffect, useState } from "react";
import {
  Button,
  Grid,
  TextField,
  Box,
  Divider,
  Typography,
  FormControlLabel,
  Radio,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";

import { useForm } from "react-hook-form";
import moment from "moment";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { postPowerOfAttorneyAction } from "../../../redux/slices/powerOfAttorney";
import {
  resetActionStatus,
  resetError,
  resetAll,
  fullData,
  setValidStatusPowerOfAttorney,
} from "../../../redux/slices/powerOfAttorney/powerOfAttorney.slice";
import { URL, ERROR_CONTACT_US } from "../../../core/constants";
import {
  ConfirmModal,
  LoadingOverlay,
  FormSteperStructure,
  MUIRadioGroup,
  Datepicker,
} from "../../common";
import blueCircleIcon from "../../../assets/images/blueCircle.svg";
import { GermanFloat } from "../../../core/utils/convert";
import { useTranslation } from "react-i18next";

function SummaryStep({ handleCurrentStep }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const { t } = useTranslation();

  const [confirmOpen, setConfirmOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const fields = useSelector(fullData);

  const requiredMsg = "validation.required";

  const schema = yup.object().shape({
    validForever: yup.boolean().required(requiredMsg),
    expirationDate: yup.date().when("validForever", {
      is: false,
      then: yup
        .date()
        .min(new Date(), "Bitte wählen Sie ein Datum in der Zukunft aus") // "Please choose future date")
        .typeError(requiredMsg)
        .nullable()
        .required(requiredMsg),
      otherwise: yup.date().nullable(),
    }),
  });

  const {
    control,
    handleSubmit,
    setError,
    getValues,
    watch,
    formState: { errors },
  } = useForm({
    mode: "all",
    defaultValues: {
      ...fields.summaryStep,
    },
    resolver: yupResolver(schema),
  });

  const watchValidForever = watch("validForever");
  useEffect(() => {
    if (
      fields.globalData.actionStatus &&
      fields.globalData.actionType === "postPowerOfAttorney"
    ) {
      enqueueSnackbar("Successful", {
        variant: "success",
        autoHideDuration: 3000,
      });
      setConfirmOpen(true);
      dispatch(resetActionStatus());
      setLoading(false);
    }
  }, [fields.globalData.actionStatus]);

  useEffect(() => {
    if (fields.globalData.error != null) {
      enqueueSnackbar(fields.globalData.error + ERROR_CONTACT_US, {
        variant: "error",
        autoHideDuration: 20000,
      });
      setLoading(false);
      dispatch(resetError());
    }
  }, [fields.globalData.error]);

  const onSubmit = (data) => {
    dispatch(setValidStatusPowerOfAttorney(data));
    dispatch(postPowerOfAttorneyAction());
    setLoading(true);
  };

  const boxStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  };
  const headrStyle = {
    color: "rgba(0, 0, 0, 0.38)",
    fontSize: 14,
  };
  const infoStyle = {
    color: "rgba(0, 0, 0, 0.87);",
    fontSize: 16,
  };
  const tomorrowDate = () => {
    const today = new Date();
    let tomorrow = new Date();
    tomorrow.setDate(today.getDate() + 1);
    return tomorrow;
  };
  return (
    <LoadingOverlay show={loading}>
      <ConfirmModal
        open={confirmOpen}
        imageSrc={blueCircleIcon}
        title="Sign contract"
        showCloseButton={false}
        message={
          <Box sx={{ margin: "0 auto" }}>
            We send the contract to your email address Ratior@Gmail.com, please
            sign that by using the link inside the email with Adobe sign.
          </Box>
        }
        okTitle="GOT IT"
        onOk={() => {
          dispatch(resetAll());
          navigate(URL.POWER_OF_ATTORNEY_BASE);
        }}
      ></ConfirmModal>
      <Box>
        <Grid container spacing={8}>
          <Grid xs={12} item>
            <Typography variant="body1">
              You are granting a power of attorney for{" "}
            </Typography>
            <Divider
              sx={{
                marginTop: "20px",
                color: "rgba(0, 0, 0, 0.38)",
                "&::before": {
                  width: 0,
                },
              }}
              textAlign="left"
            >
              For
            </Divider>
          </Grid>
          <Grid xs={12} item>
            <Box sx={{ ...boxStyle }}>
              <Typography sx={{ ...headrStyle }}>Storage numbers</Typography>
              {fields.chooseFinalStorageAndSubStoragesStep.mainStorage && (
                <Typography sx={{ ...infoStyle }}>
                  {fields.globalData.storageList.map((item) => {
                    if (item.key === fields.chooseStorageStep.storage) {
                      return item.label;
                    }
                    return false;
                  })}
                </Typography>
              )}
              {fields.chooseFinalStorageAndSubStoragesStep.subStorages.map(
                (item) => (
                  <Typography key={item} sx={{ ...infoStyle }}>
                    {fields.globalData.subStorageList.map((substorage) => {
                      if (substorage.key === item) {
                        return substorage.label;
                      }
                      return false;
                    })}
                  </Typography>
                )
              )}
            </Box>
          </Grid>

          <Grid xs={12} item>
            <Divider
              sx={{
                marginTop: "20px",
                color: "rgba(0, 0, 0, 0.38)",
                "&::before": {
                  width: 0,
                },
              }}
              textAlign="left"
            >
              To
            </Divider>
          </Grid>
          <Grid xs={12} item>
            <Box sx={{ ...boxStyle }}>
              <Typography sx={{ ...headrStyle }}>Name</Typography>
              <Typography sx={{ ...infoStyle }}>
                {fields.choosePersonStep.ownerInfo?.firstname}{" "}
                {fields.choosePersonStep.ownerInfo?.lastname}
              </Typography>
            </Box>
            <Box mt="20px" sx={{ ...boxStyle }}>
              <Typography sx={{ ...headrStyle }}>Ratior key</Typography>
              <Typography sx={{ ...infoStyle }}>
                {fields.choosePersonStep.ownerKey}
              </Typography>
            </Box>
          </Grid>
          <Grid xs={12} item>
            <Box mt="32px">
              <Typography variant="body1">
                And it will be valid untill
              </Typography>
              <Box>
                <MUIRadioGroup
                  sx={{ width: 1 }}
                  error={Boolean(errors.validForever)}
                  helperText={errors.validForever?.message}
                  control={control}
                  name="validForever"
                >
                  <Box>
                    <FormControlLabel
                      sx={{ whiteSpace: "nowrap" }}
                      value={true}
                      control={<Radio />}
                      label="Forever"
                    />

                    <Grid xs={12} container>
                      <Grid item xs={12} lg={2}>
                        <FormControlLabel
                          sx={{ whiteSpace: "nowrap" }}
                          value={false}
                          control={<Radio />}
                          label="Pick a date"
                        />
                      </Grid>
                      <Grid item xs={12} lg={4}>
                        {watchValidForever === false ||
                          (watchValidForever === "false" && (
                            <Datepicker
                              // disablePast
                              //minDate={tomorrowDate()}
                              label="Expiration Date"
                              control={control}
                              name="expirationDate"
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  error={Boolean(errors.expirationDate)}
                                  helperText={t(errors.expirationDate?.message)}
                                  onBlur={() => {
                                    if (getValues("expirationDate") === "") {
                                      setError("expirationDate", {
                                        type: "manual",
                                        message: requiredMsg,
                                      });
                                    }
                                  }}
                                />
                              )}
                            />
                          ))}
                      </Grid>
                    </Grid>
                  </Box>
                </MUIRadioGroup>
              </Box>
            </Box>
          </Grid>
        </Grid>
        <FormSteperStructure.Footer>
          <Button
            onClick={handleSubmit(onSubmit)}
            fullWidth
            sx={{ backgroundColor: "info.main", maxWidth: "192px" }}
            variant="contained"
          >
            Weiter
            {/* Next */}
          </Button>
        </FormSteperStructure.Footer>
      </Box>
    </LoadingOverlay>
  );
}

export default SummaryStep;
