import axios from "axios";
import { BASE_URL } from "../../../core/apiconfig/environment";
import { getStorageAmountFunc } from "../";
import { TOKEN_NAME } from "../../../core/apiconfig/constantApi";

const getStorageByIdFunc = async (id, { getState }) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `bearer ${localStorage.getItem(TOKEN_NAME)}`,
    },
  };

  let fetchQuery = `${BASE_URL}data/warehouses/${id}/`;

  const storageResult = await axios.get(fetchQuery, config);
  if (storageResult?.data?.status != 200 || !storageResult?.data?.data)
    throw Error("Lagerplatz konnte nicht gefunden werden"); //("Source storage not found");
  let storage = storageResult.data.data;

  let amountResult = await getStorageAmountFunc(id, { getState });

  storage.arades_verfuegbare_menge = amountResult.arades_verfuegbare_menge;

  return storage;
};

export default getStorageByIdFunc;
