import React, { useEffect, useState } from "react";
import { Box, FormControl, FormLabel, Button, Typography } from "@mui/material";
import { RadioListIcon, FormSteperStructure } from "../../common";
import { useDispatch, useSelector } from "react-redux";
import { FEE_PAYER_TYPE, METAL_CHARS } from "../../../core/constants";
import { GermanFloat } from "../../../core/utils/convert";
import {
  transferSelectWhoPaysFeeFields,
  setSelectWhoPaysFee,
  transferFullState,
  transferFromStorage,
  transferFee,
} from "../../../redux/slices/transfer/transfer.slice";
import { globalsRatiorData } from "../../../redux/slices/globals/globals.slice";
import { useTranslation } from "react-i18next";
import { MetalPart } from "../../../core/utils/business";

function SelectWhoPays({ handleCurrentStep, currentStep }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const ratiorData = useSelector(globalsRatiorData);
  const transferFullData = useSelector(transferFullState);
  const fromStorage = useSelector(transferFromStorage);
  const transferFeeData = useSelector(transferFee);
  const feeUnit = t("transfer.gram-ab");

  const [radioList, setRadioList] = useState([
    {
      label: t("transfer.me"),
      value: FEE_PAYER_TYPE.SELLER_PAYS,
    },
    {
      label: t("transfer.receptor"),
      value: FEE_PAYER_TYPE.BUYER_PAYS,
    },
    {
      label: t("transfer.half-half"),
      value: FEE_PAYER_TYPE.HALF_HALF,
    },
  ]);
  const { whoPays: who } = useSelector(transferSelectWhoPaysFeeFields);
  const [whoPays, setWhoPays] = useState(who);

  const zeroFee =
    fromStorage?.msdyn_name?.substring(0, 8) ==
      transferFullData?.selectStorage?.subStorageNumber?.substring(0, 8) ||
    (fromStorage?.msdyn_name !=
      transferFullData?.selectStorage?.storageNumber &&
      fromStorage?.msdyn_name?.substring(0, 8) ==
        transferFullData?.selectStorage?.storageNumber?.substring(0, 8));

  const submitForm = () => {
    dispatch(setSelectWhoPaysFee(whoPays));
    handleCurrentStep("next");
  };

  return (
    <>
      <Box
        sx={{
          margin: "0 auto",
          minHeight: "calc(100vh - 250px)",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "space-around",
        }}
      >
        <Box sx={{ width: { xs: "100%", md: "50%" } }}>
          {!zeroFee && (
            <>
              {" "}
              <Typography
                sx={{
                  color: "rgba(0, 0, 0, 0.87)",
                  fontSize: 14,
                  textDecoration: transferFullData.globalData.actionCodeData
                    .actionCodeId
                    ? "line-through"
                    : "initial",
                }}
              >
                {`${t("transfer.your-fee")} ${ratiorData.transferFee}% ${t(
                  "transfer.as-fee"
                )}`}
              </Typography>
              {transferFullData.globalData.actionCodeData.actionCodeId && (
                <Typography
                  sx={{
                    color: "rgba(0, 0, 0, 0.87)",
                    fontSize: 14,
                  }}
                >
                  {`${t("transfer.your-fee-only")}  ${
                    transferFullData.globalData.actionCodeData.arades_prozent
                  }% ${t("transfer.as-fee")}`}
                </Typography>
              )}
            </>
          )}

          <FormControl sx={{ minWidth: "100%" }}>
            <FormLabel sx={{ fontSize: "14px", mb: "20px" }} id="radio-group">
              {t("transfer.select-who")}
            </FormLabel>
            <RadioListIcon
              currentValue={whoPays}
              handleSetCurrentValue={(value) => {
                setWhoPays(value);
              }}
              data={radioList}
            />
          </FormControl>
        </Box>

        <FormSteperStructure.Footer>
          <Button
            onClick={submitForm}
            fullWidth
            sx={{ backgroundColor: "info.main", maxWidth: "192px" }}
            variant="contained"
          >
            {t("buttons.next")}
          </Button>
        </FormSteperStructure.Footer>
      </Box>
    </>
  );
}

export default SelectWhoPays;
