import { createSlice } from "@reduxjs/toolkit";
import { DEPOSIT_DIRECTION } from "../../../core/constants";
import { Currency } from "../../../core/utils/convert";

import { getCustomerDepositListAction } from ".";

const initialState = {
  globalData: {
    actionStatus: false,
    actionType: "",
    loading: false,
    error: null,
  },
  customerDeposits: [],
};
export const vermittlerListsSlice = createSlice({
  name: "vermittlerLists",
  initialState,
  reducers: {
    resetError: (state) => {
      state.globalData.error = null;
    },
    resetActionStatus: (state) => {
      state.globalData.actionStatus = false;
      state.globalData.actionType = null;
    },
    resetAll: () => {
      return initialState;
    },
  },
  extraReducers: {
    //customer deposits
    [getCustomerDepositListAction.pending]: (state, action) => {
      state.globalData.loading = true;
    },
    [getCustomerDepositListAction.fulfilled]: (state, action) => {
      state.globalData.loading = false;
      state.globalData.actionStatus = true;
      state.customerDeposits = action.payload;
      state.globalData.actionType = "getCustomerDeposits";
    },
    [getCustomerDepositListAction.rejected]: (state, action) => {
      state.globalData.loading = false;
      state.globalData.error = action.error.message;
    },
  },
});

export const fullData = (state) => state.vermittlerLists;
export const globalData = (state) => state.vermittlerLists.globalData;

export const customerDeposits = (state) =>
  state.vermittlerLists.customerDeposits;

export const { resetError, resetActionStatus, resetAll } =
  vermittlerListsSlice.actions;
export default vermittlerListsSlice.reducer;
