import React, { useState, useEffect } from "react";
import { DataTable, LoadingOverlay } from "../common";
import { Box, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  myStructureList,
  globalData,
  resetError,
  resetAll,
  resetActionStatus,
} from "../../redux/slices/myStructure/myStructure.slice";
import { getMyStructureAction } from "../../redux/slices/myStructure";
import { useSnackbar } from "notistack";
import { WORKSPACE_ID } from "../../core/apiconfig/constantApi";
import { CELL_RENDER_TYPE, ERROR_CONTACT_US } from "../../core/constants";
import { useTranslation } from "react-i18next";
import ShowAddressModal from "./showAddressModal";

const MyStructureList = () => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [addressModalOpen, setAddressModalOpen] = useState(false);
  const [addressModalFields, setAddressModalFields] = useState(false);

  const myStructureListData = useSelector(myStructureList);
  const myStructureGlobalData = useSelector(globalData);

  useEffect(() => {
    dispatch(resetAll());
    dispatch(
      getMyStructureAction({
        workspaceId: localStorage.getItem(WORKSPACE_ID),
      })
    );
  }, []);

  useEffect(() => {
    if (
      myStructureGlobalData.actionStatus &&
      myStructureGlobalData.actionType === "getMyStructureList"
    ) {
      dispatch(resetActionStatus());
    }
  }, [myStructureGlobalData.actionStatus]);

  useEffect(() => {
    setLoading(myStructureGlobalData.loading);
  }, [myStructureGlobalData.loading]);

  useEffect(() => {
    if (myStructureGlobalData.error != null) {
      enqueueSnackbar(myStructureGlobalData.error + ERROR_CONTACT_US, {
        variant: "error",
        autoHideDuration: 20000,
      });
      dispatch(resetError());
    }
  }, [myStructureGlobalData.error]);

  const showAddress = (rowData, col) => {
    let fields = {
      arades_firmenname_partner: rowData.arades_firmenname_partner,
      telephone3: rowData.telephone3,
      arades_mobil_partner: rowData.arades_mobil_partner,
      emailaddress3: rowData.emailaddress3,
      arades_address3_line1: rowData.arades_address3_line1,
      arades_address3_line2: rowData.arades_address3_line2,
      arades_address3_postalcode: rowData.arades_address3_postalcode,
      arades_address3_city: rowData.arades_address3_city,
      arades_land_partner: rowData.arades_land_partner,
    };
    if (col.names[0] == "varades_firmenname_partner")
      fields = {
        arades_firmenname_partner: rowData.varades_firmenname_partner,
        telephone3: rowData.vtelephone3,
        arades_mobil_partner: rowData.varades_mobil_partner,
        emailaddress3: rowData.vemailaddress3,
        arades_address3_line1: rowData.varades_address3_line1,
        arades_address3_line2: rowData.varades_address3_line2,
        arades_address3_postalcode: rowData.varades_address3_postalcode,
        arades_address3_city: rowData.varades_address3_city,
        arades_land_partner: rowData.varades_land_partner,
      };
    setAddressModalFields(fields);
    setAddressModalOpen(true);
  };
  return (
    <LoadingOverlay show={loading}>
      <ShowAddressModal
        open={addressModalOpen}
        onClose={() => setAddressModalOpen(false)}
        initVal={addressModalFields}
      ></ShowAddressModal>
      <div className="container-card">
        <Typography variant="h5" sx={{ fontWeight: 500 }}>
          {t("my-structure.title")}
        </Typography>

        <Box sx={{ width: "100%", typography: "body1" }}>
          <DataTable
            entityName={"account"}
            data={myStructureListData}
            showPageSizeSelector={true}
            columns={[
              {
                names: ["arades_firmenname_partner", "primaryContact"],
                caption: t("my-structure.name"),
                cellRenderType: CELL_RENDER_TYPE.LINK,
                onClick: showAddress,
                minWidth: "220",
              },
              {
                name: "level",
                caption: t("my-structure.level"),
              },
              {
                names: ["varades_firmenname_partner", "vprimaryContact"],
                caption: t("my-structure.seller-name"),
                cellRenderType: CELL_RENDER_TYPE.LINK,
                onClick: showAddress,
                minWidth: "220",
              },
              {
                name: "sum_arades_betrag",
                caption: t("my-structure.commission-amount"),
                alignment: "right",
              },
              {
                name: "createdon_formatted",
                caption: t("my-structure.created-on"),
              },
            ]}
          ></DataTable>
        </Box>
      </div>
    </LoadingOverlay>
  );
};
export default MyStructureList;
