import axios from "axios";
import { BASE_URL } from "../../../core/apiconfig/environment";
import { TOKEN_NAME } from "../../../core/apiconfig/constantApi";
import { WORKFLOW_IDS } from "../../../core/constants";

const changeContactInfoWorkflowFunc = async (contactId, { getState }) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `bearer ${localStorage.getItem(TOKEN_NAME)}`,
    },
  };

  const result = await axios.post(`${BASE_URL}data/workflows/${WORKFLOW_IDS.CONTACT_CHANGE_INFO}/`, { EntityId: contactId }, config);

  if (
    !result?.data?.status ||
    (result.data.status != 204 && result.data.status != 200)
  )
    throw Error("Ändern von Kontaktinformationen fehlgeschlagen"); //("Change contact info workflow failed ");

  return result?.data;
};

export default changeContactInfoWorkflowFunc;
