import { createSlice } from "@reduxjs/toolkit";
import { resetRatiorKeyAction } from ".";

const initialState = {
  globalData: {
    actionStatus: false,
    actionType: "",
    loading: false,
    error: null,
  },
};
export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    resetError: (state) => {
      state.globalData.error = null;
    },
    resetActionStatus: (state) => {
      state.globalData.actionStatus = false;
      state.globalData.actionType = null;
    },
    resetAll: () => {
      return initialState;
    },
  },
  extraReducers: {
    [resetRatiorKeyAction.pending]: (state, action) => {
      state.globalData.loading = true;
    },
    [resetRatiorKeyAction.fulfilled]: (state, action) => {
      state.globalData.loading = false;
      state.globalData.actionStatus = true;
      state.globalData.actionType = "resetRatiorKey";
    },
    [resetRatiorKeyAction.rejected]: (state, action) => {
      state.globalData.loading = false;
      state.globalData.error = action.error.message;
    },
  },
});

// if you had some redusers that had not need to send request and they added at reducers object use below line to export action creators
export const { resetError, resetActionStatus, resetAll } = authSlice.actions;

export const fullData = (state) => state.auth;
export const globalData = (state) => state.auth.globalData;

export default authSlice.reducer;
