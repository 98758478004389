import React, { useEffect, useState } from "react";
import { Box, Button, Grid, Divider, Typography } from "@mui/material";
import { FormSteperStructure, DiscountAmount } from "../../common";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { FEE_PAYER_TYPE } from "../../../core/constants";
import {
  transferFromStorage,
  transferFullState,
  transferFee,
} from "../../../redux/slices/transfer/transfer.slice";
import {
  //getOwnerByStorageIdAction,
  verificationSendSMSCodeAction,
} from "../../../redux/slices/transfer";
import { GermanFloat } from "../../../core/utils/convert";
import { globalsRatiorData } from "../../../redux/slices/globals/globals.slice";
import { WORKSPACE_NAME } from "../../../core/apiconfig/constantApi";

function SummaryStep({ handleCurrentStep }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const ratiorData = useSelector(globalsRatiorData);
  const transferFullData = useSelector(transferFullState);
  const fromStorage = useSelector(transferFromStorage);
  const transferFeeData = useSelector(transferFee);
  const feeUnit = t("transfer.gram");
  const senderFee =
    transferFullData.selectWhoPaysFee.whoPays == FEE_PAYER_TYPE.SELLER_PAYS
      ? transferFeeData.discountedFee
      : transferFullData.selectWhoPaysFee.whoPays == FEE_PAYER_TYPE.BUYER_PAYS
      ? 0
      : transferFeeData.discountedFee / 2;
  const receptorFee =
    transferFullData.selectWhoPaysFee.whoPays == FEE_PAYER_TYPE.SELLER_PAYS
      ? 0
      : transferFullData.selectWhoPaysFee.whoPays == FEE_PAYER_TYPE.BUYER_PAYS
      ? transferFeeData.discountedFee
      : transferFeeData.discountedFee / 2;
  // useEffect(()=>{
  //     dispatch(getOwnerByStorageIdAction(transferFullData.selectRecipient.storage.msdyn_name))
  // },[])

  const sendSMS = (to = null) => {
    let phoneNumber;
    if (to) {
      phoneNumber = to;
    } else {
      phoneNumber = localStorage.getItem("mobileNumber");
    }
    dispatch(
      verificationSendSMSCodeAction({
        mobileNumber: phoneNumber,
      })
    );
  };

  const onSubmit = (data) => {
    sendSMS();
    handleCurrentStep("next");
  };

  const boxStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  };
  const headrStyle = {
    color: "rgba(0, 0, 0, 0.38)",
    fontSize: 14,
  };
  const infoStyle = {
    color: "rgba(0, 0, 0, 0.87);",
    fontSize: 16,
  };

  return (
    <Box
      sx={{
        maxWidth: "816px",
        margin: "0 auto",
        marginTop: "32px",
      }}
    >
      <Box>
        <Grid container spacing={8}>
          <Grid xs={12} item>
            <Divider
              sx={{
                marginTop: "20px",
                color: "rgba(0, 0, 0, 0.38)",
                "&::before": {
                  width: 0,
                },
              }}
              textAlign="left"
            >
              {t("transfer.from")}
            </Divider>
          </Grid>
          <Grid xs={12} item>
            <Box sx={{ ...boxStyle }}>
              <Typography sx={{ ...headrStyle }}>
                {" "}
                {t("transfer.storage-number")}
              </Typography>
              <Typography sx={{ ...infoStyle }}>
                {fromStorage.msdyn_name}
              </Typography>
            </Box>
          </Grid>
          <Grid xs={12} item>
            <Box sx={{ ...boxStyle }}>
              <Typography sx={{ ...headrStyle }}>
                {" "}
                {t("transfer.storage-owner")}
              </Typography>
              <Typography sx={{ ...infoStyle }}>
                {localStorage.getItem(WORKSPACE_NAME)}
              </Typography>
            </Box>
          </Grid>
          <Grid xs={12} item>
            <Divider
              sx={{
                marginTop: "20px",
                color: "rgba(0, 0, 0, 0.38)",
                "&::before": {
                  width: 0,
                },
              }}
              textAlign="left"
            >
              {t("transfer.to")}
            </Divider>
          </Grid>
          <Grid xs={12} item>
            <Box sx={{ ...boxStyle }}>
              <Typography sx={{ ...headrStyle }}>
                {" "}
                {t("transfer.storage-number")}
              </Typography>
              <Typography sx={{ ...infoStyle }}>
                {transferFullData.selectRecipient.storage.msdyn_name}
              </Typography>
            </Box>
          </Grid>
          <Grid xs={12} item>
            <Box sx={{ ...boxStyle }}>
              <Typography sx={{ ...headrStyle }}>
                {" "}
                {t("transfer.storage-owner")}
              </Typography>
              <Typography sx={{ ...infoStyle }}>
                {transferFullData.selectRecipient.contact &&
                  transferFullData.selectRecipient.contact.firstname +
                    " " +
                    transferFullData.selectRecipient.contact.lastname}
              </Typography>
            </Box>
          </Grid>
          <Grid sx={{ mt: "30px" }} xs={12} item>
            <Box sx={{ ...boxStyle }}>
              <Typography sx={{ ...headrStyle }}>
                {" "}
                {t("transfer.amount")}{" "}
              </Typography>
              <Typography sx={{ ...infoStyle }}>
                {GermanFloat(transferFullData.enterAmount.amountPerGram * 1)}{" "}
                &nbsp; {feeUnit}
              </Typography>
            </Box>
          </Grid>
          <Grid xs={12} item>
            <Box sx={{ ...boxStyle }}>
              <Typography sx={{ ...headrStyle }}>
                {t("transfer.transfer-fee")}{" "}
              </Typography>
              <Typography sx={{ ...infoStyle }}>
                <DiscountAmount
                  price={GermanFloat(transferFeeData.fee) + " " + feeUnit}
                  discount={
                    GermanFloat(transferFeeData.discountedFee) + " " + feeUnit
                  }
                  discountPercentage={transferFeeData.discountPercentage}
                />{" "}
                {transferFullData.selectWhoPaysFee.whoPays ==
                FEE_PAYER_TYPE.SELLER_PAYS
                  ? t("transfer.you-pay-fee")
                  : transferFullData.selectWhoPaysFee.whoPays ==
                    FEE_PAYER_TYPE.BUYER_PAYS
                  ? t("transfer.receptor-fee")
                  : t("transfer.half")}
              </Typography>
            </Box>
          </Grid>
          <Grid xs={12} item>
            <Box sx={{ ...boxStyle }}>
              <Typography sx={{ ...headrStyle }}>
                {t("transfer.sender-payable-fee")}
              </Typography>
              <Typography sx={{ ...infoStyle }}>
                {GermanFloat(senderFee)}
                &nbsp;
                {feeUnit}
              </Typography>
            </Box>
            <Box sx={{ ...boxStyle }}>
              <Typography sx={{ ...headrStyle }}>
                {t("transfer.withdrawn-amount")}{" "}
              </Typography>
              <Typography sx={{ ...infoStyle }}>
                {GermanFloat(
                  transferFullData.enterAmount.amountPerGram * 1 + senderFee
                )}
                &nbsp;
                {feeUnit}
              </Typography>
            </Box>
          </Grid>

          <Grid xs={12} item>
            <Box sx={{ ...boxStyle }}>
              <Typography sx={{ ...headrStyle }}>
                {t("transfer.receptor-payable-fee")}
              </Typography>
              <Typography sx={{ ...infoStyle }}>
                {GermanFloat(receptorFee)}
                &nbsp;
                {feeUnit}
              </Typography>
            </Box>
            <Box sx={{ ...boxStyle }}>
              <Typography sx={{ ...headrStyle }}>
                {t("transfer.receptor-gets")}
              </Typography>
              <Typography sx={{ ...infoStyle }}>
                {GermanFloat(
                  transferFullData.enterAmount.amountPerGram * 1 - receptorFee
                )}
                &nbsp;
                {feeUnit}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>

      <FormSteperStructure.Footer>
        <Button
          onClick={onSubmit}
          fullWidth
          sx={{ bgcolor: "info.main", maxWidth: "192px" }}
          variant="contained"
        >
          {t("buttons.next")}
        </Button>
      </FormSteperStructure.Footer>
    </Box>
  );
}

export default SummaryStep;
