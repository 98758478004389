import getPersonalDataFunc from "../contact/getPersonalData.func";
import getCompanyDataFunc from "../company/getCompanyData.func";
import getLoyaltyDataFunc from "./getLoyaltyData.func";
import getLevelsDataFunc from "./getLevelsData.func";

const getCustomerLoyaltyDataFunc = async (params, { getState }) => {
  let customerLoyaltyInfo = {};
  if (params.isContact) {
    const contact = await getPersonalDataFunc({ id: params.customerId }, { getState });
    customerLoyaltyInfo = {
      loyaltyMonthes: contact.arades_treue_monate,
      loyaltyMonthId: contact._arades_treuebonus_value,
      investedAmount: contact.arades_uberweisungsbetrag,
      ratiorRating: contact.arades_aktionscodesumme,
      sumAmount: contact.arades_transaktionssumme,
      feeLevel:
        contact[
          "_arades_gebhrenstaffelung_value@OData.Community.Display.V1.FormattedValue"
        ] ?? "",
    };
  } //if customer is company
  else {
    const account = await getCompanyDataFunc(
      { id: params.customerId, full: true }, // Request full data to also get e.g. treuebonus
      { getState }
    );
    customerLoyaltyInfo = {
      loyaltyMonthes: account.arades_treue_monate ?? 0,
      loyaltyMonthId: account._arades_treuebonus_value,
      investedAmount: account.arades_uberweisungsbetrag ?? 0,
      ratiorRating: account.arades_aktionscodesumme,
      sumAmount: account.arades_transaktionssumme,
      feeLevel:
        account[
          "_arades_gebhrenstaffelung_value@OData.Community.Display.V1.FormattedValue"
        ] ?? "",
    };
  }

  let levelsData = {
    typeIsLevels: false,
  };

  if (
    customerLoyaltyInfo.feeLevel &&
    customerLoyaltyInfo.feeLevel != "Mitarbeiter" &&
    customerLoyaltyInfo.feeLevel != "Vermittler"
  ) {
    levelsData = await getLevelsDataFunc(
      {
        sumAmount: customerLoyaltyInfo.sumAmount,
      },
      { getState }
    );
    levelsData.typeIsLevels = true;
  }

  let loyalty = {
    name: "",
    toMonth: 23, //parichehr: dont hardcode , get it from first loyaty month
    fromMonth: 1,
    feePercent: 0,
    feePercent_formatted: "0",
  };
  if (customerLoyaltyInfo.loyaltyMonthId)
    loyalty = await getLoyaltyDataFunc(
      { id: customerLoyaltyInfo.loyaltyMonthId },
      { getState }
    );

  return {
    hasLoyalty: customerLoyaltyInfo.loyaltyMonthId,
    loyaltyMonthes: customerLoyaltyInfo.loyaltyMonthes,
    loyaltyMonthId: customerLoyaltyInfo.loyaltyMonthId,
    loyaltyName: loyalty.name,
    monthesToNextLevel:
      loyalty.toMonth - customerLoyaltyInfo.loyaltyMonthes + 1,
    loyaltyProgressPercent:
      ((customerLoyaltyInfo.loyaltyMonthes - loyalty.fromMonth + 1) /
        (loyalty.toMonth - loyalty.fromMonth + 1)) *
      100,
    feePercent: loyalty.feePercent,
    feePercent_formatted: loyalty.feePercent_formatted,
    investedAmount: customerLoyaltyInfo.investedAmount,
    ratiorRating: customerLoyaltyInfo.ratiorRating,
    sumAmount: customerLoyaltyInfo.sumAmount,
    feeLevel: customerLoyaltyInfo.feeLevel,
    typeIsLevels: levelsData.typeIsLevels,
    feeAmountToNextLevel: levelsData.remaintToNextLevel,
    feeAmountToNextLevel_formatted: levelsData.remaintToNextLevel_formatted,
    nextLevel: levelsData.nextLevel,
    currentLevelIsLast: levelsData.currentLevelIsLast,
  };
};

export default getCustomerLoyaltyDataFunc;
