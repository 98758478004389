
import styled from '@emotion/styled';
import React from 'react';

const SteperContainer=styled.div`
width:${({width})=>width || 'auto'};
height:${({height})=>height || 'auto'};
background-color: rgba(0, 0, 0, 0.08);
color:rgba(0, 0, 0, 0.38);
border-radius: 30px;
display: flex;
`
const SteperItem=styled.div`
padding:0 5px;
flex-grow: 1;
display: flex;
align-items: center;
justify-content: center;
font-size: 16px;
&.activeStep{
    background-color: #64B5F6;
    color:#fff;
}
&.activeStep:first-of-type{
    border-radius: 30px 0 0 30px;
}
&.activeStep:last-of-type{
    border-radius: 0 30px 30px 0;
}
&.activeStep.right-rounded{
    border-radius: 0 30px 30px 0;
}
&.activeStep.single{
    border-radius: 30px;
}
`
function Steper({data, currentStep=1,width,height}) {
  return <SteperContainer width={width} height={height}>
      {data.map((item,index)=>(
        <SteperItem key={item.id}  className={`${index+1<=currentStep ? 'activeStep':''} ${currentStep===index+1?'right-rounded':''} ${currentStep===1?'single':''}`}>{item?.icon}</SteperItem>
      ))}
  </SteperContainer>
}

export default Steper;
