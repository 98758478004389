import React, { useState } from 'react';
import './ChooseMainStorageDialog.scss';
import {
  Dialog,
  DialogContent,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
  Typography,
  Box,
  Paper,
  FormGroup,
} from '@mui/material';
import CloseIcon from "@mui/icons-material/Close";
import { MetalCell } from '../../../common';
import { useTranslation } from 'react-i18next';
import { localeFormat } from '../../../../core/utils/convert';

const ChooseMainStorageDialog = ({ open, onClose, options }) => { // open -> boolean to show or hide dialog, onClose -> function to close dialog (parameter is a metal option or undefined when cancelled), options -> array of metal options
  const [selectedOption, setSelectedOption] = useState(undefined);
  const { t } = useTranslation();

  const handleChange = (event) => {
    setSelectedOption(event.target.value);
  };

  return (
    <Dialog open={open} onClose={() => onClose(undefined)} maxWidth="sm" fullWidth classes={{ container: "choose-main-storage-dialog" }}>
      {/* Make the content scrollable, while keeping padding for visual separation */}
      <DialogContent sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
        <Box sx={{ flexGrow: 1, overflowY: 'auto' }}>
          <Typography variant="h4" mt={4}>{t("storage-list.create-sub-storage.title")}</Typography>
          <Typography variant="subtitle2" gutterBottom mb={2}>{t("storage-list.create-sub-storage.subtitle")}</Typography>
          <RadioGroup value={selectedOption} onChange={handleChange}>
            {options.filter(o => !o.arades_uebergeordneter_lagerplatz).map((option) => ( // Filter to only show main warehouses
              <Paper
                key={option.msdyn_warehouseid}
                variant="outlined"
                style={{ padding: '8px', marginBottom: '8px', borderRadius: '8px' }}
              >
                <FormGroup>
                  <FormControlLabel
                    value={option.msdyn_warehouseid}
                    control={<Radio />}
                    label={
                      <Box sx={{ display: "flex", alignItems: "center", width: "100%" }}>
                        <MetalCell metal={option.metalName} hideName></MetalCell>
                        <Box flexGrow={1}>
                          <Typography>{option.msdyn_name}</Typography>
                        </Box>
                        <Box textAlign="right" minWidth="100px">
                          <Typography variant="body2">
                            {option.isPending ? t("storage-list.pending") : (localeFormat(option.arades_menge, t("general.locale-for-number-formatting"), { minimumFractionDigits: 4 }) + " g")}
                          </Typography>
                        </Box>
                      </Box>
                    }
                  />
                </FormGroup>
              </Paper>
            ))}
          </RadioGroup>
        </Box>
        {/* Fixed action button box at the bottom so that the buttons are always visible, even when the list so large that it scrolls */}
        <Box mt={2} sx={{ position: 'sticky', bottom: 0, backgroundColor: 'white', paddingTop: '16px', paddingBottom: '16px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Button variant="text" color="primary" onClick={() => onClose(undefined)}>
            <CloseIcon />
            {t("buttons.cancel")}
          </Button>
          <Button variant="contained" color="primary" onClick={() => onClose(selectedOption)} sx={{ bgcolor: "info.main", paddingLeft: "32px", paddingRight: "32px" }}>
          {t("buttons.next")}
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default ChooseMainStorageDialog;
