import React, { useEffect, useState } from "react";
import { WORKSPACE_ID } from "../../../core/apiconfig/constantApi";
import {
  Typography,
  Box,
  Paper,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  TableHead,
  IconButton,
} from "@mui/material";
import MdModeDelete from "@mui/icons-material/DeleteOutline";
import Mdlink from "@mui/icons-material/Link";
import { useSnackbar } from "notistack";
import { ConfirmModal, InputSupportCopy } from "../../common";
import {
  ACTION_CODE_TYPE,
  ERROR_CONTACT_US,
} from "../../../core/constants";
import { useDispatch, useSelector } from "react-redux";
import { BASE_URL_UI } from "../../../core/apiconfig/environment";
import {
  actionCodeList,
  globalData,
  resetError,
  resetActionStatus,
} from "../../../redux/slices/vermittlerDashboard/vermittlerDashboard.slice";
import {
  getActionCodeListAction,
  deactivateActionCodeAction,
} from "../../../redux/slices/vermittlerDashboard";
import { useTranslation } from "react-i18next";

const ActionCodeBox = ({ handleCurrentStep }) => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const dashboardGlobalData = useSelector(globalData);
  const actionCodeListFields = useSelector(actionCodeList);

  const [deactivateModalOpen, setDeactivateModalOpen] = useState(false);
  const [linkModalOpen, setLinkModalOpen] = useState(false);
  const [selectedActionCode, setSelectedActionCode] = useState();

  useEffect(() => {
    dispatch(
      getActionCodeListAction({ ownerId: localStorage.getItem(WORKSPACE_ID) })
    );
  }, []);

  useEffect(() => {
    if (
      dashboardGlobalData.actionStatus &&
      dashboardGlobalData.actionType == "deactivateActionCode"
    ) {
      enqueueSnackbar(t("messages.success"), {
        variant: "success",
        autoHideDuration: 3000,
      });
      dispatch(resetActionStatus());
      dispatch(
        getActionCodeListAction({ ownerId: localStorage.getItem(WORKSPACE_ID) })
      );
    }
  }, [dashboardGlobalData.actionStatus]);

  useEffect(() => {
    if (dashboardGlobalData.error != null) {
      enqueueSnackbar(dashboardGlobalData.error + ERROR_CONTACT_US, {
        variant: "error",
        autoHideDuration: 20000,
      });
      dispatch(resetError());
    }
  }, [dashboardGlobalData.error]);

  useEffect(() => {
    if (
      dashboardGlobalData.actionStatus &&
      dashboardGlobalData.actionType == "getSellerActionCodes"
    ) {
      dispatch(resetActionStatus());
    }
  }, [dashboardGlobalData.actionStatus]);

  const affiliateLink = (actionCode) => {
    return `${BASE_URL_UI}registration/personalinfo?ac=${actionCode}`;
  };

  return (
    <>
      <ConfirmModal
        open={deactivateModalOpen}
        contentType="warning"
        noImage={true}
        title={t("action-code.deactivate-title")}
        showCloseButton={false}
        message={<div>{t("action-code.deactivate-message")}</div>}
        acceptTitle={t("action-code.deactivate")}
        onAccept={() => {
          setDeactivateModalOpen(false);
          dispatch(deactivateActionCodeAction(selectedActionCode.id));
        }}
        rejectTitle={t("buttons.cancel")}
        onReject={() => {
          setDeactivateModalOpen(false);
        }}
      ></ConfirmModal>
      <ConfirmModal
        open={linkModalOpen}
        title={t("action-code.share-title")}
        showCloseButton={false}
        noImage={true}
        size="lg"
        message={
          <Box mt="8px" sx={{ width: "100%" }}>
            <InputSupportCopy
              disabled
              sx={{ bgcolor: "rgba(0, 0, 0, 0.04)", width: "100%" }}
              value={affiliateLink(selectedActionCode?.actionCode)}
            />
          </Box>
        }
        acceptTitle={t("action-code.copy-link")}
        onAccept={() => {
          setLinkModalOpen(false);
          navigator.clipboard.writeText(
            affiliateLink(selectedActionCode?.actionCode)
          );
        }}
        rejectTitle={t("buttons.close")}
        onReject={() => {
          setLinkModalOpen(false);
        }}
      ></ConfirmModal>

      {actionCodeListFields.length === 0 && (
        <Paper sx={{ p: "16px", height: 1 }} variant="outlined">
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              mb: "10px",
            }}
          >
            <Typography variant="subtitle1" component="h6">
              {t("action-code.title")}
            </Typography>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "calc(100% - 28px)",
              }}
            >
              <Typography
                onClick={() => handleCurrentStep("next")}
                className="dashboard-link"
                variant="subtitle2"
              >
                {`+ ${t("action-code.generate")}`}
              </Typography>
            </Box>
          </Box>
        </Paper>
      )}
      {actionCodeListFields.length > 0 && (
        <>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              mb: "10px",
            }}
          >
            <Typography variant="subtitle1" component="h6">
              {t("action-code.title")}
            </Typography>

            <Typography
              onClick={() => handleCurrentStep("next")}
              className="dashboard-link"
              variant="subtitle2"
            >
              {`+ ${t("action-code.generate")}`}
            </Typography>
          </Box>
          <Box mt={"20px"} sx={{ height: 320 }}>
            <TableContainer sx={{ height: 320 }}>
              <Table sx={{ width: "100%" }} aria-label="simple table" stickyHeader>
                <TableHead sx={{ backgroundColor: "rgba(33, 33, 33, 0.08)" }}>
                  <TableRow>
                    <TableCell>{t("action-code.code")}</TableCell>
                    <TableCell>{t("action-code.type")}</TableCell>
                    <TableCell>{t("action-code.status")}</TableCell>
                    <TableCell>{t("action-code.exp-date")}</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {actionCodeListFields.map((item, index) => (
                    <TableRow
                      key={index}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell
                        align="left"
                        className="no-wrap"
                        sx={{
                          fontWeight: 400,
                          paddingLeft: "15px",
                        }}
                      >
                        {item.actionCode}
                      </TableCell>
                      <TableCell
                        align="left"
                        className="no-wrap"
                        sx={{
                          fontWeight: 400,
                          padding: "5px",
                        }}
                      >
                        <Typography variant="caption">
                          {item.arades_typ == ACTION_CODE_TYPE.STORAGE_FEE
                            ? (item.discount ? item.discount : 0) +
                              " " +
                              t("action-code.fix-fee")
                            : item.arades_typ == ACTION_CODE_TYPE.BECOME_SELLER
                            ? t("action-code.become-seller")
                            : item.arades_typ == ACTION_CODE_TYPE.LOYALTY
                            ? (item.loyalityMonthCount
                                ? item.loyalityMonthCount
                                : 0) +
                              " " +
                              t("action-code.loyalty-months")
                            : ""}
                        </Typography>
                      </TableCell>
                      <TableCell
                        align="left"
                        className="no-wrap"
                        sx={{
                          fontWeight: 400,
                          padding: "5px",
                        }}
                      >
                        <Typography variant="caption">
                          {item.registeredCount ?? 0} /
                          {item.usersUnLimited
                            ? t("action-code.no-limit")
                            : item.allowRegisterCount}
                        </Typography>
                      </TableCell>
                      <TableCell
                        className="no-wrap"
                        align="left"
                        sx={{
                          fontWeight: 400,
                          padding: "5px",
                        }}
                      >
                        <Typography variant="caption">
                          {item.validForever
                            ? t("action-code.no-limit")
                            : item.endDate}
                        </Typography>
                      </TableCell>
                      <TableCell sx={{ display: "flex" }}>
                        <IconButton
                          aria-label="affiliate link"
                          component="span"
                          onClick={() => {
                            setLinkModalOpen(true);
                            setSelectedActionCode(item);
                          }}
                        >
                          <Mdlink />
                        </IconButton>
                        <IconButton
                          aria-label="deactivate"
                          component="span"
                          onClick={() => {
                            setDeactivateModalOpen(true);
                            setSelectedActionCode(item);
                          }}
                        >
                          <MdModeDelete />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </>
      )}
    </>
  );
};

export default ActionCodeBox;
