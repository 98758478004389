import { createSlice } from "@reduxjs/toolkit";
import { getMyStructureAction } from "./";

const initialState = {
  globalData: {
    actionStatus: false,
    actionType: "",
    loading: false,
    error: null,
  },
  myStructure: [],
};
export const myStructureSlice = createSlice({
  name: "myStructure",
  initialState,
  reducers: {
    resetError: (state) => {
      state.globalData.error = null;
    },
    resetActionStatus: (state) => {
      state.globalData.actionStatus = false;
      state.globalData.actionType = null;
    },
    resetAll: () => {
      return initialState;
    },
  },
  extraReducers: {
    //get my structure Action
    [getMyStructureAction.pending]: (state, action) => {
      state.globalData.loading = true;
    },
    [getMyStructureAction.fulfilled]: (state, action) => {
      state.globalData.loading = false;
      state.globalData.actionStatus = true;
      state.myStructure = action.payload;
      state.globalData.actionType = "getMyStructureList";
    },
    [getMyStructureAction.rejected]: (state, action) => {
      state.globalData.loading = false;
      state.globalData.error = action.error.message;
    },
  },
});

export const fullData = (state) => state.myStructure;
export const globalData = (state) => state.myStructure.globalData;

export const myStructureList = (state) => state.myStructure.myStructure;

export const { resetError, resetActionStatus, resetAll } =
  myStructureSlice.actions;
export default myStructureSlice.reducer;
