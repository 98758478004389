import React, { useState, useEffect } from "react";
import { ConfirmModal, LoadingOverlay, Verification } from "../common";
import { useDispatch, useSelector } from "react-redux";
import {
  verificationCheckSMSCodeAction,
  verificationSendSMSCodeAction,
} from "../../redux/slices/sell";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import {
  verificationSteps,
  sellGlobalState,
  sellSource,
  resetError,
  resetActionStatus,
  resetAll,
} from "../../redux/slices/sell/sell.slice";
import { postSellAction } from "../../redux/slices/sell";

import { useTranslation } from "react-i18next";
import { URL, ERROR_CONTACT_US } from "../../core/constants";
import { Box } from "@mui/material";
import tickIcon from "../../assets/images/tick.svg";

function VerificationOtherPersonStep({ handleCurrentStep }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const sellGlobalData = useSelector(sellGlobalState);
  const sellSourceData = useSelector(sellSource);
  const verificationStepsData = useSelector(verificationSteps);

  const [successStatusModal, setSuccessStatusModal] = useState(false);
  const [activationCode, setActivationCode] = useState();
  const [loading, setLoading] = useState(false);

  const onSubmit = () => {
    if (!verificationStepsData.verification) {
      setLoading(true);
      dispatch(
        verificationCheckSMSCodeAction({
          activationCode: activationCode,
          mobileNumber: verificationStepsData.partnerPhoneNumber,
        })
      );
    }
  };

  useEffect(() => {
    if (
      sellGlobalData.actionStatus &&
      sellGlobalData.actionType === "verification"
    ) {
      enqueueSnackbar(t("messages.verification-successful"), {
        variant: "success",
        autoHideDuration: 3000,
      });
      dispatch(resetActionStatus());
      dispatch(postSellAction());
    }

    if (
      sellGlobalData.actionStatus &&
      sellGlobalData.actionType === "postSell"
    ) {
      enqueueSnackbar("Sell successful", {
        variant: "success",
        autoHideDuration: 3000,
      });
      setLoading(false);
      setSuccessStatusModal(true);
    }
  }, [sellGlobalData.actionStatus]);

  useEffect(() => {
    if (sellGlobalData.error != null) {
      enqueueSnackbar(sellGlobalData.error + ERROR_CONTACT_US, {
        variant: "error",
        autoHideDuration: 20000,
      });
      dispatch(resetError());
      setLoading(false);
    }
  }, [sellGlobalData.error]);

  const sendSMS = (to) => {
    dispatch(
      verificationSendSMSCodeAction({
        mobileNumber: to,
      })
    );
  };

  const resendCode = () => {
    sendSMS(verificationStepsData.partnerPhoneNumber);
  };

  return (
    <>
      <ConfirmModal
        imageSrc={tickIcon}
        open={successStatusModal}
        title="Your request has been sent"
        showCloseButton={false}
        message={
          <Box sx={{ margin: "0 auto" }}>
            We got your request for selling metals. After reviewing your order
            we will sell your metals and send the money to your bank account.
            Also, You will get notified by an E-mail when we’re done.
          </Box>
        }
        okTitle="GO TO STORAGE"
        onOk={() => {
          dispatch(resetAll());
          navigate(
              URL.STORAGE +
              "/" +
              sellSourceData.fromStorage.msdyn_warehouseid
          );
        }}
      ></ConfirmModal>

      <LoadingOverlay show={loading}>
        <Verification
          number={verificationStepsData.partnerPhoneNumber}
          activationCode={activationCode}
          setActivationCode={setActivationCode}
          handleNext={onSubmit}
          resendCode={resendCode}
          verified={verificationStepsData.verification}
        />
      </LoadingOverlay>
    </>
  );
}

export default VerificationOtherPersonStep;
