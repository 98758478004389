import { createSlice } from "@reduxjs/toolkit";

import {
  verificationCheckSMSCodeAction,
  verificationSendSMSCodeAction,
  changeEmailAddressAction,
  changePhoneNumberAction,
  changeContactInfoWorkflowAction,
} from ".";

const initialState = {
  globalData: {
    loading: false,
    actionStatus: false,
    actionType: null,
    error: null,
  },

  verificationData: {
    verification: false,
    sentSMS: false,
  },
  phoneNumberStep: {
    phoneNumber: "",
  },
};

export const ProfileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    setPhoneNumber: (state, action) => {
      state.phoneNumberStep.phoneNumber = action.payload;
    },
    resetSentSMS: (state) => {
      state.verificationData.sentSMS = false;
    },
    resetVerification: (state) => {
      state.verificationData.verification = false;
    },
    resetAll: () => initialState,
    resetActionStatus: (state) => {
      state.globalData.actionStatus = false;
      state.globalData.actionType = false;
    },
    resetError: (state) => {
      state.globalData.error = null;
    },
  },
  extraReducers: {
    //Verifications send
    [verificationSendSMSCodeAction.pending]: (state, action) => {
      state.globalData.loading = true;
    },
    [verificationSendSMSCodeAction.fulfilled]: (state, action) => {
      state.globalData.loading = false;
      state.verificationData.sentSMS = true;
    },
    [verificationSendSMSCodeAction.rejected]: (state, action) => {
      state.globalData.loading = false;
      state.globalData.error = action.error.message;
      state.verificationData.sentSMS = false;
    }, // Verifications Check
    [verificationCheckSMSCodeAction.pending]: (state, action) => {
      state.globalData.loading = true;
    },
    [verificationCheckSMSCodeAction.fulfilled]: (state, action) => {
      state.globalData.loading = false;
      const { data } = action.payload;

      if (data.status === 200 || data.status === 201) {
        state.globalData.actionStatus = true;
        state.verificationData.verification = true;
        state.globalData.actionType = "verification";
      } else if (Object.keys(data?.data?.error).length > 0) {
        state.globalData.actionStatus = false;
        state.verificationData.verification = false;
        state.globalData.actionType = null;
        state.globalData.error = data.data.error.message;
      }
    },
    [verificationCheckSMSCodeAction.rejected]: (state, action) => {
      state.globalData.loading = false;
      state.verificationData.verification = false;
      state.globalData.error = action.error.message;
      state.globalData.actionStatus = false;
      state.globalData.actionType = null;
    },
    //change Email Address Action
    [changeEmailAddressAction.pending]: (state, action) => {
      state.globalData.loading = true;
    },
    [changeEmailAddressAction.fulfilled]: (state, action) => {
      state.globalData.loading = false;
      state.globalData.actionStatus = true;
      state.globalData.actionType = "changeEmail";
    },
    [changeEmailAddressAction.rejected]: (state, action) => {
      state.globalData.loading = false;
      state.globalData.error = action.error.message;
      state.globalData.actionStatus = false;
      state.globalData.actionType = null;
    },
    //change phone number action
    [changePhoneNumberAction.pending]: (state, action) => {
      state.globalData.loading = true;
    },
    [changePhoneNumberAction.fulfilled]: (state, action) => {
      state.globalData.loading = false;
      state.globalData.actionStatus = true;
      state.globalData.actionType = "changePhoneNumber";
    },
    [changePhoneNumberAction.rejected]: (state, action) => {
      state.globalData.loading = false;
      state.globalData.error = action.error.message;
      state.globalData.actionStatus = false;
      state.globalData.actionType = null;
    },

    //change info workflow action
    [changeContactInfoWorkflowAction.pending]: (state, action) => {
      state.globalData.loading = true;
    },
    [changeContactInfoWorkflowAction.fulfilled]: (state, action) => {
      state.globalData.loading = false;
      state.globalData.actionStatus = true;
      state.globalData.actionType = "changeContactInfoWorkflow";
    },
    [changeContactInfoWorkflowAction.rejected]: (state, action) => {
      state.globalData.loading = false;
      state.globalData.error = action.error.message;
      state.globalData.actionStatus = false;
      state.globalData.actionType = null;
    },
  },
});

export const fullState = (state) => state.profile;
export const globalData = (state) => state.profile.globalData;
export const verificationData = (state) => state.profile.verificationData;
export const phoneNumberStep = (state) => state.profile.phoneNumberStep;

export const {
  setPhoneNumber,
  resetVerification,
  resetActionStatus,
  resetSentSMS,
  resetError,
  resetAll,
} = ProfileSlice.actions;
export default ProfileSlice.reducer;
