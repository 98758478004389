import { createAsyncThunk } from "@reduxjs/toolkit";
import { verificationCheckSMSCodeFunc } from "../../sharedFunctions";


const verificationCheckSMSCodeAction = createAsyncThunk(
  "storage/verification/CheckSMS",
  verificationCheckSMSCodeFunc
);

export default verificationCheckSMSCodeAction;
