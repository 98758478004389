import React from "react";
import "./button.scss";
const Button = ({
  children,
  primary,
  disabled,
  secondary,
  className,
  type,
  ...rest
}) => {
  return (
    <div className="d-flex">
      <button
        {...rest}
        disabled={disabled}
        className={`button ${
          disabled ? "disabled" : secondary ? "secondary" : "primary"
        } ${className}`}
        type={type}
      >
        {children}
      </button>
    </div>
  );
};
export default Button;
