import React from "react";
import {Controller } from 'react-hook-form';
import {FormControl, Slider,FormHelperText,Typography,Box} from '@mui/material';
 
const SliderInput = ({
  control=null,
  label,
  error,
  required,
  helperText,
  component,
  onChange,
  onBlur,
  value,
  name,
  inputRef,
  sx,
  children,
  maxLabel,
  minLabel,
  ...props
}) => {
  return (
    <>
    <Controller
      control={control}
      name={name}
      render={({
          field: {onChange:formOnChange, onBlur:formOnBlur, value:formValue, name:formName, ref:fromRef },
          fieldState: { invalid, isTouched, isDirty, error:formError },
          formState,
      }) => (


        <>
      <FormControl
        required={required}
        error={Boolean(formError)}
        component={component}
        sx={{width:'100%',...sx}}
      >     
      <Box sx={{display:'flex',alignItems:'center'}}>
            {minLabel && (
                <Typography pr="12px" variant='caption'>{minLabel}</Typography>
            )}
            <Slider
            valueLabelDisplay="auto"
            aria-labelledby="non-linear-slider"
            sx={{color:'info.main'}}
            onChange={(e)=>{
                formOnChange(e)
                if(onChange) onChange(e)
            }}
            onBlur={formOnBlur}
            value={formValue}
            name={formName}
            ref={fromRef}
            {...props} 
            />
            {maxLabel && (
                <Typography pl="12px" variant='caption'>{maxLabel}</Typography>
            )}
       
      </Box>
           
            {helperText && (
            <FormHelperText sx={{mx:0}}>{helperText}</FormHelperText>
            )}
        </FormControl>
        </>

  
      )} />

    </>
  );
};

export default SliderInput;
