import { createSlice } from "@reduxjs/toolkit";
import {
  STORAGE_PARTNER_SECURITY,
  STORAGE_UNDER_AGE_SECURITY,
  METAL_CHARS,
} from "../../../core/constants";
import {
  getStorageListAction,
  getSubStorageListAction,
  getStorageAmountAction,
  verificationCheckSMSCodeAction,
  verificationSendSMSCodeAction,
  postExchangeAction,
  getPartnerDataAction,
  getFormattedStorageAndSubStoragesAction,
  getStorageListWithSubstoragesAction,
} from ".";
import { MetalPart } from "../../../core/utils/business";

const initialState = {
  globalData: {
    actionStatus: false,
    actionType: "",
    loading: false,
    error: null,
    storages: [],
    subStorages: [],
    storagesListWithSubstorages: [],
  },
  exchangeSource: {
    fromStorageId: "",
    fromStorage: {},
    options: [],
  },
  verificationSteps: {
    needOtherPersonVerification: false,
    verification: false,
    sentSMS: false,
    partnerPhoneNumber: null,
  },
  selectStorageType: {
    storageType: "mainStorage",
    canSelectStorage: false,
    canSelectSubstorage: false,
  },
  exchangeInfo: {
    fee: 0.01,
    from: "",
    to: "",
  },
  selectStorage: {
    storageId: "",
    storageNumber: "",
    subStorageId: "",
    subStorageNumber: "",
  },
  enterAmount: {
    amount: "",
    amountPerGram: "",
    maxAmountPerGram: "",
  },
};
export const exchangeSlice = createSlice({
  name: "exchange",
  initialState,
  reducers: {
    setSelectedSource: (state, action) => {
      const { fromStorage, fromStorageId } = action.payload;
      state.exchangeSource.fromStorage = fromStorage;
      state.exchangeSource.fromStorageId = fromStorageId;

      if (
        String(fromStorage.arades_vertretungsberechtigung) ===
          STORAGE_PARTNER_SECURITY.BOTH ||
        String(fromStorage.arades_sicherheit) ===
          STORAGE_UNDER_AGE_SECURITY.BOTH_LEGAL_GUARDIAN
      )
        state.verificationSteps.needOtherPersonVerification = true;
      else state.verificationSteps.needOtherPersonVerification = false;

      if (MetalPart(fromStorage.msdyn_name) == METAL_CHARS.GOLD) {
        state.exchangeInfo.from = "Gold";
        state.exchangeInfo.to = "Silver";
      } else if (MetalPart(fromStorage.msdyn_name) == METAL_CHARS.SILVER) {
        state.exchangeInfo.from = "Silver";
        state.exchangeInfo.to = "Gold";
      }
    },
    setActionType: (state, action) => {
      state.globalData.actionType = action.payload;
    },
    setSelectStorageType: (state, action) => {
      state.selectStorageType.storageType = action.payload.storageType;
      state.selectStorageType.canSelectStorage =
        action.payload.canSelectStorage;
      state.selectStorageType.canSelectSubstorage =
        action.payload.canSelectSubstorage;
    },
    setEnterAmount: (state, action) => {
      state.enterAmount.amount = action.payload.amount;
      state.enterAmount.amountPerGram = action.payload.amountPerGram;
    },
    selectStorage: {
      storageId: "",
      storageNumber: "",
      subStorageId: "",
      subStorageNumber: "",
    },
    setSelectStorage: (state, action) => {
      const { storageId, storageNumber, subStorageId, subStorageNumber } =
        action.payload;

      state.selectStorage.storageId = storageId;
      state.selectStorage.storageNumber = storageNumber;
      state.selectStorage.subStorageId = subStorageId;
      state.selectStorage.subStorageNumber = subStorageNumber;
    },
    resetSentSMS: (state) => {
      state.verificationSteps.sentSMS = false;
    },
    resetVerification: (state) => {
      state.verificationSteps.verification = false;
    },
    resetError: (state) => {
      state.globalData.error = null;
    },
    resetActionStatus: (state) => {
      state.globalData.actionStatus = false;
      state.globalData.actionType = null;
    },
    resetAll: () => {
      return initialState;
    },
  },
  extraReducers: {
    // get Storage List With Substorages Action
    [getStorageListWithSubstoragesAction.pending]: (state, action) => {
      state.globalData.loading = true;
    },
    [getStorageListWithSubstoragesAction.fulfilled]: (state, action) => {
      state.globalData.loading = false;
      state.globalData.storagesListWithSubstorages = action.payload;
      // state.globalData.actionType = "getStorageListwithSubstorages";
      // state.globalData.actionStatus = true;
    },
    [getStorageListWithSubstoragesAction.rejected]: (state, action) => {
      state.globalData.loading = false;
      state.globalData.error = action.error.message;
    },

    //Storage max amount
    [getStorageAmountAction.pending]: (state, action) => {
      state.globalData.loading = true;
    },
    [getStorageAmountAction.fulfilled]: (state, action) => {
      state.globalData.loading = false;
      state.enterAmount.maxAmountPerGram =
        action.payload.arades_verfuegbare_menge;
      state.globalData.actionType = "getStorageAmount";
      state.globalData.actionStatus = true;
    },
    [getStorageAmountAction.rejected]: (state, action) => {
      state.globalData.loading = false;
      state.globalData.error = action.error.message;
      state.globalData.actionStatus = false;
    },

    //Storages
    [getStorageListAction.pending]: (state, action) => {
      state.globalData.loading = true;
    },
    [getStorageListAction.fulfilled]: (state, action) => {
      state.globalData.loading = false;
      const allStorages = action.payload;
      let filteredStorages = [];
      if (!allStorages || allStorages.length == 0) return;
      filteredStorages = allStorages.filter(
        (x) =>
          (MetalPart(state.exchangeSource.fromStorage.msdyn_name) ==
          METAL_CHARS.GOLD
            ? MetalPart(x.msdyn_name) == METAL_CHARS.SILVER
            : MetalPart(state.exchangeSource.fromStorage.msdyn_name) ==
              METAL_CHARS.SILVER
            ? MetalPart(x.msdyn_name) == METAL_CHARS.GOLD
            : false) && !x.pending
      );
      let list = [];
      filteredStorages.map((item) => {
        list.push({
          key: item.msdyn_warehouseid,
          value: item.msdyn_name,
        });
      });
      state.globalData.storages = list;
    },
    [getStorageListAction.rejected]: (state, action) => {
      state.globalData.loading = false;
      state.globalData.error = action.error.message;
    },

    //subStorages
    [getSubStorageListAction.pending]: (state, action) => {
      state.globalData.loading = true;
    },
    [getSubStorageListAction.fulfilled]: (state, action) => {
      state.globalData.loading = false;

      const allSubStorages = action.payload;
      if (!allSubStorages || allSubStorages.length == 0) {
        state.globalData.subStorages = [];
        return;
      }
      const filteredSubStorages = allSubStorages.filter((x) => !x.pending);

      let list = [];
      filteredSubStorages.map((storage) => {
        list.push({
          key: storage.msdyn_warehouseid,
          value:
            storage.msdyn_name +
            " (" +
            (storage.msdyn_description
              ? storage.msdyn_description
              : "No description") +
            ")",
        });
      });

      state.globalData.subStorages = list;
    },
    [getSubStorageListAction.rejected]: (state, action) => {
      state.globalData.loading = false;
      state.globalData.error = action.error.message;
    },

    //Verifications send
    [verificationSendSMSCodeAction.pending]: (state, action) => {
      state.globalData.loading = true;
    },
    [verificationSendSMSCodeAction.fulfilled]: (state, action) => {
      state.globalData.loading = false;
      state.verificationSteps.sentSMS = true;
    },
    [verificationSendSMSCodeAction.rejected]: (state, action) => {
      state.globalData.loading = false;
      state.globalData.error = action.error.message;
      state.verificationSteps.sentSMS = false;
    }, // Verifications Check
    [verificationCheckSMSCodeAction.pending]: (state, action) => {
      state.globalData.loading = true;
    },
    [verificationCheckSMSCodeAction.fulfilled]: (state, action) => {
      state.globalData.loading = false;
      const { data } = action.payload;

      if (data.status === 200 || data.status === 201) {
        state.globalData.actionStatus = true;
        state.verificationSteps.verification = true;
        state.globalData.actionType = "verification";
      } else if (Object.keys(data?.data?.error).length > 0) {
        state.globalData.actionStatus = false;
        state.verificationSteps.verification = false;
        state.globalData.actionType = null;
        state.globalData.error = data.data.error.message;
      }
    },
    [verificationCheckSMSCodeAction.rejected]: (state, action) => {
      state.globalData.loading = false;
      state.verificationSteps.verification = false;
      state.globalData.error = action.error.message;
      state.globalData.actionStatus = false;
      state.globalData.actionType = null;
    },

    //Post exchange
    [postExchangeAction.pending]: (state, action) => {
      state.globalData.loading = true;
    },
    [postExchangeAction.fulfilled]: (state, action) => {
      state.globalData.loading = false;
      state.globalData.actionStatus = true;
      state.globalData.savedExchange = true;
      state.globalData.actionType = "postExchange";
    },
    [postExchangeAction.rejected]: (state, action) => {
      state.globalData.loading = false;
      state.globalData.error = action.error.message;
      state.globalData.actionStatus = false;
      state.globalData.savedExchange = false;
      state.globalData.actionType = null;
    }, // get other person phone onlyNumber
    [getPartnerDataAction.pending]: (state, action) => {
      state.globalData.loading = true;
    },
    [getPartnerDataAction.fulfilled]: (state, action) => {
      state.globalData.loading = false;
      state.verificationSteps.partnerPhoneNumber = action.payload.mobilephone;
    },
    [getPartnerDataAction.rejected]: (state, action) => {
      state.globalData.loading = false;
      state.globalData.error = action.error.message;
      state.verificationSteps.partnerPhoneNumber = null;
    },
    // get Source Storage And SubStorages Action
    [getFormattedStorageAndSubStoragesAction.pending]: (state, action) => {
      state.globalData.loading = true;
    },
    [getFormattedStorageAndSubStoragesAction.fulfilled]: (state, action) => {
      state.globalData.loading = false;
      state.exchangeSource.options = action.payload;
    },
    [getFormattedStorageAndSubStoragesAction.rejected]: (state, action) => {
      state.globalData.loading = false;
      state.globalData.error = action.error.message;
    },
  },
});

// if you had some redusers that had not need to send request and they added at reducers object use below line to export action creators
export const {
  setSelectedSource,
  setActionType,
  setEnterAmount,
  setSelectStorage,
  setSelectStorageType,
  resetSentSMS,
  resetVerification,
  resetError,
  resetActionStatus,
  resetAll,
} = exchangeSlice.actions;

export const exchangeFullData = (state) => state.exchange;
export const selectStorageTypeFields = (state) =>
  state.exchange.selectStorageType;
export const globalData = (state) => state.exchange.globalData;
export const subStoragesFields = (state) =>
  state.exchange.globalData.subStorages;
export const storagesFields = (state) => state.exchange.globalData.storages;
export const selectStorageFields = (state) => state.exchange.selectStorage;
export const enterAmountFields = (state) => state.exchange.enterAmount;
export const exchangeInfo = (state) => state.exchange.exchangeInfo;
export const exchangeFromStorage = (state) =>
  state.exchange.exchangeSource.fromStorage;
export const verificationSteps = (state) => state.exchange.verificationSteps;
export const exchangeSource = (state) => state.exchange.exchangeSource;
export const storagesListWithSubstorages = (state) =>
  state.exchange.globalData.storagesListWithSubstorages;

export default exchangeSlice.reducer;
