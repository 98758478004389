import React,{useState,useEffect} from "react";
import {Controller,useForm } from 'react-hook-form';
import {Checkbox as MUICheckbox,FormControl,Box, FormControlLabel,FormHelperText} from '@mui/material';

const MUICheckBox = ({
  control=null,
  error,
  required,
  helperText,
  component,
  onChange,
  onBlur,
  value,
  name,
  options,
  inputRef,
  sx,
  defaultValues=[],
  ...props
}) => {


  const [checkedValues, setCheckedValues] = useState(defaultValues);

  useEffect(()=>{
    setCheckedValues(defaultValues)
  },[defaultValues])

  const handleSelect=(checkedName)=> {

    const newNames = checkedValues?.includes(checkedName)
      ? checkedValues?.filter(name => name !== checkedName)
      : [...(checkedValues ?? []), checkedName];
    setCheckedValues(newNames);
    return newNames;
  }

  return (
    <>
    {

    options.map(({key,label}) =>
      <Controller
      key={key}
      control={control}
      name={name}
      render={({
          field: {onChange:formOnChange, onBlur:formOnBlur, value:formValue, name:formName, ref:fromRef },
          fieldState: { invalid, isTouched, isDirty, error:formError },
          formState,
      }) => (
        <Box>

        <FormControl
        required={required}
        error={Boolean(formError)}
        component={component}
        sx={sx}
      >
        <FormControlLabel 
          control={
          <MUICheckbox 
            onChange={(e) => {
              formOnChange(handleSelect(key))
            }}
            onBlur={formOnBlur}
            checked={Boolean(checkedValues.includes(key))}
            name={key}
            sx={{
              '&.Mui-checked': {
                color: 'info.main',
              },
            }}
            inputRef={fromRef}
            {...props} 
          />
          } 
          label={label} 
        />
       
      </FormControl>
      </Box>
      )} />
      )
      
    }
      <Box>
         {helperText && (
        <FormHelperText sx={{mx:0}}>{helperText}</FormHelperText>
      )} 
      </Box>    
    </>
  );
};

export default MUICheckBox;
