import React, { useState } from "react";
import { TableRow, TableCell, Typography } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperclip } from "@fortawesome/free-solid-svg-icons";
//import { useTranslation } from "react-i18next";
import i18next from "i18next";

const InboxRow = (rowInfo) => {
  //const { t } = useTranslation();
  const currentLanguage = i18next.language || window.localStorage.i18nextLng;

  return (
    <React.Fragment>
      {rowInfo && (
        <TableRow
          className={!rowInfo.data.viewed ? "inbox-viewed" : ""}
          sx={{
            position: "relative",
            cursor: "pointer",
            "&:hover": {
              bgcolor: "#f5f5f5",
              transition: ".3s all",
            },
            "& td": {
              verticalAlign: "middle !important",
            },
            "&.inbox-viewed>td:first-of-type::before": {
              content: "''",
              bgcolor: "rgba(21, 101, 192, 1)",
              borderRadius: "0px 10px 10px 0px",
              position: "absolute",
              left: 0,
              top: "50%",
              transform: "translateY(-50%)",
              width: "6px",
              height: "80%",
            },
            "&.inbox-viewed>td:first-of-type": {
              fontWeight: "bold",
            },
          }}
        >
          <TableCell>{rowInfo.data.from} </TableCell>
          <TableCell>
            {rowInfo.data.labels.map((item, index) => (
              <Typography
                key={item.type + index}
                variant="caption"
                component="h6"
                sx={{
                  bgcolor:
                    item.type === "NOREPLY"
                      ? "rgba(33, 33, 33, 0.08)"
                      : item.type === "IMPORTANT"
                      ? "#FFEBEE"
                      : "",
                  color: item.type === "IMPORTANT" ? "#B71C1C" : "#000",
                  display: "inline-block",
                  padding: "4px 8px",
                  borderRadius: "5px",
                  marginRight: "16px",
                  fontWeight: "bold",
                  lineHeight: "16px",
                }}
              >
                {item.type == "IMPORTANT"
                  ? currentLanguage == "de"
                    ? "WICHTIG"
                    : "IMPORTANT" //t("inbox.important")
                  : item.type == "NOREPLY"
                  ? currentLanguage == "de"
                    ? "No Reply"
                    : "No Reply" //t("inbox.no-reply")
                  : ""}
              </Typography>
            ))}

            {rowInfo.data.subject}
          </TableCell>
          <TableCell>
            <Typography variant="caption">{rowInfo.data.regardedTo}</Typography>
          </TableCell>
          <TableCell>
            <Typography variant="caption">
              {" "}
              {rowInfo.data.receivedTime} . {rowInfo.data.receivedDate}{" "}
            </Typography>
          </TableCell>
          <TableCell>
            {rowInfo.data.attachedFiles.length > 0 && (
              <FontAwesomeIcon color="rgba(0, 0, 0, 0.38)" icon={faPaperclip} />
            )}
          </TableCell>
        </TableRow>
      )}
    </React.Fragment>
  );
};
export default InboxRow;
