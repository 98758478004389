import axios from "axios";
import { BASE_URL } from "../../../core/apiconfig/environment";
import { dateString } from "../../../core/utils/convert";
import { storageIsPending, storageStatus } from "../../../core/utils/business";
import { WORKSPACETYPE } from "../../../core/constants";
import { TOKEN_NAME } from "../../../core/apiconfig/constantApi";

const getStorageListWithSubstoragesFunc = async (filter, { getState }) => {
  const { workspaceId, workspaceType } = filter;

  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `bearer ${localStorage.getItem(TOKEN_NAME)}`,
    },
  };

  let result;
  if (workspaceType === WORKSPACETYPE.CONTACT) {
    result = await axios.get(`${BASE_URL}data/warehouses/contact/${workspaceId}/`, config);
  } else if (workspaceType === WORKSPACETYPE.UNDER_AGE) {
    result = await axios.get(`${BASE_URL}data/warehouses/contact/${workspaceId}/child/`, config);
  } else {
    result = await axios.get(`${BASE_URL}data/warehouses/account/${workspaceId}/`, config);
  }

  if (result?.data?.status != 200)
    throw Error("Lager konnten nicht geladen werden"); //("Storages not fetched");

  let list = [];
  result.data.data.value.map((item) => {
    if (!list.find((x) => x.msdyn_warehouseid == item["s.msdyn_warehouseid"])) {
      let tmp = {
        msdyn_warehouseid: item["s.msdyn_warehouseid"],
        msdyn_name: item["s.msdyn_name"],
        statuscode: item["s.statuscode"],
        statecode: item["s.statecode"],
        arades_verzicht_auf_widerruf: item["s.arades_verzicht_auf_widerruf"],
        arades_einrichtungsgebuehr_beglichen:
          item["s.arades_einrichtungsgebuehr_beglichen"],
        arades_vertrag: item["s.arades_vertrag"],
        storageStatus: storageStatus(item["s.statuscode"]),
        createDate: item["s.createdon"],
        metalId: item["s.arades_edelmetall_typ"],
        metalName:
          item[
            "s.arades_edelmetall_typ@OData.Community.Display.V1.FormattedValue"
          ],
        subStorageList: [],
      };

      tmp.pending = storageIsPending(tmp);

      let substorages = result.data.data.value.filter(
        (x) =>
          x["s.msdyn_warehouseid"] == item["s.msdyn_warehouseid"] &&
          x["sub.arades_uebergeordneter_lagerplatz"]
      );
      for (let i = 0; i < substorages?.length; i++) {
        let subItem = substorages[i];
        let subTmp = {
          msdyn_warehouseid: subItem["sub.msdyn_warehouseid"],
          msdyn_name: subItem["sub.msdyn_name"],
          msdyn_description: subItem["sub.msdyn_description"],
          statuscode: subItem["sub.statuscode"],
          statecode: subItem["sub.statecode"],
          arades_verzicht_auf_widerruf:
            subItem["sub.arades_verzicht_auf_widerruf"],
          arades_einrichtungsgebuehr_beglichen:
            subItem["sub.arades_einrichtungsgebuehr_beglichen"],
          arades_vertrag: subItem["sub.arades_vertrag"],
          storageStatus: storageStatus(subItem["sub.statuscode"]),
          createdon: subItem["sub.createdon"],
          createDate_formatted: dateString(subItem["sub.createdon"]),
          metalId: subItem["sub.arades_edelmetall_typ"],
          metalName:
            subItem[
              "sub.arades_edelmetall_typ@OData.Community.Display.V1.FormattedValue"
            ],
        };

        subTmp.pending = storageIsPending(subTmp);
        tmp.subStorageList.push(subTmp);
      }

      list.push(tmp);
    }
  });
  return list.sort((a, b) => (a?.createDate >= b?.createDate ? -1 : 1));
};

export default getStorageListWithSubstoragesFunc;
