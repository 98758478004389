import React, { useState, useEffect } from "react";
import { Box, Button, Typography, FormControl, FormLabel } from "@mui/material";
import {
  FormSteperStructure,
  ConfirmModal,
  RadioListIcon,
  LoadingOverlay,
} from "../../common";
import { useSnackbar } from "notistack";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { URL, ERROR_CONTACT_US } from "../../../core/constants";
import { postSellerInfoAction } from "../../../redux/slices/becomeSeller";
import {
  setSelectSignContract,
  selectSignContract,
  globalData,
  resetActionStatus,
  resetError,
} from "../../../redux/slices/becomeSeller/becomeSeller.slice";
import { useTranslation } from "react-i18next";
import { BYPASS_ADOBE_SIGN } from "../../../core/apiconfig/environment";

function SelectSignContractStep({ handleCurrentStep }) {
  const selectSignContractData = useSelector(selectSignContract);
  const becomeSellerGlobalData = useSelector(globalData);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [confirmSuccessOpen, setConfirmSuccessOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(selectSignContractData);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const radioList = [
    {
      label: <div>{t("become-seller.read-now")}</div>,
      value: "now",
    },
    {
      label: <div>{t("become-seller.document-confirm")}</div>,
      value: "later",
    },
  ];

  const onSubmit = () => {
    if (selectedOption == "now") setOpenConfirm(true);
    else if (BYPASS_ADOBE_SIGN) setOpenConfirm(true);
    else dispatch(postSellerInfoAction());
  };

  useEffect(() => {
    if (
      becomeSellerGlobalData.actionStatus &&
      becomeSellerGlobalData.actionType == "postSellerInfo"
    ) {
      setConfirmSuccessOpen(true);
      dispatch(resetActionStatus());
    }
  }, [becomeSellerGlobalData.actionStatus]);

  useEffect(() => {
    if (becomeSellerGlobalData.error != null) {
      enqueueSnackbar(becomeSellerGlobalData.error + ERROR_CONTACT_US, {
        variant: "error",
        autoHideDuration: 20000,
      });
      dispatch(resetError());
    }
  }, [becomeSellerGlobalData.error]);

  useEffect(() => {
    setLoading(becomeSellerGlobalData.loading);
  }, [becomeSellerGlobalData.loading]);

  return (
    <LoadingOverlay show={loading}>
      <ConfirmModal
        open={openConfirm}
        size="md"
        noImage={true}
        showCloseButton={false}
        message={<div>{t("become-seller.only-read")}</div>}
        okTitle={t("buttons.next")}
        onOk={() => {
          setOpenConfirm(false);
          handleCurrentStep("next");
          dispatch(setSelectSignContract("now"));
        }}
      ></ConfirmModal>
      <ConfirmModal
        open={confirmSuccessOpen}
        title={t("become-seller.congrats")}
        showCloseButton={false}
        message={<div>{t("become-seller.email-contract")}</div>}
        okTitle={t("buttons.go-to-dashboard")}
        onOk={() => {
          navigate(URL.DASHBOARD);
        }}
      ></ConfirmModal>

      <Box
        sx={{
          marginTop: "32px",
        }}
      >
        <Box
          sm={12}
          sx={{
            width: "100%",
            borderRadius: "8px",
            backgroundColor: "#E3F2FD",
            padding: 10,
          }}
        >
          <Typography
            variant="subtitle2"
            mb="13px"
            sx={{
              fontSize: "16px",
              fontWeight: "400",
            }}
          >
            {t("become-seller.contract-available")}
          </Typography>
        </Box>
        <Box
          sm={12}
          mt={20}
          sx={{
            width: "100%",
            "& span": {
              marginRight: "0 !important",
            },
          }}
        >
          <RadioListIcon
            currentValue={selectedOption}
            handleSetCurrentValue={(value) => {
              setSelectedOption(value);
            }}
            data={radioList}
          />
        </Box>

        <FormSteperStructure.Footer>
          <Button
            onClick={onSubmit}
            fullWidth
            sx={{ bgcolor: "info.main", maxWidth: "192px" }}
            variant="contained"
          >
            {t("buttons.next")}
          </Button>
        </FormSteperStructure.Footer>
      </Box>
    </LoadingOverlay>
  );
}

export default SelectSignContractStep;
