import axios from "axios";
import { BASE_URL } from "../../../core/apiconfig/environment";
import { TOKEN_NAME } from "../../../core/apiconfig/constantApi";
import changeContactInfoWorkflowFunc from "./changeContactInfoWorkflow.func";

const changePhoneNumberFunc = async (data, { getState }) => {
  const { contactID, mobileNumber } = data;

  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `bearer ${localStorage.getItem(TOKEN_NAME)}`,
    },
  };
  const result = await axios.patch(`${BASE_URL}data/contacts/${contactID}/`, { mobilephone: mobileNumber }, config);

  if (result?.data?.status != 200)
    throw Error("Mobilnummer  konnte nicht geändert werden"); //("Mobile not changed");

  const changeWorkFlow = await changeContactInfoWorkflowFunc(data.contactID, {
    getState,
  });

  return result.data.data;
};

export default changePhoneNumberFunc;
