import { createSlice } from "@reduxjs/toolkit";
import { getPriceListAction } from ".";

const initialState = {
  globalData: {
    actionStatus: false,
    actionType: "",
    loading: false,
    error: null,
  },
  prices: {
    gold: "57.00 €",
    silver: "57.00 €",
    platin: "57.00 €",
    palladium: "57.00 €",

    euroPerCHF: "0.98",
    euroPerUSD: "0.98",
    euroPerGBP: "0.98",

    goldPerSilver: "0.98",
    goldPerPlatin: "0.98",
    goldPerPalladium: "0.98",
    dowJonesPerGold: "0.98",

    silverPerGold: "0.98",
    silverPerPlatin: "0.98",
    silverPerPalladium: "0.98",

    platinPerGold: "0.98",
    platinPerSilver: "0.98",
    platinPerPalladium: "0.98",

    paladiumPerGold: "0.98",
    paladiumPerSilver: "0.98",
    paladiumPerPlatin: "0.98",

    goldPercent: "+0.3%",
    silverPercent: "-0.3%",
    platinPercent: "+0.3%",
    palladiumPercent: "+0.3%",

    euroPerCHFPercent: "+0.3%",
    euroPerUSDPercent: "-0.3%",
    euroPerGBPPercent: "+0.3%",

    goldPerSilverPercent: "+0.3%",
    goldPerPlatinPercent: "+0.3%",
    goldPerPalladiumPercent: "-0.3%",
    dowJonesPerGoldPercent: "+0.3%",

    silverPerGoldPercent: "+0.3%",
    silverPerPlatinPercent: "+0.3%",
    silverPerPalladiumPercent: "+0.3%",

    platinPerGoldPercent: "-0.3%",
    platinPerSilverPercent: "+0.3%",
    platinPerPalladiumPercent: "-0.3%",

    palladiumPerGoldPercent: "+0.3%",
    palladiumPerSilverPercent: "+0.3%",
    palladiumPerPlatinPercent: "-0.3%",
  },
};

export const priceListSlice = createSlice({
  name: "priceList",
  initialState,
  reducers: {
    resetError: (state) => {
      state.globalData.error = null;
    },
    resetActionStatus: (state) => {
      state.globalData.actionStatus = false;
      state.globalData.actionType = null;
    },
    resetAll: () => {
      return initialState;
    },
  },
  extraReducers: {
    [getPriceListAction.pending]: (state, action) => {
      state.globalData.loading = true;
    },
    [getPriceListAction.fulfilled]: (state, action) => {
      state.globalData.loading = false;
      state.globalData.actionStatus = true;
      //state.prices = action.payload;
      state.globalData.actionType = "getPriceList";
    },
    [getPriceListAction.rejected]: (state, action) => {
      state.globalData.loading = false;
      state.globalData.error = action.error.message;
    },
  },
});

export const fullData = (state) => state.priceList;
export const pricesData = (state) => state.priceList.prices;
export const globalData = (state) => state.priceList.globalData;

export const { resetError, resetActionStatus, resetAll } =
  priceListSlice.actions;

export default priceListSlice.reducer;
