import { createAsyncThunk } from "@reduxjs/toolkit";

const getBankAccountAction = createAsyncThunk(
  "storage/getBankAccount",
  async (dataParams, { getState }) => {
    try {
      return [];
    } catch (err) {
      throw Error(
        err.response && err.response.data.detail
          ? err.response.data.detail
          : err.message
      );
    }
  }
);

export default getBankAccountAction;
