import React, { useState, useEffect } from "react";
import { LoadingOverlay, Verification } from "../../common";
import { useDispatch, useSelector } from "react-redux";
import {
  verificationCheckSMSCodeAction,
  verificationSendSMSCodeAction,
} from "../../../redux/slices/profile";
import { useSnackbar } from "notistack";
import {
  resetVerification,
  resetError,
  resetActionStatus,
  resetSentSMS,
  verificationData,
  globalData,
} from "../../../redux/slices/profile/profile.slice";
import { ERROR_CONTACT_US } from "../../../core/constants";
import { useTranslation } from "react-i18next";

function VerificationStep({ handleCurrentStep }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const profileGlobalData = useSelector(globalData);
  const profileVerificationData = useSelector(verificationData);

  const [activationCode, setActivationCode] = useState();
  const [loading, setLoading] = useState(false);

  const onSubmit = () => {
    if (!profileVerificationData.verification) {
      dispatch(
        verificationCheckSMSCodeAction({
          activationCode: activationCode,
          mobileNumber: localStorage.getItem("mobileNumber"),
        })
      );
    } else {
      handleCurrentStep("next");
    }
  };
  useEffect(() => {
    if (!profileVerificationData.verification) {
      sendSMS();
    } else {
      handleCurrentStep("next");
    }
  }, []);
  useEffect(() => {
    if (
      profileGlobalData.actionStatus &&
      profileGlobalData.actionType === "verification"
    ) {
      enqueueSnackbar(t("messages.verification-successful"), {
        variant: "success",
        autoHideDuration: 3000,
      });

      dispatch(resetActionStatus());
      dispatch(resetSentSMS());
      dispatch(resetVerification());
      handleCurrentStep("next");
    }
  }, [profileGlobalData.actionStatus]);

  useEffect(() => {
    if (profileGlobalData.error != null) {
      enqueueSnackbar(profileGlobalData.error + ERROR_CONTACT_US, {
        variant: "error",
        autoHideDuration: 20000,
      });
      dispatch(resetError());
      setLoading(false);
    }
  }, [profileGlobalData.error]);

  const sendSMS = () => {
    const currentAccountPhoneNumber = localStorage.getItem("mobileNumber");
    dispatch(
      verificationSendSMSCodeAction({
        mobileNumber: currentAccountPhoneNumber,
      })
    );
  };

  const resendCode = () => {
    sendSMS();
  };

  return (
    <>
      <LoadingOverlay show={loading}>
        <Verification
          number={localStorage?.getItem("mobileNumber")}
          activationCode={activationCode}
          setActivationCode={setActivationCode}
          handleNext={onSubmit}
          resendCode={resendCode}
        />
      </LoadingOverlay>
    </>
  );
}

export default VerificationStep;
