import React, { useState } from "react";
import { useApi } from "../../../core/hooks/useApi";
import {
  BASE_URL,
  SHOW_MAINTENANCE_RIBBON,
  MAINTENANCE_TEXT,
} from "../../../core/apiconfig/environment";
import { CONTACT_STATUS, URL, WORKSPACETYPE } from "../../../core/constants";
import redAlarm from "../../../assets/images/redAlarm.svg";
import sslLogo from "../../../assets/images/ssl.png";
import { useParams } from "react-router";
import {
  TOKEN_NAME,
  MOBILE_VERIFIED,
  ADDRESS_VERIFIED,
  FULLNAME,
  RATIOR_KEY,
  CONTACT_BIRTHDATE,
  CONTACT_ID,
  MOBILE_NUMBER,
  WORKSPACE_ID,
  WORKSPACE_TYPE,
  WORKSPACE_NAME,
  VERMITTLER_ID,
  ACTION_CODE_ID,
  STATUS_CODE,
  NECT_VERIFIED,
  GUARDIAN1,
  GUARDIAN2,
  LANGUAGE,
} from "../../../core/apiconfig/constantApi";
import {
  Input,
  Button,
  ConfirmModal,
  CheckBox,
  LoadingOverlay,
  Ribbon,
} from "../../common";
import { useNavigate } from "react-router-dom";
import { useLoginState } from "../../../context/login/LoginState";
import { useSnackbar } from "notistack";
import "./login.scss";
import MUIButton from "@mui/material/Button";
import { Link } from "react-router-dom";
import { Box } from "@mui/material";
import { resetCache } from "../../../core/utils/cachManager";
import { useTranslation } from "react-i18next";

const Login = () => {
  const { t } = useTranslation();
  const { post } = useApi();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const first = useParams().first == "first";

  const [loading, setLoading] = useState(false);
  const [token, setToken] = useState();
  const [rememberPass, setRememberPass] = useState(false);
  const [value, setValue] = useState({
    ratiorKey: "",
    password: "",
  });
  //const [deactivated, setDeactivated] = useState(false);
  const [openModalDeactivated, setOpenModalDeactivated] = useState(false);

  const handleChange = (event) => {
    setValue({ ...value, [event.target.name]: event.target.value });
  };

  const handleSubmit = async () => {
    resetCache();
    if (!value.ratiorKey || !value.password) {
      enqueueSnackbar(t("login.enter-username-and-password"), {
        variant: "error",
        autoHideDuration: 20000,
      });
      return;
    }

    let loginObj = {
      ratiorKey: value.ratiorKey.trim(),
      password: value.password,
      rememberMe: rememberPass,
    };
    setLoading(true);
    const result = await post(BASE_URL + "auth/login", loginObj);
    setLoading(false);
    //setDeactivated(false);
    setOpenModalDeactivated(false);
    if (result) {
      if (result.status == 498) setOpenModalDeactivated(true); // setDeactivated(true);
      else if (result.status != 200)
        enqueueSnackbar(t("login.wrong"), {
          variant: "error",
          autoHideDuration: 20000,
        });
      else {
        let loginToken = result.data.token.token;
        setToken(loginToken);

        //paricher should fix detector
        localStorage.setItem(LANGUAGE, "de");

        localStorage.setItem(TOKEN_NAME, loginToken);
        if (result.data.user) setContact(result.data.user);
        if (!result.data.user.arades_mobilnummer_verifiziert) {
          navigate(URL.MOBILEVERIFICATION);
        } else if (
          !result.data.user.arades_nectverifiziert &&
          !result.data.user.arades_adresse_verifiziert &&
          result.data.statuscode == CONTACT_STATUS.INVITED
        ) {
          navigate(URL.ADDRESSVERIFICATION);
        } else navigate("/");
        window.location.reload();

        //removed two factor authentication
        // loginInfoAction({
        //   ...value,
        //   ["step"]: 2,
        //   [TOKEN_NAME]: loginToken,
        // });
      }
    }
  };

  const setContact = (contact) => {
    let title =
      contact["_arades_titel_value@OData.Community.Display.V1.FormattedValue"];
    let fullName =
      (title ? title + " " : "") + contact.firstname + " " + contact.lastname;

    localStorage.setItem(RATIOR_KEY, contact.arades_ratior_key);
    localStorage.setItem(CONTACT_ID, contact.contactid);
    localStorage.setItem(WORKSPACE_ID, contact.contactid);
    localStorage.setItem(WORKSPACE_TYPE, WORKSPACETYPE.CONTACT);
    localStorage.setItem(WORKSPACE_NAME, fullName);
    localStorage.setItem(MOBILE_NUMBER, contact.mobilephone);
    localStorage.setItem(VERMITTLER_ID, contact._arades_vermittler_value);
    localStorage.setItem(ACTION_CODE_ID, contact._arades_aktionscode_value);
    localStorage.setItem(FULLNAME, fullName);
    localStorage.setItem(
      GUARDIAN1,
      contact._arades_erziehungsberechtigter1_value
    );
    localStorage.setItem(
      GUARDIAN2,
      contact._arades_erziehungsberechtigter2_value
    );
    localStorage.setItem(STATUS_CODE, contact.statuscode);
    if (contact.birthdate)
      localStorage.setItem(CONTACT_BIRTHDATE, contact.birthdate);
    if (contact.arades_nectverifiziert)
      localStorage.setItem(NECT_VERIFIED, "true");
    else localStorage.setItem(NECT_VERIFIED, "false");
    if (contact.arades_mobilnummer_verifiziert)
      localStorage.setItem(MOBILE_VERIFIED, "true");
    else localStorage.setItem(MOBILE_VERIFIED, "false");
    if (contact.arades_adresse_verifiziert)
      localStorage.setItem(ADDRESS_VERIFIED, "true");
    else localStorage.setItem(ADDRESS_VERIFIED, "false");
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleSubmit();
    }
  };

  return (
    <LoadingOverlay show={loading}>
      <ConfirmModal
        open={openModalDeactivated}
        showCloseButton={false}
        size="md"
        imageSrc={redAlarm}
        message={
          <div>
            {t("login.locked.1")}
            <br></br>
            <span hidden="hidden">{t("login.locked.2")}</span>
            <br></br>
            {t("login.locked.3")}
            <br></br>
            {t("login.locked.4")}
            <br></br>
            {t("login.locked.5")}
          </div>
        }
        okTitle="OK"
        onOk={() => {
          setOpenModalDeactivated(false);
        }}
      ></ConfirmModal>
      {SHOW_MAINTENANCE_RIBBON && (
        <div>
          <Ribbon
            height={110}
            description={
              <div>
                Liebe Kunden,
                <br></br> {MAINTENANCE_TEXT} <br></br>{" "}
                Vielen Dank für Ihr Verständnis!
              </div>
            }
          ></Ribbon>
        </div>
      )}

      {first && (
        <div>
          <Ribbon
            height={110}
            description={
              <div>{t("login.instructions-after-registration")}</div>
            }
          ></Ribbon>
        </div>
      )}

      <div className="login-box-wrapper" onKeyDown={handleKeyDown}>
        <div
          className="flex-column login-box"
          style={{
            paddingTop: SHOW_MAINTENANCE_RIBBON || first ? "0px" : "45px",
          }}
        >
          <span className="login-title">{t("login.title")}</span>
          <form>
            <div className="padding-30">
              <Input
                name={"ratiorKey"}
                value={value.ratiorKey}
                onChange={handleChange}
                label={t("login.ratior-key-or-alias")}
                autoComplete="username"
              />
              <Box sx={{ textAlign: "right" }}>
                <div className="forgot ratior-key">
                  <span
                    tabIndex="1"
                    onClick={() => {
                      navigate(URL.FORGOTRATIORKEY);
                    }}
                  >
                    {t("login.forgot-ratior-key")}
                  </span>
                </div>
              </Box>
            </div>
            <div className="padding-30">
              <Input
                name={"password"}
                value={value.password}
                onChange={handleChange}
                label={t("login.password")}
                type="password"
              />
            </div>
          </form>
          <div className="padding-30 checkboxrow">
            <CheckBox
              id={"rememberPass"}
              name={"rememberPass"}
              value={value.rememberPass}
              onChange={() => {
                setRememberPass(!rememberPass);
              }}
              label={t("login.remember-me")}
            ></CheckBox>
          </div>
          <div className="padding-30">
            <Button primary className={"form-item"} onClick={handleSubmit}>
              {t("login.login")}
            </Button>
          </div>
          <div className="forgot password">
            <span
              tabIndex="1"
              onClick={() => {
                navigate(URL.FORGETPASS);
              }}
            >
              {t("login.forgot-password")}
            </span>
          </div>
          <hr className="margin-10"></hr>
          <div className="padding-20">
            <Button
              className={"form-item tertiary"}
              onClick={() => navigate(URL.REGISTERSTERPERSONALINFO)}
            >
              {t("login.register")}
            </Button>
          </div>
          <div className="padding-20" style={{ textAlign: "center" }}>
            <img src={sslLogo} style={{ width: "80px" }}></img>
          </div>
        </div>
      </div>
    </LoadingOverlay>
  );
};

export default Login;
