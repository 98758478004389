import axios from "axios";
import { BASE_URL } from "../../../core/apiconfig/environment";
import { WORKSPACETYPE } from "../../../core/constants";
import { TOKEN_NAME, WORKSPACE_ID, WORKSPACE_TYPE } from "../../../core/apiconfig/constantApi";

const getVermittlerBuyInvoiceList = async () => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `bearer ${localStorage.getItem(TOKEN_NAME)}`,
    },
  };

  const vermittlerId = localStorage.getItem(WORKSPACE_ID);
  const vermittlerType = localStorage.getItem(WORKSPACE_TYPE);
  try {
    let result;
    if (vermittlerType === WORKSPACETYPE.CONTACT || vermittlerType === WORKSPACETYPE.UNDER_AGE) {
      result = await axios.get(`${BASE_URL}data/invoices/vermittler/contact/${vermittlerId}/`, config);
    } else {
      result = await axios.get(`${BASE_URL}data/invoices/vermittler/account/${vermittlerId}/`, config);
    }

    return result.data;
  } catch (e) {
    console.error(e);
    throw new Error("Vermittler Rechnung wurde nicht gefunden");
  }
};

const getVermittlerBuyInvoiceListFunc = async (data, { getState }) => {
  let allInvoices = await getVermittlerBuyInvoiceList();

  let list = [];

  //parichehr you can fetch other fields also , if this list is needed some where else
  allInvoices.map((item) => {
    let tmp = {
      totalPrice: item["totalamount"],
      createDate: item["createdon"],
      customerId: item["_customerid_value"],
      customerName: item["c.firstname"]
        ? item["t.arades_name"]
          ? item["t.arades_name"] +
            " " +
            item["c.firstname"] +
            " " +
            item["c.lastname"]
          : "" + item["c.firstname"] + " " + item["c.lastname"]
        : item["a.name"],
      metalName: item["p.name"],
      productNumber: item["p.productnumber"],
    };
    list.push(tmp);
  });

  return list.sort((a, b) => (a?.createDate >= b?.createDate ? -1 : 1));
};

export default getVermittlerBuyInvoiceListFunc;
