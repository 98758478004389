import React, { useState, useEffect } from "react";
import { ConfirmModal, LoadingOverlay, Verification } from "../common";
import { useDispatch, useSelector } from "react-redux";
import {
  verificationCheckSMSCodeAction,
  verificationSendSMSCodeAction,
} from "../../redux/slices/underage";
import { useSnackbar } from "notistack";
import {
  underAgeFullState,
  resetActionStatus,
  resetVerification,
  resetError,
  resetAll,
} from "../../redux/slices/underage/underage.slice";
import { useTranslation } from "react-i18next";
import { ERROR_CONTACT_US } from "../../core/constants";

function VerificationStep({ handleCurrentStep }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const underAgeData = useSelector(underAgeFullState);
  const [activationCode, setActivationCode] = useState();
  const [loading, setLoading] = useState(false);

  const onSubmit = () => {
    setLoading(true);
    dispatch(
      verificationCheckSMSCodeAction({
        activationCode: activationCode,
        mobileNumber:
          underAgeData.legalGuardianStatusStep?.partnerPhoneNumber ||
          localStorage?.getItem("mobileNumber"),
      })
    );
  };

  useEffect(() => {
    if (underAgeData.verificationStep.verification) {
      enqueueSnackbar(t("underage.verification-successful"), {
        variant: "success",
        autoHideDuration: 3000,
      });
      dispatch(resetVerification());
      dispatch(resetActionStatus());
      handleCurrentStep("next");
    }
  }, [underAgeData.verificationStep.verification]);

  useEffect(() => {
    if (underAgeData.error != null) {
      enqueueSnackbar(underAgeData.error + ERROR_CONTACT_US, {
        variant: "error",
        autoHideDuration: 20000,
      });
      setLoading(false);
      dispatch(resetError());
    }
  }, [underAgeData.error]);

  const resendCode = () => {
    if (
      underAgeData.legalGuardianStatusStep.legalStatus === "partner" &&
      underAgeData.legalGuardianStatusStep.partnerPhoneNumber &&
      !underAgeData.error
    ) {
      dispatch(
        verificationSendSMSCodeAction({
          mobileNumber: underAgeData.legalGuardianStatusStep.partnerPhoneNumber,
        })
      );
    } else if (
      underAgeData.legalGuardianStatusStep.legalStatus === "only" &&
      !underAgeData.error
    ) {
      const currentAccountPhoneNumber = localStorage.getItem("mobileNumber");
      dispatch(
        verificationSendSMSCodeAction({
          mobileNumber: currentAccountPhoneNumber,
        })
      );
    } else if (underAgeData.error != null) {
      enqueueSnackbar(underAgeData.error + ERROR_CONTACT_US, {
        variant: "error",
        autoHideDuration: 20000,
      });
      setLoading(false);
      dispatch(resetError());
    }
  };
  return (
    <LoadingOverlay show={loading}>
      <Verification
        number={
          underAgeData.legalGuardianStatusStep?.partnerPhoneNumber ||
          localStorage?.getItem("mobileNumber")
        }
        activationCode={activationCode}
        setActivationCode={setActivationCode}
        handleNext={onSubmit}
        resendCode={resendCode}
      />
    </LoadingOverlay>
  );
}

export default VerificationStep;
