import React, { useState, useEffect } from "react";
import ModalBox from "./modalBox";
import modalOk from "../../../assets/images/modalOk.svg";
import "./confirmModal.scss";
import Button from "../buttons/button";
import { LoadingOverlay } from "../";
const SubmitModal = ({
  title,
  content,
  open,
  acceptTitle,
  rejectTitle,
  onAccept,
  onReject,
  okTitle,
  onOk,
  onClose,
  formIsValid,
  showCloseButton,
  loading,
  size,
}) => {
  const boxContent = (
    <div className={`modal-content ${size ? size : ""}`}>
      <LoadingOverlay show={loading}>
        <div className="modal-title ">{title}</div>
        <div className="content">{content}</div>
        <div className="div-btn">
          {rejectTitle && (
            <div className="cta-btn white right confirm" onClick={onReject}>
              {rejectTitle}
            </div>
          )}
          {acceptTitle && (
            <Button
              disabled={!formIsValid}
              style={{ paddingTop: 0 }}
              className={`cta-btn right confirm `}
              onClick={onAccept}
            >
              {acceptTitle}
            </Button>
          )}
          {okTitle && (
            <Button
              style={{ paddingTop: 0 }}
              className={`cta-btn right confirm `}
              onClick={onOk}
            >
              {okTitle}
            </Button>
          )}
        </div>
      </LoadingOverlay>
    </div>
  );

  return (
    <ModalBox
      open={open}
      content={boxContent}
      onClose={onClose}
      showCloseButton={showCloseButton}
    />
  );
};

export default SubmitModal;
