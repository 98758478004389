import axios from "axios";
import { CONTACT_STATUS, CONTACT_TYPE } from "../../../core/constants";
import { BASE_URL } from "../../../core/apiconfig/environment";
import { TOKEN_NAME } from "../../../core/apiconfig/constantApi";

const getContactsList = async (vermittlerId) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `bearer ${localStorage.getItem(TOKEN_NAME)}`,
    },
  };
  const result = await axios.get(`${BASE_URL}data/contacts/account/${vermittlerId}/managed/`, config);
  if (result?.data?.status != 200) throw Error("Kontakte der Firma konnten nicht geladen werden");

  let toShowList = result.data.data.value;

  let list = [];
  toShowList.map((item) => {
    let tmp = {
      id: item.contactid,
      type: "contact",
      storages: [],
      name:
        (item["t.arades_name"]
          ? item["t.arades_name"]
          : item.arades_anrede == "100000000"
          ? "Herr"
          : item.arades_anrede == "100000001"
          ? "Frau"
          : item.arades_anrede == "100000001"
          ? "Divers"
          : "") +
        " " +
        item.firstname +
        " " +
        item.lastname,
      ratiorKey: item["arades_ratior_key"],
      statuscode: item["statuscode"],
      status:
        item["statuscode"]?.toString() === CONTACT_STATUS.ACTIVE
          ? "customer-list.status-name.active"
          : item["statuscode"]?.toString() === CONTACT_STATUS.INACTIVE
          ? "customer-list.status-name.inactive"
          : item["statuscode"]?.toString() === CONTACT_STATUS.INVITED
          ? "customer-list.status-name.invited"
          : item["statuscode"]?.toString() === CONTACT_STATUS.VERIFIED
          ? "customer-list.status-name.verified"
          : item["statuscode"]?.toString() === CONTACT_STATUS.UNDER_REVIEW
          ? "customer-list.status-name.under-review"
          : (item["statuscode"]?.toString() === CONTACT_STATUS.LOCKED_DEV || item["statuscode"]?.toString() === CONTACT_STATUS.LOCKED_PROD)
          ? "customer-list.status-name.locked"
          : "",
      isUnderage: item["arades_kontakttyp"]?.toString() === CONTACT_TYPE.UNDER_AGE,
      guardians: [item["arades_erziehungsberechtigter1"], item["arades_erziehungsberechtigter2"]].filter((guardian) => guardian), // Full names of one or both guardians of an underage contact
      lagerplatz: [],
      createDate: item.createdon,
      verifiedStatus: !item["arades_email_verifiziert"]
        ? 0
        : !item["arades_mobilnummer_verifiziert"]
        ? 1
        : !item["arades_adresse_verifiziert"]
        ? 2
        : 3,
    };
    list.push(tmp);
  });

  return list;
};

const getAccountsList = async (vermittlerId) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `bearer ${localStorage.getItem(TOKEN_NAME)}`,
    },
  };
  const result = await axios.get(`${BASE_URL}data/accounts/account/${vermittlerId}/managed/`, config);
  if (result?.data?.status !== 200) { throw Error("Firmen nicht geladen werden"); }

  let toShowList = result.data.data.value;

  let list = [];
  toShowList.map((item) => {
    let tmp = {
      id: item.accountid,
      type: "account",
      storages: [],
      name: item.name,
      ratiorKey: item["primarycontactid"]["arades_ratior_key"],
      statuscode: item["primarycontactid"]["statuscode"],
      status:
        item["statuscode"]?.toString() === CONTACT_STATUS.ACTIVE
        ? "customer-list.status-name.active"
        : item["statuscode"]?.toString() === CONTACT_STATUS.INACTIVE
        ? "customer-list.status-name.inactive"
        : item["statuscode"]?.toString() === CONTACT_STATUS.INVITED
        ? "customer-list.status-name.invited"
        : item["statuscode"]?.toString() === CONTACT_STATUS.VERIFIED
        ? "customer-list.status-name.verified"
        : (item["statuscode"]?.toString() === CONTACT_STATUS.LOCKED_DEV || item["statuscode"]?.toString() === CONTACT_STATUS.LOCKED_PROD)
        ? "customer-list.status-name.locked"
        : "",
      lagerplatz: [],
      createDate: item.createdon,
      verifiedStatus: !item["primarycontactid"]["arades_email_verifiziert"]
        ? 0
        : !item["primarycontactid"]["arades_mobilnummer_verifiziert"]
        ? 1
        : !item["primarycontactid"]["arades_adresse_verifiziert"]
        ? 2
        : 3,
    };
    list.push(tmp);
  });

  return list;
};
const getCustomerDetailedListFunc = async ({ vermittlerId }, { getState }) => {
  let contacts = await getContactsList(vermittlerId);
  let accounts = await getAccountsList(vermittlerId);
  // Add Vermittler ID to contacts and accounts to show the correct vermittler when switching to the contact or account
  contacts = contacts.map((contact) => ({ ...contact, vermittlerId }));
  accounts = accounts.map((account) => ({ ...account, vermittlerId }));
  // Sort contacts and accounts by name
  return contacts.concat(accounts).sort((a, b) => (a.name > b.name ? 1 : -1));
};
export default getCustomerDetailedListFunc;
