import axios from "axios";
import { BASE_URL } from "../../core/apiconfig/environment";
import { TOKEN_NAME, WORKSPACE_ID } from "../../core/apiconfig/constantApi";

const postBankAccountFunc = async (bankAccountInfo, { getState }) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `bearer ${localStorage.getItem(TOKEN_NAME)}`,
    },
  };

  let value = { ...bankAccountInfo };
  if (!value.arades_kontonummer) delete value.arades_kontonummer;
  if (!value.arades_kontoid) delete value.arades_kontoid;

  const result = await axios.post(`${BASE_URL}data/kontos/account/${localStorage.getItem(WORKSPACE_ID)}/`, value, config);

  if (result?.data?.status != 201)
    throw Error("Bankkonto wurde nicht gespeichert"); //("Bank account not saved");
  return result.data.data;
};

export default postBankAccountFunc;
