// Export and download table data as Excel file
// - filename: Filename for the download
// - tableData: Array of objects
// - schema: Array of { column: "Name", type: String / Date / ..., format: "" / "#,##0.00" / "DD.MM.YYYY", value: row => row.name }
export async function exportAndDownloadExcel(filename, tableData, schema) {
    const writeXlsxFile = (await import("write-excel-file")).default;
    await writeXlsxFile(tableData, {
        schema,
        fileName: filename
    });
}
