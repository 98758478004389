import { createSlice } from "@reduxjs/toolkit";

import { getMessagesListReceivedAction, getMessagesListSentAction, postNewMessageAction } from ".";

const initialState = {
  globalData: {
    actionStatus: false,
    actionType: "",
    loading: false,
    error: null,
  },
  lists: {
    inbox: [],
    sent: [],
  },
  newMessage: {
    sendTo: "",
    message: "",
    files: null,
    show: false,
  },
};
export const inboxSlice = createSlice({
  name: "inbox",
  initialState,
  reducers: {
    resetError: (state) => {
      state.globalData.error = null;
    },
    resetActionStatus: (state) => {
      state.globalData.actionStatus = false;
      state.globalData.actionType = null;
    },
    showNewMessageForm: (state, action) => {
      if (action.payload?.reply) {
        console.log(action.payload.reply);
        state.newMessage.sendTo = action.payload.reply;
      }
      state.newMessage.show = true;
    },
    hideNewMessageForm: (state) => {
      state.newMessage.show = false;
      state.newMessage.sendTo = "";
      state.newMessage.message = "";
      state.newMessage.files = null;
    },
    setNewMessage: (state, action) => {
      state.newMessage.sendTo = action.payload.sendTo;
      state.newMessage.message = action.payload.message;
      state.newMessage.files = action.payload?.files || null;
    },
    resetAll: () => {
      return initialState;
    },
  },
  extraReducers: {
    //Get Inbox List Action
    [getMessagesListReceivedAction.pending]: (state, action) => {
      state.globalData.loading = true;
    },
    [getMessagesListReceivedAction.fulfilled]: (state, action) => {
      state.globalData.loading = false;
      state.lists.inbox = action.payload;
    },
    [getMessagesListReceivedAction.rejected]: (state, action) => {
      state.globalData.loading = false;
      state.globalData.error = action.error.message;
    },
    //Get Sent List Action
    [getMessagesListSentAction.pending]: (state, action) => {
      state.globalData.loading = true;
    },
    [getMessagesListSentAction.fulfilled]: (state, action) => {
      state.globalData.loading = false;
      state.lists.sent = action.payload;
    },
    [getMessagesListSentAction.rejected]: (state, action) => {
      state.globalData.loading = false;
      state.globalData.error = action.error.message;
    },
    //Post New Message Action
    [postNewMessageAction.pending]: (state, action) => {
      state.globalData.loading = true;
    },
    [postNewMessageAction.fulfilled]: (state, action) => {
      state.globalData.loading = false;
      state.globalData.actionStatus = true;
      state.globalData.actionType = "postMessage";
    },
    [postNewMessageAction.rejected]: (state, action) => {
      state.globalData.loading = false;
      state.globalData.error = action.error.message;
    },
  },
});

export const {
  resetError,
  resetActionStatus,
  resetAll,
  showNewMessageForm,
  hideNewMessageForm,
  setNewMessage,
} = inboxSlice.actions;

export const fullData = (state) => state.inbox;
export const globalData = (state) => state.inbox.globalData;
export const lists = (state) => state.inbox.lists;
export const newMessage = (state) => state.inbox.newMessage;

export default inboxSlice.reducer;
