import { createAsyncThunk } from "@reduxjs/toolkit";

const getGrantedPowerOfAttorneyListAction = createAsyncThunk(
  "powerOfAttorney/getGrantedPowerOfAttorneyList",
  async (dataParams, { getState }) => {
      return [
        {
            id:1,
            authorizedRepresentative:"Ahmad Aliov",
            storageNumber:"A12345AU00",
            status:"Wait for signing",
            receivedAt:"22/11/2021",
            returnOn:"22/11/2021",
        },
        {
            id:2,
            authorizedRepresentative:"Ahmad Aliov",
            storageNumber:"A12345AU00",
            status:"Active",
            receivedAt:"22/11/2021",
            returnOn:"22/11/2021",
        },
        {
          id:3,
          authorizedRepresentative:"Ahmad Aliov",
          storageNumber:"A12345AU00",
          status:"Expired",
          receivedAt:"22/11/2021",
          returnOn:"22/11/2021",
      }
      ]
  }
);

export default getGrantedPowerOfAttorneyListAction;
