import React, { useState, useEffect } from "react";
import Button from "../../common/buttons/button";
import back from "../../../assets/images/back.svg";
import { useRegisterCompanyState } from "../../../context/registerCompany/RegisterCompanyState";
import { BASE_URL } from "../../../core/apiconfig/environment";
import { useApi } from "../../../core/hooks/useApi";
import InputUpload from "../../common/inputs/inputUpload";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import ConfirmModal from "../../common/popups/confirmModal";
import { URL } from "../../../core/constants";
import LoadingOverlay from "../../common/LoadingOverlay/LoadingOverlay";
import "./documents.scss";
import { useTranslation } from "react-i18next";

const Documents = () => {
  const MAXFILESIZE = 20000000;

  const { get, post } = useApi();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [value, setValue] = useState({});
  const [documentList, setDocumentList] = useState([]);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  /// const [documentLoading, setDocumentLoading] = useState(false);
  const [documentsFetched, setDocumentsFetched] = useState(false);
  const [documentsFetchAgain, setDocumentsFetchAgain] = useState(false);

  const { registerCompanyInfoAction, registerCompanyInfo } =
    useRegisterCompanyState();

  useEffect(() => {
    if (!documentsFetched) {
      setLoading(true);
      const timerId = setInterval(async () => {
        let fetched = await fetchDocuments();

        if (fetched) {
          if (registerCompanyInfo && registerCompanyInfo.documents)
            setValue({ ...registerCompanyInfo.documents });
          setLoading(false);
          setDocumentsFetched(true);
        } else {
          setDocumentsFetchAgain(!documentsFetchAgain);
        }
      }, 5000);
      return () => clearInterval(timerId);
    }
  }, [documentsFetched, documentsFetchAgain]);

  const fetchDocuments = async () => {
    const result = await get(`${BASE_URL}data/documents/account/${registerCompanyInfo.company.accountid}/`);
    if (result.data && result.data.value) {
      setDocumentList(result.data.value);
      return true;
    } else { return false; }
  };

  const handleFileChange = async (e, documentId) => {
    if (!e.target.files || e.target.files.length == 0) return;

    if (e.target.files[0].size > MAXFILESIZE) {
      enqueueSnackbar(t("messages.max-file"), {
        variant: "error",
        autoHideDuration: 20000,
      });
    }

    setValue({ ...value, ["d" + documentId]: e.target.files[0].name });

    const formData = new FormData();
    formData.append("firmendokumentId", documentId);
    formData.append("file", e.target.files[0]);
    //setDocumentLoading(true);
    setLoading(true);
    const result = await post(`${BASE_URL}upload`, formData);
    setLoading(false);
    // setDocumentLoading(false);
    if (result.status == 200)
      enqueueSnackbar(t("messages.upload-done"), {
        variant: "success",
        autoHideDuration: 3000,
      });
    else setValue({ ...value, ["d" + documentId]: null });
  };

  const handleSubmit = () => {
    setConfirmOpen(true);
  };

  const backToCompany = () => {
    registerCompanyInfoAction({
      ...registerCompanyInfo,
      documents: { ...value },
      ["step"]: 1,
    });
  };

  const formIsValid = () => {
    let formvalid = true;
    documentList.map((doc) => {
      if (!value["d" + doc.arades_firmendokumentid]) formvalid = false;
    });
    return formvalid; //&& !documentLoading;
  };

  return (
    <LoadingOverlay show={loading}>
      <div className="container-card">
        <ConfirmModal
          open={confirmOpen}
          title={t("company-register.confirm-congrats")}
          showCloseButton={false}
          message={t("company-register.confirm-done-message")}
          okTitle={t("company-register.confirm-go-dashboard")}
          onOk={() => {
            navigate(URL.DASHBOARD);
            window.location.reload();
          }}
        ></ConfirmModal>
        <h1 className="title">{t("company-register.add-documents")}</h1>
        <div className="title mt-4" style={{ fontSize: 18 }}>
          {registerCompanyInfo.company.name}
        </div>
        <div className="row d-flex">
          <div className="d-flex flex-column col-12 ">
            <div className="col-12 mt-4 " style={{ maxWidth: 800 }}>
              {t("company-register.document-desc")}
              <br></br>
              <br></br>* Die maximale Upload-Größe beträgt 20MB
              <br></br>* Erlaubte Dateitypen: PDF, png, jpeg, jpg
            </div>
            <div
              className="row d-flex align-self-start mt-4"
              style={{ width: "100%" }}
            >
              {documentList.map((doc) => {
                return (
                  <div
                    className="col-12 col-md-6 mt-4 "
                    style={{ width: "100%" }}
                  >
                    <InputUpload
                      name={"d" + doc.arades_firmendokumentid}
                      value={value["d" + doc.arades_firmendokumentid]}
                      onChange={(e) =>
                        handleFileChange(e, doc.arades_firmendokumentid)
                      }
                      placeholder={doc.arades_name}
                      label={doc.arades_name}
                      //parichehr ask
                      accept=".pdf,.jpg,.jpeg,.png,.svg"
                    />
                  </div>
                );
              })}
            </div>
            <div className="col-12 mt-4 ">
              Der Uploadvorgang kann eine gewisse Zeit in Anspruch nehmen. Bitte
              warten Sie ab, bis das jeweilige Dokument als hochgeladen
              gekennzeichnet ist.
            </div>
            <div className="form-row div-btn " style={{ paddingTop: 22 }}>
              {!registerCompanyInfo.hideBackLink && (
                <div className="link-btn" onClick={backToCompany}>
                  <span>
                    <img src={back} className="imgback"></img>{" "}
                    {t("company-register.back")}
                  </span>
                </div>
              )}
              <Button
                disabled={!formIsValid()}
                className="btn-action"
                onClick={handleSubmit}
                style={{ marginRight: 20 }}
              >
                {t("company-register.register")}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </LoadingOverlay>
  );
};

export default Documents;
