import React, { Component } from 'react';
import { FormStepContainer,FormStepHeader,FormStepFooter,StepBack,StepRightButton } from './FormSteperStructure.styles';

import { Box } from '@mui/material';
import { LoadingOverlay } from '..';

class FormSteperStructure extends Component {
  static Container = FormStepContainer
  static Header = FormStepHeader
  static Footer= FormStepFooter
  static StepBack= ({children,...props})=><StepBack {...props}>{children}</StepBack>
  static StepRightButton= ({children,...props})=><StepRightButton {...props}>{children}</StepRightButton>
  static Content=({children,currentStep,...props})=>{
   return React.Children.map(children, (child,index) => {
     return currentStep===index+1 && (
      <Box sx={{
        width:'100%',
       }} 
       {...props}
       >
            {React.cloneElement(child)}
      </Box>
     )
   })
  }
  
  render() {
    return <LoadingOverlay show={this.props.loading}>{this.props.children}</LoadingOverlay>;
  }
}


export default FormSteperStructure