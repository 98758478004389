import { createAsyncThunk } from "@reduxjs/toolkit";
import { postCustomerActionCodeFunc } from "../../sharedFunctions";
import { getActionCodeDataFunc } from "../../sharedFunctions";
import axios from "axios";
import { BASE_URL } from "../../../core/apiconfig/environment";
import { TOKEN_NAME } from "../../../core/apiconfig/constantApi";

const postCustomerActionCodeByNameAction = createAsyncThunk(
  "customerList/postCustomerActionCode",
  async (params, { getState }) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${localStorage.getItem(TOKEN_NAME)}`,
      },
    };

    let actionCodeData = await getActionCodeDataFunc(params.actionCode, {
      getState,
    });
    if (!actionCodeData) throw Error("Aktionscode wurde nicht gefunden"); //("Action code not found");
    if (!actionCodeData.isValidDate) throw Error("Aktionscode ist abgelaufen"); //("Action code expired");
    if (!actionCodeData.isValidUserNum)
      throw Error("Maximale Anzahl Aktionscode Nutzer erreicht"); //("Action code reached max register count");
    if (!actionCodeData.isActive) throw Error("Aktionscode ist nicht aktiv"); //("Action Code not active");

    let result;
    if (params.contactId) {
      result = await axios.get(`${BASE_URL}data/action-codes/action-code/${actionCodeData.id}/contact/${params.contactId}/`, config);
    } else {
      result = await axios.get(`${BASE_URL}data/action-codes/action-code/${actionCodeData.id}/account/${params.accountId}/`, config);
    }

    if (result?.data?.status != 200)
      throw Error("Action Code user not fetched");
    else if (result?.data?.data?.value?.length > 0)
      throw Error("Aktionscode wurde Ihnen bereits zugewiesen"); //("This Action Code is already assigned to the customer");

    await postCustomerActionCodeFunc({ actionCodeId: actionCodeData.id, contactId: params.contactId, accountId: params.accountId }, { getState });

    return true;
  }
);

export default postCustomerActionCodeByNameAction;
