import { createAsyncThunk } from "@reduxjs/toolkit";

const getReceivedPowerOfAttorneyListAction = createAsyncThunk(
  "powerOfAttorney/getReceivedPowerOfAttorneyList",
  async (dataParams, { getState }) => {
      return [
        {
            id:1,
            storageOwner:"Ahmad Aliov",
            storageNumber:"A12345AU00",
            status:"Wait for signing",
            receivedAt:"22/11/2021",
            returnOn:"22/11/2021",
        },
        {
            id:2,
            storageOwner:"Ahmad Aliov",
            storageNumber:"A12345AU00",
            status:"Active",
            receivedAt:"22/11/2021",
            returnOn:"22/11/2021",
        },
        {
          id:3,
          storageOwner:"Ahmad Aliov",
          storageNumber:"A12345AU00",
          status:"Expired",
          receivedAt:"22/11/2021",
          returnOn:"22/11/2021",
      }
    ]
  }
);

export default getReceivedPowerOfAttorneyListAction;
