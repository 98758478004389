import { createSlice } from "@reduxjs/toolkit";
import {
  STORAGE_PARTNER_SECURITY,
  STORAGE_UNDER_AGE_SECURITY,
  FEE_PAYER_TYPE,
} from "../../../core/constants";

import {
  postTransferAction,
  getTransferedStorageListAction,
  getOwnerByStorageIdAction,
  verificationSendSMSCodeAction,
  verificationCheckSMSCodeAction,
  getStorageListAction,
  getSubStorageListAction,
  getStorageAmountAction,
  getStorageByNumberBirthAction,
  getPartnerDataAction,
  getFormattedStorageAndSubStoragesAction,
  getStorageListWithSubstoragesAction,
  getActionCodeCustomerDataAction,
} from ".";

const initialState = {
  globalData: {
    loading: false,
    error: null,
    actionStatus: false,
    actionType: null,
    storages: [],
    subStorages: [],
    storagesListWithSubstorages: [],
    actionCodeData: {},
  },
  transferFee: {
    fee: 0,
    discountedFee: 0,
    discountPercentage: 0,
    zerofee: false,
  },
  transferSource: {
    fromStorageId: "",
    mainStorageId: "",
    fromStorage: {},
    options: [],
  },
  verificationSteps: {
    needOtherPersonVerification: false,
    verification: false,
    sentSMS: false,
    otherPersonPhoneNumber: null,
  },
  selectReceptorType: {
    receptorType: "mySelf",
    detailStorageId: "",
    fromStorageNumber: "",
  },
  selectWhoPaysFee: {
    whoPays: FEE_PAYER_TYPE.SELLER_PAYS,
  },
  selectStorageType: {
    storageType: "mainStorage",
    canSelectStorage: false,
    canSelectSubstorage: false,
  },
  selectStorage: {
    storageId: "",
    storageNumber: "",
    subStorageId: "",
    subStorageNumber: "",
  },
  selectRecipient: {
    recipientList: null,
    recipient: "new",
    owner: "",
    storage_owner: "",
    storage_number: "",
    birthdate_owner: "",
    //save_recipient: false,
    storage: {},
    contact: {},
  },
  enterAmount: {
    amount: "",
    amountPerGram: "",
    maxAmountPerGram: "",
  },
};

export const transferSlice = createSlice({
  name: "transfer",
  initialState,
  reducers: {
    setSelectedSource: (state, action) => {
      const { fromStorage, fromStorageId, mainStorageId } = action.payload;
      state.transferSource.fromStorage = fromStorage;
      state.transferSource.fromStorageId = fromStorageId;
      state.transferSource.mainStorageId = mainStorageId;

      if (
        String(fromStorage.arades_vertretungsberechtigung) ===
          STORAGE_PARTNER_SECURITY.BOTH ||
        String(fromStorage.arades_sicherheit) ===
          STORAGE_UNDER_AGE_SECURITY.BOTH_LEGAL_GUARDIAN
      )
        state.verificationSteps.needOtherPersonVerification = true;
      else state.verificationSteps.needOtherPersonVerification = false;
    },
    setSelectReceptorType: (state, action) => {
      const { receptorType, detailStorageId } = action.payload;

      state.selectReceptorType.receptorType = receptorType;
      state.selectReceptorType.detailStorageId = detailStorageId;
    },
    setSelectWhoPaysFee: (state, action) => {
      const whoPays = action.payload;

      state.selectWhoPaysFee.whoPays = whoPays;
    },
    setSelectStorageType: (state, action) => {
      state.selectStorageType.storageType = action.payload.storageType;
      state.selectStorageType.canSelectStorage =
        action.payload.canSelectStorage;
      state.selectStorageType.canSelectSubstorage =
        action.payload.canSelectSubstorage;
    },
    setSelectStorage: (state, action) => {
      const { storageId, storageNumber, subStorageId, subStorageNumber } =
        action.payload;

      state.selectStorage.storageId = storageId;
      state.selectStorage.storageNumber = storageNumber;
      state.selectStorage.subStorageId = subStorageId;
      state.selectStorage.subStorageNumber = subStorageNumber;
    },
    setEnterAmount: (state, action) => {
      state.enterAmount.amount = action.payload.amount;
      state.enterAmount.amountPerGram = action.payload.amountPerGram;
    },
    setTransferFee: (state, action) => {
      state.transferFee.fee = action.payload.fee;
      state.transferFee.discountedFee = action.payload.discountedFee;
      state.transferFee.discountPercentage = action.payload.discountPercentage;
      state.transferFee.zeroFee = action.payload.zeroFee;
    },
    setSelectRecipient: (state, action) => {
      const {
        recipient,
        selectedRecipient,
        contact,
        storage,
        storage_number,
        birthdate_owner,
        //save_recipient,
      } = action.payload;
      state.selectRecipient.recipient = recipient;
      state.selectRecipient.selectedRecipient = selectedRecipient;
      state.selectRecipient.contact = contact;
      state.selectRecipient.storage = storage;
      state.selectRecipient.storage_number = storage_number;
      state.selectRecipient.birthdate_owner = birthdate_owner;
      //state.selectRecipient.save_recipient = save_recipient;
    },
    resetSelectStorage: (state) => {
      state.selectStorage.storageId = null;
      state.selectStorage.storageNumber = "";
      state.selectStorage.subStorageId = null;
      state.selectStorage.subStorageNumber = "";
    },
    resetAll: () => initialState,
    resetActionStatus: (state) => {
      state.globalData.actionStatus = false;
      state.globalData.actionType = null;
    },
    resetError: (state) => {
      state.globalData.error = null;
    },
    resetSentSMS: (state) => {
      state.verificationSteps.sentSMS = false;
    },
    resetVerification: (state) => {
      state.verificationSteps.verification = false;
    },
  },
  extraReducers: {
    // get Storage List With Substorages Action
    [getStorageListWithSubstoragesAction.pending]: (state, action) => {
      state.globalData.loading = true;
    },
    [getStorageListWithSubstoragesAction.fulfilled]: (state, action) => {
      state.globalData.loading = false;
      state.globalData.storagesListWithSubstorages = action.payload;
      // state.globalData.actionType = "getStorageListwithSubstorages";
      // state.globalData.actionStatus = true;
    },
    [getStorageListWithSubstoragesAction.rejected]: (state, action) => {
      state.globalData.loading = false;
      state.globalData.error = action.error.message;
    },

    //Storages
    [getStorageListAction.pending]: (state, action) => {
      state.globalData.loading = true;
    },
    [getStorageListAction.fulfilled]: (state, action) => {
      state.globalData.loading = false;

      const allStorages = action.payload;
      if (!allStorages || allStorages.length == 0) return;
      const filteredStorages = allStorages.filter((x) => {
        return (
          !x.pending &&
          x._arades_edelmetall_typ_value ==
            state.transferSource.fromStorage._arades_edelmetall_typ_value &&
          (state.selectStorageType.storageType != "mainStorage" ||
            x.msdyn_warehouseid !=
              state.transferSource.fromStorage.msdyn_warehouseid)
        );
      });

      let list = [];
      filteredStorages.map((item) => {
        list.push({
          key: item.msdyn_warehouseid,
          value: item.msdyn_name,
        });
      });
      state.globalData.storages = list;
      state.globalData.actionType = "getStorageList";
      state.globalData.actionStatus = true;
    },
    [getStorageListAction.rejected]: (state, action) => {
      state.globalData.loading = false;
      state.globalData.error = action.error.message;
    },

    //subStorages
    [getSubStorageListAction.pending]: (state, action) => {
      state.globalData.loading = true;
    },
    [getSubStorageListAction.fulfilled]: (state, action) => {
      state.globalData.loading = false;

      const allSubStorages = action.payload;

      let list = [];

      if (allSubStorages && allSubStorages.length > 0) {
        const filteredSubStorages = allSubStorages.filter(
          (x) =>
            !x.pending &&
            x.msdyn_warehouseid !=
              state.transferSource.fromStorage.msdyn_warehouseid
        );
        filteredSubStorages.map((storage) => {
          list.push({
            key: storage.msdyn_warehouseid,
            value:
              storage.msdyn_name +
              " (" +
              (storage.msdyn_description
                ? storage.msdyn_description
                : "No description") +
              ") ",
          });
        });
      }
      state.globalData.subStorages = list;
      state.globalData.actionType = "getSubStorageList";
      state.globalData.actionStatus = true;
    },
    [getSubStorageListAction.rejected]: (state, action) => {
      state.globalData.loading = false;
      state.globalData.error = action.error.message;
    },

    //Storage max amount
    [getStorageAmountAction.pending]: (state, action) => {
      state.globalData.loading = true;
    },
    [getStorageAmountAction.fulfilled]: (state, action) => {
      state.globalData.loading = false;
      state.enterAmount.maxAmountPerGram =
        action.payload.arades_verfuegbare_menge;
      state.globalData.actionType = "getStorageAmount";
      state.globalData.actionStatus = true;
    },
    [getStorageAmountAction.rejected]: (state, action) => {
      state.globalData.loading = false;
      state.globalData.error = action.error.message;
      state.globalData.actionStatus = false;
    },

    //Storage by number and birthDate
    [getStorageByNumberBirthAction.pending]: (state, action) => {
      state.globalData.loading = true;
    },
    [getStorageByNumberBirthAction.fulfilled]: (state, action) => {
      state.globalData.loading = false;
      state.selectRecipient.storage = action.payload.storage;
      state.selectRecipient.contact = action.payload.contact;
      state.globalData.actionType = "getStorageByNumberBirth";
      state.globalData.actionStatus = true;
    },
    [getStorageByNumberBirthAction.rejected]: (state, action) => {
      state.globalData.loading = false;
      state.globalData.error = action.error.message;
    },

    //ActionCode Data
    [getActionCodeCustomerDataAction.pending]: (state, action) => {
      state.globalData.loading = true;
    },
    [getActionCodeCustomerDataAction.fulfilled]: (state, action) => {
      state.globalData.loading = false;
      state.globalData.actionCodeData = {
        arades_prozent:
          action.payload.length > 0 ? action.payload[0].arades_prozent : 0,
        actionCodeId: action.payload.length > 0 ? action.payload[0].id : null,
        actionCodeUserId:
          action.payload.length > 0 ? action.payload[0].actionCodeUserId : null,
      };
    },
    [getActionCodeCustomerDataAction.rejected]: (state, action) => {
      state.globalData.loading = false;
      state.globalData.error = action.error.message;
    },

    // post transfer
    [postTransferAction.pending]: (state, action) => {
      state.globalData.loading = true;
    },
    [postTransferAction.fulfilled]: (state, action) => {
      state.globalData.loading = false;
      state.globalData.actionStatus = true;
      state.globalData.actionType = "postTransfer";
    },
    [postTransferAction.rejected]: (state, action) => {
      state.globalData.loading = false;
      state.globalData.error = action.error.message;
      state.globalData.actionType = null;
    },

    // get recipient list
    [getTransferedStorageListAction.pending]: (state, action) => {
      state.globalData.loading = true;
    },
    [getTransferedStorageListAction.fulfilled]: (state, action) => {
      state.globalData.loading = false;
      state.selectRecipient.recipientList = action.payload;
      state.globalData.actionType = "getTransferedStorageList";
      state.globalData.actionStatus = true;
    },
    [getTransferedStorageListAction.rejected]: (state, action) => {
      state.globalData.loading = false;
      state.globalData.error = action.error.message;
    },

    //get owner
    [getOwnerByStorageIdAction.pending]: (state, action) => {
      state.globalData.loading = true;
    },
    [getOwnerByStorageIdAction.fulfilled]: (state, action) => {
      state.globalData.loading = false;
      state.selectRecipient.owner = action.payload.firstname;
    },
    [getOwnerByStorageIdAction.rejected]: (state, action) => {
      state.globalData.loading = false;
      state.globalData.error = action.error.message;
    },

    //Verifications send
    [verificationSendSMSCodeAction.pending]: (state, action) => {
      state.globalData.loading = true;
    },
    [verificationSendSMSCodeAction.fulfilled]: (state, action) => {
      state.globalData.loading = false;
      state.verificationSteps.sentSMS = true;
    },
    [verificationSendSMSCodeAction.rejected]: (state, action) => {
      state.globalData.loading = false;
      state.globalData.error = action.error.message;
      state.verificationSteps.sentSMS = false;
    },

    // Verifications Check
    [verificationCheckSMSCodeAction.pending]: (state, action) => {
      state.globalData.loading = true;
    },
    [verificationCheckSMSCodeAction.fulfilled]: (state, action) => {
      state.globalData.loading = false;
      const { data } = action.payload;

      if (data.status === 200 || data.status === 201) {
        state.globalData.actionStatus = true;
        state.verificationSteps.verification = true;
        state.globalData.actionType = "verification";
      } else if (Object.keys(data?.data?.error).length > 0) {
        state.globalData.actionStatus = false;
        state.verificationSteps.verification = false;
        state.globalData.actionType = null;
        state.globalData.error = data.data.error.message;
      }
    },
    [verificationCheckSMSCodeAction.rejected]: (state, action) => {
      state.globalData.loading = false;
      state.verificationSteps.verification = false;
      state.globalData.error = action.error.message;
      state.globalData.actionStatus = false;
      state.globalData.actionType = null;
    },
    // get other person phone onlyNumber
    [getPartnerDataAction.pending]: (state, action) => {
      state.globalData.loading = true;
    },
    [getPartnerDataAction.fulfilled]: (state, action) => {
      state.globalData.loading = false;
      state.verificationSteps.partnerPhoneNumber = action.payload.mobilephone;
    },
    [getPartnerDataAction.rejected]: (state, action) => {
      state.globalData.loading = false;
      state.globalData.error = action.error.message;
      state.verificationSteps.partnerPhoneNumber = null;
    },
    // get Source Storage And SubStorages Action

    [getFormattedStorageAndSubStoragesAction.pending]: (state, action) => {
      state.globalData.loading = true;
    },
    [getFormattedStorageAndSubStoragesAction.fulfilled]: (state, action) => {
      state.globalData.loading = false;
      state.transferSource.options = action.payload;
    },
    [getFormattedStorageAndSubStoragesAction.rejected]: (state, action) => {
      state.globalData.loading = false;
      state.globalData.error = action.error.message;
    },
  },
});

export const transferStorages = (state) => state.transfer.globalData.storages;
export const transferSubStorages = (state) =>
  state.transfer.globalData.subStorages;
export const transferFromStorage = (state) =>
  state.transfer.transferSource.fromStorage;

export const transferGlobalState = (state) => state.transfer.globalData;

export const transferFullState = (state) => state.transfer;
export const transferSelectReceptorTypeFields = (state) =>
  state.transfer.selectReceptorType;
export const transferSelectStorageTypeFields = (state) =>
  state.transfer.selectStorageType;
export const transferSelectStorageFields = (state) =>
  state.transfer.selectStorage;
export const transferEnterAmountFields = (state) => state.transfer.enterAmount;
export const transferSelectRecipientFields = (state) =>
  state.transfer.selectRecipient;
export const transferSelectWhoPaysFeeFields = (state) =>
  state.transfer.selectWhoPaysFee;
export const transferSummeryFields = (state) => state.transfer.summery;
export const transferVerificationSteps = (state) =>
  state.transfer.verificationSteps;
export const transferSource = (state) => state.transfer.transferSource;
export const storagesListWithSubstorages = (state) =>
  state.transfer.globalData.storagesListWithSubstorages;
export const transferFee = (state) => state.transfer.transferFee;

export const {
  setSelectedSource,
  setSelectReceptorType,
  setSelectStorageType,
  setTransferFee,
  setSelectStorage,
  setSelectWhoPaysFee,
  setEnterAmount,
  resetActionStatus,
  setSelectRecipient,
  resetError,
  resetAll,
  resetSentSMS,
  resetVerification,
  resetSelectStorage,
} = transferSlice.actions;
export default transferSlice.reducer;
