import React, { useEffect, useState } from "react";
import { Button, Grid, Box, Divider, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import {
  postTransferAction,
  getPartnerDataAction,
  verificationSendSMSCodeAction,
} from "../../../redux/slices/transfer";
import {
  transferFromStorage,
  resetActionStatus,
  transferSelectReceptorTypeFields,
  transferFee,
  transferVerificationSteps,
  resetError,
  resetAll,
  transferFullState,
} from "../../../redux/slices/transfer/transfer.slice";

import { URL, ERROR_CONTACT_US } from "../../../core/constants";
import { WORKSPACE_NAME } from "../../../core/apiconfig/constantApi";
import { useTranslation } from "react-i18next";
import {
  ConfirmModal,
  LoadingOverlay,
  FormSteperStructure,
  DiscountAmount,
} from "../../common";
import tickIcon from "../../../assets/images/tick.svg";
import { GermanFloat } from "../../../core/utils/convert";
import { globalsRatiorData } from "../../../redux/slices/globals/globals.slice";

function Summary({ handleCurrentStep }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const ratiorData = useSelector(globalsRatiorData);
  const fromStorage = useSelector(transferFromStorage);
  const transferVerificationData = useSelector(transferVerificationSteps);
  const transferFeeData = useSelector(transferFee);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const transferFullData = useSelector(transferFullState);

  const feeUnit = t("transfer.gram");

  useEffect(() => {
    if (transferVerificationData.needOtherPersonVerification) {
      dispatch(getPartnerDataAction(fromStorage.msdyn_warehouseid));
    }
  }, [transferVerificationData.needOtherPersonVerification]);

  useEffect(() => {
    if (
      transferFullData.globalData.actionStatus &&
      transferFullData.globalData.actionType === "verification"
    ) {
      enqueueSnackbar(t("messages.verification-successful"), {
        variant: "success",
        autoHideDuration: 3000,
      });

      dispatch(resetActionStatus());
      dispatch(postTransferAction());
      setLoading(true);
    }
    if (
      transferFullData.globalData.actionStatus &&
      transferFullData.globalData.actionType === "postTransfer"
    ) {
      enqueueSnackbar("Transfer erfolgreich", {
        variant: "success",
        autoHideDuration: 3000,
      });
      setConfirmOpen(true);
      dispatch(resetActionStatus());
      setLoading(false);
    }
  }, [transferFullData.globalData.actionStatus]);

  useEffect(() => {
    if (transferFullData.globalData.error != null) {
      enqueueSnackbar(transferFullData.globalData.error + ERROR_CONTACT_US, {
        variant: "error",
        autoHideDuration: 20000,
      });
      setLoading(false);
      dispatch(resetError());
    }
  }, [transferFullData.globalData.error]);

  const onSubmit = (data) => {
    if (transferVerificationData.needOtherPersonVerification) {
      sendSMS(transferVerificationData.partnerPhoneNumber);
      handleCurrentStep("next");
    } else {
      dispatch(postTransferAction());
      setLoading(true);
    }
  };

  const sendSMS = (to) => {
    dispatch(
      verificationSendSMSCodeAction({
        mobileNumber: to,
      })
    );
  };

  const boxStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  };
  const headrStyle = {
    color: "rgba(0, 0, 0, 0.38)",
    fontSize: 14,
  };
  const infoStyle = {
    color: "rgba(0, 0, 0, 0.87);",
    fontSize: 16,
  };

  return (
    <LoadingOverlay show={loading}>
      <ConfirmModal
        open={confirmOpen}
        imageSrc={tickIcon}
        title={t("transfer.request-sent")}
        showCloseButton={false}
        message={
          <Box sx={{ margin: "0 auto" }}>
            {t("transfer.request-modal-content")}
          </Box>
        }
        okTitle={t("transfer.go-to-storage")}
        onOk={() => {
          dispatch(resetAll());
          navigate(URL.STORAGE + "/" + fromStorage.msdyn_warehouseid);
        }}
      ></ConfirmModal>
      <Box>
        <Grid container spacing={8}>
          <Grid xs={12} item>
            <Divider
              sx={{
                marginTop: "20px",
                color: "rgba(0, 0, 0, 0.38)",
                "&::before": {
                  width: 0,
                },
              }}
              textAlign="left"
            >
              {t("transfer.from")}
            </Divider>
          </Grid>
          <Grid xs={12} item>
            <Box sx={{ ...boxStyle }}>
              <Typography sx={{ ...headrStyle }}>
                {t("transfer.storage-number")}
              </Typography>
              <Typography sx={{ ...infoStyle }}>
                {fromStorage.msdyn_name}
              </Typography>
            </Box>
          </Grid>
          <Grid xs={12} item>
            <Box sx={{ ...boxStyle }}>
              <Typography sx={{ ...headrStyle }}>
                {" "}
                {t("transfer.storage-owner")}
              </Typography>
              <Typography sx={{ ...infoStyle }}>
                {localStorage.getItem(WORKSPACE_NAME)}
              </Typography>
            </Box>
          </Grid>
          <Grid xs={12} item>
            <Divider
              sx={{
                marginTop: "20px",
                color: "rgba(0, 0, 0, 0.38)",
                "&::before": {
                  width: 0,
                },
              }}
              textAlign="left"
            >
              {t("transfer.to")}
            </Divider>
          </Grid>
          <Grid xs={12} item>
            <Box sx={{ ...boxStyle }}>
              <Typography sx={{ ...headrStyle }}>
                {" "}
                {t("transfer.storage-number")}
              </Typography>
              <Typography sx={{ ...infoStyle }}>
                {transferFullData.selectStorage.storageNumber}
              </Typography>
            </Box>
          </Grid>
          {transferFullData.selectStorage.subStorageId && (
            <Grid xs={12} item>
              <Box sx={{ ...boxStyle }}>
                <Typography sx={{ ...headrStyle }}>
                  {t("transfer.substorage-number")}
                </Typography>
                <Typography sx={{ ...infoStyle }}>
                  {transferFullData.selectStorage.subStorageNumber}
                </Typography>
              </Box>
            </Grid>
          )}

          <Grid sx={{ mt: "30px" }} xs={12} item>
            <Box sx={{ ...boxStyle }}>
              <Typography sx={{ ...headrStyle }}>
                {t("transfer.total-amount")}
              </Typography>
              <Typography sx={{ ...infoStyle }}>
                {GermanFloat(transferFullData.enterAmount.amountPerGram * 1)}
                &nbsp; {feeUnit}
              </Typography>
            </Box>
          </Grid>
          <Grid xs={12} item>
            <Box sx={{ ...boxStyle }}>
              <Typography sx={{ ...headrStyle }}>
                {t("transfer.transfer-fee")}{" "}
              </Typography>
              {/* <Typography sx={{ ...infoStyle }}>
                {GermanFloat(fee)} &nbsp; {feeUnit}
              </Typography> */}
              <DiscountAmount
                price={GermanFloat(transferFeeData.fee) + " " + feeUnit}
                discount={
                  GermanFloat(transferFeeData.discountedFee) + " " + feeUnit
                }
                discountPercentage={transferFeeData.discountPercentage}
              />{" "}
            </Box>
          </Grid>
          <Grid xs={12} item>
            <Box sx={{ ...boxStyle }}>
              <Typography sx={{ ...headrStyle }}>
                {t("transfer.transfer-amount")}
              </Typography>
              <Typography sx={{ ...infoStyle }}>
                {GermanFloat(
                  transferFullData.enterAmount.amountPerGram * 1 -
                    transferFeeData.discountedFee
                )}
                &nbsp; {feeUnit}
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <FormSteperStructure.Footer>
          <Button
            onClick={onSubmit}
            fullWidth
            sx={{ backgroundColor: "info.main", maxWidth: "192px" }}
            variant="contained"
          >
            {t("buttons.next")}
          </Button>
        </FormSteperStructure.Footer>
      </Box>
    </LoadingOverlay>
  );
}

export default Summary;
