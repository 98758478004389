import axios from "axios";
import { BASE_URL } from "../../../core/apiconfig/environment";
import {
  WORKSPACETYPE,
  STORAGE_PERSON_TYPE,
  STORAGE_STATUS,
} from "../../../core/constants";
import {
  WORKSPACE_TYPE,
  TOKEN_NAME,
} from "../../../core/apiconfig/constantApi";
import { storageIsPending } from "../../../core/utils/business";

const getStorageListFunc = async (ownerId, { getState }) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${localStorage.getItem(TOKEN_NAME)}`,
      },
    };

    const workspaceType = localStorage.getItem(WORKSPACE_TYPE);
    let typeSelection = STORAGE_PERSON_TYPE.OWNER;

    if (workspaceType === WORKSPACETYPE.UNDER_AGE) {
      typeSelection = STORAGE_PERSON_TYPE.UNDER_AGE;
    }
    typeSelection += `,${STORAGE_PERSON_TYPE.OTHER_PERSON}`;
    
    let result;
    if (workspaceType === WORKSPACETYPE.CONTACT || workspaceType === WORKSPACETYPE.UNDER_AGE) { // Contact workspace (person)?
      result = await axios.get(`${BASE_URL}data/warehouses/contact/${ownerId}/${typeSelection}/`, config);
    } else { // Account workspace (company)?
      result = await axios.get(`${BASE_URL}data/warehouses/account/${ownerId}/${typeSelection}/`, config);
    }
    const { data: { data } } = result;


    let list = [];

    data.value.map((item) => {
      let storage = item.arades_lagerplatz;
      if (storage) {
        storage.pending = storageIsPending(storage);
        storage.metalName =
          storage[
            "_arades_edelmetall_typ_value@OData.Community.Display.V1.FormattedValue"
          ];
        storage.statusText =
          storage.statuscode == STORAGE_STATUS.PENDING_FOR_CONTRACT
            ? "Warten auf Unterschrift"
            : storage.statuscode == STORAGE_STATUS.PENDING_FOR_PAYMENT
            ? "Warten auf Einrichtungsgebühr"
            : storage.statuscode == STORAGE_STATUS.PENDING_FOR_14_DAYS
            ? "Warten auf Widerruf"
            : storage.statuscode == STORAGE_STATUS.ACTIVE
            ? "Vollständig eingerichtet"
            : "";
        list.push(storage);
      }
    });
    return list;
  } catch (err) {
    throw Error(
      err.response && err.response.data.detail
        ? err.response.data.detail
        : err.message
    );
  }
};
export default getStorageListFunc;
