import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import "./addressShow.scss";

const AddressShow = ({
  title,
  country,
  address,
  index,
  onEdit,
  onDelete,
  readonly,
}) => {
  console.log(address);
  return (
    <div className="divAddress">
      <div className="d-flex ">
        <div className="d-flex flex-column col-10">
          <p className="h-title">{title}</p>
          <div>
            {(address.line1 ? address.line1 + "," : "") +
              (address.line2 ? address.line2 + "," : "") +
              (address.postbox ? address.postbox + "," : "") +
              address.postalcode +
              "," +
              address.city +
              (address.countryName ? "," + address.countryName : "")}
          </div>
        </div>
        {!readonly && (
          <div className="col-2 d-flex justify-content-end align-items-center">
            <span className="icon" onClick={() => onEdit(index)}>
              <FontAwesomeIcon icon={faEdit} />
            </span>
            <span className="icon" onClick={() => onDelete(index)}>
              <FontAwesomeIcon icon={faTimesCircle} />
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

export default AddressShow;
