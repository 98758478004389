import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../../../core/apiconfig/environment";
import { CONTACT_ID, TOKEN_NAME } from "../../../core/apiconfig/constantApi";
import { longDateTo10 } from "../../../core/utils/convert";
import { CONTACT_TYPE } from "../../../core/constants";

const postUnderAgeInfoAction = createAsyncThunk(
  "underage/postUnderAgeInfo",
  async (dataParams, { getState }) => {
    const childDataStepFields = getState().underage.childDataStep;
    const legalGuardianStatusFields =
      getState().underage.legalGuardianStatusStep;

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${localStorage.getItem(TOKEN_NAME)}`,
      },
    };

    let saveObj = { ...childDataStepFields };

    // Check if email is already in use
    if (saveObj.emailaddress1) {
      const emailAvailableResult = await axios.get(`${BASE_URL}data/contacts/is-email-available/${encodeURIComponent(saveObj.emailaddress1)}`, config);
      if (!emailAvailableResult.data.available) { // Email already in use?
        throw Error("Diese E-Mail-Adresse wird bereits verwendet"); //("email already in use");
      }
    }

    // Find contact ID of partner by Ratior Key
    let partnerId = null;
    if (legalGuardianStatusFields.legalStatus === "partner") {
      let result = await axios.get(`${BASE_URL}data/contacts/id-by-ratior-key/${legalGuardianStatusFields.partnerKey}`, config);
      if (result.status == 200 && result?.data?.contactid) {
        partnerId = result.data.contactid;
        saveObj["arades_erziehungsberechtigter2@odata.bind"] = "/contacts(" + partnerId + ")";
      } else throw Error("Ratior Key wurde nicht gefunden"); //("Ratior Key not found");
    }

    delete saveObj.emailRepeat;
    delete saveObj.arades_land;

    saveObj.arades_kontakttyp = CONTACT_TYPE.UNDER_AGE;
    saveObj.birthdate = longDateTo10(new Date(childDataStepFields.birthdate));

    if (childDataStepFields.arades_land) {
      saveObj["arades_land1@odata.bind"] = "/arades_lands(" + childDataStepFields.arades_land + ")";
    } else { saveObj["arades_land1@odata.bind"] = null; }

    // Clean up object
    delete saveObj.arades_land;
    delete saveObj.emailRepeat;

    let result = null;
    if (!saveObj.contactid) {
      delete saveObj.contactid;
      result = await axios.post(`${BASE_URL}data/contacts/${localStorage.getItem(CONTACT_ID)}/children/`, saveObj, config);
    } else
      result = await axios.patch(`${BASE_URL}data/contacts/${saveObj.contactid}/`, saveObj, config);

    if (result?.data?.data) {
      return result.data.data;
    } else throw Error("Kontaktdaten könnten nicht gespeichert werden"); //("Child contact not saved");
  }
);

export default postUnderAgeInfoAction;
