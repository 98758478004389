import React, { useState, useEffect } from "react";
import { LoadingOverlay, Verification } from "../../common";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { CONTACT_ID, MOBILE_NUMBER } from "../../../core/apiconfig/constantApi";
import {
  verificationCheckSMSCodeAction,
  verificationSendSMSCodeAction,
  changePhoneNumberAction,
} from "../../../redux/slices/profile";
import { useSnackbar } from "notistack";
import {
  resetVerification,
  resetError,
  resetActionStatus,
  resetSentSMS,
  phoneNumberStep,
  verificationData,
  globalData,
  resetAll,
} from "../../../redux/slices/profile/profile.slice";
import { URL, ERROR_CONTACT_US } from "../../../core/constants";
import { useTranslation } from "react-i18next";

function VerificationNewNumberStep({ handleCurrentStep }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const profileGlobalData = useSelector(globalData);
  const profileVerificationData = useSelector(verificationData);
  const profilePhoneNumberStep = useSelector(phoneNumberStep);

  const [activationCode, setActivationCode] = useState();
  const [loading, setLoading] = useState(false);

  const onSubmit = () => {
    if (!profileVerificationData.verification) {
      dispatch(
        verificationCheckSMSCodeAction({
          activationCode: activationCode,
          mobileNumber: profilePhoneNumberStep?.phoneNumber,
        })
      );
    }
  };

  useEffect(() => {
    if (
      profileGlobalData.actionStatus &&
      profileGlobalData.actionType === "verification"
    ) {
      enqueueSnackbar(t("messages.verification-successful"), {
        variant: "success",
        autoHideDuration: 3000,
      });

      dispatch(resetActionStatus());
      dispatch(resetSentSMS());
      dispatch(resetVerification());
      dispatch(
        changePhoneNumberAction({
          mobileNumber: profilePhoneNumberStep.phoneNumber,
          contactID: localStorage.getItem(CONTACT_ID),
        })
      );
    }

    if (
      profileGlobalData.actionStatus &&
      profileGlobalData.actionType === "changePhoneNumber"
    ) {
      enqueueSnackbar(t("components.change-phone.success"), {
        variant: "success",
        autoHideDuration: 3000,
      });
      localStorage.setItem(MOBILE_NUMBER, profilePhoneNumberStep.phoneNumber);
      dispatch(resetAll());
      navigate(URL.PROFILE);
    }
  }, [profileGlobalData.actionStatus]);

  useEffect(() => {
    if (profileGlobalData.error != null) {
      enqueueSnackbar(profileGlobalData.error + ERROR_CONTACT_US, {
        variant: "error",
        autoHideDuration: 20000,
      });
      dispatch(resetError());
      setLoading(false);
    }
  }, [profileGlobalData.error]);

  const sendSMS = () => {
    const currentAccountPhoneNumber = profilePhoneNumberStep.phoneNumber;
    dispatch(
      verificationSendSMSCodeAction({
        mobileNumber: currentAccountPhoneNumber,
      })
    );
  };

  const resendCode = () => {
    sendSMS();
  };

  return (
    <>
      <LoadingOverlay show={loading}>
        <Verification
          number={profilePhoneNumberStep?.phoneNumber}
          activationCode={activationCode}
          setActivationCode={setActivationCode}
          handleNext={onSubmit}
          resendCode={resendCode}
        />
      </LoadingOverlay>
    </>
  );
}

export default VerificationNewNumberStep;
