import React from "react";
import { RegisterStateProvider } from "./register/RegisterState";
import { LoginStateProvider } from "./login/LoginState";
import { RegisterCompanyStateProvider } from "./registerCompany/RegisterCompanyState";

const AppStateProvider = (props) => {
  return (
    <RegisterCompanyStateProvider>
      <LoginStateProvider>
        <RegisterStateProvider>{props.children}</RegisterStateProvider>
      </LoginStateProvider>
    </RegisterCompanyStateProvider>
  );
};

export default AppStateProvider;
