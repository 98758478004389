import axios from "axios";
import { BASE_URL } from "../../../core/apiconfig/environment";
import {
  CONTACT_ID,
  WORKSPACE_ID,
  WORKSPACE_TYPE,
  TOKEN_NAME,
} from "../../../core/apiconfig/constantApi";
import { WORKSPACETYPE } from "../../../core/constants";

//gets the storage partner or the guardian other than logged-in one
const getPartnerDataFunc = async (storageId, { getState }) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${localStorage.getItem(TOKEN_NAME)}`,
      },
    };

    let filter = { storageId: storageId };

    if (localStorage.getItem(WORKSPACE_TYPE) == WORKSPACETYPE.UNDER_AGE)
      filter.ownerId = localStorage.getItem(CONTACT_ID);
    else filter.ownerId = localStorage.getItem(WORKSPACE_ID);

    const storagePersonResult = await axios.get(`${BASE_URL}data/contacts/${filter.ownerId}/warehouse/${filter.storageId}/co-owner/`, config);
    if (storagePersonResult?.data?.data?.value?.length == 0)
      throw Error("Partner nicht gefunden"); //("Partner not found");

    let partner = storagePersonResult.data.data.value.filter(
      (x) => x.arades_besitzer_contact
    )[0].arades_besitzer_contact;

    return partner;
  } catch (err) {
    throw Error(
      err.response && err.response.data.detail
        ? err.response.data.detail
        : err.message
    );
  }
};

export default getPartnerDataFunc;
