import React, { useState, useEffect } from "react";
import { Box, Button, Typography } from "@mui/material";

function TimerActiveButton({
  time = 60,
  show = true,
  handleClick,
  buttonText = "Resend code",
}) {
  const [start, setStart] = useState(false);
  const [countdown, setCountdown] = useState(time);
  const [activeStatus, setActiveStatus] = useState(false);

  const format = (time) => {
    let seconds = time % 60;
    let minutes = Math.floor(time / 60);
    minutes = minutes.toString().length === 1 ? "0" + minutes : minutes;
    seconds = seconds.toString().length === 1 ? "0" + seconds : seconds;
    return minutes + ":" + seconds;
  };
  useEffect(() => {
    setStart(true);
  }, []);
  useEffect(() => {
    if (start) {
      if (countdown !== 0) {
        if (activeStatus) {
          setActiveStatus(false);
        }
        const timer = setInterval(() => {
          setCountdown(countdown - 1);
        }, 1000);
        return () => clearInterval(timer);
      }
      if (countdown === 0) {
        setActiveStatus(true);
        setCountdown(time);
        setStart(false);
      }
    }
  }, [countdown, start]);

  const handleButtonClick = () => {
    handleClick();
    setStart(true);
  };

  return (show &&
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        mt: "26px",
        mb: "50px",
      }}
    >
      <Button
        disabled={!activeStatus}
        onClick={handleButtonClick}
        variant="contained"
      >
        {buttonText + " " + format(countdown)}
      </Button>
    </Box>
  );
}

export default TimerActiveButton;
