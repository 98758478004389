import React, { useState } from "react";
import { useApi } from "../../../core/hooks/useApi";
import { BASE_URL } from "../../../core/apiconfig/environment";
import { MobileNumberHide } from "../../../core/utils/convert";
import { useSnackbar } from "notistack";
import Button from "../../common/buttons/button";
import { useNavigate } from "react-router-dom";
import InputCode from "../common/inputCode";
import {
  TOKEN_NAME,
  RATIOR_KEY,
  MOBILE_NUMBER,
} from "../../../core/apiconfig/constantApi";
import { useLoginState } from "../../../context/login/LoginState";
import "./twoFactor.scss";
import { Box } from "@mui/material";
import LoadingOverlay from "../../common/LoadingOverlay/LoadingOverlay";

const TwoFactor = () => {
  const { post, get } = useApi();
  const navigate = useNavigate();
  const { loginInfoAction, loginInfo } = useLoginState();
  const { enqueueSnackbar } = useSnackbar();

  const [activationCode, setActivationCode] = useState();
  const [timeEnded, setTimeEnded] = useState(false);
  const [counter, setCounter] = React.useState(60);
  const [counterText, setCounterText] = React.useState("01:00");
  const [loading, setLoading] = useState(false);

  React.useEffect(() => {
    if (counter == 0) {
      setTimeEnded(true);
    } else {
      setTimeEnded(false);

      const timerID = setInterval(() => {
        setCounter(counter - 1);
        let ctxt = (counter - 1).toString();
        if (counter - 1 < 10) ctxt = "0" + (counter - 1).toString();
        setCounterText("00:" + ctxt);
      }, 1000);
      return () => clearInterval(timerID);
    }
  }, [counter]);

  const handleSubmit = async () => {
    setLoading(true);
    const result = await post(`${BASE_URL}auth/2FA`, { code: activationCode });
    setLoading(false);
    if (result && result.status && result.status != 200 && result.status != 201)
      enqueueSnackbar(result.statusText, {
        variant: "error",
        autoHideDuration: 20000,
      });
    else {
      localStorage.setItem(TOKEN_NAME, result.data.token);
      navigate("/");
      window.location.reload();
    }
  };

  const sendCode = async () => {
    const result = await get(`${BASE_URL}auth/2FA`);
    if (result.status == 200) {
      enqueueSnackbar("Code gesendet", {
        variant: "success",
        autoHideDuration: 3000,
      });
      setActivationCode("");
      setCounter(60);
      setCounterText("01:00");
    }
  };

  return (
    <LoadingOverlay show={loading}>
      <div className="twofactor-box-wrapper">
        <span className="twofactor-title">{"ANMELDUNG"}</span>
        <span className="step">Zwei-Faktor-Authentifizierung</span>
        <div className="twofactor-box">
          <div className="d-flex justify-content-center">
            <span className="sent-description">
              Wir haben Ihnen einen Code an Ihre Telefonnummer
              <br />
              <b>{MobileNumberHide(localStorage.getItem(MOBILE_NUMBER))}</b>.
              Bitte geben Sie den Code ein:
            </span>
          </div>
          <div className="d-flex justify-content-center">
            <InputCode
              length={4}
              label="Code Label"
              loading={loading}
              clearMe={!activationCode}
              onComplete={(code) => {
                //setLoading(true);
                //setTimeout(() => setLoading(false), 10000);
                setActivationCode(code);
              }}
            />
          </div>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginTop: "26px",
            }}
          >
            <div
              className={`resend-code-btn ${timeEnded ? "enabled" : ""}`}
              disabled={!timeEnded}
              onClick={() => {
                if (timeEnded) sendCode();
              }}
            >
              <span>{"Code erneut senden"}</span>
            </div>
            <div className={`counter`}>
              <span>{counterText}</span>
            </div>
          </Box>
          <div className="padding-30">
            <Button
              disabled={activationCode && activationCode.length < 4}
              className={"form-item"}
              onClick={handleSubmit}
            >
              {"Verifizieren"}
            </Button>
          </div>
          <div className="d-flex justify-content-center">
            <span className="down-description">
              Wenn Sie keinen Zugang zu Ihrem Telefon haben, wenden Sie sich
              bitte an Kunden@Ratior.de, um Ihr Konto wiederherzustellen
            </span>
          </div>
          <hr className="margin-10"></hr>
          <div className="anotherLogin">
            <span
              onClick={() => {
                loginInfoAction({
                  ...loginInfo,
                  ["step"]: 1,
                  [TOKEN_NAME]: null,
                  [RATIOR_KEY]: null,
                });
              }}
            >
              {"Melden Sie sich mit einem anderen Konto an"}
            </span>
          </div>
        </div>
      </div>
    </LoadingOverlay>
  );
};

export default TwoFactor;
