import { REGISTER_COMPANY_INFO_TYPE } from "../type";

const registerCompanyReducer = (state, action) => {
  switch (action.type) {
    case REGISTER_COMPANY_INFO_TYPE:
      return {
        ...state,
        registerCompanyInfo: action.payload,
      };

    default:
      return state;
  }
};

export default registerCompanyReducer;
