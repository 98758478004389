import { createAsyncThunk } from "@reduxjs/toolkit";

const getCommissionMonthlyAction = createAsyncThunk(
  "vermittlerDashboard/getCommissionMonthly",
  async (dataParams, { getState }) => {
      return [
        {month:"March",amount:50},
        {month:"April",amount:30},
        {month:"May",amount:45},
        {month:"June",amount:20},
        {month:"July",amount:120},
      ]
  }
);

export default getCommissionMonthlyAction;
