import {
  Box,
  Grid,
  FormControlLabel,
  Radio,
  Button,
  Typography,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { MUIRadioGroup } from "../../common";

import { FormSteperStructure, LoadingOverlay } from "../../common";
import { useForm } from "react-hook-form";
import { WORKSPACE_ID } from "../../../core/apiconfig/constantApi";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { getStorageListAction } from "../../../redux/slices/powerOfAttorney";
import { useDispatch, useSelector } from "react-redux";
import { ERROR_CONTACT_US } from "../../../core/constants";
import {
  chooseStorageStep,
  setStorage,
  storageList,
  globalData,
  resetError,
} from "../../../redux/slices/powerOfAttorney/powerOfAttorney.slice";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

function SelectStorageStep({ handleCurrentStep }) {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const requiredMsg = "validation.required";
  const fields = useSelector(chooseStorageStep);
  const storageListFields = useSelector(storageList);
  const globalFields = useSelector(globalData);

  useEffect(() => {
    setLoading(true);
    dispatch(getStorageListAction(localStorage.getItem(WORKSPACE_ID)));
  }, []);

  useEffect(() => {
    setLoading(globalFields.loading);
  }, [globalFields.loading]);

  useEffect(() => {
    if (globalFields.error != null) {
      enqueueSnackbar(globalFields.error + ERROR_CONTACT_US, {
        variant: "error",
        autoHideDuration: 20000,
      });
      setLoading(false);
      dispatch(resetError());
    }
  }, [globalFields.error]);

  const submitForm = (data) => {
    dispatch(setStorage(data));
    handleCurrentStep("next");
  };

  const schema = yup.object().shape({
    storage: yup.string().required(requiredMsg),
  });

  const {
    control,
    handleSubmit,
    setError,
    getValues,
    formState: { errors },
  } = useForm({
    mode: "all",
    defaultValues: {
      ...fields,
    },
    resolver: yupResolver(schema),
  });

  return (
    <LoadingOverlay show={loading}>
      <Box
        sx={{
          margin: "0 auto",
          minHeight: "calc(100vh - 250px)",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "space-around",
        }}
      >
        <Box sx={{ margin: "0 auto" }}>
          <Typography
            mb="20px"
            sx={{ color: "rgba(0, 0, 0, 0.38)" }}
            variant="subtitle2"
          >
            Which one do you want to grant a power of attorney for?
          </Typography>

          <Grid spacing={8} container direction="row">
            <Grid xs={12} lg={6} item>
              <MUIRadioGroup
                error={Boolean(errors.storage)}
                helperText={errors.storage?.message}
                control={control}
                name="storage"
              >
                {storageListFields.map(({ key, label }) => (
                  <FormControlLabel
                    sx={{ whiteSpace: "nowrap" }}
                    key={key}
                    value={key}
                    control={<Radio />}
                    label={label}
                  />
                ))}
              </MUIRadioGroup>
            </Grid>
          </Grid>
        </Box>
        <FormSteperStructure.Footer>
          <Button
            onClick={handleSubmit(submitForm)}
            fullWidth
            sx={{ backgroundColor: "info.main", maxWidth: "192px" }}
            variant="contained"
          >
            Weiter
            {/* Next */}
          </Button>
        </FormSteperStructure.Footer>
      </Box>
    </LoadingOverlay>
  );
}

export default SelectStorageStep;
