import {
    CONTACT_ID,
    FULLNAME,
    WORKSPACE_ID,
    WORKSPACE_NAME,
    WORKSPACE_TYPE,
    TMP_CONTACT_ID,
    TMP_FULLNAME,
    TMP_WORKSPACE_ID,
    TMP_WORKSPACE_NAME,
    TMP_WORKSPACE_TYPE,
    VERMITTLER_ID,
    TMP_VERMITTLER_ID,
  } from "../../core/apiconfig/constantApi";
  import { URL, WORKSPACETYPE } from "../../core/constants";

// View the dashboard from the customer's perspective
export default function switchToContact(id, name, vermittlerId, navigate) {
  console.log("switchToContact", id, name, vermittlerId, navigate);
    localStorage.setItem(TMP_CONTACT_ID, localStorage.getItem(CONTACT_ID));
    localStorage.setItem(TMP_WORKSPACE_ID, localStorage.getItem(WORKSPACE_ID));
    localStorage.setItem(TMP_VERMITTLER_ID, localStorage.getItem(VERMITTLER_ID));
    localStorage.setItem(
      TMP_WORKSPACE_TYPE,
      localStorage.getItem(WORKSPACE_TYPE)
    );
    localStorage.setItem(
      TMP_WORKSPACE_NAME,
      localStorage.getItem(WORKSPACE_NAME)
    );
    localStorage.setItem(TMP_FULLNAME, localStorage.getItem(FULLNAME));

    localStorage.setItem(CONTACT_ID, id);
    localStorage.setItem(WORKSPACE_ID, id);
    localStorage.setItem(WORKSPACE_TYPE, WORKSPACETYPE.CONTACT);
    localStorage.setItem(WORKSPACE_NAME, name);
    localStorage.setItem(FULLNAME, name);
    localStorage.setItem(VERMITTLER_ID, vermittlerId);

    navigate(URL.DASHBOARD);
    window.location.reload();
}