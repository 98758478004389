import React, { useState, useEffect } from "react";
import { useRegisterCompanyState } from "../../../context/registerCompany/RegisterCompanyState";
import RegisterCompany from "./registerCompany";
import Documents from "./documents";

const RegisterCompanyStepper = () => {
  const { registerCompanyInfoAction, registerCompanyInfo } =
    useRegisterCompanyState();

  useEffect(() => {
    if (!registerCompanyInfo) registerCompanyInfoAction({ step: 1 });
  }, []);

  return (
    <div>
      {(!registerCompanyInfo || registerCompanyInfo.step == 1) && (
        <RegisterCompany></RegisterCompany>
      )}
      {registerCompanyInfo && registerCompanyInfo.step == 2 && (
        <Documents></Documents>
      )}
    </div>
  );
};

export default RegisterCompanyStepper;
