import React, { useEffect, useState } from "react";
import "./passToolTipContent.scss";
import { passwordIsStrong } from "../../../core/validations";
import { useTranslation } from "react-i18next";

const PassToolTipContent = ({ password }) => {
  const { t } = useTranslation();
  const [passwordStrength, setPasswordStrength] = useState(null);
  useEffect(() => {
    setPasswordStrength(passwordIsStrong(password));
  }, [password]);

  return (
    <div
      class="password-tooltip"
      style={{
        marginTop: "10px",
        padding: "20px",
        backgroundColor: "#E3F2FD",
        borderRadius: "8px",
      }}
    >
      {t("registration.set-password.tooltip.preamble")}
      <ul class="password-checklist">
        <li className={passwordStrength?.length        ? "checked" : ""}>{t("registration.set-password.tooltip.length")}</li>
        <li className={passwordStrength?.caps          ? "checked" : ""}>{t("registration.set-password.tooltip.caps")}</li>
        <li className={passwordStrength?.number        ? "checked" : ""}>{t("registration.set-password.tooltip.number")}</li>
        <li className={passwordStrength?.specialLetter ? "checked" : ""}>{t("registration.set-password.tooltip.special")}</li>
      </ul>
    </div>
  );
};

export default PassToolTipContent;
