import React from "react";
import SubmitModal from "../common/popups/submitModal";
import { Grid, Box, Typography, List, ListItem, Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import switchToContact from "./switchToContact";
import { useNavigate } from "react-router";

const LogInAsLegalGuardianModal = ({
  open,
  underageContact, // e.g. {"id": "afdf5277-5a37-ed11-9db1-000d3a8321bd", "type": "contact", "storages": [], "name": "Frau Elsa Marie Luitz", "ratiorKey": null, "statuscode": 100000000, "status": "customer-list.status-name.verified", "isUnderage": true, "guardians": [{"fullname": "Maria Mustermensch", "contactid": "93cb250c-a7fc-ec11-82e5-0022489e1490"}, {"fullname": "Max Mustermensch", "contactid": "99f6a483-5637-ed11-9db1-000d3a831ff7"}], "lagerplatz": [], "createDate": "2022-09-18T14:02:11Z", "verifiedStatus": 0, "vermittlerId": "2718905c-5df5-ec11-bb3d-0022489e0b48"}
  onClose,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <SubmitModal
      open={open}
      size="lg"
      okTitle={t("buttons.close")}
      onOk={() => onClose()}
      title={t("customer-list.log-in-as-underage-modal.title")}
      content={open &&
        <Box sx={{ minWidth: "300px" }}>
          <Grid container spacing={6} mt={"10px"} sx={{ width: "100%" }}>
            <Typography variant="subtitle2" mb={6}>
              {underageContact.name}&nbsp;
              {t("customer-list.log-in-as-underage-modal.log-in-as-guardian-message-1")} <br />
              <b style={{ fontFamily: "Arial" }}>{t("customer-list.log-in-as-underage-modal.log-in-as-guardian-message-2")}</b>{/* Use Arial because the default font does not support bold text */}
            </Typography>
            <List sx={{ width: "100%" }}>
              {underageContact.guardians.map((guardian) => {
                return (
                  <ListItem
                    key={guardian.contactid}
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      border: '1px solid #ddd',
                      borderRadius: '8px',
                      padding: '10px',
                      marginBottom: '10px',
                    }}
                  >
                    <Typography>{guardian.fullname}</Typography>
                    <Button
                      aria-label="login"
                      variant="contained"
                      onClick={() =>
                        switchToContact(
                          guardian.contactid,
                          guardian.fullname,
                          underageContact.vermittlerId,
                          navigate
                        )
                      }
                    >
                      {t("customer-list.log-in-as-customer")}
                    </Button>
                  </ListItem>
                );
              })}
            </List>
          </Grid>
        </Box>
      }
    ></SubmitModal>
  );
};

export default LogInAsLegalGuardianModal;
