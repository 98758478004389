import axios from "axios";
import { BASE_URL } from "../../../core/apiconfig/environment";
import { longDateTo10 } from "../../../core/utils/convert";
import { storageIsPending } from "../../../core/utils/business";
import { TOKEN_NAME } from "../../../core/apiconfig/constantApi";

const getStorageByNumberBirthFunc = async (filter, { getState }) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `bearer ${localStorage.getItem(TOKEN_NAME)}`,
    },
  };

  let storageAndContactResult;
  try {
    storageAndContactResult = await axios.get(`${BASE_URL}data/warehouses/basic/${filter.storageNumber}/${longDateTo10(new Date(filter.birthdate))}`, config);
  } catch (error) {
    throw Error("Diese Kombination aus Lagernummer und Geburtstag wurde nicht gefunden. " + error); //("Storage number not found");
  }
  if (!storageAndContactResult?.data) {
    throw Error("Diese Kombination aus Lagernummer und Geburtstag wurde nicht gefunden"); //("Storage number not found");
  }
  const { storage, contact } = storageAndContactResult.data;
  if (storageIsPending(storage)) {
    throw Error("Lagerplatz Status ist ausstehend und kann nicht werwendet werden"); //("This Storage status is pending , can not be used");
  }
  return { storage, contact };
};

export default getStorageByNumberBirthFunc;
