import React, { useState } from "react";
import { FormSteper } from "../../../../common";
import { useTranslation } from "react-i18next";
import BankAccountStep from "../../sharedSteps/bankAccount.step";
import StorageFeeStep from "../../storageFeeStep.step";
import StorageInformation from "../../sharedSteps/StorageInformation.step";
import Verification from "../../sharedSteps/Verification.step";
import SignStorageContract from "../../sharedSteps/signStorageContract.step";
import StorageCreated from "../../sharedSteps/StorageCreated.step";
//import StoragePaymentStep from "../../sharedSteps/storagePaymentStep.step";

import { useDispatch } from "react-redux";
import { resetAll } from "../../../../../redux/slices/storage/storage.slice";
import { useNavigate } from "react-router-dom";
import { URL } from "../../../../../core/constants";
import { WORKSPACE_TYPE } from "../../../../../core/apiconfig/constantApi";
import { WORKSPACETYPE } from "../../../../../core/constants";

function CompanyWith2FAStepManager({
  handleCurrentStep: handlerParentStep,
  parent,
}) {
  const isContact =
    localStorage.getItem(WORKSPACE_TYPE) === WORKSPACETYPE.CONTACT;
  const [loading, setLoading] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const stepsCount = 7;

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const HandleCurrentStep = (step, parent) => {
    if (parent) {
      handlerParentStep(parent);
    } else {
      if (isNaN(parseInt(step))) {
        if (step === "next" && currentStep < stepsCount) {
          setCurrentStep((prev) => prev + 1);
        } else if (step === "prev" && currentStep > 1) {
          setCurrentStep((prev) => prev - 1);
        }
      } else {
        if (currentStep <= stepsCount && currentStep >= 1) {
          setCurrentStep(step);
        }
      }
    }
  };

  const handleCancel = () => {
    dispatch(resetAll());
    navigate(URL.STORAGE);
  };

  return (
    <FormSteper
      parent={parent}
      currentStep={currentStep}
      setCurrentStep={HandleCurrentStep}
      loading={loading}
      hasCancel={[1, 2, 3, 4]}
      onCancel={handleCancel}
      noBackButton={[5, 6]}
      noFooter={[1, 2, 3, 4, 5, 6]}
      headerTitles={[
        t("add-storage.storage-fee"),
        <div>
          &nbsp;{t("add-storage.bank-account-1")} <br></br> &nbsp;
          <span style={{ color: "rgba(0, 0, 0, 0.87)", fontSize: 18 }}>
            {t("add-storage.bank-account-2")}
          </span>
        </div>,
        t("add-storage.wait-14"),
        t("add-storage.sign-contract"),
        "Zwei-Faktor-Authentifizierung",
        t("add-storage.storage-created"),
        //"Payment",
      ]}
    >
      {/* Step 1- PromotionCode */}
      <StorageFeeStep handleCurrentStep={HandleCurrentStep} />

      {/* Step 2- BankAccountStep */}
      <BankAccountStep handleCurrentStep={HandleCurrentStep} />

      {/* Step 3- StorageInformation */}
      <StorageInformation handleCurrentStep={HandleCurrentStep} />

      {/* Step 4- SignStorageContract */}
      <SignStorageContract handleCurrentStep={HandleCurrentStep} />

      {/* Step 5- Verification*/}
      <Verification handleCurrentStep={HandleCurrentStep} />

      {/* Step 6- Storage created */}
      <StorageCreated handleCurrentStep={HandleCurrentStep} />

      {/* Step 7- StoragePaymentStep */}
      {/* <StoragePaymentStep handleCurrentStep={HandleCurrentStep} /> */}
    </FormSteper>
  );
}

export default CompanyWith2FAStepManager;
