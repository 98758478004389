import React, { useState, useEffect } from "react";
import DataTable from "../../common/grids";
import { LoadingOverlay, ConfirmModal } from "../../common";
import { useSnackbar } from "notistack";
import {
  getTransactionDetailedListAction,
  cancelSellAction,
} from "../../../redux/slices/transactionList";
import {
  transactionDetailedList,
  globalData,
  resetError,
  resetActionStatus,
  resetAll,
} from "../../../redux/slices/transactionList/transactionList.slice";
import { ERROR_CONTACT_US, QUOTE_DIRECTION } from "../../../core/constants";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

const TransactionList = ({ storageId, metalName }) => {
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [openCancelModal, setOpenCancelModal] = useState(false);
  const [sellRowToCancel, setSellRowToCancel] = useState(null);
  const transactionListData = useSelector(transactionDetailedList);
  const transactionListGlobalData = useSelector(globalData);

  useEffect(() => {
    dispatch(resetAll());
    dispatch(
      getTransactionDetailedListAction({storageid: storageId,})
    );
  }, [storageId]);

  const cancelConfirm = (rowData) => {
    setOpenCancelModal(true);
    setSellRowToCancel(rowData);
  };

  const cancelSell = async () => {
    dispatch(
      cancelSellAction({
        quoteid: sellRowToCancel.quoteid,
      })
    );
  };

  useEffect(() => {
    if (
      transactionListGlobalData.actionStatus &&
      transactionListGlobalData.actionType === "getDetailedList"
    ) {
      dispatch(resetActionStatus());
    }
    if (
      transactionListGlobalData.actionStatus &&
      transactionListGlobalData.actionType === "cancelSell"
    ) {
      dispatch(resetActionStatus());
      dispatch(
        getTransactionDetailedListAction({
          storageid: storageId,
        })
      );
    }
  }, [transactionListGlobalData.actionStatus]);

  useEffect(() => {
    setLoading(transactionListGlobalData.loading);
  }, [transactionListGlobalData.loading]);

  useEffect(() => {
    if (transactionListGlobalData.error != null) {
      enqueueSnackbar(transactionListGlobalData.error + ERROR_CONTACT_US, {
        variant: "error",
        autoHideDuration: 20000,
      });
      dispatch(resetError());
      setLoading(false);
    }
  }, [transactionListGlobalData.error]);

  return (
    <LoadingOverlay show={loading}>
      <ConfirmModal
        noImage={true}
        open={openCancelModal}
        title={t("storage-detail.sell-cancel")}
        showCloseButton={false}
        message={<div> {t("storage-detail.sell-cancel-message")} </div>}
        acceptTitle={t("storage-detail.sell-cancel-yes")}
        onAccept={() => {
          setOpenCancelModal(false);
          cancelSell();
        }}
        rejectTitle={t("storage-detail.sell-cancel-no")}
        onReject={() => {
          setOpenCancelModal(false);
          setSellRowToCancel(null);
        }}
      ></ConfirmModal>
      <DataTable
        title={t("storage-detail.transactions")}
        entityName={"transactions"}
        canExport={true}
        data={transactionListData}
        showPageSizeSelector={true}
        customOperations={[
          {
            title: t("buttons.cancel"),
            onClick: cancelConfirm,
            showConditions: [
              { fieldName: "statecode", fieldValue: 0 },
              {
                fieldName: "arades_richtung",
                fieldValue: QUOTE_DIRECTION.SELL,
              },
            ],
          },
        ]}
        columns={[
          { name: "createdon_formatted", caption: t("storage-detail.date") },
          { name: "name", caption: t("storage-detail.name") },
          {
            name: "arades_ueberweisungsbetrag_buyer_formatted",
            caption: t("storage-detail.transfer-amount"),
            alignment: "right",
          },
          {
            name: "arades_gebuehr_betrag_buyer_formatted",
            caption: t("storage-detail.buy-fee"),
            alignment: "right",
          },
          {
            name: "arades_anlagebetrag_formatted",
            caption: t("storage-detail.invest-amount"),
            alignment: "right",
          },
          {
            name: "extendedamount_formatted",
            caption: t("storage-detail.sales"),
            alignment: "right",
          },
          {
            name: "arades_gebuehr_betrag_seller_formatted",
            caption: t("storage-detail.sales-fee"),
            alignment: "right",
          },
          {
            name: "arades_ueberweisungsbetrag_seller_formatted",
            caption: t("storage-detail.payout"),
            alignment: "right",
          },
          { name: "name14", caption: t("storage-detail.storage-fee") },
          {
            name: "arades_leistungserbringung",
            caption: metalName + " " + t("storage-detail.submitted"),
          },
          {
            name: "arades_menge_formatted",
            caption: t("storage-detail.change"),
            alignment: "right",
          },
          {
            name: "arades_verfuegbare_menge_formatted",
            caption: t("storage-detail.total"),
            alignment: "right",
          },
          {
            name: "name16",
            caption: t("storage-detail.gold-silver-ratio"),
            alignment: "right",
          },
        ]}
      ></DataTable>
    </LoadingOverlay>
  );
};
export default TransactionList;
