import React, { useEffect, useState } from "react";
import { IconButton, Paper, InputBase } from "@mui/material";

import MdSearch from "@mui/icons-material/Search";
function Search({ onSearch, placeholder }) {
  const [term, setTerm] = useState("");
  const [debouncedTerm, setDebouncedTerm] = useState(term);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setDebouncedTerm(term);
    }, 700);
    return () => {
      clearTimeout(timeoutId);
    };
  }, [term]);

  useEffect(() => {
    onSearch(debouncedTerm);
  }, [debouncedTerm]);

  return (
    <>
      <Paper
        component="form"
        sx={{
          p: "1px 4px",
          display: "flex",
          margin: "32px 0 32px 32px",
          alignItems: "center",
          width: 1,
          maxWidth: "400px",
          minWidth: "350px",
          boxShadow: "none",
          border: "1px solid rgba(0, 0, 0, 0.12)",
        }}
      >
        <InputBase
          sx={{ flex: 1 }}
          placeholder={placeholder}
          value={term}
          onChange={(event) => setTerm(event.target.value)}
        />
        <IconButton type="submit" sx={{ p: "10px" }} aria-label="search">
          <MdSearch />
        </IconButton>
      </Paper>
    </>
  );
}

export default Search;
