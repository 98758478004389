import React, { useState, useEffect } from "react";
import {
  faBuilding,
  faUser,
  faSignOutAlt,
  faChild,
  faArrowLeft,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { URL, WORKSPACETYPE } from "../../../core/constants";
import {
  CONTACT_ID,
  WORKSPACE_ID,
  WORKSPACE_TYPE,
  WORKSPACE_NAME,
  TMP_WORKSPACE_ID,
  TMP_WORKSPACE_NAME,
  TMP_CONTACT_ID,
  TMP_FULLNAME,
  TMP_WORKSPACE_TYPE,
  FULLNAME,
  ADDRESS_VERIFIED,
  MOBILE_VERIFIED,
  VERMITTLER_ID,
  TMP_VERMITTLER_ID,
} from "../../../core/apiconfig/constantApi";
import { BASE_URL } from "../../../core/apiconfig/environment";
import { useNavigate } from "react-router-dom";
import { useApi } from "../../../core/hooks/useApi";
import RelatedMenus from "./relatedMenus";
import { resetCache } from "../../../core/utils/cachManager";
import { useRegisterCompanyState } from "../../../context/registerCompany/RegisterCompanyState";
import "./sidebar.scss";
import ConfirmModal from "../../common/popups/confirmModal";
import { setFullname } from "../../../redux/slices/underage/underage.slice";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { screenIsDesktop } from "../../../core/utils/responsive";
import MenuIcon from "@mui/icons-material/Menu";

//menu
import {
  Menu,
  MenuItem,
  Box,
  Divider,
  IconButton,
  Tooltip,
} from "@mui/material";

const Sidebar = ({ fullname, workspaceName, onClick }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { get } = useApi();
  const { t } = useTranslation();

  const { registerCompanyInfoAction, registerCompanyInfo } = useRegisterCompanyState();

  const [notVerifiedModalOpen, setNotVerifiedModalOpen] = useState(false);
  const [selectedWorkSpace, setSelectedWorkSpace] = useState({});
  const [myWorkSpaces, setMyWorkSpaces] = useState([]);

  const [openProfileOptions, setOpenProfileOptions] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const SELLERTYPE = "100000001";
  const VERIFIEDSTATUS = "100000000";
  const ACCOUNT_FILE_UPLOADED = "100000001";
  const UNDERAGE_FILE_UPLOADED = "100000005";

  const isDesktop = screenIsDesktop();

  useEffect(() => {
    if (fullname) {
      const mainWorkspace = {
        id: localStorage.getItem(CONTACT_ID),
        name: fullname,
        type: WORKSPACETYPE.CONTACT,
        icon: faUser,
      };

      const defaultWorkspace = {
        id: localStorage.getItem(WORKSPACE_ID),
        name: workspaceName,
        type: localStorage.getItem(WORKSPACE_TYPE),
        icon:
          localStorage.getItem(WORKSPACE_TYPE) == WORKSPACETYPE.CONTACT
            ? faUser
            : faBuilding,
      };

      setSelectedWorkSpace(defaultWorkspace);

      setMyWorkSpaces([{ ...mainWorkspace }]);
      setWorkspaceList(mainWorkspace);
    }
  }, [fullname, workspaceName]);

  const setWorkspaceList = async (mainWorkspace) => {
    let myaccounts = await getMyAccounts();
    let myUnderAges = await getMyUnderAges();

    let workspaces = [{ ...mainWorkspace }];

    myaccounts.map((account) => {
      workspaces.push({
        id: account.id,
        name: account.name,
        type: getAccountType(
          account.companyType,
          account.statuscode,
          account.sellerVerificationStatus,
          account.filesUploaded
        ),
        icon: faBuilding,
      });
    });

    for (let i = 0; i < myUnderAges.length; i++) {
      let contact = myUnderAges[i];
      let filesUploaded =
        contact.statuscode == UNDERAGE_FILE_UPLOADED ||
        contact.statuscode == VERIFIEDSTATUS; //await documentsUploadedForUnderAge(contact.contactid);
      workspaces.push({
        id: contact.contactid,
        name: contact.firstname + " " + contact.lastname,
        type: WORKSPACETYPE.UNDER_AGE,
        icon: faChild,
        filesUploaded: filesUploaded,
        verified: !filesUploaded || contact.statuscode == VERIFIEDSTATUS,
      });
    }

    let rereshedWorkSpace = workspaces.filter(
      (x) => x.id == localStorage.getItem(WORKSPACE_ID)
    )[0];

    setSelectedWorkSpace(rereshedWorkSpace);
    setMyWorkSpaces(workspaces);
  };

  const getMyUnderAges = async () => {
    const result = await get(`${BASE_URL}data/contacts/${localStorage.getItem(CONTACT_ID)}/children`);
    if (result.status == 200 && result.data && result.data.value)
      return result.data.value;
    else return [];
  };

  const getAccountType = (
    companyType,
    accountStatus,
    sellerVerificationStatus,
    filesUploaded
  ) => {
    if (companyType.indexOf(SELLERTYPE) >= 0) {
      if (sellerVerificationStatus != VERIFIEDSTATUS)
        return WORKSPACETYPE.SELLER_ACCOUNT_UNVERIFIED;
      else return WORKSPACETYPE.SELLER_ACCOUNT_VERIFIED;
    } else if (accountStatus == VERIFIEDSTATUS)
      return WORKSPACETYPE.SIMPLE_ACCOUNT_VERIFIED;
    else if (filesUploaded) return WORKSPACETYPE.SIMPLE_ACCOUNT_UNVERIFIED;
    else return WORKSPACETYPE.SIMPLE_ACCOUNT_NEED_FILE;
  };

  const getMyAccounts = async () => {
    const result = await get(`${BASE_URL}data/accounts/contact/${localStorage.getItem(CONTACT_ID)}/with-mitarbeiter/`);
    if (result.data && result.data.value) {
      let list = [];
      for (let i = 0; i < result.data.value.length; i++) {
        let item = result.data.value[i].arades_firma;
        if (item) {
          let account = {
            id: item.accountid,
            name: item.name,
            statuscode: item.statuscode,
            statecode: item.statecode,
            companyType: item.arades_firmentyp,
            canBeSeller: item.arades_vermittlerwerden,
            sellerVerificationStatus: item.arades_vermittlerstatus,
          };
          account.filesUploaded =
            item.statuscode == ACCOUNT_FILE_UPLOADED ||
            item.statuscode == VERIFIEDSTATUS;

          list.push(account);
        }
      }
      return list;
    } else return [];
  };

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
    if (onClick) onClick();
  };

  const toggleProfileOptions = () => {
    setOpenProfileOptions(!openProfileOptions);
  };

  const setWorkSpace = (workspace) => {
    setSelectedWorkSpace(workspace);
    localStorage.setItem(WORKSPACE_ID, workspace.id);
    localStorage.setItem(WORKSPACE_TYPE, workspace.type);
    localStorage.setItem(WORKSPACE_NAME, workspace.name);
  };

  const continueRegister = (ws) => {
    if (ws.type == WORKSPACETYPE.SIMPLE_ACCOUNT_NEED_FILE) {
      registerCompanyInfoAction({
        company: { accountid: ws.id, name: ws.name },
        step: 2,
        hideBackLink: true,
      });
      navigate(URL.REGISTERCOMPANY);
    } else if (ws.type == WORKSPACETYPE.UNDER_AGE && !ws.filesUploaded) {
      dispatch(setFullname(ws.name));
      navigate(URL.UNDERAGE_UPLOAD_BASE + "/" + ws.id);
    }
  };

  const switchToVermittler = () => {
    localStorage.setItem(CONTACT_ID, localStorage.getItem(TMP_CONTACT_ID));
    localStorage.setItem(WORKSPACE_ID, localStorage.getItem(TMP_WORKSPACE_ID));
    localStorage.setItem(
      WORKSPACE_TYPE,
      localStorage.getItem(TMP_WORKSPACE_TYPE)
    );
    localStorage.setItem(
      WORKSPACE_NAME,
      localStorage.getItem(TMP_WORKSPACE_NAME)
    );
    localStorage.setItem(FULLNAME, localStorage.getItem(TMP_FULLNAME));
    localStorage.setItem(VERMITTLER_ID, localStorage.getItem(TMP_VERMITTLER_ID));

    localStorage.setItem(ADDRESS_VERIFIED, "true");
    localStorage.setItem(MOBILE_VERIFIED, "true");

    localStorage.removeItem(TMP_CONTACT_ID);
    localStorage.removeItem(TMP_WORKSPACE_ID);
    localStorage.removeItem(TMP_WORKSPACE_TYPE);
    localStorage.removeItem(TMP_WORKSPACE_NAME);
    localStorage.removeItem(TMP_FULLNAME);
    localStorage.removeItem(TMP_VERMITTLER_ID);

    navigate(URL.CUSTOMER);
    window.location.reload();
  };

  const menuProps = {
    elevation: 0,
    sx: {
      overflow: "visible",
      filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
      mt: 1.5,
      "& .MuiAvatar-root": {
        width: 32,
        height: 32,
        ml: -0.5,
        mr: 1,
      },
      "&:before": {
        content: '""',
        display: "block",
        position: "absolute",
        top: 0,
        right: 14,
        width: 10,
        height: 10,
        bgcolor: "background.paper",
        zIndex: 0,
      },
    },
  };
  return (
    <Box>
      <Menu
        anchorEl={anchorEl}
        id="workspace-menu"
        open={open}
        onClose={handleMenuClose}
        onClick={handleMenuClose}
        PaperProps={menuProps}
        transformOrigin={{
          horizontal: isDesktop ? "left" : "right",
          vertical: "top",
        }}
        anchorOrigin={{
          horizontal: isDesktop ? "left" : "right",
          vertical: "bottom",
        }}
      >
        {fullname &&
          myWorkSpaces.map((ws, index) => (
            <MenuItem
              key={ws.name + index}
              disabled={
                ws.type == WORKSPACETYPE.SIMPLE_ACCOUNT_UNVERIFIED ||
                (ws.type == WORKSPACETYPE.UNDER_AGE && !ws.verified)
              }
              onClick={() => {
                if (
                  (ws.type != WORKSPACETYPE.SIMPLE_ACCOUNT_NEED_FILE &&
                    ws.type != WORKSPACETYPE.UNDER_AGE) ||
                  (ws.type == WORKSPACETYPE.UNDER_AGE && ws.filesUploaded)
                ) {
                  setWorkSpace(ws);
                  navigate(URL.DASHBOARD);
                  window.location.reload(); // Parichehr badan ba redux hallesh kon
                }
              }}
            >
              <FontAwesomeIcon icon={ws.icon} />
              <div
                className={`menu-name ${
                  ws.type == WORKSPACETYPE.SIMPLE_ACCOUNT_UNVERIFIED ||
                  (ws.type == WORKSPACETYPE.UNDER_AGE && !ws.verified)
                    ? "unverified-span"
                    : ""
                }`}
                style={{ width: "100%" }}
              >
                <span>{ws.name}</span>
                {(ws.type == WORKSPACETYPE.SIMPLE_ACCOUNT_UNVERIFIED ||
                  (ws.type == WORKSPACETYPE.UNDER_AGE && !ws.verified)) && (
                  <span
                    style={{ paddingLeft: 30, paddingTop: 3 }}
                    className="unverified-span"
                  >
                    {t("layout.verification")}
                  </span>
                )}
                {(ws.type == WORKSPACETYPE.SIMPLE_ACCOUNT_NEED_FILE ||
                  (ws.type == WORKSPACETYPE.UNDER_AGE &&
                    !ws.filesUploaded)) && (
                  <span
                    style={{ paddingLeft: 30 }}
                    className="continue-span"
                    onClick={() => continueRegister(ws)}
                  >
                    {t("layout.continue")}
                  </span>
                )}
                {ws.type == WORKSPACETYPE.SELLER_ACCOUNT_VERIFIED && (
                  <span
                    style={{ paddingLeft: 30, paddingTop: 3 }}
                    className="unverified-span"
                  >
                    {t("layout.seller")}
                  </span>
                )}
                {ws.type == WORKSPACETYPE.UNDER_AGE &&
                  ws.verified &&
                  ws.filesUploaded && (
                    <span
                      style={{ paddingLeft: 30, paddingTop: 3 }}
                      className="unverified-span"
                    >
                      {t("layout.underage")}
                    </span>
                  )}
              </div>
            </MenuItem>
          ))}
        <Divider />

        {localStorage.getItem(TMP_WORKSPACE_ID) && (
          <MenuItem onClick={() => switchToVermittler()}>
            <FontAwesomeIcon icon={faArrowLeft} />
            <div style={{ paddingLeft: "5px" }}>
              {"Zurück zu " + localStorage.getItem(TMP_WORKSPACE_NAME)}
            </div>
          </MenuItem>
        )}
        <MenuItem
          onClick={() => {
            resetCache();
            navigate(URL.LOGIN);
            window.location.reload();
          }}
        >
          <FontAwesomeIcon icon={faSignOutAlt} />
          {t("layout.logout")}
        </MenuItem>
      </Menu>
      <div className="sidebar-wrapper">
        <ConfirmModal
          open={notVerifiedModalOpen}
          showCloseButton={false}
          size="md"
          message={
            <div>
              Um vollständigen Zugriff zu erhalten, schließen Sie das
              Identifizierungsprozess ab.
            </div>
          }
          noImage={true}
          rejectTitle={"SPÄTER"}
          onReject={() => {
            setNotVerifiedModalOpen(false);
          }}
          showNect={true}
        ></ConfirmModal>
        <ul className="sidebar-menu ">
          <li className="workspace">
            <span className="group">
              {t("layout.workspace")}
              {selectedWorkSpace.type == WORKSPACETYPE.SELLER_ACCOUNT_VERIFIED
                ? ` (${t("layout.seller")})`
                : selectedWorkSpace.type == WORKSPACETYPE.UNDER_AGE
                ? ` (${t("layout.underage")})`
                : ""}
            </span>
            <div className="div-name" onClick={() => toggleProfileOptions()}>
              <div style={{ display: "flex" }}>
                <FontAwesomeIcon
                  icon={selectedWorkSpace.icon}
                ></FontAwesomeIcon>
                &nbsp;
                <span className="name">{selectedWorkSpace.name}</span>
              </div>
              <span className="btnProfile">
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    textAlign: "center",
                  }}
                >
                  <Tooltip title={t("layout.select-workspace")}>
                    <IconButton
                      onClick={handleMenuClick}
                      size="small"
                      sx={{ ml: 2 }}
                      aria-controls={open ? "workspace-menu" : undefined}
                      aria-haspopup="true"
                      aria-expanded={open ? "true" : undefined}
                    >
                      <MenuIcon sx={{ color: "white", marginTop: "-5px" }} />
                    </IconButton>
                  </Tooltip>
                </Box>
              </span>
            </div>
          </li>
          <RelatedMenus
            workspaceType={selectedWorkSpace.type}
            onClick={onClick}
          ></RelatedMenus>

          <div className="seller-container">
            Downloadbereich
            <a
              href="https://ratior.de/kunden-download-bereich/"
              target="_blank"
            >
              <div className="seller-btn">Kunden Downloadbereich</div>
            </a>
            {selectedWorkSpace.type ==
              WORKSPACETYPE.SELLER_ACCOUNT_VERIFIED && (
              <a
                href="https://ratior.team/partner-download-bereich/"
                target="_blank"
              >
                <div className="seller-btn">Partner Downloadbereich</div>
              </a>
            )}

            {selectedWorkSpace.type ==
              WORKSPACETYPE.SELLER_ACCOUNT_VERIFIED && (
              <a
                href="https://ratior.team/partner-marketing-bereich/"
                target="_blank"
              >
                <div className="seller-btn">Partner Marketingbereich</div>
              </a>
            )}
          </div>
          <Divider style={{ color: "black", opacity: 100 }} />
        </ul>
      </div>
    </Box>
  );
};

export default Sidebar;
