import axios from "axios";
import { BASE_URL } from "../../../core/apiconfig/environment";
import { getMetalPriceFunc } from "../";
import {
  dateString,
  zeroPad,
  Currency,
  localeFormat,
} from "../../../core/utils/convert";
import { storageIsPending, storageStatus } from "../../../core/utils/business";
import {
  WORKSPACETYPE,
  METAL_CHARS,
} from "../../../core/constants";
import { TOKEN_NAME } from "../../../core/apiconfig/constantApi";

const getCustomerStorages = async (
  workspaceType,
  customerId,
  prices,
  fetchSubStorages
) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `bearer ${localStorage.getItem(TOKEN_NAME)}`,
    },
  };

  let result;
  if (workspaceType === WORKSPACETYPE.CONTACT) {
    result = await axios.get(`${BASE_URL}data/warehouses/aggregated/contact/${customerId}/?fetchSubStorages=${fetchSubStorages ? "true" : "false"}`, config);
  } else if (workspaceType === WORKSPACETYPE.UNDER_AGE) {
    result = await axios.get(`${BASE_URL}data/warehouses/aggregated/contact/${customerId}/child/?fetchSubStorages=${fetchSubStorages ? "true" : "false"}`, config);
  } else {
    result = await axios.get(`${BASE_URL}data/warehouses/aggregated/account/${customerId}/?fetchSubStorages=${fetchSubStorages ? "true" : "false"}`, config);
  }

  if (result?.data?.status != 200)
    throw Error("Lager konnten nicht geladen werden"); //("Storages not fetched");

  let list = [];
  result.data.data.value.map((item) => {
    let metalName =
      item["arades_edelmetall_typ@OData.Community.Display.V1.FormattedValue"];

    let createDate =
      zeroPad(item.createyear, 4) +
      "-" +
      zeroPad(item.createmonth, 2) +
      "-" +
      zeroPad(item.createday, 0);

    let unitPrice =
      metalName.toLowerCase().indexOf("gold") >= 0
        ? prices.gold
        : metalName.toLowerCase().indexOf("silber") >= 0
        ? prices.silver
        : metalName.toLowerCase().indexOf("platin") >= 0
        ? prices.platin
        : metalName.toLowerCase().indexOf("palladium") >= 0
        ? prices.palladium
        : 0;
    let tmp = {
      msdyn_warehouseid: item["msdyn_warehouseid"],
      msdyn_name: item["msdyn_name"],
      storageDescription: item["msdyn_description"],
      arades_uebergeordneter_lagerplatz: item["arades_uebergeordneter_lagerplatz"],
      statuscode: item["statuscode"],
      statecode: item["statecode"],
      arades_lagerplatz_typ: item["arades_lagerplatz_typ"],
      statusText: storageStatus(item.statuscode),
      arades_menge: item["arades_menge"] ?? 0,
      arades_verzicht_auf_widerruf: item["arades_verzicht_auf_widerruf"],
      arades_einrichtungsgebuehr_beglichen: item["arades_einrichtungsgebuehr_beglichen"],
      arades_vertrag: item["arades_vertrag"],
      createdon: createDate,
      createdon_formatted: dateString(createDate),
      metalName: metalName,
      unitPrice: unitPrice,
      totalPrice: unitPrice * (item["arades_verfuegbare_menge"] ?? 0),
      subStorageCount: item.subStorageCount,
      subStorageList: [],
    };

    let isPending = storageIsPending(tmp);
    tmp.isPending = isPending;
    tmp.arades_verfuegbare_menge = !isPending
      ? item["arades_verfuegbare_menge"] ?? 0
      : 0;
    tmp.arades_menge = !isPending ? (item["arades_menge"] ?? 0) : 0;
    tmp.arades_verfuegbare_menge_formatted = !isPending
      ? localeFormat(item["arades_verfuegbare_menge"] ?? 0, "de-DE", { minimumFractionDigits: 4 }) + " g"
      : "Pending";
    tmp.arades_menge_formatted = !isPending
      ? localeFormat(item["arades_menge"] ?? 0, "de-DE", { minimumFractionDigits: 4 }) + " g"
      : "Pending";
    tmp.price = !isPending
      ? unitPrice * (item["arades_verfuegbare_menge"] ?? 0)
      : 0;
    tmp.price_formatted = Currency(tmp.price);

    list.push(tmp);
  });
  return list;
};

const getCustomerStorageListFunc = async (filter, { getState }) => { // Data to #storageList
  const { workspaceId, workspaceType, fetchSubStorages, fetchPrices } = filter;

  let prices = { gold: 0, silver: 0, platin: 0, palladium: 0 };

  if (fetchPrices) {
    let pResult = {};

    pResult = await getMetalPriceFunc(
      { productName: METAL_CHARS.GOLD },
      { getState }
    );
    prices.gold = pResult.amount;

    pResult = await getMetalPriceFunc(
      { productName: METAL_CHARS.SILVER },
      { getState }
    );
    prices.silver = pResult.amount;

    pResult = await getMetalPriceFunc(
      { productName: METAL_CHARS.PLATIN },
      { getState }
    );
    prices.platin = pResult.amount;

    pResult = await getMetalPriceFunc(
      { productName: METAL_CHARS.PALLADIUM },
      { getState }
    );
    prices.palladium = pResult.amount;
  }

  const storages = await getCustomerStorages(
    workspaceType,
    workspaceId,
    prices,
    fetchSubStorages
  );

  return storages.sort((a, b) => (a?.createDate >= b?.createDate ? -1 : 1));
};

export default getCustomerStorageListFunc;
