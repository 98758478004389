import React, { useState, useEffect } from "react";

import { Box, Button, Grid } from "@mui/material";

import { Select, LoadingOverlay, FormSteperStructure } from "../common";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useSnackbar } from "notistack";
import { useDispatch, useSelector } from "react-redux";
import { ERROR_CONTACT_US } from "../../core/constants";
import {
  getStorageListAction,
  getSubStorageListAction,
} from "../../redux/slices/exchange";
import {
  globalData,
  setSelectStorage,
  selectStorageTypeFields,
  selectStorageFields,
  storagesFields,
  subStoragesFields,
  resetError,
  resetActionStatus,
} from "../../redux/slices/exchange/exchange.slice";
import { WORKSPACE_ID } from "../../core/apiconfig/constantApi";
import { useTranslation } from "react-i18next";

function SelectStorageStep({ handleCurrentStep, currentStep }) {
  const requiredMsg = "erforderlich";
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const storageTypeFields = useSelector(selectStorageTypeFields);
  const storageFields = useSelector(selectStorageFields);
  const storageList = useSelector(storagesFields);
  const subStorageList = useSelector(subStoragesFields);
  const exchangeGlobalData = useSelector(globalData);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    dispatch(getStorageListAction(localStorage.getItem(WORKSPACE_ID)));
    if (storageFields.storageId) {
      dispatch(
        getSubStorageListAction({
          storageId: storageFields.storageId,
          fetchAmount: false,
        })
      );
    }
  }, []);

  const onSubmit = (data) => {
    if (storageTypeFields.storageType == "mainStorage") {
      data.subStorageId = "";
      data.subStorageNumber = "";
    }

    if (data.storageId)
      data.storageNumber = storageList.filter((x) => {
        return x.key == data.storageId;
      })[0].value;
    if (data.subStorageId)
      data.subStorageNumber = subStorageList.filter((x) => {
        return x.key == data.subStorageId;
      })[0].value;

    dispatch(setSelectStorage(data));
    handleCurrentStep("next");
  };

  const storageChanegd = (e) => {
    if (storageTypeFields.storageType != "mainStorage")
      if (e.target.value) {
        dispatch(
          getSubStorageListAction({
            storageId: e.target.value,
            fetchAmount: false,
          })
        );
      }
  };

  useEffect(() => {
    if (exchangeGlobalData.actionStatus) {
      dispatch(resetActionStatus());
    }
  }, [exchangeGlobalData.actionStatus]);

  useEffect(() => {
    setLoading(exchangeGlobalData.loading);
  }, [exchangeGlobalData.loading]);

  useEffect(() => {
    if (exchangeGlobalData.error != null) {
      enqueueSnackbar(exchangeGlobalData.error + ERROR_CONTACT_US, {
        variant: "error",
        autoHideDuration: 20000,
      });
      dispatch(resetError());
      setLoading(false);
    }
  }, [exchangeGlobalData.error]);

  const schema = yup.object().shape({
    storageId: yup.string().required(requiredMsg),
    subStorageId:
      storageTypeFields.storageType == "mainStorage"
        ? yup.string()
        : yup.string().required(requiredMsg),
  });

  const {
    control,
    handleSubmit,
    setError,
    getValues,
    formState: { errors },
  } = useForm({
    mode: "all",
    defaultValues: {
      ...storageFields,
    },
    resolver: yupResolver(schema),
  });

  return (
    <LoadingOverlay show={loading}>
      <Box
        sx={{
          margin: "0 auto",
          minHeight: "calc(100vh - 250px)",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "space-around",
        }}
      >
        <Box sx={{ minWidth: "50%", margin: "0 auto" }}>
          <Grid spacing={8} container direction="column">
            <Grid xs={12} lg={6} item>
              <Select
                control={control}
                error={Boolean(errors.storageId)}
                helperText={errors.storageId?.message}
                name="storageId"
                label={t("exchange.select-storage")}
                onChange={storageChanegd}
                options={storageList}
              />
            </Grid>
            {storageTypeFields.storageType != "mainStorage" && (
              <Grid xs={12} lg={6} item>
                <Select
                  control={control}
                  error={Boolean(errors.subStorageId)}
                  helperText={errors.subStorageId?.message}
                  name="subStorageId"
                  label={t("exchange.select-substorage")}
                  options={subStorageList}
                />
              </Grid>
            )}
          </Grid>
        </Box>

        <FormSteperStructure.Footer>
          <Button
            onClick={handleSubmit(onSubmit)}
            fullWidth
            sx={{ bgcolor: "info.main", maxWidth: "192px" }}
            variant="contained"
          >
            {t("buttons.next")}
          </Button>
        </FormSteperStructure.Footer>
      </Box>
    </LoadingOverlay>
  );
}

export default SelectStorageStep;
