
export const BASE_URL =
  process.env.REACT_APP_ENV === "production"
    ? "https://api.ratior.ag/api/v1/"
        :  process.env.REACT_APP_ENV === "develop"
            ? "https://dev-api.ratior.ag/api/v1/"
            : "http://localhost:8080/api/v1/";
            // : "https://tunnel2.pschwind.de/api/v1/";
            
            
export const BASE_URL_UI =
process.env.REACT_APP_ENV === "production"
  ? "https://my.ratior.ag/"
      :  process.env.REACT_APP_ENV === "develop"
          ? "https://develop.ratior.ag/"
          : "http://localhost:3000/";
          // : "https://tunnel1.pschwind.de/";


export const NECT_URL = "https://jump.test.nect.com/";

//parichehr check it not to have  security side effect
export const BYPASS_ME = false;
export const BYPASS_NECT = true;
export const BYPASS_ADOBE_SIGN = true;
export const IS_UNDER_CONSTRUCTION = false;
export const SHOW_MAINTENANCE_RIBBON = false;

export const EMAIL_IMPERSONATED_USER = "BDDD8A50-0DF7-EC11-BB3D-0022489E074B";

export const MAINTENANCE_TEXT = "in der Zeit zwischen 31.07.20 9:00 Uhr und 01.08.2023 21:00 Uhr (CEST), werden wir planmäßige Wartungsarbeiten durchführen. Die Erreichbarkeit des Portals ist davon nicht betroffen.";
