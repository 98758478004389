import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../../../core/apiconfig/environment";
import { TOKEN_NAME } from "../../../core/apiconfig/constantApi";

const getUnderAgeDocumentListAction = createAsyncThunk(
  "underage/getdocuments",
  async (contactId, { getState }) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${localStorage.getItem(TOKEN_NAME)}`,
      },
    };

    const result = await axios.get(`${BASE_URL}data/documents/contact/${contactId}/`, config);

    if (result?.data?.data?.value?.length > 0) {
      return result.data.data.value;
    } else throw Error("Dokumente wurden nicht gefunden"); //("Documnets not found");
  }
);

export default getUnderAgeDocumentListAction;
