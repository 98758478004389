import React, { useEffect, useState } from "react";
import {
  Typography,
  Box,
  Grid,
  Paper,
  Table,
  TableBody,
  TableRow,
  TableCell,
} from "@mui/material";
import { Currency } from "../../../core/utils/convert";
import { useSnackbar } from "notistack";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPhone,
  faEnvelope,
  faBuilding,
  faAngleRight,
} from "@fortawesome/free-solid-svg-icons";
import {
  MetalCell,
  ColorizeIncreaseAndDecrease,
  LoadingOverlay,
  ConfirmModal,
  Ribbon,
  BorderLinearProgress,
} from "../../common";
import { Link } from "react-router-dom";
import {
  URL,
  WORKSPACETYPE,
  CONTACT_STATUS,
  ERROR_CONTACT_US,
} from "../../../core/constants";
import { useDispatch, useSelector } from "react-redux";
import { customerLoyaltyData } from "../../../redux/slices/globals/globals.slice";
import {
  storageList,
  transactionList,
  vermittler,
  investList,
  globalData,
  resetError,
  resetActionStatus,
  companyData,
  invoiceBoxData,
} from "../../../redux/slices/clientDashboard/clientDashboard.slice";
import { useNavigate } from "react-router-dom";
import {
  getVermittlerAction,
  getTransactionListAction,
  getCustomerStorageListAction,
  getCompanyDataAction,
  getInvoiceTransferDataAction,
  getInvoiceTotalDataAction,
} from "../../../redux/slices/clientDashboard";
// chart
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";
import {
  STATUS_CODE,
  WORKSPACE_ID,
  WORKSPACE_TYPE,
  VERMITTLER_ID,
  WORKSPACE_NAME,
  TMP_CONTACT_ID,
} from "../../../core/apiconfig/constantApi";
//import CustomerActionCodeBox from "./customerActionCodeBox";
import { resetAll } from "../../../redux/slices/becomeSeller/becomeSeller.slice";
import { useTranslation } from "react-i18next";
import {
  BYPASS_ADOBE_SIGN,
  SHOW_MAINTENANCE_RIBBON,
  MAINTENANCE_TEXT,
} from "../../../core/apiconfig/environment";
import { getCustomerLoyaltyDataAction } from "../../../redux/slices/globals";
import PreciousMetalsChart from "../../common/PreciousMetalsChart/PreciousMetalsChart";

ChartJS.register(ArcElement, Tooltip, Legend);

const CustomerDashboard = (props) => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [notVerifiedModalOpen, setNotVerifiedModalOpen] = useState(false);
  const companyInfo = useSelector(companyData);
  const dashboardGlobalData = useSelector(globalData);
  const storageListFields = useSelector(storageList);
  const transactionListFields = useSelector(transactionList);
  const vermittlerFields = useSelector(vermittler);
  const investListFields = useSelector(investList);
  const customerLoyaltyInfo = useSelector(customerLoyaltyData);
  const invoiceBox = useSelector(invoiceBoxData);
  const [companyInfoState, setCompanyInfoState] = useState(false);
  const [loading, setLoading] = useState(false);

  const loggedinAsCustomer =
    localStorage.getItem(TMP_CONTACT_ID) !== null &&
    localStorage.getItem(TMP_CONTACT_ID) !== "";

  useEffect(() => {
    setLoading(true);

    if (
      localStorage.getItem(WORKSPACE_TYPE) !== WORKSPACETYPE.CONTACT &&
      localStorage.getItem(WORKSPACE_TYPE) !== WORKSPACETYPE.UNDER_AGE
    ) {
      dispatch(
        getCompanyDataAction({ id: localStorage.getItem(WORKSPACE_ID) })
      );
    }

    dispatch(
      getInvoiceTransferDataAction()
    );
    dispatch(
      getInvoiceTotalDataAction()
    );

    dispatch(getVermittlerAction(localStorage.getItem(VERMITTLER_ID)));
    dispatch(
      getCustomerStorageListAction({
        workspaceId: localStorage.getItem(WORKSPACE_ID),
        workspaceType: localStorage.getItem(WORKSPACE_TYPE),
        fetchSubStorages: false,
        fetchPrices: true,
      })
    );
    dispatch(
      getTransactionListAction()
    );

    dispatch(
      getCustomerLoyaltyDataAction({
        customerId: localStorage.getItem(WORKSPACE_ID),
        isContact:
          localStorage.getItem(WORKSPACE_TYPE) === WORKSPACETYPE.CONTACT ||
          localStorage.getItem(WORKSPACE_TYPE) === WORKSPACETYPE.UNDER_AGE,
      })
    );
  }, [dispatch, loggedinAsCustomer]);

  useEffect(() => {
    if (dashboardGlobalData.error != null) {
      enqueueSnackbar(dashboardGlobalData.error + ERROR_CONTACT_US, {
        variant: "error",
        autoHideDuration: 20000,
      });
      dispatch(resetError());
      setLoading(false);
    }
  }, [dashboardGlobalData.error, dispatch]);

  useEffect(() => {
    if (dashboardGlobalData.actionStatus) {
      if (dashboardGlobalData.actionType == "getCompanyData") {
        setCompanyInfoState(companyInfo);
        if (companyInfo.canBecomeSeller)
          localStorage.setItem(
            WORKSPACE_TYPE,
            WORKSPACETYPE.SIMPLE_ACCOUNT_VERIFIED
          );
        dispatch(resetActionStatus());
      } else if (dashboardGlobalData.actionType == "storageDetailList") {
        setLoading(false);
        dispatch(resetActionStatus());
      }
    }
  }, [dashboardGlobalData.actionStatus, dispatch]);

  return (
    <LoadingOverlay show={loading}>
      <ConfirmModal
        open={notVerifiedModalOpen}
        showCloseButton={false}
        size="md"
        message={<div>{t("customer-dashboard.nect-modal-message")}</div>}
        noImage={true}
        rejectTitle={t("buttons.later")}
        onReject={() => {
          setNotVerifiedModalOpen(false);
        }}
        showNect={true}
      ></ConfirmModal>

      {SHOW_MAINTENANCE_RIBBON && (
        <Ribbon
          height={110}
          description={
            <div>
              Liebe Kunden,
              <br></br> {MAINTENANCE_TEXT} <br></br>{" "}
              Vielen Dank für Ihr Verständnis!
            </div>
          }
        ></Ribbon>
      )}

      {(localStorage.getItem(STATUS_CODE) == CONTACT_STATUS.INVITED ||
        localStorage.getItem(STATUS_CODE) == CONTACT_STATUS.NECT_LATER) && (
        <Ribbon
          description={t("customer-dashboard.nect-modal-message")}
          showNect={true}
        ></Ribbon>
      )}

      {companyInfoState.canBecomeSeller && (
        <Ribbon
          buttonText={t("customer-dashboard.become-seller")}
          description={t("customer-dashboard.become-seller-question")}
          onClick={() => {
            dispatch(resetAll());
            navigate(URL.BECOMEASELLER);
          }}
        ></Ribbon>
      )}

      {localStorage.getItem(WORKSPACE_TYPE) ==
        WORKSPACETYPE.SELLER_ACCOUNT_UNVERIFIED &&
        companyInfoState.signedContract && (
          <Ribbon
            description={t("customer-dashboard.become-seller-in-process")}
          ></Ribbon>
        )}

      {!BYPASS_ADOBE_SIGN &&
        localStorage.getItem(WORKSPACE_TYPE) ==
          WORKSPACETYPE.SELLER_ACCOUNT_UNVERIFIED &&
        !companyInfoState.signedContract && (
          <Ribbon
            buttonText={t("buttons.sign-now")}
            description={t("customer-dashboard.become-seller-unsigned")}
            onClick={() => navigate(URL.SIGN_SELLER_CONTRACTS)}
          ></Ribbon>
        )}

      <Box
        sx={{
          paddingTop: "40px",
          paddingBottom: "20px",
          paddingLeft: "72px",
          paddingRight: "72px",
        }}
      >
        <Grid mt="10px" spacing={8} container>
          <Grid item xs={12}>
            <Typography variant="h5">
              {localStorage.getItem(WORKSPACE_NAME)}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6} lg={5}>
            <Paper sx={{ p: "16px", height: 1 }} variant="outlined">
              <Typography variant="subtitle1" component="h6">{ t("customer-dashboard.invest-chart") }</Typography>
              <Box mt={6}>
                <PreciousMetalsChart prices={investListFields} />
              </Box>
            </Paper>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <Paper sx={{ p: "16px", height: 1 }} variant="outlined">
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  mb: "10px",
                }}
              >
                <Typography variant="subtitle1" component="h6">
                  {t("customer-dashboard.invest")}
                </Typography>
                <Typography
                  sx={{ color: "#1976D2", fontWaight: 700 }}
                  variant="subtitle1"
                  component="h6"
                >
                  {Currency(
                    parseFloat(investListFields?.AU?.price) +
                      parseFloat(investListFields?.AG?.price) +
                      parseFloat(investListFields?.PT?.price) +
                      parseFloat(investListFields?.PL?.price)
                  )}
                </Typography>
              </Box>
              <Table
                sx={{
                  "& td": {
                    px: 0,
                  },
                }}
              >
                <TableBody>
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell
                      align="left"
                      sx={{
                        fontWeight: 500,
                      }}
                    >
                      <MetalCell metal="Gold"></MetalCell>
                    </TableCell>
                    <TableCell
                      align="right"
                      sx={{
                        whiteSpace: "nowrap",
                      }}
                    >
                      {Currency(investListFields?.AU?.price)}
                    </TableCell>
                  </TableRow>

                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell
                      align="left"
                      sx={{
                        fontWeight: 500,
                      }}
                    >
                      <MetalCell metal="Silver"></MetalCell>
                    </TableCell>
                    <TableCell
                      align="right"
                      sx={{
                        whiteSpace: "nowrap",
                      }}
                    >
                      {Currency(investListFields?.AG?.price)}
                    </TableCell>
                  </TableRow>

                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell
                      align="left"
                      sx={{
                        fontWeight: 500,
                      }}
                    >
                      <MetalCell metal="Platin"></MetalCell>
                    </TableCell>
                    <TableCell
                      align="right"
                      sx={{
                        whiteSpace: "nowrap",
                      }}
                    >
                      {Currency(investListFields?.PT?.price)}
                    </TableCell>
                  </TableRow>

                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell
                      align="left"
                      sx={{
                        fontWeight: 500,
                      }}
                    >
                      <MetalCell metal="Palladium"></MetalCell>
                    </TableCell>
                    <TableCell
                      align="right"
                      sx={{
                        whiteSpace: "nowrap",
                      }}
                    >
                      {Currency(investListFields?.PL?.price)}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Paper>
          </Grid>

          <Grid item xs={12} md={6} lg={3}>
            <Paper
              sx={{
                p: "16px",
                height: 1,
                bgcolor: "#E3F2FD",
                color: "#0D47A1",
              }}
              variant="outlined"
            >
              <Typography
                sx={{ color: "rgba(0, 0, 0, 0.6)" }}
                variant="subtitle1"
                component="h6"
              >
                {t("customer-dashboard.my-ratior")}
              </Typography>
              {localStorage.getItem(VERMITTLER_ID) && (
                <>
                  <Typography my="20px" variant="h6" component="h6">
                    {vermittlerFields.name}
                  </Typography>
                  <Box
                    sx={{
                      "& p": {
                        mb: "10px",
                      },
                    }}
                  >
                    <Typography
                      sx={{ whiteSpace: "nowrap" }}
                      variant="caption"
                      component="p"
                    >
                      <FontAwesomeIcon size="lg" icon={faPhone} /> &nbsp;
                      <a href={"tel:" + vermittlerFields.phoneNumber}>
                        {vermittlerFields.phoneNumber}
                      </a>
                    </Typography>
                    <Typography
                      sx={{ whiteSpace: "nowrap" }}
                      variant="caption"
                      component="p"
                    >
                      <FontAwesomeIcon size="lg" icon={faEnvelope} /> &nbsp;
                      <a
                        target="_blank" rel="noopener noreferrer"
                        href={"mailto:" + vermittlerFields.email}
                      >
                        {vermittlerFields.email}
                      </a>
                    </Typography>
                    <Typography variant="caption" component="p">
                      <FontAwesomeIcon size="lg" icon={faBuilding} /> &nbsp;
                      <a
                        target="_blank" rel="noopener noreferrer"
                        href={
                          "https://www.google.com/maps/search/?api=1&query=" +
                          vermittlerFields?.latitude +
                          "," +
                          vermittlerFields?.longitude
                        }
                      >
                        {vermittlerFields.addressLine1}
                        <br></br>
                        {vermittlerFields.postalcode}
                        &nbsp;&nbsp;
                        {vermittlerFields.city}
                        <br></br>
                        {vermittlerFields.landTitle}
                      </a>
                    </Typography>
                  </Box>
                </>
              )}
            </Paper>
          </Grid>
          <Grid item xs={12} md={6}>
            <Paper sx={{ p: "16px", height: 1 }} variant="outlined">
              <Grid item xs={12}>
                <Typography variant="subtitle1" component="h6">
                  {t("customer-dashboard.sales-fee")}
                </Typography>
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      paddingBottom: "5px",
                    }}
                  >
                    {t("action-code.loyalty-months") +
                      ": " +
                      customerLoyaltyInfo?.loyaltyMonthes}
                  </Typography>
                </Box>

                <BorderLinearProgress
                  variant="determinate"
                  value={customerLoyaltyInfo?.loyaltyProgressPercent}
                />
                <Typography
                  sx={{
                    fontSize: "14px",
                    color: "rgba(0, 0, 0, 0.6)",
                    paddingTop: "5px",
                  }}
                >
                  {customerLoyaltyInfo?.hasLoyalty
                    ? `${customerLoyaltyInfo?.loyaltyName}`
                    : ""}
                </Typography>
                <Typography
                  sx={{
                    fontSize: "14px",
                    color: "rgba(0, 0, 0, 0.6)",
                    paddingTop: "5px",
                  }}
                >
                  {customerLoyaltyInfo?.hasLoyalty
                    ? `(${t("action-code.fee-percent")} : ${
                        customerLoyaltyInfo?.feePercent_formatted
                      }%)`
                    : ""}
                </Typography>

                {customerLoyaltyInfo?.monthesToNextLevel >= 0 && (
                  <Typography
                    sx={{
                      fontSize: "14px",
                      color: "rgba(0, 0, 0, 0.6)",
                      paddingTop: "5px",
                    }}
                  >
                    {`${t("customer-dashboard.month-to-next-1")} ${
                      customerLoyaltyInfo?.monthesToNextLevel
                    } ${t("customer-dashboard.month-to-next-2")}`}
                  </Typography>
                )}
              </Grid>
            </Paper>
          </Grid>
          <Grid item xs={12} md={6}>
            <Paper sx={{ p: "16px", height: 1 }} variant="outlined">
              <Grid container xs={12}>
                <Grid item xs={12}>
                  <Typography variant="subtitle1" component="h6">
                    {t("customer-dashboard.transfer-title")}
                  </Typography>
                </Grid>

                {customerLoyaltyInfo?.ratiorRating > 0 && (
                  <Grid item xs={12}>
                    <Grid container xs={12}>
                      <Grid item xs={12} sm={8}>
                        <Typography
                          sx={{
                            fontSize: "14px",
                            color: "rgba(0, 0, 0, 0.6)",
                            paddingTop: "5px",
                          }}
                        >
                          {t("customer-dashboard.transfer-amount1")}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <Typography
                          sx={{
                            fontSize: "14px",
                            color: "rgba(0, 0, 0, 0.6)",
                            paddingTop: "5px",
                            textAlign: "right",
                          }}
                        >
                          {` ${Currency(customerLoyaltyInfo?.ratiorRating)}`}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                )}
                <Grid item xs={12} sm={8}>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      color: "rgba(0, 0, 0, 0.6)",
                      paddingTop: "5px",
                    }}
                  >
                    {t("customer-dashboard.transfer-amount2")}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={4}>
                  {" "}
                  <Typography
                    sx={{
                      fontSize: "14px",
                      color: "rgba(0, 0, 0, 0.6)",
                      paddingTop: "5px",
                      textAlign: "right",
                    }}
                  >
                    {` ${Currency(customerLoyaltyInfo?.investedAmount)}`}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={8}>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      color: "rgba(0, 0, 0, 0.6)",
                      paddingTop: "5px",
                    }}
                  >
                    {t("customer-dashboard.transfer-sum")}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={4}>
                  {" "}
                  <Typography
                    sx={{
                      fontSize: "14px",
                      color: "rgba(0, 0, 0, 0.6)",
                      paddingTop: "5px",
                      textAlign: "right",
                    }}
                  >
                    {` ${Currency(customerLoyaltyInfo?.sumAmount)}`}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      color: "rgba(0, 0, 0, 0.6)",
                      paddingTop: "5px",
                    }}
                  >
                    {t("customer-dashboard.transfer-current-level") + " " + (customerLoyaltyInfo?.feeLevel ?? "…")}
                  </Typography>
                </Grid>
                {customerLoyaltyInfo.typeIsLevels &&
                  !customerLoyaltyInfo.currentLevelIsLast && (
                    <Grid item xs={12}>
                      <Typography
                        sx={{
                          fontSize: "14px",
                          color: "rgba(0, 0, 0, 0.6)",
                          paddingTop: "5px",
                        }}
                      >
                        {`${t("customer-dashboard.transfer-to")} ${Currency(
                          customerLoyaltyInfo?.feeAmountToNextLevel
                        )}${t("customer-dashboard.transfer-level")} ${
                          customerLoyaltyInfo?.nextLevel
                        }`}
                      </Typography>
                    </Grid>
                  )}
              </Grid>
            </Paper>
          </Grid>
          <Grid item xs={12} md={6}>
            <Paper
              sx={{ p: "16px", height: 0.355, minHeight: "150px" }}
              variant="outlined"
            >
              <Grid container xs={12}>
                <Grid item xs={12}>
                  <Typography variant="subtitle1" component="h6">
                    {t("customer-dashboard.invoice-title")}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={8}>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      color: "rgba(0, 0, 0, 0.6)",
                      paddingTop: "5px",
                    }}
                  >
                    {t("customer-dashboard.invoice-amount1")}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      color: "rgba(0, 0, 0, 0.6)",
                      paddingTop: "5px",
                      textAlign: "right",
                    }}
                  >
                    {` ${Currency(invoiceBox?.totalSum)}`}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={8}>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      color: "rgba(0, 0, 0, 0.6)",
                      paddingTop: "5px",
                    }}
                  >
                    {t("customer-dashboard.invoice-amount2")}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      color: "rgba(0, 0, 0, 0.6)",
                      paddingTop: "5px",
                      textAlign: "right",
                    }}
                  >
                    {` ${Currency(invoiceBox?.transferSum)}`}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={8}>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      color: "rgba(0, 0, 0, 0.6)",
                      paddingTop: "5px",
                    }}
                  >
                    {t("customer-dashboard.invoice-sum")}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      color: "rgba(0, 0, 0, 0.6)",
                      paddingTop: "5px",
                      textAlign: "right",
                    }}
                  >
                    {` ${Currency(
                      (invoiceBox?.transferSum ?? 0) +
                        (invoiceBox?.totalSum ?? 0)
                    )}`}
                  </Typography>
                </Grid>
              </Grid>
            </Paper>
            <Paper
              sx={{
                marginTop: "20px",
                p: "16px",
                height: 0.6,
                "& a": {
                  textDecoration: "none",
                },
              }}
              variant="outlined"
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  mb: "10px",
                }}
              >
                <Typography variant="subtitle1" component="h6">
                  {`${t("customer-dashboard.my-storages")} (${
                    storageListFields.length
                  })`}
                </Typography>
                {!loggedinAsCustomer && (
                  <Typography
                    onClick={() => {
                      if (
                        localStorage.getItem(STATUS_CODE) ==
                          CONTACT_STATUS.INVITED ||
                        localStorage.getItem(STATUS_CODE) ==
                          CONTACT_STATUS.NECT_RED ||
                        localStorage.getItem(STATUS_CODE) ==
                          CONTACT_STATUS.NECT_LATER
                      )
                        setNotVerifiedModalOpen(true);
                      else if (
                        !storageListFields ||
                        storageListFields.length == 0
                      )
                        navigate(URL.ADDSTORAGE);
                      else navigate(URL.STORAGE);
                    }}
                    sx={{
                      color: "rgba(21, 101, 192, 1)",
                      fontWaight: 700,
                      textTransform: "uppercase",
                      cursor: "pointer",
                    }}
                    variant="subtitle2"
                    component="h6"
                  >
                    {!storageListFields || storageListFields.length == 0
                      ? `+ ${t("customer-dashboard.add-storage")} `
                      : t("customer-dashboard.view-all")}
                  </Typography>
                )}
              </Box>

              {!storageListFields ||
                (storageListFields.length == 0 && (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "calc(100% - 28px)",
                    }}
                  >
                    <Typography
                      sx={{ color: "rgba(0, 0, 0, 0.38)", marginTop: "20px" }}
                      variant="caption"
                    >
                      {t("customer-dashboard.no-storages")}
                    </Typography>
                  </Box>
                ))}
              <Table
                sx={{
                  "& td": {
                    px: 0,
                  },
                }}
              >
                <TableBody>
                  {storageListFields
                    .slice(0, 4)
                    .map(
                      ({
                        msdyn_warehouseid,
                        metalName,
                        msdyn_name,
                        arades_verfuegbare_menge,
                      }) => (
                        <TableRow
                          key={msdyn_warehouseid}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell
                            align="left"
                            sx={{
                              fontWeight: 500,
                              display: "flex",
                            }}
                          >
                            <MetalCell metal={metalName}></MetalCell>
                            <Typography
                              mt="6px"
                              variant="caption"
                              component="p"
                            >
                              &nbsp;&nbsp;{`(${msdyn_name})`}
                            </Typography>
                          </TableCell>
                          <TableCell
                            align="right"
                            sx={{
                              whiteSpace: "nowrap",
                              color: "rgba(33, 150, 243, 1)",
                            }}
                          >
                            {!loggedinAsCustomer && (
                              <Link to={`${URL.STORAGE}/${msdyn_warehouseid}`}>
                                {arades_verfuegbare_menge}
                                &nbsp; Gramm &nbsp;
                                <FontAwesomeIcon
                                  color="rgba(0, 0, 0, 0.6)"
                                  size="lg"
                                  icon={faAngleRight}
                                />
                              </Link>
                            )}
                            {loggedinAsCustomer && (
                              <Typography
                                mt="6px"
                                variant="caption"
                                component="p"
                              >
                                &nbsp;&nbsp;{arades_verfuegbare_menge}
                              </Typography>
                            )}
                          </TableCell>
                        </TableRow>
                      )
                    )}
                </TableBody>
              </Table>
            </Paper>
          </Grid>
          <Grid item xs={12} md={6}>
            <Paper
              sx={{ p: "16px", height: 1, minHeight: "420px" }}
              mb="10"
              variant="outlined"
            >
              <Typography variant="subtitle1" component="h6">
                {t("customer-dashboard.my-transactions")}
              </Typography>
              {!transactionListFields ||
                (transactionListFields.length == 0 && (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "calc(100% - 28px)",
                    }}
                  >
                    <Typography
                      sx={{ color: "rgba(0, 0, 0, 0.38)", marginTop: "20px" }}
                      variant="caption"
                    >
                      {t("customer-dashboard.no-transactions")}
                    </Typography>
                  </Box>
                ))}
              <Table
                sx={{
                  "& td": {
                    px: 0,
                  },
                }}
              >
                <TableBody>
                  {transactionListFields.map(
                    (
                      {
                        storageName,
                        metalName,
                        createdon_formatted,
                        amount_formatted,
                        direction,
                      },
                      index
                    ) => (
                      <TableRow
                        key={metalName + index}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell
                          align="left"
                          sx={{
                            fontWeight: 500,
                          }}
                        >
                          <Box
                            align="left"
                            sx={{
                              fontWeight: 500,
                              display: "flex",
                            }}
                          >
                            <MetalCell metal={metalName}></MetalCell>
                            <Typography
                              mt="6px"
                              variant="caption"
                              component="p"
                            >
                              &nbsp;&nbsp;{`(${storageName})`}
                            </Typography>
                          </Box>
                          <Typography mt="6px" variant="caption" component="p">
                            {createdon_formatted}
                          </Typography>
                        </TableCell>

                        <TableCell
                          align="right"
                          sx={{
                            whiteSpace: "nowrap",
                            verticalAlign: "top",
                          }}
                        >
                          <ColorizeIncreaseAndDecrease
                            status={direction == "up"}
                          >
                            {amount_formatted}
                          </ColorizeIncreaseAndDecrease>
                        </TableCell>
                      </TableRow>
                    )
                  )}
                </TableBody>
              </Table>
            </Paper>
          </Grid>
          {/* <Grid item xs={12} md={6}>
            <CustomerActionCodeBox></CustomerActionCodeBox>
          </Grid> */}
        </Grid>
      </Box>
    </LoadingOverlay>
  );
};

export default CustomerDashboard;
