import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  Grid,
} from "@mui/material";
import { ColorizePriceStatus, LoadingOverlay } from "../common";
import { ERROR_CONTACT_US } from "../../core/constants";
import { useDispatch, useSelector } from "react-redux";
import { getPriceListAction } from "../../redux/slices/priceList";
import {
  pricesData,
  globalData,
  resetAll,
  resetActionStatus,
  resetError,
} from "../../redux/slices/priceList/priceList.slice";
import { useSnackbar } from "notistack";

function PriceList() {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(false);
  const prices = useSelector(pricesData);
  const priceListGlobalData = useSelector(globalData);

  useEffect(() => {
    dispatch(resetAll());
    dispatch(getPriceListAction());
  }, []);

  useEffect(() => {
    if (
      priceListGlobalData.actionStatus &&
      priceListGlobalData.actionType === "getDetailedList"
    ) {
      dispatch(resetActionStatus());
    }
  }, [priceListGlobalData.actionStatus]);

  useEffect(() => {
    if (priceListGlobalData.error != null) {
      enqueueSnackbar(priceListGlobalData.error + ERROR_CONTACT_US, {
        variant: "error",
        autoHideDuration: 20000,
      });
      dispatch(resetError());
    }
  }, [priceListGlobalData.error]);

  useEffect(() => {
    setLoading(priceListGlobalData.loading);
  }, [priceListGlobalData.loading]);

  return (
    <LoadingOverlay show={loading}>
      <Box className="container-card">
        <Box>
          <Typography variant="h5" component="h5">
            Edelmetallpreise
            {/* Precious Metals prices */}
          </Typography>
          <Typography variant="caption">15.05.2021 - 12:00 PM</Typography>
        </Box>
        <Box>
          <Grid mt="32px" alignItems="stretch" spacing={8} container>
            <Grid item xs={12} md={6} lg={4}>
              <Paper sx={{ p: "16px", height: 1 }} variant="outlined">
                <Typography variant="caption" component="h6">
                  Edelmetall
                  {/* Precious Metals */}
                </Typography>
                <Table
                  sx={{
                    "& td": {
                      px: 0,
                    },
                  }}
                >
                  <TableBody>
                    <TableRow
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell
                        align="left"
                        sx={{
                          fontWeight: 500,
                        }}
                      >
                        Gold
                      </TableCell>
                      <TableCell
                        align="right"
                        sx={{
                          whiteSpace: "nowrap",
                        }}
                      >
                        {prices.gold}
                      </TableCell>
                      <TableCell align="right">
                        <ColorizePriceStatus
                          percentage={prices.goldPercent}
                          status={prices.goldPercent[0] == "-" ? "down" : "up"}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell
                        align="left"
                        sx={{
                          fontWeight: 500,
                        }}
                      >
                        Silber
                      </TableCell>
                      <TableCell
                        align="right"
                        sx={{
                          whiteSpace: "nowrap",
                        }}
                      >
                        {prices.silver}
                      </TableCell>
                      <TableCell align="right">
                        <ColorizePriceStatus
                          percentage={prices.silverPercent}
                          status={prices.goldPercent[0] == "-" ? "down" : "up"}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell
                        align="left"
                        sx={{
                          fontWeight: 500,
                        }}
                      >
                        Palladium
                      </TableCell>
                      <TableCell
                        align="right"
                        sx={{
                          whiteSpace: "nowrap",
                        }}
                      >
                        {prices.palladium}
                      </TableCell>
                      <TableCell align="right">
                        <ColorizePriceStatus
                          percentage={prices.palladiumPercent}
                          status={
                            prices.palladiumPercent[0] == "-" ? "down" : "up"
                          }
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell
                        align="left"
                        sx={{
                          fontWeight: 500,
                        }}
                      >
                        Platin
                      </TableCell>
                      <TableCell
                        align="right"
                        sx={{
                          whiteSpace: "nowrap",
                        }}
                      >
                        {prices.platin}
                      </TableCell>
                      <TableCell align="right">
                        <ColorizePriceStatus
                          percentage={prices.platinPercent}
                          status={
                            prices.platinPercent[0] == "-" ? "down" : "up"
                          }
                        />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Paper>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <Paper sx={{ p: "16px", height: 1 }} variant="outlined">
                <Typography variant="caption" component="h6">
                  Wechselkurs
                  {/* Currencies */}
                </Typography>
                <Table
                  sx={{
                    "& td": {
                      px: 0,
                    },
                  }}
                >
                  <TableBody>
                    <TableRow
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell
                        align="left"
                        sx={{
                          fontWeight: 500,
                        }}
                      >
                        EURO / CHF
                      </TableCell>
                      <TableCell
                        align="right"
                        sx={{
                          whiteSpace: "nowrap",
                        }}
                      >
                        {prices.euroPerCHF}
                      </TableCell>
                      <TableCell align="right">
                        <ColorizePriceStatus
                          percentage={prices.euroPerCHFPercent}
                          status={
                            prices.euroPerCHFPercent[0] == "-" ? "down" : "up"
                          }
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell
                        align="left"
                        sx={{
                          fontWeight: 500,
                        }}
                      >
                        EURO / USD
                      </TableCell>
                      <TableCell
                        align="right"
                        sx={{
                          whiteSpace: "nowrap",
                        }}
                      >
                        {prices.euroPerUSD}
                      </TableCell>
                      <TableCell align="right">
                        <ColorizePriceStatus
                          percentage={prices.euroPerUSDPercent}
                          status={
                            prices.euroPerUSDPercent[0] == "-" ? "down" : "up"
                          }
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell
                        align="left"
                        sx={{
                          fontWeight: 500,
                        }}
                      >
                        EURO / GBP
                      </TableCell>
                      <TableCell
                        align="right"
                        sx={{
                          whiteSpace: "nowrap",
                        }}
                      >
                        {prices.euroPerGBP}
                      </TableCell>
                      <TableCell align="right">
                        <ColorizePriceStatus
                          percentage={prices.euroPerGBPPercent}
                          status={
                            prices.euroPerGBPPercent[0] == "-" ? "down" : "up"
                          }
                        />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Paper>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <Paper sx={{ p: "16px", height: 1 }} variant="outlined">
                <Typography variant="caption" component="h6">
                  Gold
                </Typography>
                <Table
                  sx={{
                    "& td": {
                      px: 0,
                    },
                  }}
                >
                  <TableBody>
                    <TableRow
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell
                        align="left"
                        sx={{
                          fontWeight: 500,
                        }}
                      >
                        Gold / Silber
                      </TableCell>
                      <TableCell
                        align="right"
                        sx={{
                          whiteSpace: "nowrap",
                        }}
                      >
                        {prices.goldPerSilver}
                      </TableCell>
                      <TableCell align="right">
                        <ColorizePriceStatus
                          percentage={prices.goldPerSilverPercent}
                          status={
                            prices.goldPerSilverPercent[0] == "-"
                              ? "down"
                              : "up"
                          }
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell
                        align="left"
                        sx={{
                          fontWeight: 500,
                        }}
                      >
                        Gold / Palladium
                      </TableCell>
                      <TableCell
                        align="right"
                        sx={{
                          whiteSpace: "nowrap",
                        }}
                      >
                        {prices.goldPerPalladium}
                      </TableCell>
                      <TableCell align="right">
                        <ColorizePriceStatus
                          percentage={prices.goldPerPalladiumPercent}
                          status={
                            prices.goldPerPalladiumPercent[0] == "-"
                              ? "down"
                              : "up"
                          }
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell
                        align="left"
                        sx={{
                          fontWeight: 500,
                        }}
                      >
                        Gold / Platin
                      </TableCell>
                      <TableCell
                        align="right"
                        sx={{
                          whiteSpace: "nowrap",
                        }}
                      >
                        {prices.goldPerPlatin}
                      </TableCell>
                      <TableCell align="right">
                        <ColorizePriceStatus
                          percentage={prices.goldPerPlatinPercent}
                          status={
                            prices.goldPerPlatinPercent[0] == "-"
                              ? "down"
                              : "up"
                          }
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell
                        align="left"
                        sx={{
                          fontWeight: 500,
                        }}
                      >
                        Dow Jones / Gold
                      </TableCell>
                      <TableCell
                        align="right"
                        sx={{
                          whiteSpace: "nowrap",
                        }}
                      >
                        {prices.dowJonesPerGold}
                      </TableCell>
                      <TableCell align="right">
                        <ColorizePriceStatus
                          percentage={prices.dowJonesPerGoldPercent}
                          status={
                            prices.dowJonesPerGoldPercent[0] == "-"
                              ? "down"
                              : "up"
                          }
                        />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Paper>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <Paper sx={{ p: "16px", height: 1 }} variant="outlined">
                <Typography variant="caption" component="h6">
                  Silber
                </Typography>
                <Table
                  sx={{
                    "& td": {
                      px: 0,
                    },
                  }}
                >
                  <TableBody>
                    <TableRow
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell
                        align="left"
                        sx={{
                          fontWeight: 500,
                        }}
                      >
                        Silber / Gold
                      </TableCell>
                      <TableCell
                        align="right"
                        sx={{
                          whiteSpace: "nowrap",
                        }}
                      >
                        {prices.silverPerGold}
                      </TableCell>
                      <TableCell align="right">
                        <ColorizePriceStatus
                          percentage={prices.silverPerGoldPercent}
                          status={
                            prices.silverPerGoldPercent[0] == "-"
                              ? "down"
                              : "up"
                          }
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell
                        align="left"
                        sx={{
                          fontWeight: 500,
                        }}
                      >
                        Silber / Palladium
                      </TableCell>
                      <TableCell
                        align="right"
                        sx={{
                          whiteSpace: "nowrap",
                        }}
                      >
                        {prices.silverPerPalladium}
                      </TableCell>
                      <TableCell align="right">
                        <ColorizePriceStatus
                          percentage={prices.silverPerPalladiumPercent}
                          status={
                            prices.silverPerPalladiumPercent[0] == "-"
                              ? "down"
                              : "up"
                          }
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell
                        align="left"
                        sx={{
                          fontWeight: 500,
                        }}
                      >
                        Silber / Platin
                      </TableCell>
                      <TableCell
                        align="right"
                        sx={{
                          whiteSpace: "nowrap",
                        }}
                      >
                        {prices.silverPerPlatin}
                      </TableCell>
                      <TableCell align="right">
                        <ColorizePriceStatus
                          percentage={prices.silverPerPlatinPercent}
                          status={
                            prices.silverPerPlatinPercent[0] == "-"
                              ? "down"
                              : "up"
                          }
                        />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Paper>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <Paper sx={{ p: "16px", height: 1 }} variant="outlined">
                <Typography variant="caption" component="h6">
                  Platin
                </Typography>
                <Table
                  sx={{
                    "& td": {
                      px: 0,
                    },
                  }}
                >
                  <TableBody>
                    <TableRow
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell
                        align="left"
                        sx={{
                          fontWeight: 500,
                        }}
                      >
                        Platin / Gold
                      </TableCell>
                      <TableCell
                        align="right"
                        sx={{
                          whiteSpace: "nowrap",
                        }}
                      >
                        {prices.platinPerGold}
                      </TableCell>
                      <TableCell align="right">
                        <ColorizePriceStatus
                          percentage={prices.platinPerGoldPercent}
                          status={
                            prices.platinPerGoldPercent[0] == "-"
                              ? "down"
                              : "up"
                          }
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell
                        align="left"
                        sx={{
                          fontWeight: 500,
                        }}
                      >
                        Platin / Silber
                      </TableCell>
                      <TableCell
                        align="right"
                        sx={{
                          whiteSpace: "nowrap",
                        }}
                      >
                        {prices.platinPerSilver}
                      </TableCell>
                      <TableCell align="right">
                        <ColorizePriceStatus
                          percentage={prices.platinPerSilverPercent}
                          status={
                            prices.platinPerSilverPercent[0] == "-"
                              ? "down"
                              : "up"
                          }
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell
                        align="left"
                        sx={{
                          fontWeight: 500,
                        }}
                      >
                        Platin / Palladium
                      </TableCell>
                      <TableCell
                        align="right"
                        sx={{
                          whiteSpace: "nowrap",
                        }}
                      >
                        {prices.platinPerPalladium}
                      </TableCell>
                      <TableCell align="right">
                        <ColorizePriceStatus
                          percentage={prices.platinPerPalladiumPercent}
                          status={
                            prices.platinPerPalladiumPercent[0] == "-"
                              ? "down"
                              : "up"
                          }
                        />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Paper>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <Paper sx={{ p: "16px", height: 1 }} variant="outlined">
                <Typography variant="caption" component="h6">
                  Palladium
                </Typography>
                <Table
                  sx={{
                    "& td": {
                      px: 0,
                    },
                  }}
                >
                  <TableBody>
                    <TableRow
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell
                        align="left"
                        sx={{
                          fontWeight: 500,
                        }}
                      >
                        Palladium / Gold
                      </TableCell>
                      <TableCell
                        align="right"
                        sx={{
                          whiteSpace: "nowrap",
                        }}
                      >
                        {prices.palladiumPerGold}
                      </TableCell>
                      <TableCell align="right">
                        <ColorizePriceStatus
                          percentage={prices.palladiumPerGoldPercent}
                          status={
                            prices.palladiumPerGoldPercent[0] == "-"
                              ? "down"
                              : "up"
                          }
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell
                        align="left"
                        sx={{
                          fontWeight: 500,
                        }}
                      >
                        Palladium / Silber
                      </TableCell>
                      <TableCell
                        align="right"
                        sx={{
                          whiteSpace: "nowrap",
                        }}
                      >
                        {prices.palladiumPerSilver}
                      </TableCell>
                      <TableCell align="right">
                        <ColorizePriceStatus
                          percentage={prices.palladiumPerSilverPercent}
                          status={
                            prices.palladiumPerSilverPercent[0] == "-"
                              ? "down"
                              : "up"
                          }
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell
                        align="left"
                        sx={{
                          fontWeight: 500,
                        }}
                      >
                        Palladium / Platin
                      </TableCell>
                      <TableCell
                        align="right"
                        sx={{
                          whiteSpace: "nowrap",
                        }}
                      >
                        {prices.palladiumPerPlatin}
                      </TableCell>
                      <TableCell align="right">
                        <ColorizePriceStatus
                          percentage={prices.palladiumPerPlatinPercent}
                          status={
                            prices.palladiumPerPlatinPercent[0] == "-"
                              ? "down"
                              : "up"
                          }
                        />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Paper>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </LoadingOverlay>
  );
}

export default PriceList;
