import axios from "axios";
import {
  BASE_URL,
  BYPASS_ADOBE_SIGN,
  EMAIL_IMPERSONATED_USER,
} from "../../core/apiconfig/environment";
import { TOKEN_NAME } from "../../core/apiconfig/constantApi";
import { STORAGE_TYPE, WORKFLOW_IDS } from "../../core/constants";

const signContractWorkFlowFunc = async (
  { storageType, ownerId, wait14Days },
  { getState }
) => {
  let workflowId = "";

  if (BYPASS_ADOBE_SIGN) {
    if (storageType == STORAGE_TYPE.COMPANY)
      workflowId = WORKFLOW_IDS.SIGN_STORAGE_CONTRACT_COMPANY_BYPASS;
    else if (storageType == STORAGE_TYPE.PARTNER)
      workflowId = WORKFLOW_IDS.SIGN_STORAGE_CONTRACT_PARTNER_BYPASS;
    else workflowId = WORKFLOW_IDS.SIGN_STORAGE_CONTRACT_CONTACT_BYPASS;
  } else {
    if (storageType == STORAGE_TYPE.MYSELF)
      if (wait14Days)
        workflowId = WORKFLOW_IDS.SIGN_STORAGE_CONTRACT_MYSELF_WOL;
      else workflowId = WORKFLOW_IDS.SIGN_STORAGE_CONTRACT_MYSELF_WL;
    else if (storageType == STORAGE_TYPE.COMPANY)
      if (wait14Days)
        workflowId = WORKFLOW_IDS.SIGN_STORAGE_CONTRACT_COMPANY_WOL;
      else workflowId = WORKFLOW_IDS.SIGN_STORAGE_CONTRACT_COMPANY_WL;
    else if (storageType == STORAGE_TYPE.UNDER_AGE)
      if (wait14Days)
        workflowId = WORKFLOW_IDS.SIGN_STORAGE_CONTRACT_UNDER_AGE_WOL;
      else workflowId = WORKFLOW_IDS.SIGN_STORAGE_CONTRACT_UNDER_AGE_WL;
    else if (storageType == STORAGE_TYPE.PARTNER)
      if (wait14Days)
        workflowId = WORKFLOW_IDS.SIGN_STORAGE_CONTRACT_PARTNER_WOL;
      else workflowId = WORKFLOW_IDS.SIGN_STORAGE_CONTRACT_PARTNER_WL;
  }

  let headers = !BYPASS_ADOBE_SIGN
    ? {
        "Content-Type": "application/json",
        Authorization: `bearer ${localStorage.getItem(TOKEN_NAME)}`,
        MSCRMCallerID: EMAIL_IMPERSONATED_USER, // FIXME this should be handled in backend
      }
    : {
        "Content-Type": "application/json",
        Authorization: `bearer ${localStorage.getItem(TOKEN_NAME)}`,
      };

  const config = {
    headers: headers,
  };

  const result = await axios.post(`${BASE_URL}data/workflows/${workflowId}`, { EntityId: ownerId }, config);

  if (
    !result?.data?.status ||
    (result.data.status != 204 && result.data.status != 200)
  )
    throw Error("Vertragsunterzeichnungsprozess fehlgeschlagen"); //("Sign contract workflow failed ");
};

export default signContractWorkFlowFunc;
