import React, { useState, useEffect } from "react";
import { DataTable, LoadingOverlay } from "../common";
import {
  Box,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Link,
} from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { useDispatch, useSelector } from "react-redux";
import {
  commissionHeaderList,
  commissionListAG,
  commissionListGmbh,
  commissionListGoldGmbh,
  // sumAGBetrag,
  // sumGmbhBetrag,
  // sumGoldGmbhBetrag,
  globalData,
  resetError,
  resetAll,
  resetActionStatus,
} from "../../redux/slices/commissions/commissions.slice";
import {
  getCommissionListAction,
  getCommissionHeaderListAction,
} from "../../redux/slices/commissions";
import { useSnackbar } from "notistack";
import { WORKSPACE_ID, WORKSPACE_NAME } from "../../core/apiconfig/constantApi";
import { ERROR_CONTACT_US } from "../../core/constants";
import { useTranslation } from "react-i18next";
import { localeFormat } from "../../core/utils/convert";

const CommissionList = () => {
  const [selectedTab, setSelectedTab] = useState("1");

  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);

  const commissionListHeader = useSelector(commissionHeaderList);
  const commissionAGList = useSelector(commissionListAG);
  const commissionGmbhList = useSelector(commissionListGmbh);
  const commissionGoldGmbhList = useSelector(commissionListGoldGmbh);

  const [comAGListFiltered, setComAGListFiltered] = useState();
  const [comGmbhListFiltered, setComGmbhListFiltered] = useState();
  const [comGoldGmbhListFiltered, setComGoldGmbhListFiltered] = useState();

  const [selectedMonth, setSelectedMonth] = useState(null);

  // const sumAGBetragInfo = useSelector(sumAGBetrag);
  // const sumGmbhBetragInfo = useSelector(sumGmbhBetrag);
  // const sumGoldGmbhBetragInfo = useSelector(sumGoldGmbhBetrag);

  const commissionsGlobalData = useSelector(globalData);

  useEffect(() => {
    dispatch(resetAll());
    dispatch(
      getCommissionHeaderListAction({
        workspaceId: localStorage.getItem(WORKSPACE_ID),
        workspaceName: localStorage.getItem(WORKSPACE_NAME),
      })
    );
  }, []);

  useEffect(() => {
    setLoading(commissionsGlobalData.loading);
  }, [commissionsGlobalData.loading]);

  useEffect(() => {
    if (
      commissionsGlobalData.actionStatus &&
      commissionsGlobalData.actionType === "getCommissionHeaderList"
    ) {
      dispatch(resetActionStatus());
      dispatch(
        getCommissionListAction({
          workspaceId: localStorage.getItem(WORKSPACE_ID),
        })
      );
    } else if (
      commissionsGlobalData.actionStatus &&
      commissionsGlobalData.actionType === "getCommissionList"
    ) {
      dispatch(resetActionStatus());
      if (commissionListHeader.length > 0)
        loadRelatedDetails(commissionListHeader[0].month);
    }
  }, [commissionsGlobalData.actionStatus]);

  useEffect(() => {
    if (commissionsGlobalData.error != null) {
      enqueueSnackbar(commissionsGlobalData.error + ERROR_CONTACT_US, {
        variant: "error",
        autoHideDuration: 20000,
      });
      dispatch(resetError());
    }
  }, [commissionsGlobalData.error]);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const loadRelatedDetails = (hedearMonth) => {
    setSelectedMonth(hedearMonth);
    setComAGListFiltered(
      commissionAGList.filter((x) =>
        x.arades_geldeingang
          ? new Date(x.arades_geldeingang).getMonth() + 1 == hedearMonth * 1
          : false
      )
    );
    setComGmbhListFiltered(
      commissionGmbhList.filter((x) =>
        x.arades_geldeingang
          ? new Date(x.arades_geldeingang).getMonth() + 1 == hedearMonth * 1
          : false
      )
    );
    setComGoldGmbhListFiltered(
      commissionGoldGmbhList.filter((x) =>
        x.arades_geldeingang
          ? new Date(x.arades_geldeingang).getMonth() + 1 == hedearMonth * 1
          : false
      )
    );
  };

  return (
    <div className="container-card">
      <LoadingOverlay show={loading}>
        <Typography variant="h5" sx={{ fontWeight: 500 }}>
          {t("commission-list.title")}
        </Typography>
        <TableContainer component={Box}>
          <Table aria-label="simple table">
            <TableHead sx={{ backgroundColor: "rgba(33, 33, 33, 0.08)" }}>
              <TableRow>
                <TableCell>{t("commission-list.year")}</TableCell>
                <TableCell>{t("commission-list.month")}</TableCell>
                <TableCell align="right">{t("commission-list.ag")}</TableCell>
                <TableCell align="right">{t("commission-list.gmbh")}</TableCell>
                <TableCell align="right">
                  {t("commission-list.gold-gmbh")}
                </TableCell>
                <TableCell align="right">
                  {t("commission-list.total")}
                </TableCell>
                <TableCell>{t("commission-list.ag-docs")}</TableCell>
                <TableCell>{t("commission-list.gmbh-docs")}</TableCell>
                <TableCell>{t("commission-list.gold-gmbh-docs")}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {commissionListHeader?.map((row) => (
                <TableRow
                  key={row.month}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  onClick={() => {
                    loadRelatedDetails(row.month);
                  }}
                  selected={selectedMonth === row.month}
                  classes={{ selected: {} }}
                >
                  <TableCell>{row.year}</TableCell>
                  <TableCell>{row.month_formatted}</TableCell>
                  <TableCell align="right">
                    {row.ratiorAgAmount_formatted}
                  </TableCell>
                  <TableCell align="right">
                    {row.ratiorGmbhAmount_formatted}
                  </TableCell>
                  <TableCell align="right">
                    {row.ratiorGoldAmount_formatted}
                  </TableCell>
                  <TableCell align="right">{row.total_formatted}</TableCell>
                  <TableCell>
                    {row.ratiorAgAmount && (
                      <Box sx={{ display: "flex" }}>
                        <Link
                          href={row.ratiorAg_non_detail_link}
                          target="_blank"
                        >
                          <Typography>Deckblatt</Typography>
                        </Link>
                        <Link
                          sx={{ paddingLeft: "10px" }}
                          href={row.ratiorAg_detail_link}
                          target="_blank"
                        >
                          <Typography>Details </Typography>
                        </Link>
                      </Box>
                    )}
                  </TableCell>
                  <TableCell>
                    {row.ratiorGmbhAmount && (
                      <Box sx={{ display: "flex" }}>
                        <Link
                          href={row.ratiorGmbh_non_detail_link}
                          target="_blank"
                        >
                          <Typography>Deckblatt</Typography>
                        </Link>
                        <Link
                          sx={{ paddingLeft: "10px" }}
                          href={row.ratiorGmbh_detail_link}
                          target="_blank"
                        >
                          <Typography>Details</Typography>
                        </Link>
                      </Box>
                    )}
                  </TableCell>
                  <TableCell>
                    {row.ratiorGoldAmount && (
                      <Box sx={{ display: "flex" }}>
                        <Link
                          href={row.ratiorGold_non_detail_link}
                          target="_blank"
                        >
                          <Typography>Deckblatt</Typography>
                        </Link>
                        <Link
                          sx={{ paddingLeft: "10px" }}
                          href={row.ratiorGold_detail_link}
                          target="_blank"
                        >
                          <Typography>Details</Typography>
                        </Link>
                      </Box>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        {/* <DataTable
        title={""}
        entityName={""}
        data={commissionListHeader}
        size={"sm"}
        columns={[
          {
            name: "year",
            caption: t("commission-list.year"),
          },
          {
            name: "month_formatted",
            caption: t("commission-list.month"),
          },
          {
            name: "ratiorAgAmount_formatted",
            caption: t("commission-list.ag"),
          },
          {
            name: "ratiorGmbhAmount_formatted",
            caption: t("commission-list.gmbh"),
          },
          {
            name: "ratiorGoldAmount_formatted",
            caption: t("commission-list.gold-gmbh"),
          },
          {
            name: "total_formatted",
            caption: t("commission-list.total"),
          },
        ]}
      ></DataTable> */}
        {/* <Grid container spacing={8}>
          <Grid item sm={12}>
            <Typography sx={{ fontWeight: 400, color: "rgba(0, 0, 0, 0.85)" }}>
              {t("commission-list.title")}
            </Typography>
          </Grid>
          <Grid item sm={12}>
            {" "}
            <Typography sx={{ fontWeight: 400, color: "rgba(0, 0, 0, 0.65)" }}>
              Ratior AG:{" " + Currency(sumAGBetragInfo)}
            </Typography>
          </Grid>
          <Grid item sm={12}>
            {" "}
            <Typography sx={{ fontWeight: 400, color: "rgba(0, 0, 0, 0.65)" }}>
              Ratior GmbH:{" " + Currency(sumGmbhBetragInfo)}
            </Typography>
          </Grid>
          <Grid item sm={12}>
            <Typography sx={{ fontWeight: 400, color: "rgba(0, 0, 0, 0.65)" }}>
              Ratior Gold GmbH:{" " + Currency(sumGoldGmbhBetragInfo)}
            </Typography>
          </Grid>
          <Grid item sm={12}>
            <Typography sx={{ fontWeight: 400, color: "rgba(0, 0, 0, 0.65)" }}>
              {t("commission-list.total")} :
              {" " +
                Currency(
                  sumGoldGmbhBetragInfo + sumGmbhBetragInfo + sumAGBetragInfo
                )}
            </Typography>
          </Grid>
        </Grid> */}
        <Box sx={{ width: "100%", typography: "body1", paddingTop: "10px" }}>
          <TabContext value={selectedTab}>
            <Box
              sx={{
                borderBottom: 1,
                borderColor: "divider",
                marginTop: "10px",
              }}
            >
              <TabList
                onChange={handleTabChange}
                aria-label="lab API tabs example"
              >
                <Tab label="Ratior AG" value="1" />
                <Tab label="Ratior GmbH" value="2" />
                <Tab label="Ratior Gold GmbH" value="3" />
              </TabList>
            </Box>
            <TabPanel value="1">
              <Box
                sx={{
                  marginTop: "10px",
                }}
              >
                <DataTable
                  title={t("commission-list.commission-for-fee")}
                  entityName={"salesorder"}
                  size={"sm"}
                  defaultPageSize={10}
                  data={comAGListFiltered}
                  columns={[
                    {
                      name: "arades_geldeingang_formatted",
                      caption: t("commission-list.money-received"),
                    },
                    {
                      name: "arades_lagerplatzstammnummer",
                      caption: t("commission-list.customer-number"),
                    },
                    {
                      name: "customerName",
                      caption: t("commission-list.storage-owner"),
                    },
                    {
                      name: "discountpercentage_formatted",
                      caption: t("commission-list.discount"),
                      alignment: "right",
                    },
                    {
                      name: "totalamount_formatted",
                      caption: t("commission-list.amount"),
                      alignment: "right",
                    },
                    {
                      name: "arades_prozentsatz_formatted",
                      caption: t("commission-list.commission-rate"),
                      alignment: "right",
                    },
                    {
                      name: "arades_betrag",
                      caption: t("commission-list.commission-amount"),
                      alignment: "right",
                      format: ({ value }) => localeFormat(value, t("general.locale-for-number-formatting"), { significantDigits: 2, currency: "EUR" }),
                    },
                  ]}
                ></DataTable>
              </Box>
            </TabPanel>
            <TabPanel value="2">
              <Box
                sx={{
                  marginTop: "10px",
                }}
              >
                <DataTable
                  title={t("commission-list.tab2-description")}
                  entityName={"salesorder"}
                  size={"sm"}
                  defaultPageSize={10}
                  data={comGmbhListFiltered}
                  columns={[
                    {
                      name: "arades_geldeingang_formatted",
                      caption: t("commission-list.money-received"),
                    },
                    {
                      name: "detailDate_formatted",
                      caption: t("commission-list.buy-date"),
                    },
                    {
                      name: "storageName",
                      caption: t("commission-list.storage-number"),
                    },
                    {
                      name: "customerName",
                      caption: t("commission-list.storage-owner"),
                    },
                    {
                      name: "arades_ueberweisungsbetrag_formatted",
                      caption: t("commission-list.transfer-amount"),
                      alignment: "right",
                    },
                    {
                      name: "name1",
                      caption: t("commission-list.discount-level"),
                      alignment: "right",
                    },
                    {
                      name: "arades_anlagebetrag_formatted",
                      caption: t("commission-list.invest-amount"),
                      alignment: "right",
                    },
                    {
                      name: "productName",
                      caption: t("commission-list.metal-type"),
                    },
                    {
                      name: "arades_rabatt_partner_formatted",
                      caption: t("commission-list.partner-discount"),
                      alignment: "right",
                    },
                    {
                      name: "arades_prozentsatz_formatted",
                      caption: t("commission-list.commission-rate"),
                      alignment: "right",
                    },
                    {
                      name: "arades_betrag",
                      caption: t("commission-list.commission-amount"),
                      alignment: "right",
                      format: ({ value }) => localeFormat(value, t("general.locale-for-number-formatting"), { significantDigits: 2, currency: "EUR" }),
                    },
                  ]}
                ></DataTable>
              </Box>
            </TabPanel>
            <TabPanel value="3">
              <Box
                sx={{
                  marginTop: "10px",
                }}
              >
                <DataTable
                  title={t("commission-list.tab3-description")}
                  entityName={"salesorder"}
                  size={"sm"}
                  defaultPageSize={10}
                  data={comGoldGmbhListFiltered}
                  columns={[
                    {
                      name: "arades_geldeingang_formatted",
                      caption: t("commission-list.money-received"),
                    },
                    {
                      name: "detailDate_formatted",
                      caption: t("commission-list.buy-date"),
                    },
                    {
                      name: "storageName",
                      caption: t("commission-list.storage-number"),
                    },
                    {
                      name: "customerName",
                      caption: t("commission-list.storage-owner"),
                    },
                    {
                      name: "arades_ueberweisungsbetrag_formatted",
                      caption: t("commission-list.transfer-amount"),
                      alignment: "right",
                    },
                    {
                      name: "name1",
                      caption: t("commission-list.discount-level"),
                      alignment: "right",
                    },
                    {
                      name: "arades_anlagebetrag_formatted",
                      caption: t("commission-list.invest-amount"),
                      alignment: "right",
                    },
                    {
                      name: "productName",
                      caption: t("commission-list.metal-type"),
                    },
                    {
                      name: "arades_rabatt_partner_formatted",
                      caption: t("commission-list.partner-discount"),
                      alignment: "right",
                    },
                    {
                      name: "arades_prozentsatz_formatted",
                      caption: t("commission-list.commission-rate"),
                      alignment: "right",
                    },
                    {
                      name: "arades_betrag",
                      caption: t("commission-list.commission-amount"),
                      alignment: "right",
                      format: ({ value }) => localeFormat(value, t("general.locale-for-number-formatting"), { significantDigits: 2, currency: "EUR" }),
                    },
                  ]}
                ></DataTable>
              </Box>
            </TabPanel>
          </TabContext>
        </Box>
      </LoadingOverlay>
    </div>
  );
};
export default CommissionList;
