import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, Grid, Button, TextField, Typography } from "@mui/material";
import {
  Datepicker,
  FormSteperStructure,
  Input,
  ConfirmModal,
  LoadingOverlay,
} from "../../../common";
import moment from "moment";
import { useSnackbar } from "notistack";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  getPartnerDataAction,
  verificationSendSMSCodeAction,
} from "../../../../redux/slices/storage";
import {
  storageOtherPersonStep,
  setOtherPersonStep,
  storageFullData,
  resetError,
  resetActionStatus,
  setVerificationType,
} from "../../../../redux/slices/storage/storage.slice";
import { WORKSPACE_NAME } from "../../../../core/apiconfig/constantApi";
import { ERROR_CONTACT_US } from "../../../../core/constants";

function OtherPersonInfo({ handleCurrentStep }) {
  const { t } = useTranslation();

  const requiredMsg = "erforderlich";
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const otherPersonData = useSelector(storageOtherPersonStep);
  const storageData = useSelector(storageFullData);

  const [openConfirmPartner, setOpenConfirmPartner] = useState(false);
  const [loading, setLoading] = useState(false);

  const workspaceName = localStorage.getItem(WORKSPACE_NAME);

  const schema = yup.object().shape({
    partner_key: yup.string().required(requiredMsg),
    partner_birth_date: yup
      .string()
      .required(requiredMsg)
      .test(
        "DOB",
        "Alter unter 18 Jahren ist nicht akzeptabel", //"Age below 18 is not acceptable"
        (value) => {
          return moment().diff(moment(value), "years") >= 18;
        }
      )
      .nullable(),
  });
  const {
    control,
    handleSubmit,
    getValues,
    setError,
    formState: { errors },
  } = useForm({
    mode: "all",
    defaultValues: {
      partner_key: otherPersonData.partner_key,
      partner_birth_date: otherPersonData.partner_birth_date,
      agreed: false,
    },
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (
      otherPersonData.partnerPhoneNumber &&
      storageData?.globalData?.actionStatus &&
      !storageData.error
    ) {
      setOpenConfirmPartner(true);
      dispatch(setVerificationType("partner"));
      dispatch(resetActionStatus());
    } else if (storageData.error != null) {
      enqueueSnackbar(storageData.error + ERROR_CONTACT_US, {
        variant: "error",
        autoHideDuration: 20000,
      });
      dispatch(resetError());
    }
  }, [
    storageData?.loading,
    storageData?.globalData?.actionStatus,
    ,
    storageData.error,
  ]);

  const onSubmit = (data) => {
    dispatch(setOtherPersonStep(data));
    dispatch(getPartnerDataAction({ ratiorKey: data.partner_key, birthdate: data.partner_birth_date }));
    //handleCurrentStep("next");
  };

  useEffect(() => {
    setLoading(storageData?.loading);
  }, [storageData?.loading]);

  return (
    <LoadingOverlay show={loading}>
      <ConfirmModal
        open={openConfirmPartner}
        showCloseButton={false}
        size="md"
        message={
          <div>
            Sie eröffnen vier gemeinsame Lagerplätze:<br></br>
            <ul>
              <li>Gold</li>
              <li>Silber</li>
              <li>Platin</li>
              <li>Palladium</li>
            </ul>
            auf die Namen "{workspaceName}" and "{otherPersonData.partnerName}"
            (im Folgenden zur Vereinfachung „Kunde“ genannt).
          </div>
        }
        noImage={true}
        acceptTitle={"Weiter"}
        onAccept={() => {
          setOpenConfirmPartner(false);
          dispatch(
            verificationSendSMSCodeAction({
              mobileNumber: otherPersonData.partnerPhoneNumber,
            })
          );
          handleCurrentStep("next");
        }}
        rejectTitle={"Zurück"}
        onReject={() => {
          setOpenConfirmPartner(false);
          dispatch(
            setOtherPersonStep({
              partner_key: "",
              partner_birth_date: "",
              partnerPhoneNumber: "",
              partnerId: "",
              partnerName: "",
            })
          );
        }}
      ></ConfirmModal>

      <Box
        sx={{
          maxWidth: "816px",
          margin: "0 auto",
          marginTop: "32px",
        }}
      >
        <Box mt="120px" mb="120px">
          <Grid spacing={8} direction="column" container>
            <Grid item>
              <Typography variant="body2" sx={{ color: "rgba(0, 0, 0, 0.57)" }}>
                Bitte geben Sie den RatiorKey der Person an, mit der Sie
                gemeinsame Lagerplätze eröffnen möchten
              </Typography>
            </Grid>
            <Grid item>
              <Input
                control={control}
                error={Boolean(errors.partner_key)}
                helperText={errors.partner_key?.message}
                name="partner_key"
                label="Partner Ratior key*"
              />
            </Grid>
            <Grid item>
              <Datepicker
                label="Geburtsdatum*"
                control={control}
                name="partner_birth_date"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={Boolean(errors.partner_birth_date)}
                    helperText={errors.partner_birth_date?.message}
                    onBlur={() => {
                      if (getValues("partner_birth_date") === "") {
                        setError("partner_birth_date", {
                          type: "manual",
                          message: requiredMsg,
                        });
                      }
                    }}
                  />
                )}
              />
            </Grid>
          </Grid>
        </Box>

        <FormSteperStructure.Footer>
          <Button
            onClick={handleSubmit(onSubmit)}
            fullWidth
            sx={{ bgcolor: "info.main", maxWidth: "192px" }}
            variant="contained"
          >
            Weiter
          </Button>
        </FormSteperStructure.Footer>
      </Box>
    </LoadingOverlay>
  );
}

export default OtherPersonInfo;
