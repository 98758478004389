import React from 'react'
import {Typography} from '@mui/material'
import { useState,useEffect } from 'react';


function ColorizePriceStatus({percentage,status}) {
  const [color,setColor]=useState('initial')
  
  const statusColor=()=>{
    if(status==='up'){
      setColor('green')
    }
    else if(status==='down'){
      setColor('red')
    }
    else {
      setColor('initial')
    }
  }
  const statusCarret=()=>{
    let styles={}
    if(status==='down'){
      styles={
        borderTop:'5px solid'
      }
     
      
    }
    else if(status==='up'){
      styles={
        borderBottom:'5px solid'
      }
    }
    else {
      styles={
      display:'none'
      }
    }
    return styles
  }

  useEffect(()=>{
    statusColor()
    statusCarret()
  },[status])
  return (
    <>
      <Typography sx={{
            color:color,
            display:'flex',
            alignItems:'center',
            justifyContent:'flex-end'
        }}> 
            <Typography 
            component="span">{percentage}</Typography> 
            <Typography 
            component="span"
            sx={{
                ml:'8px',
                width: 0, 
                height: 0,
                borderLeft: '5px solid transparent',
                borderRight: '5px solid transparent',
                ...statusCarret()
            }}
      
            ></Typography>
        </Typography>
    </>
  )
}

export default ColorizePriceStatus