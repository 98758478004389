export const calculateAge = (birthdate) => {
  var bdate = new Date(birthdate);
  var ageDifMs = Date.now() - bdate.getTime();
  var ageDate = new Date(ageDifMs); // miliseconds from epoch
  return Math.abs(ageDate.getUTCFullYear() - 1970);
};

export const calculateDaysDiff = (date1, date2) => {
  var Difference_In_Time = date2.getTime() - date1.getTime();
  var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
  return Difference_In_Days;
};
