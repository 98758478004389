import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { Controller } from "react-hook-form";

const AutoComplete = React.forwardRef(
  ({ control = null, inputProps = {}, ...props }, ref) => {
    const [defaultVal, setDefaultVal] = useState();

    // useEffect(() => {
    //   if (props.initialValue) {
    //     var selectedItem = props.options.filter((item) => {
    //       return item.key == props.value;
    //     });
    //     if (selectedItem.length > 0) setDefaultVal(selectedItem[0]);
    //     else setDefaultVal(null);
    //   } else setDefaultVal(null);
    // }, [[props.initialValue]]);

    const handleChange = (e, value) => {
      let event = {
        target: { name: props.name, value: value },
      };
      props.onChange(event);
    };

    return (
      <>
        <div className="d-flex ml-4 mr-4 flex-column ">
          {control ? (
            <Controller
              control={control}
              name={props.name}
              render={({
                field: {
                  onChange: formOnChange,
                  onBlur: formOnBlur,
                  value: formValue,
                  name: formName,
                  ref: fromRef,
                },
                fieldState: { invalid, isTouched, isDirty, error: formError },
                formState,
              }) => (
                <Autocomplete
                  {...props}
                  //disablePortal
                  onChange={(e, data) => {
                    formOnChange(data?.key ?? "");
                  }}
                  onBlur={formOnBlur}
                  value={formValue}
                  inputRef={fromRef}
                  name={formName}
                  inputProps={{
                    autoComplete: "new-password",
                    form: {
                      autoComplete: "off",
                    },
                    ...inputProps,
                  }}
                  id="combo-box-demo"
                  options={props.options}
                  //defaultValue={defaultVal}
                  //inputValue={defaultVal}
                  getOptionLabel={(option) => {
                    return (
                      option?.value ??
                      props.options.find((x) => x.key === option)?.value ??
                      ""
                    );
                  }}
                  sx={{ width: "100%" }}
                  fullWidth
                  variant="outlined"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={props.label}
                      error={props.error}
                      helperText={props.helperText}
                      onBlur={props.onBlur}
                      //value={props.value}
                      //defaultValue={defaultVal ? defaultVal.value : ""}
                      fullWidth
                    />
                  )}
                />
              )}
            />
          ) : (
            <Autocomplete
              {...props}
              //disablePortal
              inputRef={ref}
              inputProps={{
                autoComplete: "new-password",
                form: {
                  autoComplete: "off",
                },
                ...inputProps,
              }}
              id="combo-box-demo"
              options={props.options}
              //defaultValue={defaultVal}
              //inputValue={defaultVal}
              getOptionLabel={(option) => (option.value ? option.value : "")}
              sx={{ width: "100%" }}
              fullWidth
              onChange={handleChange}
              variant="outlined"
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={props.label}
                  error={props.error}
                  helperText={props.helperText}
                  onBlur={props.onBlur}
                  //value={props.value}
                  //defaultValue={defaultVal ? defaultVal.value : ""}
                  fullWidth
                />
              )}
            />
          )}
        </div>
      </>
    );
  }
);

export default AutoComplete;
