import React, { useReducer, useContext } from "react";

import { REGISTER_PERSONAL_INFO_TYPE } from "../type";

import RegisterContext from "./regsiterContext";
import RegisterReducer from "./registerReducer";

export const RegisterStateProvider = (props) => {
  const initState = {
    step: 0,
    personalInfo: null,
  };

  const [state, dispatch] = useReducer(RegisterReducer, initState);

  // Actions

  const personalInfoAction = (value) => {
    dispatch({
      type: REGISTER_PERSONAL_INFO_TYPE,
      payload: value,
    });
  };

  return (
    <RegisterContext.Provider
      value={{
        personalInfo: state.personalInfo,
        personalInfoAction,
      }}
    >
      {props.children}
    </RegisterContext.Provider>
  );
};

export const useRegisterState = () => useContext(RegisterContext);
