import React, { useState, useEffect } from "react";
import {
  Input,
  Datepicker,
  Select,
  Button,
  NectRedirect,
  PhoneInput,
  LoadingOverlay,
} from "../common";
import { useApi } from "../../core/hooks/useApi";
import { useSnackbar } from "notistack";
import { Box, Tab, Grid, Typography } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { BASE_URL } from "../../core/apiconfig/environment";
import { CONTACT_ID, WORKSPACE_ID } from "../../core/apiconfig/constantApi";
import PasswordInputs from "../home/common/passwordInputs";
import MUIButtom from "@mui/material/Button";
import { Link, useSearchParams } from "react-router-dom";
import "./contactProfile.scss";
import { URL, ERROR_CONTACT_US } from "../../core/constants";
import { BASE_URL_UI } from "../../core/apiconfig/environment";
import { useTranslation } from "react-i18next";
import {
  globalsCountries,
  globalsGenders,
  globalsTitles,
} from "../../redux/slices/globals/globals.slice";
import { changeContactInfoWorkflowAction } from "../../redux/slices/profile";
import { useDispatch, useSelector } from "react-redux";

const ContactProfile = ({}) => {
  const { get, patch, post } = useApi();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [searchParams, setSearchParams] = useSearchParams();

  const countries = useSelector(globalsCountries);
  const genders = useSelector(globalsGenders);
  const titles = useSelector(globalsTitles);

  const [passwordIsValid, setPasswordIsValid] = useState(false);
  const [oldPass, setOldPass] = useState();
  const [newPass, setNewPass] = useState();
  const [touched, setTouched] = useState({});
  const [loading, setLoading] = useState(false);
  const [currentTab, setCurrentTab] = React.useState("1");

  const [value, setValue] = useState({
    contactid: null,
    firstname: "",
    lastname: "",
    telephone1: "",
    telephone2: "",
    mobilephone: "",
    address1_name: "",
    address1_line1: "",
    address1_line2: "",
    address1_postalcode: "",
    emailaddress1: "",
    arades_anrede: "",
    arades_titel: "",
    arades_land: "",
    birthdate: null,
    arades_alias: "",
    arades_ratior_key: "",
  });

  const requiredMsg = t("validation.required");

  useEffect(() => {
    loadPage();
  }, []);

  const loadPage = async () => {
    setContact();

    const firstLogin = Boolean(searchParams.get("first-login"));
    if (firstLogin)
      dispatch(
        changeContactInfoWorkflowAction(localStorage.getItem(CONTACT_ID))
      );
  };

  const setContact = async () => {
    const result = await get(`${BASE_URL}data/contacts/${localStorage.getItem(WORKSPACE_ID)}/`);
    const contact = result.data;
    if (contact) {
      console.log(contact.contactid);
      setValue({
        ...value,
        contactid: contact.contactid,
        telephone1: contact.telephone1 ?? "",
        telephone2: contact.telephone2 ?? "",
        arades_anrede: contact.arades_anrede ? contact.arades_anrede : "",
        arades_titel: contact._arades_titel_value,
        arades_alias: contact.arades_alias,

        firstname: contact.firstname,
        lastname: contact.lastname,
        mobilephone: contact.mobilephone ?? "",
        emailaddress1: contact.emailaddress1,
        birthdate: contact.birthdate,
        arades_ratior_key: contact.arades_ratior_key,
        arades_land: contact._arades_land1_value,
        address1_name: contact.address1_name,
        address1_line1: contact.address1_line1,
        address1_line2: contact.address1_line2,
        address1_postalcode: contact.address1_postalcode,
        address1_city: contact.address1_city,
      });
    }
  };

  const saveContact = async (tab) => {
    let saveObj = {};
    if (tab == "personal") {
      saveObj = {
        telephone1: value.telephone1,
        telephone2: value.telephone2
      };
      if (value.arades_titel)
        saveObj["arades_titel@odata.bind"] =
          "/arades_titels(" + value.arades_titel + ")";
      else saveObj["arades_titel@odata.bind"] = null;
    } else if (tab == "address") {
      saveObj = {
        address1_name: value.address1_name,
        address1_line1: value.address1_line1,
        address1_line2: value.address1_line2,
        address1_postalcode: value.address1_postalcode,
        address1_city: value.address1_city,
      };
      if (value.arades_land)
        saveObj["arades_land1@odata.bind"] =
          "/arades_lands(" + value.arades_land + ")";
      else saveObj["arades_land1@odata.bind"] = null;
    } else if (tab == "password")
      saveObj = {
        telephone1: value.telephone1,
        arades_alias: value.arades_alias,
      };

    setLoading(true);
    const result = await patch(`${BASE_URL}data/contacts/${value.contactid}/`, saveObj);
    if (result.status == 200) {
      if (tab == "password" && oldPass && newPass) {
        let passObj = {
          oldPassword: oldPass,
          password: newPass,
          confirmPassword: newPass,
        };
        let passResult = await post(`${BASE_URL}user/password/change`, passObj);
        if (passResult.status == 200) {
          setLoading(false);
          enqueueSnackbar(t("messages.success"), {
            variant: "success",
            autoHideDuration: 3000,
          });
        } else if (
          passResult.data &&
          passResult.data.length > 0 &&
          passResult.data[0].msg
        ) {
          setLoading(false);
          enqueueSnackbar(passResult.data[0].msg, {
            variant: "error",
            autoHideDuration: 20000,
          });
        }
      } else {
        setLoading(false);
        enqueueSnackbar(t("messages.success"), {
          variant: "success",
          autoHideDuration: 3000,
        });
      }
    } else if (result.data && result.data.error) {
      setLoading(false);
      let message = result.data.error.message;
      if (result.data.error.message.indexOf("already exists") >= 0)
        message = t("profile.repeated-alias");
      else message += ERROR_CONTACT_US;

      enqueueSnackbar(message, {
        variant: "error",
        autoHideDuration: 20000,
      });
    }
  };

  const handleChange = (event) => {
    if (event.target.name == "password") {
      setNewPass(event.target.value);
      setPasswordIsValid(event.target.isValid);
    } else setValue({ ...value, [event.target.name]: event.target.value });
  };

  const personalTabIsValid = () => {
    return value.arades_anrede;
  };
  const addressTabIsValid = () => {
    return (
      value.arades_land &&
      value.address1_city &&
      value.address1_line1 &&
      value.address1_postalcode
    );
  };
  const passwordTabIsValid = () => {
    return !oldPass || passwordIsValid;
  };

  const handleChangeTab = (event, newValue) => {
    setCurrentTab(newValue);
  };

  return (
    <LoadingOverlay show={loading}>
      <div className="container-card">
        <Typography
          sx={{ fontWeight: "500", fontSize: "24px", marginBottom: "20px" }}
        >
          {t("profile.title")}
        </Typography>
        <Box>
          <TabContext value={currentTab}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList onChange={handleChangeTab}>
                <Tab label={t("profile.tab-personal")} value="1" />
                <Tab label={t("profile.tab-address")} value="2" />
                <Tab label={t("profile.tab-login")} value="3" />
              </TabList>
            </Box>
            <TabPanel value="1">
              <Box mt="20px">
                <Grid container spacing={6} sm={12} mt={"10px"}>
                  <Grid xs={12} lg={6} item>
                    <Select
                      disabled={true}
                      name={"arades_anrede"}
                      value={value.arades_anrede}
                      onChange={handleChange}
                      options={genders}
                      label={t("personal-info.gender") + "*"}
                    />
                  </Grid>
                  <Grid xs={12} lg={6} item>
                    <Select
                      name={"arades_titel"}
                      value={value.arades_titel}
                      onChange={handleChange}
                      options={titles}
                      label={t("personal-info.title")}
                    />
                  </Grid>
                  <Grid xs={12} lg={6} item>
                    <Input
                      disabled={true}
                      name={"firstname"}
                      value={value.firstname}
                      onChange={handleChange}
                      label={t("personal-info.first-name") + "*"}
                    />
                  </Grid>
                  <Grid xs={12} lg={6} item>
                    <Input
                      disabled={true}
                      name={"lastname"}
                      value={value.lastname}
                      onChange={handleChange}
                      label={t("personal-info.last-name") + "*"}
                    />
                  </Grid>

                  <Grid xs={12} lg={6} item>
                    <Datepicker
                      disabled={true}
                      name={"birthdate"}
                      value={value.birthdate}
                      onChange={handleChange}
                      label={t("personal-info.birth-date") + "*"}
                    />
                  </Grid>
                  <Grid xs={12} lg={6} item>
                    <Input
                      disabled={true}
                      name={"emailaddress1"}
                      value={value.emailaddress1}
                      onChange={handleChange}
                      label={t("personal-info.email-address") + "*"}
                    />
                    <MUIButtom
                      variant="text"
                      component={Link}
                      to={`/${URL.PROFILE_CHANGE_EMAIL}`}
                      sx={{
                        color: "info.main",
                        cursor: "pointer",
                      }}
                    >
                      {t("profile.change-email")}
                    </MUIButtom>
                  </Grid>
                  <Grid xs={12} lg={12} item>
                    <PhoneInput
                      disabled={true}
                      country="de"
                      style={{ fontSize: 14 }}
                      name={"mobilephone"}
                      value={value.mobilephone}
                      onChange={handleChange}
                      label={t("personal-info.mobile") + "*"}
                      className="phone-input-small"
                    />
                    <MUIButtom
                      variant="text"
                      component={Link}
                      to={URL.PROFILE_CHANGE_PHONE_NUMBER}
                      sx={{
                        color: "info.main",
                        cursor: "pointer",
                      }}
                    >
                      {t("profile.change-mobile")}
                    </MUIButtom>
                  </Grid>
                  <Grid xs={12} lg={6} item>
                    <PhoneInput
                      country="de"
                      name={"telephone1"}
                      value={value.telephone1 ?? "+49"}
                      onChange={handleChange}
                      label={t("personal-info.phone")}
                      className="phone-input-small"
                      placeholder="+49 6227 655060"
                    />
                  </Grid>
                  <Grid xs={12} lg={6} item>
                    <PhoneInput
                      country="de"
                      name={"telephone2"}
                      value={value.telephone2 ?? "+49"}
                      onChange={handleChange}
                      label={t("personal-info.phone2")}
                      className="phone-input-small"
                      placeholder="+49 6227 655060"
                    />
                  </Grid>
                  <Grid xs={12} item mt={20} sx={{ display: "flex" }}>
                    <Button
                      disabled={!personalTabIsValid()}
                      onClick={() => saveContact("personal")}
                    >
                      {t("buttons.save")}
                    </Button>
                    <NectRedirect
                      style={{ height: 40, marginLeft: 10 }}
                      title={t("profile.change-personal-info")}
                      redirectUrl={BASE_URL_UI + "profile"}
                    ></NectRedirect>
                  </Grid>
                </Grid>
              </Box>
            </TabPanel>
            <TabPanel value="2">
              <Box mt="20px">
                <Grid container spacing={6} sm={12} mt={"10px"}>
                  <Grid xs={12} item>
                    <Select
                      //disabled={true}
                      error={touched.arades_land && value.arades_land === ""}
                      helperText={
                        touched.arades_land && value.arades_land === ""
                          ? requiredMsg
                          : ""
                      }
                      onBlur={() =>
                        setTouched({ ...touched, arades_land: true })
                      }
                      name={"arades_land"}
                      value={value.arades_land}
                      onChange={handleChange}
                      options={countries}
                      label={t("address.country") + "*"}
                    />
                  </Grid>
                  <Grid xs={12} item>
                    <Input
                      //disabled={true}
                      error={
                        touched.address1_line1 && value.address1_line1 === ""
                      }
                      helperText={
                        touched.address1_line1 && value.address1_line1 === ""
                          ? requiredMsg
                          : ""
                      }
                      onBlur={() =>
                        setTouched({ ...touched, address1_line1: true })
                      }
                      name={"address1_line1"}
                      value={value.address1_line1}
                      onChange={handleChange}
                      label={t("address.house-no-and-street") + "*"}
                    />
                  </Grid>
                  <Grid xs={12} lg={6} item>
                    <Input
                      //disabled= {true}
                      error={
                        touched.address1_postalcode &&
                        value.address1_postalcode === ""
                      }
                      helperText={
                        touched.address1_postalcode &&
                        value.address1_postalcode === ""
                          ? requiredMsg
                          : ""
                      }
                      onBlur={() =>
                        setTouched({ ...touched, address1_postalcode: true })
                      }
                      name={"address1_postalcode"}
                      value={value.address1_postalcode}
                      onChange={handleChange}
                      label={t("address.postal-code") + "*"}
                    />
                  </Grid>
                  <Grid xs={12} lg={6} item>
                    <Input
                      //disabled={true}
                      error={
                        touched.address1_city && value.address1_city === ""
                      }
                      helperText={
                        touched.address1_city && value.address1_city === ""
                          ? requiredMsg
                          : ""
                      }
                      onBlur={() =>
                        setTouched({ ...touched, address1_city: true })
                      }
                      name={"address1_city"}
                      value={value.address1_city}
                      onChange={handleChange}
                      label={t("address.city") + "*"}
                    />
                  </Grid>

                  <Grid xs={12} item>
                    <Input
                      //disabled={true}
                      name={"address1_line2"}
                      value={value.address1_line2}
                      onChange={handleChange}
                      label={t("address.line2")}
                    />
                  </Grid>
                  {/* <div className="row d-flex col-12 mt-4">
                <NectRedirect
                  title="Change Address"
                  redirectUrl={BASE_URL_UI + "profile"}
                ></NectRedirect>
              </div> */}

                  <Grid xs={12} item>
                    <Button
                      disabled={!addressTabIsValid()}
                      onClick={() => saveContact("address")}
                    >
                      {t("buttons.save")}
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </TabPanel>
            <TabPanel value="3">
              <Box mt="20px">
                <Grid container spacing={6} sm={12} mt={"10px"}>
                  <Grid xs={12} lg={6} item>
                    <Input
                      name={"arades_alias"}
                      value={value.arades_alias}
                      onChange={handleChange}
                      label={t("profile.alias")}
                    />
                  </Grid>
                  <Grid xs={12} lg={6} item>
                    <Input
                      name={"oldPass"}
                      value={oldPass}
                      onChange={(e) => setOldPass(e.target.value)}
                      type={"password"}
                      label={t("profile.old-pass")}
                    />
                  </Grid>
                  <Grid xs={12} lg={6} item>
                    <Input
                      disabled={true}
                      name={"arades_ratior_key"}
                      value={value.arades_ratior_key}
                      onChange={handleChange}
                      label={t("profile.ratior-key")}
                    />
                  </Grid>
                  <Grid xs={12} lg={6} item>
                    <PasswordInputs
                      oneColumn={true}
                      onChange={handleChange}
                    ></PasswordInputs>
                  </Grid>

                  <Grid xs={12} item>
                    <Button
                      disabled={!passwordTabIsValid()}
                      onClick={() => saveContact("password")}
                    >
                      {t("buttons.save")}
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </TabPanel>
          </TabContext>
        </Box>
      </div>
    </LoadingOverlay>
  );
};

export default ContactProfile;
