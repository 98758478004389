import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Button, Grid } from "@mui/material";
import { FormSteperStructure, Input, LoadingOverlay } from "../../common";
import { useDispatch, useSelector } from "react-redux";
import { URL, ERROR_CONTACT_US } from "../../../core/constants";
import { useTranslation } from "react-i18next";
import { CONTACT_ID } from "../../../core/apiconfig/constantApi";
import { changeEmailAddressAction } from "../../../redux/slices/profile";
import {
  resetAll,
  resetError,
  globalData,
} from "../../../redux/slices/profile/profile.slice";
import { useSnackbar } from "notistack";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

function ChangeEmailStep({ handleCurrentStep }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const profileGlobalData = useSelector(globalData);
  const [loading, setLoading] = useState(false);

  const requiredMsg = "validation.required";
  const emailMismatchMsg = "validation.email-mismatch";
  const emailIsValidMsg = "validation.email-invalid";

  const schema = yup.object().shape({
    emailaddress1: yup.string().email(emailIsValidMsg).required(requiredMsg),
    emailRepeat: yup
      .string()
      .email(emailIsValidMsg)
      .oneOf([yup.ref("emailaddress1")], emailMismatchMsg)
      .required(requiredMsg),
  });

  const {
    control,
    handleSubmit,
    setError,
    getValues,
    formState: { errors },
  } = useForm({
    mode: "all",
    defaultValues: {
      emailaddress1: "",
      emailRepeat: "",
    },
    resolver: yupResolver(schema),
  });

  const submitForm = (data) => {
    setLoading(true);
    dispatch(
      changeEmailAddressAction({
        email: data.emailaddress1,
        contactID: localStorage.getItem(CONTACT_ID),
      })
    );
  };

  useEffect(() => {
    if (
      profileGlobalData.actionStatus &&
      profileGlobalData.actionType === "changeEmail"
    ) {
      enqueueSnackbar("E-Mail changed successfully", {
        variant: "success",
        autoHideDuration: 3000,
      });

      dispatch(resetAll());
      setLoading(false);
      navigate(URL.PROFILE);
    }
  }, [profileGlobalData.actionStatus]);

  useEffect(() => {
    if (profileGlobalData.error != null) {
      enqueueSnackbar(profileGlobalData.error + ERROR_CONTACT_US, {
        variant: "error",
        autoHideDuration: 20000,
      });
      dispatch(resetError());
      setLoading(false);
    }
  }, [profileGlobalData.error]);

  return (
    <LoadingOverlay show={loading}>
      <Box
        sx={{
          margin: "0 auto",
          minHeight: "calc(100vh - 250px)",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "space-around",
        }}
      >
        <Box sx={{ width: { xs: "100%", md: "50%" } }}>
          <Grid direction="column" container spacing={8}>
            <Grid item>
              <Input
                control={control}
                error={Boolean(errors.emailaddress1)}
                helperText={t(errors.emailaddress1?.message)}
                name="emailaddress1"
                label={t("personal-info.email-address")}
                placeholder="Exp: mail@website.com"
              />
            </Grid>

            <Grid item>
              <Input
                control={control}
                error={Boolean(errors.emailRepeat)}
                helperText={t(errors.emailRepeat?.message)}
                name="emailRepeat"
                placeholder="Exp: mail@website.com"
                label={t("personal-info.confirm-email-address")}
              />
            </Grid>
          </Grid>
        </Box>

        <FormSteperStructure.Footer>
          <Button
            onClick={handleSubmit(submitForm)}
            fullWidth
            sx={{ backgroundColor: "info.main", maxWidth: "192px" }}
            variant="contained"
          >
            Speichern
          </Button>
        </FormSteperStructure.Footer>
      </Box>
    </LoadingOverlay>
  );
}

export default ChangeEmailStep;
