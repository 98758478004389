import axios from "axios";
import { BASE_URL } from "../../core/apiconfig/environment";
import { dateString, Currency } from "../../core/utils/convert";
import { TOKEN_NAME, WORKSPACE_ID, WORKSPACE_TYPE } from "../../core/apiconfig/constantApi";
import { WORKSPACETYPE } from "../../core/constants";

const getTransactionListFunc = async (data, { getState }) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `bearer ${localStorage.getItem(TOKEN_NAME)}`,
    },
  };

  const workspaceId = localStorage.getItem(WORKSPACE_ID);
  const workspaceType = localStorage.getItem(WORKSPACE_TYPE);
  let result;
  if (workspaceType === WORKSPACETYPE.CONTACT || workspaceType === WORKSPACETYPE.UNDER_AGE) {
    result = await axios.get(`${BASE_URL}data/payment-transactions/contact/${workspaceId}/`, config);
  } else {
    result = await axios.get(`${BASE_URL}data/payment-transactions/account/${workspaceId}/`, config);
  }
  if (result?.data?.status != 200)
    throw Error("Zahlungsverkehr nicht geladen werden"); //("transactions not fetched");

  let toShowList = result.data.data.value;

  let list = [];
  toShowList.map((item) => {
    if (item["st.msdyn_name"]) {
      let tmpItem = {
        amount: item.arades_betrag,
        amount_formatted: Currency(item.arades_betrag),
        createdon: item["arades_ueberweisungsdatum"],
        createdon_formatted: dateString(item["arades_ueberweisungsdatum"]),
        storageName: item["st.msdyn_name"],
        metalName:
          item[
            "st.arades_edelmetall_typ@OData.Community.Display.V1.FormattedValue"
          ],
      };
      list.push(tmpItem);
    }
  });

  return list;
};

export default getTransactionListFunc;
