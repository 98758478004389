import { createAsyncThunk } from "@reduxjs/toolkit";

const postPowerOfAttorneyAction = createAsyncThunk(
  "powerOfAttorney/getPriceList",
  async (dataParams, { getState }) => {
      console.log("post")
  }
);

export default postPowerOfAttorneyAction;
