import React, { useState, useEffect } from "react";
import { useApi } from "../../core/hooks/useApi";
import Input from "../common/inputs/input";
import Button from "../common/buttons/button";
import { useSnackbar } from "notistack";
import { useParams } from "react-router";

const MandateAdd = () => {
  const { post, get, patch } = useApi();
  const id = useParams().id;

  const [value, setValue] = useState({
    firstName: "",
    lastName: "",
    mobile: "",
    email: "",
    address: "",
  });

  useEffect(() => {
    //if (id)
    // get("get by entity name and id")
    //   .then((response) => {
    //     setValue({ firstName: response.firstName ,...});
    //   });
  }, []);

  const handleChange = (event) => {
    setValue({ ...value, [event.target.name]: event.target.value });
  };

  const handleSubmit = () => {};

  return (
    <div className="container-card">
      <h1 className="title">{"Vollmacht"}</h1>
      <div className="row">
        <div className="d-flex flex-md-row ">
          <div className="col-md-6 col-12 ">
            <Input
              name={"firstName"}
              value={value.firstName}
              onChange={handleChange}
              label={"Company Name"}
            />
          </div>
          <div className="col-md-6 col-12 ">
            <Input
              name={"lastName"}
              value={value.lastName}
              onChange={handleChange}
              label={"Last Name"}
            />
          </div>
        </div>
        <div className="d-flex flex-md-row flex-column">
          <div className="col-md-6 col-12 ">
            <Input
              name={"mobile"}
              value={value.mobile}
              onChange={handleChange}
              label={"Mobile Number"}
            />
          </div>
          <div className="col-md-6 col-12 ">
            <Input
              name={"email"}
              value={value.email}
              onChange={handleChange}
              label={"Email"}
            />
          </div>
        </div>
        <div className="d-flex flex-md-row flex-column ">
          <div className=" col-12 ">
            <Input
              name={"address"}
              value={value.address}
              onChange={handleChange}
              label={"Address"}
              type="textarea"
            />
          </div>
        </div>
        <div className="row d-flex col-12 mt-4">
          <Button primary onClick={handleSubmit}>
            {"Speichern"}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default MandateAdd;
