import React from "react";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import InputBase from "@mui/material/InputBase";

import "./input.scss";
import { useTranslation } from "react-i18next";

const InputUpload = ({
  label,
  width,
  height,
  value,
  onChange,
  onAction,
  placeholder,
  id,
  name,
  disabled,
  style,
  className,
  accept,
  ...rest
}) => {
  const { t } = useTranslation();

  const handleFileChange = (event) => {
    if (onChange) {
      onChange(event);
    }
  };

  return (
    <div className={className} style={{ width: width, ...style }}>
      {label && (
        <Typography variant="body2" component="label" htmlFor={id}>
          {label}
        </Typography>
      )}
      <Paper
        component="form"
        sx={{
          p: "2px 4px",
          display: "flex",
          alignItems: "center",
          width: "100%",
          mt: 1,
        }}
        className="input"
      >
        <InputBase
          id={id}
          name={name}
          autoComplete={"none"}
          value={value}
          disabled={disabled}
          type="text"
          placeholder={value ? value : t("components.upload.upload-file")}
          {...rest}
          sx={{ ml: 1, flex: 1 }}
          readOnly
          onClick={() => document.querySelector(`input[name="${name}-button"]`).click()}
        />
        <Button
          variant="contained"
          component="label"
          disabled={disabled}
          sx={{
            flexShrink: 0,
            backgroundColor: value ? "#4a4a4a" : "#039189",
            ml: 1,
          }}
        >
          {value ? t("components.upload.replace") : t("components.upload.upload")}
          <input
            id={`${id}-file-input`}
            name={`${name}-button`}
            type="file"
            accept={accept}
            hidden
            onChange={(e) => handleFileChange(e)}
          />
        </Button>
      </Paper>
    </div>
  );
};

export default InputUpload;
