import { createSlice } from "@reduxjs/toolkit";
import i18next from 'i18next';

const currentLanguage = i18next.language || window.localStorage.i18nextLng
const initialState = {
  currentLanguage:currentLanguage,
};

export const localeSlice = createSlice({
    name: "locale",
    initialState,
    reducers:{
        changeLanguage:(state,action)=>{
            state.currentLanguage=action.payload
        }
    }
});

export const localefullData = (state) => state.locale;

export const {
    changeLanguage
} = localeSlice.actions;
export default localeSlice.reducer;