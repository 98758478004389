import axios from "axios";
import { BASE_URL } from "../../../core/apiconfig/environment";
import { TOKEN_NAME } from "../../../core/apiconfig/constantApi";

const postConnectionFunc = async (connectionInfo, { getState }) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `bearer ${localStorage.getItem(TOKEN_NAME)}`,
    },
  };
  const result = await axios.post(`${BASE_URL}data/partner-connections/contact/${connectionInfo.record1id}/partner-contact/${connectionInfo.record2id}/`, {}, config);

  if (result?.data?.status != 201)
    throw Error("Partner konnte nicht gespeichert werden"); //("Connection not saved");
  return result.data.data;
};

export default postConnectionFunc;
