import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL } from "../../../core/apiconfig/environment";
import { TOKEN_NAME } from "../../../core/apiconfig/constantApi";

const getBankCurrencyListAction = createAsyncThunk(
  "global/bankCurrencies",
  async (dataParams, { getState }) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${localStorage.getItem(TOKEN_NAME)}`,
      },
    };
    const ownerTable = "arades_konto";
    const optionFieldName = "arades_waehrung";

    const { data: { data } } = await axios.get(`${BASE_URL}data/options/${ownerTable}/${optionFieldName}/`, config);
    let dictionary = [];
    if (data?.OptionSet?.Options) {
      data.OptionSet.Options.map((item) => {
        if (item.Value && item.Label && item.Label.LocalizedLabels)
          dictionary.push({
            key: item.Value,
            value: item.Label.LocalizedLabels[0].Label,
          });
      });
    }
    return dictionary;
  }
);

export default getBankCurrencyListAction;
