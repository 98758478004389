import React from "react";
import { Box } from "@mui/material";
import { Bar } from "react-chartjs-2";
import { Currency } from "../../../core/utils/convert";
import { useTranslation } from "react-i18next";

// Display a bar chart of customer deposits by month
function DepositsChart({ depositMonthlyFields }) {
    const { t } = useTranslation();

    const depositChartOptions = {
        responsive: true,
        plugins: {
          legend: {
            display: false,
          },
          title: {
            display: false,
            text: "",
          },
          datalabels: {
            color: "#fff",
            formatter: function (value, context) {
              return "";
            },
          },
          tooltip: {
            callbacks: {
              label: function (context) {
                return (
                  " " + context.dataset.label + ": " + Currency(context.parsed.y)
                );
              },
            },
          },
        },
    };
    const depositChartData = {
        labels: depositMonthlyFields.map((item) => item.month),
        datasets: [
            {
            categoryPercentage: 1.0,
            barPercentage: 0.94,
            borderRadius: 3,
            label: t("metals.AU"),
            data: depositMonthlyFields.map((item) => item.priceGold),
            backgroundColor: [
                "rgba(238, 204, 36, 1)",
                "rgba(238, 204, 36, 1)",
                "rgba(238, 204, 36, 1)",
            ],
            },
            {
            categoryPercentage: 1.0,
            barPercentage: 0.94,
            borderRadius: 3,
            label: t("metals.AG"),
            data: depositMonthlyFields.map((item) => item.priceSilver),
            backgroundColor: [
                "rgba(204, 204, 204, 1)",
                "rgba(204, 204, 204, 1)",
                "rgba(204, 204, 204, 1)",
            ],
            },
            {
            categoryPercentage: 1.0,
            barPercentage: 0.94,
            borderRadius: 3,
            label: t("metals.PT"),
            data: depositMonthlyFields.map((item) => item.pricePlatin),
            backgroundColor: [
                "rgba(227, 242, 253, 1)",
                "rgba(227, 242, 253, 1)",
                "rgba(227, 242, 253, 1)",
            ],
            },
            {
            categoryPercentage: 1.0,
            barPercentage: 0.94,
            borderRadius: 3,
            label: t("metals.PL"),
            data: depositMonthlyFields.map((item) => item.pricePalladium),
            backgroundColor: [
                "rgba(250, 192, 138, 0.6)",
                "rgba(250, 192, 138, 0.6)",
                "rgba(250, 192, 138, 0.6)",
            ],
            },
        ],
    };

    return <Box mt={"30px"}>
        <Bar options={depositChartOptions} data={depositChartData} />
    </Box>;
}

export default DepositsChart;
