import { calculateDaysDiff } from "./commonfuncs";
import { STORAGE_STATUS } from "../constants";

export function MetalPart(storageName) {
  return storageName?.substring(storageName.length - 5, storageName.length - 3);
}

export const storageIsPending = (storage) => {
  return (
    !storage.arades_einrichtungsgebuehr_beglichen ||
    !storage.arades_vertrag ||
    (!storage.arades_verzicht_auf_widerruf &&
      calculateDaysDiff(new Date(storage.createdon), new Date()) <= 14)
  );
};

export const storageStatus = (statuscode) => {
  return statuscode == STORAGE_STATUS.PENDING_FOR_CONTRACT
    ? "Warten auf Unterschrift"
    : statuscode == STORAGE_STATUS.PENDING_FOR_PAYMENT
    ? "Warten auf Einrichtungsgebühr"
    : statuscode == STORAGE_STATUS.PENDING_FOR_14_DAYS
    ? "Warten auf Widerruf"
    : statuscode == STORAGE_STATUS.ACTIVE
    ? "Vollständig eingerichtet"
    : "";
};
