import axios from "axios";
import { BASE_URL } from "../../../core/apiconfig/environment";
import { longDateTo10 } from "../../../core/utils/convert";
import { TOKEN_NAME } from "../../../core/apiconfig/constantApi";


// Get a contact by id
const getContactById = async (contactId) => {
  try {
    const config = { headers: { "Content-Type": "application/json", Authorization: `bearer ${localStorage.getItem(TOKEN_NAME)}` } };
    const response = await axios.get(`${BASE_URL}data/contacts/${contactId}/`, config);
    if (!response.data?.data) { throw Error("Contact not found"); }
    return response.data.data;
  } catch (err) {
    throw Error(err.response && err.response.data.detail ? err.response.data.detail : err.message);
  }
};

// Get basic contact info by ratior key and birthday, e.g. to find the second Erziehungsberechtigen when creating a new underage contact
// This only returns the contactid, mobilephone, firstname and lastname attributes of the contact because it's enough for all use cases.
// The result is a response with a value that is a list of basic contact objects. The list is a legacy design that I didn't want to change now because I am already refactoring the Web App a lot.
const getBasicContactByRatiorKeyAndBirthday = async (ratiorKey, birthdate) => {
  try {
    const config = { headers: { "Content-Type": "application/json", Authorization: `bearer ${localStorage.getItem(TOKEN_NAME)}` } };
    const response = await axios.get(`${BASE_URL}data/contacts/basic/${ratiorKey}/${longDateTo10(new Date(birthdate))}`, config);
    return response.data.data;
  } catch (err) {
    throw Error(err.response && err.response.data.detail ? err.response.data.detail : err.message);
  }
};

// Get a contact by a filter using ID or RatiorKey + birthdate
// When getting the contact by ID, the result is the full contact object.
// When getting the contact by RatiorKey + birthdate, the result is a response with a value that is a list of basic contact objects. The list is a legacy design that I didn't want to change now because I am already refactoring the Web App a lot.
const getPersonalDataFunc = async (filter, { getState }) => {
  let data;
  if (filter.id) {
    // Get contact by ID
    data = await getContactById(filter.id);
  } else if (filter.ratiorKey && filter.birthdate) {
    // Get basic contact info by RatiorKey and birthday
    data = await getBasicContactByRatiorKeyAndBirthday(filter.ratiorKey, filter.birthdate);
    if (data.value.length > 0) {
      data.value[0].arades_ratior_key = filter.ratiorKey; // Add the RatiorKey because there is code that uses it in the result object and I don't want to change that code
      data.value[0].birthdate         = filter.birthdate; // Add the birthdate because there is code that uses it in the result object and I don't want to change that code
    }
  } else {
    // Invalid call
    throw Error("No valid filter provided: " + JSON.parse(filter));
  }
  return data;
};

export default getPersonalDataFunc;
