import {
  FULLNAME,
  TOKEN_NAME,
  CONTACT_ID,
  RATIOR_KEY,
  CONTACT_BIRTHDATE,
  MOBILE_VERIFIED,
  ADDRESS_VERIFIED,
  WORKSPACE_ID,
  WORKSPACE_NAME,
  WORKSPACE_TYPE,
  MOBILE_NUMBER,
  VERMITTLER_ID,
  ACTION_CODE_ID,
  STATUS_CODE,
  GUARDIAN1,
  GUARDIAN2,
  NECT_VERIFIED,
  DOCUMENTS_UPLOADED,
  TMP_CONTACT_ID,
  TMP_WORKSPACE_ID,
  TMP_WORKSPACE_TYPE,
  TMP_WORKSPACE_NAME,
  TMP_FULLNAME,
} from "../../core/apiconfig/constantApi";

export const resetCache = () => {
  localStorage.removeItem(FULLNAME);
  localStorage.removeItem(TOKEN_NAME);
  localStorage.removeItem(CONTACT_ID);
  localStorage.removeItem(RATIOR_KEY);
  localStorage.removeItem(CONTACT_BIRTHDATE);
  localStorage.removeItem(MOBILE_VERIFIED);
  localStorage.removeItem(ADDRESS_VERIFIED);
  localStorage.removeItem(WORKSPACE_ID);
  localStorage.removeItem(WORKSPACE_NAME);
  localStorage.removeItem(WORKSPACE_TYPE);
  localStorage.removeItem(MOBILE_NUMBER);
  localStorage.removeItem(VERMITTLER_ID);
  localStorage.removeItem(ACTION_CODE_ID);
  localStorage.removeItem(STATUS_CODE);
  localStorage.removeItem(GUARDIAN1);
  localStorage.removeItem(GUARDIAN2);
  localStorage.removeItem(NECT_VERIFIED);
  localStorage.removeItem(DOCUMENTS_UPLOADED);

  localStorage.removeItem(TMP_CONTACT_ID);
  localStorage.removeItem(TMP_WORKSPACE_ID);
  localStorage.removeItem(TMP_WORKSPACE_TYPE);
  localStorage.removeItem(TMP_WORKSPACE_NAME);
  localStorage.removeItem(TMP_FULLNAME);
};
