import { createAsyncThunk } from "@reduxjs/toolkit";
//import { getStorageNumbersFunc } from "./";
import axios from "axios";
import {
  BASE_URL,
  BYPASS_ADOBE_SIGN,
} from "../../../core/apiconfig/environment";
import { METAL_CHARS, STORAGE_TYPE, WORKSPACETYPE } from "../../../core/constants";
import { TOKEN_NAME, WORKSPACE_ID, WORKSPACE_TYPE } from "../../../core/apiconfig/constantApi";
import { MetalPart } from "../../../core/utils/business";
import { STORAGE_PERSON_TYPE, WORKFLOW_IDS } from "../../../core/constants";
import { postAddStorageInfoFunc } from "./";
import {
  postConnectionFunc,
  signContractWorkFlowFunc,
} from "./../../sharedFunctions";

//parichehr: must be batch

const getStorageNumbersAction = createAsyncThunk(
  "storage/storageNumbers",
  async (filter, { getState }) => {
    const storageTypeData = getState().storage.storageTypeStep;
    const otherPersonData = getState().storage.otherPersonStep;
    const contractSigned = getState().storage.signContract.signDone;
    const workspaceId = localStorage.getItem(WORKSPACE_ID);
    const workspaceType = localStorage.getItem(WORKSPACE_TYPE);
    const wait14Days = getState().storage.wait14Days;

    if (storageTypeData.isForPartner)
      await postConnectionFunc(
        { record1id: workspaceId, record2id: otherPersonData.partnerId },
        { getState }
      );

    let workflowId = "";
    let arades_type = STORAGE_PERSON_TYPE.OWNER;

    let storageType = storageTypeData.isForMyself
      ? STORAGE_TYPE.MYSELF
      : storageTypeData.isForCompany
      ? STORAGE_TYPE.COMPANY
      : storageTypeData.isForUnderAge
      ? STORAGE_TYPE.UNDER_AGE
      : storageTypeData.isForPartner
      ? STORAGE_TYPE.PARTNER
      : 0;

    if (storageType == STORAGE_TYPE.MYSELF)
      workflowId = WORKFLOW_IDS.STORAGE_FOR_MYSELF;
    else if (storageType == STORAGE_TYPE.COMPANY)
      workflowId = WORKFLOW_IDS.STORAGE_FOR_COMPANY;
    else if (storageType == STORAGE_TYPE.UNDER_AGE) {
      workflowId = WORKFLOW_IDS.STORAGE_FOR_UNDER_AGE;
      arades_type = STORAGE_PERSON_TYPE.UNDER_AGE;
    } else if (storageType == STORAGE_TYPE.PARTNER)
      workflowId = WORKFLOW_IDS.STORAGE_FOR_PARTNER;

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${localStorage.getItem(TOKEN_NAME)}`,
      },
    };

    const numbersResult = await axios.post(`${BASE_URL}data/workflows/${workflowId}/`, { EntityId: workspaceId }, config);

    if (
      !numbersResult?.data?.status ||
      (numbersResult.data.status != 204 && numbersResult.data.status != 200)
    )
      throw Error("Erstellung von Lagerplätzen ist fehlgeschlagen (Workflow für Lagerplatz-Nummern)"); //("Generate storage numbers workflow failed ");

    // Fetch inserted storages

    let result;
    if (workspaceType === WORKSPACETYPE.CONTACT || workspaceType === WORKSPACETYPE.UNDER_AGE) { // Contact workspace (person)?
      result = await axios.get(`${BASE_URL}data/warehouses/contact/${workspaceId}/${arades_type}`, config);
    } else { // Account workspace (company)?
      result = await axios.get(`${BASE_URL}data/warehouses/account/${workspaceId}/${arades_type}`, config);
    }

    if (
      !result?.data?.status ||
      result?.data?.data?.value?.length < 4 ||
      (result.data.status != 201 && result.data.status != 200)
    )
      throw Error("Lageplatznummer konnte nicht geladen werden"); //("Fetch storage numbers workflow failed");

    let storageList = result.data.data.value
      .filter((x) => x.arades_lagerplatz.arades_vertrag == false)
      .sort((a, b) => (a?.createdon >= b?.createdon ? -1 : 1));

    //detecting and returning metal types
    let fourStoragesData = {};
    try {
      let gold = storageList.filter(
        (x) => MetalPart(x.arades_lagerplatz.msdyn_name) == METAL_CHARS.GOLD
      )[0];
      let silver = storageList.filter(
        (x) => MetalPart(x.arades_lagerplatz.msdyn_name) == METAL_CHARS.SILVER
      )[0];
      let Platinum = storageList.filter(
        (x) => MetalPart(x.arades_lagerplatz.msdyn_name) == METAL_CHARS.PLATIN
      )[0];
      let palladium = storageList.filter(
        (x) =>
          MetalPart(x.arades_lagerplatz.msdyn_name) == METAL_CHARS.PALLADIUM
      )[0];

      fourStoragesData = {
        storage1Number: gold.arades_lagerplatz.msdyn_name,
        storage2Number: silver.arades_lagerplatz.msdyn_name,
        storage3Number: Platinum.arades_lagerplatz.msdyn_name,
        storage4Number: palladium.arades_lagerplatz.msdyn_name,

        storage1Id: gold.arades_lagerplatz.msdyn_warehouseid,
        storage2Id: silver.arades_lagerplatz.msdyn_warehouseid,
        storage3Id: Platinum.arades_lagerplatz.msdyn_warehouseid,
        storage4Id: palladium.arades_lagerplatz.msdyn_warehouseid,
      };
      //console.log("fourStoragesData", fourStoragesData);
    } catch {
      throw Error("Edelmetalltyp könnten nicht gefunden werden"); //("Detecting Metal Types Failed");
    }

    await postAddStorageInfoFunc(fourStoragesData, { getState });

    if (contractSigned || BYPASS_ADOBE_SIGN)
      await signContractWorkFlowFunc(
        {
          storageType: storageType,
          ownerId: workspaceId,
          wait14Days: wait14Days,
        },
        { getState }
      );

    return fourStoragesData;
  }
);

export default getStorageNumbersAction;
