import React, { useEffect, useState } from "react";
import {
  Typography,
  Box,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  TableHead,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { ACTION_CODE_TYPE, ERROR_CONTACT_US } from "../../core/constants";
import { useDispatch, useSelector } from "react-redux";
import {
  customerActionCodeList,
  globalData,
  resetError,
} from "../../redux/slices/customerList/customerList.slice";
import { getActionCodeCustomerDataAction } from "../../redux/slices/customerList";
import { useTranslation } from "react-i18next";

const CustomerActionCodeList = ({ customerId, customerType }) => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const dashboardGlobalData = useSelector(globalData);
  const actionCodeListFields = useSelector(customerActionCodeList);

  useEffect(() => {
    dispatch(
      getActionCodeCustomerDataAction({
        customerId: customerId,
        customerType: customerType,
        justActive: false,
      })
    );
  }, []);

  useEffect(() => {
    if (dashboardGlobalData.error != null) {
      enqueueSnackbar(dashboardGlobalData.error + ERROR_CONTACT_US, {
        variant: "error",
        autoHideDuration: 20000,
      });
      dispatch(resetError());
    }
  }, [dashboardGlobalData.error]);

  return (
    <>
      {actionCodeListFields.length > 0 && (
        <>
          <Box mt={"20px"} sx={{ maxHeight: 320 }}>
            <TableContainer sx={{ maxHeight: 270 }}>
              <Table sx={{ width: "100%" }} aria-label="simple table" stickyHeader>
                <TableHead sx={{ backgroundColor: "rgba(33, 33, 33, 0.08)" }}>
                  <TableRow>
                    <TableCell>{t("action-code.code")}</TableCell>
                    <TableCell>{t("action-code.type")}</TableCell>
                    <TableCell>{t("action-code.exp-date")}</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {actionCodeListFields.map((item, index) => (
                    <TableRow
                      key={index}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell
                        align="left"
                        className="no-wrap"
                        sx={{
                          fontWeight: 400,
                          padding: "15px",
                        }}
                      >
                        {item.actionCode}
                      </TableCell>
                      <TableCell
                        align="left"
                        className="no-wrap"
                        sx={{
                          fontWeight: 400,
                          padding: "5px",
                        }}
                      >
                        <Typography>
                          {item.arades_typ == ACTION_CODE_TYPE.STORAGE_FEE
                            ? (item.discount ? item.discount : 0) +
                              " " +
                              t("action-code.fix-fee")
                            : item.arades_typ == ACTION_CODE_TYPE.BECOME_SELLER
                            ? t("action-code.become-seller")
                            : item.arades_typ == ACTION_CODE_TYPE.LOYALTY
                            ? (item.loyalityMonthCount
                                ? item.loyalityMonthCount
                                : 0) +
                              " " +
                              t("action-code.loyalty-months")
                            : ""}
                        </Typography>
                      </TableCell>
                      <TableCell
                        align="left"
                        className="no-wrap"
                        sx={{
                          fontWeight: 400,
                          padding: "5px",
                        }}
                      >
                        <Typography>
                          {item.registeredCount} /
                          {item.usersUnLimited
                            ? t("action-code.no-limit")
                            : item.allowRegisterCount}
                        </Typography>
                      </TableCell>
                      <TableCell
                        className="no-wrap"
                        align="left"
                        sx={{
                          fontWeight: 400,
                          padding: "5px",
                        }}
                      >
                        <Typography>
                          {item.validForever
                            ? t("action-code.no-limit")
                            : item.endDate}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </>
      )}
    </>
  );
};

export default CustomerActionCodeList;
