import React from "react";
import { Box, Typography, IconButton, Grid } from "@mui/material";
import { ArrowDropUp, ArrowDropDown } from "@mui/icons-material";
import { useTranslation } from "react-i18next";

function FilterArrow({ show, setShow }) {
  const { t } = useTranslation();

  return (
    <Box>
      <Grid container spacing={1}>
        <Typography
          sx={{
            fontWeight: 500,
            fontSize: 14,
            marginTop: 3,
            color: "rgba(0, 0, 0, 0.6)",
            cursor: "default", userSelect: "none"
          }}
          onClick={() => setShow(!show)}
        >
          {t("customer-deposit-list.filter")}
        </Typography>
        <IconButton size="small" sx={{ ml: 2 }} onClick={() => setShow(!show)}>
          {show ? <ArrowDropUp /> : <ArrowDropDown />}
        </IconButton>
      </Grid>
    </Box>
  );
}

export default FilterArrow;
