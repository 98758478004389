import React, { useState, useEffect } from "react";
import SubmitModal from "../common/popups/submitModal";
import { Input, Select } from "../common";
import "./showAddressModal.scss";
import { useTranslation } from "react-i18next";
const ShowAddressModal = ({ open, onClose, initVal }) => {
  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [initVal]);

  return (
    <SubmitModal
      open={open}
      title={t("my-structure.modal-title")}
      okTitle={t("buttons.close")}
      showCloseButton={true}
      size="lg"
      onOk={() => {
        onClose();
      }}
      onClose={() => {
        onClose();
      }}
      content={
        <div className="container">
          <div className="row d-flex mt-4">
            <div className="col-md-6 col-12 ">
              <Input
                disabled={true}
                name={"arades_firmenname_partner"}
                value={initVal.arades_firmenname_partner}
                label={t("company-profile.other-name")}
              />
            </div>
            <div className="col-md-6 col-12 pl-2">
              <Input
                disabled={true}
                name={"telephone3"}
                value={initVal.telephone3}
                label={t("company-profile.other-phone")}
              />
            </div>
          </div>
          <div className="row d-flex mt-4">
            <div className="col-md-6 col-12 ">
              <Input
                disabled={true}
                name={"arades_mobil_partner"}
                value={initVal.arades_mobil_partner}
                label={t("company-profile.other-mobile")}
              />
            </div>
            <div className="col-md-6 col-12 pl-2">
              <Input
                disabled={true}
                name={"emailaddress3"}
                value={initVal.emailaddress3}
                label={t("company-profile.other-email")}
              />
            </div>
          </div>
          <div className="row d-flex ">
            <div className="col-12 mt-4">
              <Input
                disabled={true}
                name={"arades_address3_line1"}
                value={initVal.arades_address3_line1}
                label={t("company-profile.other-address-line1")}
              />
            </div>
          </div>

          <div>
            <div className="col-12 mt-4">
              <Input
                disabled={true}
                name={"arades_address3_line2"}
                value={initVal.arades_address3_line2}
                label={t("company-profile.other-address-line2")}
              />
            </div>
          </div>
          <div className="row d-flex mt-4">
            <div className="col-md-6 col-12 ">
              <Input
                disabled={true}
                name={"arades_address3_postalcode"}
                value={initVal.arades_address3_postalcode}
                label={t("company-profile.other-postalcode")}
              />
            </div>
            <div className="col-md-6 col-12 pl-2">
              <Input
                disabled={true}
                name={"arades_address3_city"}
                value={initVal.arades_address3_city}
                label={t("company-profile.other-city")}
              />
            </div>
          </div>
          <div className="col-12 mt-4 ">
            <Input
              disabled={true}
              name={"arades_land_partner"}
              value={initVal.arades_land_partner}
              label={t("company-profile.other-country")}
            />
          </div>
        </div>
      }
    ></SubmitModal>
  );
};

export default ShowAddressModal;
