import { createSlice } from "@reduxjs/toolkit";
import {
  getVermittlerStorageListAction,
  getCommissionMonthlyAction,
  getGoldSilverRatiosAction,
  getUpcomingBirthdaysAction,
  postActionCodeAction,
  getActionCodeListAction,
  getVermittlerBuyInvoiceListAction,
  getCompanyDataAction,
  deactivateActionCodeAction,
} from ".";
import { ACTION_CODE_TYPE } from "../../../core/constants";

const initialState = {
  globalData: {
    actionStatus: false,
    actionType: "",
    loading: false,
    error: null,
  },
  customerStorages: [],
  totalMetalPricesList: {},
  depositMonthly: [],
  commissionMonthly: [],
  goldSilverRatios: [],
  topCustomers: [],
  upcomingBirthdays: [],
  actionCodeList: [],
  transactionOverviewChartData: [],
  companyData: {},
  addActionCodeStep: {
    discount: 0,
    allowRegisterCount: null,
    startDate: null,
    endDate: null,
    responseActionCode: "",
    usersUnLimited: "true",
    validForever: "true",
    actionCodeType: ACTION_CODE_TYPE.STORAGE_FEE,
  },
};

const getTopCustomers = (customerStorageList) => {
  var result = [];
  customerStorageList.reduce(function (res, value) {
    if (!res[value.customerId]) {
      res[value.customerId] = {
        id: value.customerId,
        name: value.customerName,
        amount: 0,
        price: 0,
      };
      result.push(res[value.customerId]);
    }
    res[value.customerId].amount += value.arades_verfuegbare_menge;
    res[value.customerId].price += value.totalPrice;
    return res;
  }, {});

  return result.sort((a, b) => (a?.price >= b?.price ? -1 : 1)).slice(0, 4);
};

const getDepositMonthly = (vermittlerInvoiceList) => {
  var result = {
    1: {
      month: "Januar",
      priceGold: 0,
      priceSilver: 0,
      pricePlatin: 0,
      pricePalladium: 0,
    },
    2: {
      month: "Februar",
      priceGold: 0,
      priceSilver: 0,
      pricePlatin: 0,
      pricePalladium: 0,
    },
    3: {
      month: "März",
      priceGold: 0,
      priceSilver: 0,
      pricePlatin: 0,
      pricePalladium: 0,
    },
    4: {
      month: "April",
      priceGold: 0,
      priceSilver: 0,
      pricePlatin: 0,
      pricePalladium: 0,
    },
    5: {
      month: "Mai",
      priceGold: 0,
      priceSilver: 0,
      pricePlatin: 0,
      pricePalladium: 0,
    },
    6: {
      month: "Juni",
      priceGold: 0,
      priceSilver: 0,
      pricePlatin: 0,
      pricePalladium: 0,
    },
    7: {
      month: "Juli",
      priceGold: 0,
      priceSilver: 0,
      pricePlatin: 0,
      pricePalladium: 0,
    },
    8: {
      month: "August",
      priceGold: 0,
      priceSilver: 0,
      pricePlatin: 0,
      pricePalladium: 0,
    },
    9: {
      month: "September",
      priceGold: 0,
      priceSilver: 0,
      pricePlatin: 0,
      pricePalladium: 0,
    },
    10: {
      month: "Oktober",
      priceGold: 0,
      priceSilver: 0,
      pricePlatin: 0,
      pricePalladium: 0,
    },
    11: {
      month: "November",
      priceGold: 0,
      priceSilver: 0,
      pricePlatin: 0,
      pricePalladium: 0,
    },
    12: {
      month: "Dezember",
      priceGold: 0,
      priceSilver: 0,
      pricePlatin: 0,
      pricePalladium: 0,
    },
  };

  vermittlerInvoiceList.reduce(function (res, value) {
    let month = new Date(value.createDate).getMonth() + 1; // Get month of creation. +1 -> JS month starts from 0, but we need it to start from 1
    if (value.productNumber == "AU")
      result[month].priceGold += value.totalPrice;
    else if (value.productNumber == "AG")
      result[month].priceSilver += value.totalPrice;
    else if (value.productNumber == "PT")
      result[month].pricePlatin += value.totalPrice;
    else if (value.productNumber == "PL")
      result[month].pricePalladium += value.totalPrice;
    return res;
  }, {});

  let list = [];
  let currentMonth = new Date().getMonth();
  for (let i = 0; i < 12; i++) {
    if (i <= currentMonth) list.push(result[i + 1]);
  }
  return list;
};

const getTotalMetalPricesList = (customerStorageList) => {
  var result = {
    AU: {
      abbreviation: "AU",
      name: "Gold",
      amount: 0,
      price: 0,
    },
    AG: {
      abbreviation: "AG",
      name: "Silber",
      amount: 0,
      price: 0,
    },
    PT: {
      abbreviation: "PT",
      name: "Platin",
      amount: 0,
      price: 0,
    },
    PL: {
      abbreviation: "PL",
      name: "Palladium",
      amount: 0,
      price: 0,
    },
  };

  customerStorageList.reduce(function (res, value) {
    result[value.productNumber].name = value.metalName;
    result[value.productNumber].amount += value.arades_verfuegbare_menge;
    result[value.productNumber].price += value.totalPrice;
    return res;
  }, {});
  return result;
};

export const vermittlerDashboardSlice = createSlice({
  name: "vermittlerDashboard",
  initialState,
  reducers: {
    setActionCodeData: (state, action) => {
      const { discount, allowRegisterCount, startDate, endDate } =
        action.payload;

      state.addActionCodeStep.discount = discount;
      state.addActionCodeStep.allowRegisterCount = allowRegisterCount;
      state.addActionCodeStep.startDate = startDate;
      state.addActionCodeStep.endDate = endDate;
    },
    resetError: (state) => {
      state.globalData.error = null;
    },
    resetActionStatus: (state) => {
      state.globalData.actionStatus = false;
      state.globalData.actionType = null;
    },
    resetAddActionCodeStep: (state) => {
      state.addActionCodeStep.discount = 0;
      state.addActionCodeStep.allowRegisterCount = 0;
      state.addActionCodeStep.startDate = null;
      state.addActionCodeStep.endDate = null;
      state.addActionCodeStep.usersUnLimited = "true";
      state.addActionCodeStep.validForever = "true";
      state.addActionCodeStep.actionCodeType = ACTION_CODE_TYPE.STORAGE_FEE;
    },
    resetAll: () => {
      return initialState;
    },
  },
  extraReducers: {
    //Get vermittler storage List Action
    [getVermittlerStorageListAction.pending]: (state, action) => {
      state.globalData.loading = true;
    },
    [getVermittlerStorageListAction.fulfilled]: (state, action) => {
      state.globalData.loading = false;
      state.globalData.actionStatus = true;
      state.globalData.actionType = "getVermittlerStorageList";
      state.customerStorages = action.payload;
      state.topCustomers = getTopCustomers(action.payload);
      state.totalMetalPricesList = getTotalMetalPricesList(action.payload);
    },
    [getVermittlerStorageListAction.rejected]: (state, action) => {
      state.globalData.loading = false;
      state.globalData.error = action.error.message;
    },

    //Get vermittler transaction List Action
    [getCompanyDataAction.pending]: (state, action) => {
      state.globalData.loading = true;
    },
    [getCompanyDataAction.fulfilled]: (state, action) => {
      state.globalData.loading = false;
      state.globalData.actionType = "getCompanyData";
      state.companyData = action.payload;
    },
    [getCompanyDataAction.rejected]: (state, action) => {
      state.globalData.loading = false;
      state.globalData.error = action.error.message;
    },

    // Get vermittler transaction List Action
    [getVermittlerBuyInvoiceListAction.pending]: (state, action) => {
      state.globalData.loading = true;
    },
    [getVermittlerBuyInvoiceListAction.fulfilled]: (state, action) => {
      state.globalData.loading = false;
      state.depositMonthly = getDepositMonthly(action.payload);
    },
    [getVermittlerBuyInvoiceListAction.rejected]: (state, action) => {
      state.globalData.loading = false;
      state.globalData.error = action.error.message;
    },

    //Get Commission Monthly Action
    [getCommissionMonthlyAction.pending]: (state, action) => {
      state.globalData.loading = true;
    },
    [getCommissionMonthlyAction.fulfilled]: (state, action) => {
      state.globalData.loading = false;
      state.commissionMonthly = action.payload;
    },
    [getCommissionMonthlyAction.rejected]: (state, action) => {
      state.globalData.loading = false;
      state.globalData.error = action.error.message;
    },
    //Get Gold Silver Ratios Action
    [getGoldSilverRatiosAction.pending]: (state, action) => {
      state.globalData.loading = true;
    },
    [getGoldSilverRatiosAction.fulfilled]: (state, action) => {
      state.globalData.loading = false;
      state.goldSilverRatios = action.payload;
    },
    [getGoldSilverRatiosAction.rejected]: (state, action) => {
      state.globalData.loading = false;
      state.globalData.error = action.error.message;
    },

    //Get Upcoming Birthdays Action
    [getUpcomingBirthdaysAction.pending]: (state, action) => {
      state.globalData.loading = true;
    },
    [getUpcomingBirthdaysAction.fulfilled]: (state, action) => {
      state.globalData.loading = false;
      state.upcomingBirthdays = action.payload;
    },
    [getUpcomingBirthdaysAction.rejected]: (state, action) => {
      state.globalData.loading = false;
      state.globalData.error = action.error.message;
    },
    //Post Action Code Action
    [postActionCodeAction.pending]: (state, action) => {
      state.globalData.loading = true;
    },
    [postActionCodeAction.fulfilled]: (state, action) => {
      state.globalData.loading = false;
      state.globalData.actionType = "postActionCode";
      state.globalData.actionStatus = true;
      state.addActionCodeStep.responseActionCode = action.payload.actionCode;
    },
    [postActionCodeAction.rejected]: (state, action) => {
      state.globalData.loading = false;
      state.globalData.error = action.error.message;
      state.globalData.actionType = "postActionCode";
      state.globalData.actionStatus = false;
    },

    //Deactivate ActionCode Action
    [deactivateActionCodeAction.pending]: (state, action) => {
      state.globalData.loading = true;
    },
    [deactivateActionCodeAction.fulfilled]: (state, action) => {
      state.globalData.loading = false;
      state.globalData.actionType = "deactivateActionCode";
      state.globalData.actionStatus = true;
      state.addActionCodeStep.responseActionCode = action.payload.actionCode;
    },
    [deactivateActionCodeAction.rejected]: (state, action) => {
      state.globalData.loading = false;
      state.globalData.error = action.error.message;
      state.globalData.actionType = "deactivateActionCode";
      state.globalData.actionStatus = false;
    },

    //Get Action Code List Action
    [getActionCodeListAction.pending]: (state, action) => {
      state.globalData.loading = true;
    },
    [getActionCodeListAction.fulfilled]: (state, action) => {
      state.globalData.loading = false;
      state.globalData.actionType = "getSellerActionCodes";
      state.globalData.actionStatus = true;
      state.actionCodeList = action.payload;
    },
    [getActionCodeListAction.rejected]: (state, action) => {
      state.globalData.loading = false;
      state.globalData.error = action.error.message;
    },
  },
});

export const {
  resetError,
  resetActionStatus,
  resetAll,
  resetAddActionCodeStep,
} = vermittlerDashboardSlice.actions;

export const fullData = (state) => state.vermittlerDashboard;
export const globalData = (state) => state.vermittlerDashboard.globalData;

export const totalMetalPricesList = (state) =>
  state.vermittlerDashboard.totalMetalPricesList;
export const customerStorages = (state) =>
  state.vermittlerDashboard.customerStorages;

export const companyData = (state) => state.vermittlerDashboard.companyData;
export const depositMonthly = (state) =>
  state.vermittlerDashboard.depositMonthly;
export const commissionMonthly = (state) =>
  state.vermittlerDashboard.commissionMonthly;
export const goldSilverRatios = (state) =>
  state.vermittlerDashboard.goldSilverRatios;
export const topCustomers = (state) => state.vermittlerDashboard.topCustomers;
export const upcomingBirthdays = (state) =>
  state.vermittlerDashboard.upcomingBirthdays;
export const addActionCodeStep = (state) =>
  state.vermittlerDashboard.addActionCodeStep;
export const actionCodeList = (state) =>
  state.vermittlerDashboard.actionCodeList;

export default vermittlerDashboardSlice.reducer;
