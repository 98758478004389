import React, { useState } from 'react';
import { TimerActiveButton } from '..';
import InputCode from '../../home/common/inputCode'
import { useTranslation } from "react-i18next";
import { Box, Button, Typography } from '@mui/material';

function ActivationCodeHandler({time, loading, activationCode, setActivationCode, onEnter, resendCode}) {
  const { t } = useTranslation();
  const [showResendButton, setShowResendButton] = useState(false); // Hide resend button by default because the timer causes panic

  return <>
    <InputCode
      length={4}
      loading={loading}
      clearMe={!activationCode}
      onComplete={(code) => {
        setActivationCode(code);
      }}
      onEnter={onEnter}
    />
    <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
      {(!showResendButton &&
        <Button variant="text" sx={{ fontSize: "14px" }} onClick={() => { setShowResendButton(true); }}>
          {t("components.verification.resend-code")}
        </Button>
      )}
      { showResendButton && <Typography
        sx={{
          color: "rgba(95, 95, 95, 1)",
          textAlign: "center",
          maxWidth: "400px"
        }}
        variant="subtitle1"
      >
        {t("components.verification.resend-code-explain")}
      </Typography> }
      <TimerActiveButton show={ showResendButton } buttonText={t("components.verification.resend-code-button")} time={time} handleClick={()=>{
        setActivationCode(null);
        resendCode();
      }} />
    </Box>
  </>;
}

export default ActivationCodeHandler;
