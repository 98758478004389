import React from "react";
import Divider from "@mui/material/Divider";
const CustomDivider = ({ title }) => (
  <Divider
    sx={{
      marginTop: "20px",
      marginBottom: "10px",
      color: "rgba(0, 0, 0, 0.38)",
      "&::before": {
        width: 0,
      },
    }}
    textAlign="left"
  >
    {title}
  </Divider>
);

export default CustomDivider;
