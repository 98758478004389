import React, { useReducer, useContext } from "react";

import { LOGIN_INFO_TYPE } from "../type";

import LoginContext from "./loginContext";
import LoginReducer from "./loginReducer";

export const LoginStateProvider = (props) => {
  const initState = {
    loginInfo: null,
  };

  const [state, dispatch] = useReducer(LoginReducer, initState);

  // Actions

  const loginInfoAction = (value) => {
    dispatch({
      type: LOGIN_INFO_TYPE,
      payload: value,
    });
  };

  return (
    <LoginContext.Provider
      value={{
        loginInfo: state.loginInfo,
        loginInfoAction,
      }}
    >
      {props.children}
    </LoginContext.Provider>
  );
};

export const useLoginState = () => useContext(LoginContext);
