import React from 'react'
import {Typography} from '@mui/material'
import { useState,useEffect } from 'react';


function ColorizeIncreaseAndDecrease({status,children,variant,component,sx,props}) {
  const [color,setColor]=useState('initial')
  
  const statusColor=()=>{
    if(status==='up'){
      setColor('green')
    }
    else if(status==='down'){
      setColor('red')
    }
    else {
      setColor('initial')
    }
  }
  useEffect(()=>{
    statusColor()
  },[])
  return (
    <>
      <Typography variant={variant} component={component} sx={{color:color,...sx}} {...props}> 
          {children}
      </Typography>
    </>
  )
}

export default ColorizeIncreaseAndDecrease