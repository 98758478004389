import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { TOKEN_NAME, WORKSPACE_ID } from "../../../core/apiconfig/constantApi";
import { longDateTo10 } from "../../../core/utils/convert";
import { BASE_URL } from "../../../core/apiconfig/environment";

const postActionCodeAction = createAsyncThunk(
  "vermittlerDashboard/postActionCode",
  async (dataParams, { getState }) => {
    const {
      discount,
      allowRegisterCount,
      startDate,
      endDate,
      actionCodeType,
      validForever,
      usersUnLimited,
      //loyaltyMonthes,
      description,
    } = dataParams;
    console.log(discount);
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${localStorage.getItem(TOKEN_NAME)}`,
      },
    };

    const saveObj = {
      arades_startdatum: validForever == "false" ? longDateTo10(startDate) : null,
      arades_enddatum: validForever == "false" ? longDateTo10(endDate) : null,
      arades_typ: 100000000,
      arades_maximum_anzahl: usersUnLimited == "false" ? allowRegisterCount : null,
      arades_prozent: discount,
      arades_typ: actionCodeType,
      arades_aktionscode_limitiert: usersUnLimited == "false",
      arades_aktionscode_unbegrenzt_gueltig: validForever == "true",
      arades_anlas: description,
    };
    const result = await axios.post(`${BASE_URL}data/action-codes/account/${localStorage.getItem(WORKSPACE_ID)}/`, saveObj, config);

    if (result.data.status != 201)
      throw Error("Aktionscode konnte nicht erstellt werden"); //("Could not create action code");

    return {
      actionCode: result.data.data.arades_name,
      discount,
      allowRegisterCount,
      registeredCount: 0,
      startDate,
      endDate,
      actionCodeType,
    };
  }
);

export default postActionCodeAction;
