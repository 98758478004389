import React from "react";
import CustomerDashboard from "./customer/customerDashboard";
import VermittlerDashboardStepManager from "./vermittler/vermittlerDashboard.step.manager";
import { WORKSPACETYPE } from "../../core/constants";
import { WORKSPACE_TYPE } from "../../core/apiconfig/constantApi";

const Dashboard = (props) => {
  return (
    <>
      {localStorage.getItem(WORKSPACE_TYPE) ===
      WORKSPACETYPE.SELLER_ACCOUNT_VERIFIED ? (
        <VermittlerDashboardStepManager />
      ) : (
        <CustomerDashboard />
      )}
    </>
  );
};

export default Dashboard;
