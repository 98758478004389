import React, { useState, useEffect } from "react";
import { Box, Typography, Button, Tab } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { URL } from "../../core/constants";
import DataGrid, { Column, Paging } from "devextreme-react/data-grid";
import { useDispatch, useSelector } from "react-redux";
import { lists } from "../../redux/slices/powerOfAttorney/powerOfAttorney.slice";
import {
  getReceivedPowerOfAttorneyListAction,
  getGrantedPowerOfAttorneyListAction,
  cancelPowerOfAttorneyAction,
} from "../../redux/slices/powerOfAttorney";

const PowerOfAttorney = () => {
  const [currentTab, setCurrentTab] = useState("1");
  const dispatch = useDispatch();
  const listsData = useSelector(lists);
  const { t } = useTranslation();

  const handleTabChange = (event, newValue) => {
    setCurrentTab(newValue);
  };
  useEffect(() => {
    dispatch(getReceivedPowerOfAttorneyListAction());
    dispatch(getGrantedPowerOfAttorneyListAction());
  }, []);

  return (
    <Box className="container-card">
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          mb: "20px",
        }}
      >
        <Typography sx={{ fontWeight: 500 }} variant="h5" component="h5">
          Power of attorney
        </Typography>
        <Button
          component={Link}
          to={"/" + URL.POWER_OF_ATTORNEY_ADD}
          fullWidth
          sx={{
            bgcolor: "info.main",
            maxWidth: "192px",
            "&:hover": {
              color: "#fff",
            },
          }}
          variant="contained"
        >
          Create new
        </Button>
      </Box>
      <TabContext value={currentTab}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList
            sx={{
              "& .MuiButtonBase-root.Mui-selected": {
                color: "info.main",
              },
              "& .MuiTabs-indicator": {
                bgcolor: "info.main",
              },
            }}
            onChange={handleTabChange}
            aria-label="lab API tabs example"
          >
            <Tab label="RECEIVED" value="1" />
            <Tab label="GRANTED" value="2" />
          </TabList>
        </Box>
        <TabPanel value="1">
          <Box mt="20px">
            <DataGrid
              dataSource={listsData.receivedPowerOfAttorneyList}
              keyExpr="id"
              columnAutoWidth={true}
              showBorders={true}
              noDataText={t("general.no-data")}
            >
              <Column
                dataField="storageOwner"
                caption="Lagerplatzinhaber"
                allowResizing={true}
                allowSorting={false}
              />
              <Column
                dataField="storageNumber"
                caption="Lagerplatz Nr"
                allowResizing={true}
                allowSorting={false}
                cellRender={(colInfo) => {
                  return (
                    <Typography variant="caption">
                      {" "}
                      {colInfo.data.storageNumber}{" "}
                    </Typography>
                  );
                }}
              />
              <Column
                dataField="status"
                caption="Status"
                allowResizing={true}
                allowSorting={true}
                cellRender={(colInfo) => {
                  return (
                    <Typography
                      sx={{
                        color:
                          colInfo.data.status === "Expired"
                            ? "rgba(229, 57, 53, 1)"
                            : "rgba(0, 0, 0, 0.6)",
                      }}
                      variant="caption"
                    >
                      {" "}
                      {colInfo.data.status}{" "}
                    </Typography>
                  );
                }}
              />
              <Column
                dataField="receivedAt"
                caption="Erhalten am"
                allowResizing={true}
                allowSorting={false}
                cellRender={(colInfo) => {
                  return (
                    <Typography variant="caption">
                      {" "}
                      {colInfo.data.receivedAt}{" "}
                    </Typography>
                  );
                }}
              />
              <Column
                dataField="returnOn"
                caption="Zurück gegeben am"
                allowResizing={true}
                allowSorting={false}
                cellRender={(colInfo) => {
                  return (
                    <Typography variant="caption">
                      {" "}
                      {colInfo.data.returnOn}{" "}
                    </Typography>
                  );
                }}
              />
              <Paging defaultPageSize={5} />
            </DataGrid>
          </Box>
        </TabPanel>
        <TabPanel value="2">
          <Box mt="20px">
            <DataGrid
              dataSource={listsData.grantedPowerOfAttorneyList}
              keyExpr="id"
              columnAutoWidth={true}
              showBorders={true}
              noDataText={t("general.no-data")}
            >
              <Column
                dataField="authorizedRepresentative"
                caption="Bevollmächtigter"
                allowResizing={true}
                allowSorting={false}
              />
              <Column
                dataField="storageNumber"
                caption="Lagerplatz Nr"
                allowResizing={true}
                allowSorting={false}
                cellRender={(colInfo) => {
                  return (
                    <Typography variant="caption">
                      {" "}
                      {colInfo.data.storageNumber}{" "}
                    </Typography>
                  );
                }}
              />
              <Column
                dataField="status"
                caption="Status"
                allowResizing={true}
                allowSorting={true}
                cellRender={(colInfo) => {
                  return (
                    <Typography
                      sx={{
                        color:
                          colInfo.data.status === "Expired"
                            ? "rgba(229, 57, 53, 1)"
                            : "rgba(0, 0, 0, 0.6)",
                      }}
                      variant="caption"
                    >
                      {" "}
                      {colInfo.data.status}{" "}
                    </Typography>
                  );
                }}
              />
              <Column
                dataField="receivedAt"
                caption="Erhalten am"
                allowResizing={true}
                allowSorting={false}
                cellRender={(colInfo) => {
                  return (
                    <Typography variant="caption">
                      {" "}
                      {colInfo.data.receivedAt}{" "}
                    </Typography>
                  );
                }}
              />
              <Column
                dataField="returnOn"
                caption="Zurück gegeben am"
                allowResizing={true}
                allowSorting={false}
                cellRender={(colInfo) => {
                  return (
                    <Typography variant="caption">
                      {" "}
                      {colInfo.data.returnOn}{" "}
                    </Typography>
                  );
                }}
              />

              <Column
                type="buttons"
                width={110}
                cellRender={(colInfo) => {
                  return (
                    <Button
                      onClick={() =>
                        dispatch(cancelPowerOfAttorneyAction(colInfo.data.id))
                      }
                      size="small"
                      color="error"
                      variant="outlined"
                    >
                      ABBRECHEN
                      {/* Cancel */}
                    </Button>
                  );
                }}
              />

              <Paging defaultPageSize={5} />
            </DataGrid>
          </Box>
        </TabPanel>
      </TabContext>
    </Box>
  );
};

export default PowerOfAttorney;
